import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import { SendOutlined } from '@ant-design/icons';

let popConfirm;

const ResendReceiptEmailButton = ({ children, disabled = false, bookingId, resendReceipt }) => {
  useEffect(() => {
    if (popConfirm) {
      return () => {
        popConfirm.destroy();
      };
    }
  }, []);

  return (
    <Button
      icon={<SendOutlined/>}
      type="primary"
      disabled={disabled}
      onClick={async() => {
        popConfirm = Modal.confirm({
          title: 'Confirm',
          content: '領収書を再送します',
          okText: '送信',
          cancelText: 'キャンセル ',
          onOk: async() => {
            await resendReceipt({ bookingId });
            console.log('ok');
          },
          width: '600px'
        });
      }}
      // disabled={disabled}
    >
      {children || 'Resend Receipt'}
    </Button>
  );
};

ResendReceiptEmailButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any,
  bookingId: PropTypes.string,
  resendReceipt: PropTypes.func
};

export default ResendReceiptEmailButton;