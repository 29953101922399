/* eslint-disable react/display-name */
import _ from 'lodash';
import moment from 'moment';
import qs from 'querystring';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  Button,
  Row,
  Col,
  Statistic,
  Space,
  Tag,
  Popconfirm,
  Avatar
} from 'antd';
import { StopOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';

import FilterForm from './FilterForm';
import BigPageHeader from 'components/PageHeader';
import { formatYen } from 'utils/stringFormat';

import './styles.scss';

const Bread = BigPageHeader.Bread;

const GoToNail = ({ pathname, replace, search, ...props }) => {
  const [searchState, setSearchState] = useState({
    page: Number(search.p) || 1,
    limit: Number(search.l) || 10,
    dateFrom: search.f || moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: search.t || moment().endOf('month').format('YYYY-MM-DD'),
    orderBy: search.ob,
    order: search.o
  });

  const getGoToNailList = () => {
    const eventId = _.get(props.goToNailInfo, 'eventId');
    if (eventId) {
      const params = _.pick(searchState, [
        'page',
        'limit',
        'dateFrom',
        'dateTo',
        'orderBy',
        'order'
      ]);
      props.getGoToNailList({ ...params, eventId });
    }
  };

  const handleExportCsv = () => {
    const eventId = _.get(props.goToNailInfo, 'eventId');
    if (eventId) {
      const params = _.pick(searchState, [
        'page',
        'limit',
        'dateFrom',
        'dateTo',
        'orderBy',
        'order'
      ]);
      props.exportGoToNailList({ ...params, eventId });
    }
  };

  const handleStopGoToNail = async() => {
    const eventId = _.get(props.goToNailInfo, 'eventId');
    if (eventId) {
      await props.updateGoToNail({ eventId, status: 'INACTIVE' });
      props.getGoToNailInfo();
    }
  };

  useEffect(() => {
    props.getGoToNailInfo();
  }, []);

  useEffect(() => {
    getGoToNailList();
  }, [searchState, props.goToNailInfo.eventId]);

  const onFilterChange = (value) => {
    const { dateRange } = value;

    const parsed = {
      ...search,
      p: 1
    };

    const dateFrom =
      dateRange && dateRange[0]
        ? moment(dateRange[0]).format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const dateTo =
      dateRange && dateRange[1]
        ? moment(dateRange[1]).format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
    parsed.f = dateFrom;
    parsed.t = dateTo;

    replace(`${pathname}?${qs.stringify(parsed)}`);

    setSearchState((prevState) => ({
      ...prevState,
      dateFrom,
      dateTo,
      page: 1
    }));
  };

  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...search,
      p: pagination.current,
      l: pagination.pageSize,
      ob: sorter.field,
      o: sorter.order
    };

    replace(`${pathname}?${qs.stringify(parsed)}`);

    setSearchState((prevState) => ({
      ...prevState,
      page: Number(pagination.current),
      limit: Number(pagination.pageSize),
      orderBy: sorter.field,
      order: sorter.order
    }));
  };

  const filterFormInitialValues = {};
  if (searchState.dateFrom && searchState.dateTo) {
    filterFormInitialValues.dateRange = [
      moment(searchState.dateFrom),
      moment(searchState.dateTo)
    ];
  }

  const columns = [
    {
      title: 'ユーザーネーム',
      key: 'customer',
      render: (record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              icon={<UserOutlined />}
              src={_.get(record, 'userAvatar')}
            />
            <div style={{ marginLeft: '8px' }}>
              {record.userName}
            </div>
          </div>
        );
      }
    
    },
    {
      title: 'クーポンコード',
      key: 'couponCode',
      dataIndex: 'couponCode',
      render: (record) => (
        <Tag color={record === 'GT5000' ? 'pink' : 'blue'}>{record}</Tag>
      ),
      sorter: true,
      sortOrder: searchState.orderBy === 'couponCode' && searchState.order
    },
    {
      title: '有効クーポン数',
      key: 'remaining',
      render: (record) => {
        const expired = _.find(record.statusCount, (o) => o.status === 'EXPIRED');        
        if (expired !== undefined) {
          return `${_.get(expired, 'count', 0)} (Expired)`;
        }
        return _.get(_.find(record.statusCount, (o) => o.status === 'ACTIVE'), 'count', 0);
      }
    },
    {
      title: 'クーポン利用回数',
      key: 'used',
      render: (record) => _.get(_.find(record.statusCount, (o) => o.status === 'USED'), 'count', 0)
    },
    {
      title: 'クーポン割引金額',
      key: 'total',
      render: (record) => formatYen(_.get(_.find(record.statusCount, (o) => o.status === 'USED'), 'count', 0) * _.get(record, 'couponValue', 0))
    }
  ];

  const eventId = _.get(props.goToNailInfo, 'eventId');
  const couponList = _.get(props.goToNailInfo, 'couponList', []);
  const issuedAmount = _.get(props.goToNailInfo, 'issuedAmount');
  const campaignStatus = _.get(props.goToNailInfo, 'status');
  const budget = _.get(props.goToNailInfo, 'budget');
  const usedAmount = _.get(props.goToNailInfo, 'usedAmount');

  const columnSpan = Math.floor(24 / (couponList.length + 2));

  return (
    <>
      <Helmet>
        <title>Go-to-nail</title>
      </Helmet>
      <BigPageHeader title="Go-to-nail" breadcrumb={Bread.goToNail} />
      <Card bordered={false} className="styled-transparent">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card bordered={false} className="campaign-info">
            <Row gutter={24} type="flex">
              <Col span={columnSpan}>
                <Statistic
                  title="クーポン発行回数"
                  value={
                    issuedAmount !== undefined ? formatYen(issuedAmount) : '--'
                  }
                />
                <div className="detail-statistic">
                {
                  _.map(couponList, (i, index) => {
                    const code = _.get(i, 'code', '--');
                    const issuedCount = _.get(i, 'issuedCount', '--');
                    return <span>
                      {index > 0 ? ' + ' : ''}
                      ({code} x {issuedCount})
                    </span>
                })}
                </div>
                <em />
              </Col>
              <Col span={columnSpan}>
                <Statistic
                  title="予算"
                  value={
                    issuedAmount !== undefined && budget !== undefined
                      ? formatYen(budget - issuedAmount)
                      : '--'
                  }
                />
                <em />
              </Col>
              <Col span={24 - (couponList.length + 1) * columnSpan}>
                <Statistic
                  title="クーポン利用回数"
                  value={usedAmount !== undefined ? formatYen(usedAmount) : '--'}
                />
              </Col>
            </Row>
          </Card>
          <span>
            <Card bordered={false}>
              <div className="search-section">
                <FilterForm
                  onSubmit={onFilterChange}
                  initialValues={filterFormInitialValues}
                />
                <div style={{ flexGrow: 1 }} />
                <Space>
                  <Button
                    disabled={eventId === undefined}
                    loading={props.goToNailActionLoading}
                    icon={<DownloadOutlined />}
                    onClick={handleExportCsv}
                  >
                    データのエクスポート
                  </Button>
                  {!props.goToNailDataLoading &&
                    (campaignStatus === 'ACTIVE' ? (
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={handleStopGoToNail}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          disabled={eventId === undefined}
                          danger
                          type="primary"
                          loading={props.goToNailActionLoading}
                          icon={<StopOutlined />}
                        >
                          Stop Campaign
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button disabled type="link" icon={<StopOutlined />}>
                        Campaign has been stopped
                      </Button>
                    ))}
                </Space>
              </div>
            </Card>
            <Card bordered={false}>
              <Table
                columns={columns}
                dataSource={props.goToNailList}
                scroll={{ x: true }}
                onChange={onTableChange}
                loading={props.goToNailDataLoading || eventId === undefined}
                size="small"
                pagination={{
                  current: searchState.page,
                  pageSize: searchState.limit,
                  showSizeChanger: false,
                  total: props.goToNailTotal,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                }}
              />
            </Card>
          </span>
        </Space>
      </Card>
    </>
  );
};

GoToNail.propTypes = {
  getGoToNailInfo: PropTypes.func.isRequired,
  getGoToNailList: PropTypes.func.isRequired,
  exportGoToNailList: PropTypes.func.isRequired,
  updateGoToNail: PropTypes.func.isRequired,
  goToNailInfo: PropTypes.array,
  goToNailList: PropTypes.array,
  goToNailTotal: PropTypes.number,
  goToNailDataLoading: PropTypes.bool,
  goToNailActionLoading: PropTypes.bool,
  pathname: PropTypes.string,
  search: PropTypes.any,
  replace: PropTypes.func,
  location: PropTypes.any
};

export default GoToNail;
