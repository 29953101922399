import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AnnouncementList from './AnnouncementList';
import CreateAnnouncement from './CreateAnnouncement';
import EditAnnouncement from './EditAnnouncement';

const ANNOUNCEMENT_ROOT = '/dashboard/announcement';

const RedirectToAnnouncementList = () => (
  <Redirect to={ANNOUNCEMENT_ROOT} />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Announcement</title>
      </Helmet>
      <Switch>
        <Route
          path={ANNOUNCEMENT_ROOT}
          exact
          component={AnnouncementList}
        />
        <Route
          path='/dashboard/announcement/create'
          exact
          component={CreateAnnouncement}
        />
        <Route
          path='/dashboard/announcement/edit/:objectId'
          exact
          component={EditAnnouncement}
        />
        <Route path="/dashboard/announcement" component={RedirectToAnnouncementList} />
      </Switch>
    </>
  );
};

export default Router;
