export const FETCH_SALON_LIST = 'FETCH_SALON_LIST';
export const FETCH_SALON_LIST_SUCCESS = 'FETCH_SALON_LIST_SUCCESS';
export const RESET = 'RESET';

export const CREATE_SALON = 'CREATE_SALON';
export const CREATE_SALON_SUCCESS = 'CREATE_SALON_SUCCESS';
export const CREATE_SALON_FAIL = 'CREATE_SALON_FAIL';

export const SET_SALON_SEARCH_LOADING = 'SET_SALON_SEARCH_LOADING';

export const FETCH_SALON_PROFILE = 'FETCH_SALON_PROFILE';
export const FETCH_SALON_PROFILE_SUCCESS = 'FETCH_SALON_PROFILE_SUCCESS';
export const FETCH_SALON_PROFILE_FAIL = 'FETCH_SALON_PROFILE_SUCCESS_FAIL';
export const UNSET_SALON_PROFILE = 'UNSET_SALON_PROFILE';
export const UNLINK_STAFF = 'UNLINK_STAFF';
export const UNLINK_STAFF_SUCCESS = 'UNLINK_STAFF_SUCCESS';
export const UNLINK_STAFF_FAIL = 'UNLINK_STAFF_FAIL';

// Upload Image
export const UPLOAD_SALON_IMAGE = 'UPLOAD_SALON_IMAGE';
export const UPLOAD_SALON_IMAGE_SUCCESS = 'UPLOAD_SALON_IMAGE_SUCCESS';
export const UPLOAD_SALON_IMAGE_FAIL = 'UPLOAD_SALON_IMAGE_FAIL';

//Update salon profile
export const UPDATE_SALON_PROFILE = 'UPDATE_SALON_PROFILE';
export const UPDATE_SALON_PROFILE_SUCCESS = 'UPDATE_SALON_PROFILE_SUCCESS';
export const UPDATE_SALON_PROFILE_FAIL = 'UPDATE_SALON_PROFILE_FAIL';

//Update salon email
export const UPDATE_SALON_EMAIL = 'UPDATE_SALON_EMAIL';
export const UPDATE_SALON_EMAIL_SUCCESS = 'UPDATE_SALON_EMAIL_SUCCESS';
export const UPDATE_SALON_EMAIL_FAIL = 'UPDATE_SALON_EMAIL_FAIL';

// Get address by postal code
export const GET_ADDRESS_BY_POST_CODE = 'GET_ADDRESS_BY_POST_CODE';
export const GET_ADDRESS_BY_POST_CODE_SUCCESS = 'GET_ADDRESS_BY_POST_CODE_SUCCESS';
export const GET_ADDRESS_BY_POST_CODE_FAIL = 'GET_ADDRESS_BY_POST_CODE_FAIL';

// Get all Areas
export const GET_ALL_AREAS = 'GET_ALL_AREAS';
export const GET_ALL_AREAS_SUCCESS = 'GET_ALL_AREAS_SUCCESS';
export const GET_ALL_AREAS_FAIL = 'GET_ALL_AREAS_FAIL';

// Get all properties
export const GET_ALL_PROPERTIES = 'GET_ALL_PROPERTIES';
export const GET_ALL_PROPERTIES_SUCCESS = 'GET_ALL_PROPERTIES_SUCCESS';
export const GET_ALL_PROPERTIES_FAIL = 'GET_ALL_PROPERTIES_FAIL';

export const RESET_SALON_PROVIDER = 'RESET_SALON_PROVIDER';