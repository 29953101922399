import produce from 'immer';

import { SET_ACTIVE_MENU } from './constants';

const initialState = {
  activeMenu: '/dashboard'
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      state.activeMenu = action.payload.newActiveMenu;
      return state;
    default:
      return state;
  }
});

export default reducer;
