import _ from 'lodash';

export const makeSearchCustomerList = (state) => {
  return _.get(state, 'customer.search');
};
export const makeCustomerProfile = (state) => {
  return _.get(state, 'customer.profile');
};
export const makeCustomerPointInfo = (state) => {
  return _.get(state, 'customer.pointInfo');
};
export const makeCustomerPointHistory = (state) => {
  return _.get(state, 'customer.pointHistory');
};