// import _ from 'lodash';
import moment from 'moment';
const columns = {};

columns.createdAt = {
  title: '作成日',
  width: 200,
  dataIndex: '_created_at',
  render: (record) => moment(record).format('HH:mm YYYY-MM-DD'),
  key: 'createdAt',
  defaultSortOrder: 'descend',
  sorter: true,
};
columns.name = {
  title: 'カスタマー',
  key: 'userName',
  width: 320,
};
columns.points = {
  title: 'ポイント',
  dataIndex: 'point',
  key: 'point',
  render: (record) => record > 0 ? `+${record}` : record,
  width: 100
};
columns.title = {
  title: 'タイトル',
  dataIndex: 'title',
  key: 'title',
  width: 280
};
columns.content = {
  title: 'ポップアップテキスト',
  dataIndex: 'content',
  key: 'content',
  width: 350
};
columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  width: 180
};
columns.id = {
  title: 'カスタマーID',
  dataIndex: 'userId',
  key: 'userId',
  width: 180
}

export default columns;