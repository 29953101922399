export const DELETE_SUPPORT_TICKET = 'DELETE_SUPPORT_TICKET';
export const DELETE_UNANSWERED_TICKET_SUCCESS = 'DELETE_UNANSWERED_TICKET_SUCCESS';
export const DELETE_UNANSWERED_TICKET_FAILED = 'DELETE_UNANSWERED_TICKET_FAILED';
export const DELETE_ANSWERED_TICKET_SUCCESS = 'DELETE_ANSWERED_TICKET_SUCCESS';
export const DELETE_ANSWERED_TICKET_FAILED = 'DELETE_ANSWERED_TICKET_FAILED';

export const SET_IS_READ_TICKETS = 'SET_IS_READ_TICKETS';
export const REPLY_TICKET = 'REPLY_TICKET';
export const REPLY_TICKET_SUCCESS = 'REPLY_TICKET_SUCCESS';
export const REPLY_TICKET_FAILED = 'REPLY_TICKET_FAILED';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';

export const GET_SUPPORT_FOR_SALON_ANSWERED = 'GET_SUPPORT_FOR_SALON_ANSWERED';
export const GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS = 'GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS';
export const GET_SUPPORT_FOR_SALON_UNANSWERED = 'GET_SUPPORT_FOR_SALON_UNANSWERED';
export const GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS = 'GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS';
export const SET_SALON_ANSWERED_LOADING = 'SET_SALON_ANSWERED_LOADING';
export const SET_SALON_UNANSWERED_LOADING = 'SET_SALON_UNANSWERED_LOADING';
export const COUNT_UNREAD_SALON_TICKETS = 'COUNT_UNREAD_SALON_TICKETS';
export const COUNT_UNREAD_SALON_TICKETS_SUCCESS = 'COUNT_UNREAD_SALON_TICKETS_SUCCESS';
export const SET_FILTER_SALON_ANSWERED = 'SET_FILTER_SALON_ANSWERED';
export const SET_FILTER_SALON_UNANSWERED = 'SET_FILTER_SALON_UNANSWERED';

export const GET_SUPPORT_FOR_WEB_ANSWERED = 'GET_SUPPORT_FOR_WEB_ANSWERED';
export const GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS = 'GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS';
export const GET_SUPPORT_FOR_WEB_UNANSWERED = 'GET_SUPPORT_FOR_WEB_UNANSWERED';
export const GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS = 'GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS';
export const SET_WEB_ANSWERED_LOADING = 'SET_WEB_ANSWERED_LOADING';
export const SET_WEB_UNANSWERED_LOADING = 'SET_WEB_UNANSWERED_LOADING';
export const COUNT_UNREAD_WEB_TICKETS = 'COUNT_UNREAD_WEB_TICKETS';
export const COUNT_UNREAD_WEB_TICKETS_SUCCESS = 'COUNT_UNREAD_WEB_TICKETS_SUCCESS';
export const SET_FILTER_WEB_ANSWERED = 'SET_FILTER_WEB_ANSWERED';
export const SET_FILTER_WEB_UNANSWERED = 'SET_FILTER_WEB_UNANSWERED';

export const GET_SUPPORT_FOR_APP_ANSWERED = 'GET_SUPPORT_FOR_APP_ANSWERED';
export const GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS = 'GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS';
export const GET_SUPPORT_FOR_APP_UNANSWERED = 'GET_SUPPORT_FOR_APP_UNANSWERED';
export const GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS = 'GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS';
export const SET_APP_ANSWERED_LOADING = 'SET_APP_ANSWERED_LOADING';
export const SET_APP_UNANSWERED_LOADING = 'SET_APP_UNANSWERED_LOADING';
export const COUNT_UNREAD_APP_TICKETS = 'COUNT_UNREAD_APP_TICKETS';
export const COUNT_UNREAD_APP_TICKETS_SUCCESS = 'COUNT_UNREAD_APP_TICKETS_SUCCESS';
export const SET_FILTER_APP_ANSWERED = 'SET_FILTER_APP_ANSWERED';
export const SET_FILTER_APP_UNANSWERED = 'SET_FILTER_APP_UNANSWERED';

export const GET_SUPPORT_FOR_VERIFICATION_ANSWERED = 'GET_SUPPORT_FOR_VERIFICATION_ANSWERED';
export const GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS = 'GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS';
export const GET_SUPPORT_FOR_VERIFICATION_UNANSWERED = 'GET_SUPPORT_FOR_VERIFICATION_UNANSWERED';
export const GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS = 'GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS';
export const SET_VERIFICATION_ANSWERED_LOADING = 'SET_VERIFICATION_ANSWERED_LOADING';
export const SET_VERIFICATION_UNANSWERED_LOADING = 'SET_VERIFICATION_UNANSWERED_LOADING';
export const COUNT_UNREAD_VERIFICATION_TICKETS = 'COUNT_UNREAD_VERIFICATION_TICKETS';
export const COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS = 'COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS';
export const SET_FILTER_VERIFICATION_ANSWERED = 'SET_FILTER_VERIFICATION_ANSWERED';
export const SET_FILTER_VERIFICATION_UNANSWERED = 'SET_FILTER_VERIFICATION_UNANSWERED';

export const GET_REPORT_LIST = 'GET_REPORT_LIST';
export const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS';
export const GET_REPORT_LIST_BY_USER = 'GET_REPORT_LIST_BY_USER';

export const UNLINK_PHONE_AUTH = '@support/UNLINK_PHONE_AUTH';
export const UNLINK_PHONE_AUTH_SUCCESS = '@support/UNLINK_PHONE_AUTH_SUCCESS';
export const UNLINK_PHONE_AUTH_FAIL = '@support/UNLINK_PHONE_AUTH_FAIL';
