import axios from 'axios';

export default function searchSalonLocation(address) {
  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const getPostCodeAPI = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&region=JP&language=ja&key=${googleMapKey}`;
  return axios
    .get(getPostCodeAPI)
    .then((res) => {
      let postCodeData = {};
      postCodeData = res?.data?.results[0]?.geometry?.location;
      return postCodeData;
    })
    .catch((err) => {
      console.log('error:', err);
    });
}
