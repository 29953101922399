import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Space } from 'antd';
import './styles.scss';
const { TextArea } = Input;
const ReasonDetailInput = ({ statusAction, modalContent, close, submitStatusThenFetchData }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Form
      form={form}
      validateTrigger={['onBlur', 'onChange']}
      onFinish={async(value) => {
        setIsLoading(true);
        if (statusAction === 'ACTIVE' && value?.reason_delete_user.trim() === '') {
          form.resetFields();
          form.validateFields();
          setIsLoading(false);
          throw 'prevent';
        }

        await submitStatusThenFetchData();
        await setIsLoading(false);
      }}
    >
      <p>
        {statusAction === 'ACTIVE' && <span style={{ color: 'red' }}>*</span>}
        {modalContent}</p>
      {statusAction === 'ACTIVE' &&
        <Form.Item style={{ width: '100%' }}
          label=''
          name='reason_delete_user'
          rules={[{ required: true, message: '最初に強制削除の詳細を入力してください' }]}
        >
          <TextArea
            style={{ maxWidth: '100%' }}
            maxLength={200}
            showCount
            id='reason_delete_user'
            rows={4}
          />
        </Form.Item>
      }

      <div style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={close} type ='clear'>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                OK
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
ReasonDetailInput.propTypes = {
  statusAction: PropTypes.string,
  modalContent: PropTypes.string,
  close: PropTypes.func,
  submitStatusThenFetchData: PropTypes.func

};

export default ReasonDetailInput;