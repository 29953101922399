// Checked
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const CustomBreadcrumb = ({ breadcrumb = [] }) => {
  _.isFunction(breadcrumb) && (breadcrumb = breadcrumb());

  return (
    <Breadcrumb style={{ margin: '0 0 8px' }}>
      {breadcrumb.map((crumb, i) =>
        (crumb.to && i != breadcrumb.length - 1 ? (
          <Breadcrumb.Item key={crumb.to}>
            <Link to={crumb.to}>{crumb.label}</Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={crumb.to}>
            <Text>{crumb.label}</Text>
          </Breadcrumb.Item>
        ))
      )}
    </Breadcrumb>
  );
};

CustomBreadcrumb.propTypes = {
  breadcrumb: PropTypes.any
};

export default CustomBreadcrumb;
