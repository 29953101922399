import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';

import { useRouteMatch } from 'react-router-dom';
import { Card, Descriptions, Avatar, Tag, Modal, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useUserLastCheckin } from '../hooks/useUserLastCheckin.js';
import './styles.scss';
import { USER_STATUS } from 'utils/constants/index.js';
import { getContentByStatusType, getStatusParam } from './getContentByActionType.js';
import { splitInfor } from 'utils/common.js';
import ReasonDetailInput from './ReasonDetailInput.js';
import useEditCustomerInfo from '../hooks/useEditCustomerInfo';
import VerifyEmailButton from '../components/VerifyEmailButton';

export const HIDDEN_PROPS = { style: { display: 'none' } };
const Profile = ({
  profile,
  fetchCustomerProfile,
  updateUserStatus,
  currentModalControl
}) => {
  const routeMatch = useRouteMatch();
  const { customerId } = routeMatch.params;
  const cusInfor = _get(profile, 'info');
  const gender = cusInfor?.gender === 1 ? 'FEMALE' : 'MALE';
  const birthday = cusInfor?.birthday ? moment(cusInfor.birthday).format('YYYY-MM-DD') : ' -- ';
  const createdAt = moment(cusInfor?._created_at).format('YYYY-MM-DD HH:mm');
  const status = cusInfor?.deleteType || cusInfor?.status;
  const isOnlyEditMinimumLoginData = cusInfor?.status === 'PENDING' || cusInfor?.isDeleted;
  const [openEditCustomerInfoPopup, EditCustomerInfoPopup] =
    useEditCustomerInfo(cusInfor, customerId, isOnlyEditMinimumLoginData);

  const showConfirmChangeStatus = (statusAction) => {
    const { modalTitle, modalContent } = getContentByStatusType(statusAction);
    return new Promise((resolve) => {
      currentModalControl = Modal.confirm({
        title: modalTitle,
        okButtonProps: HIDDEN_PROPS,
        cancelButtonProps: HIDDEN_PROPS,
        content: <ReasonDetailInput
          statusAction={statusAction}
          modalContent={modalContent}
          close={() => currentModalControl.destroy()}
          submitStatusThenFetchData={() => {
            const { statusParam } = getStatusParam(statusAction);
            let reasonDetail = document.getElementById('reason_delete_user');
            if (statusAction === 'ACTIVE') {
              reasonDetail = reasonDetail?.value;

            } else {
              reasonDetail = 'Active user';
            }

            return updateUserStatus({
              reason: '強制退会',
              userId: customerId,
              role: 'customers',
              status: statusParam,
              detail: reasonDetail
            }).then(() => {
              fetchCustomerProfile(customerId);
              currentModalControl.destroy();
              resolve(true);
            }).catch(() => {
              currentModalControl.destroy();
            });
          }}
        />,

        // onCancel: () => reject(), // todo: fix promise mem leak
        width: '600px'
      });
    });
  };

  return (
    <Card title='ユーザー情報' column={1} className='customer-profile-tab'
      extra={<Button type='primary' onClick={openEditCustomerInfoPopup}>Edit</Button>}>
      <div id="customer-profile">
        <Descriptions column={1} bordered >
          <Descriptions.Item label="Avatar">
            <Avatar
              size={90}
              shape="square"
              icon={<UserOutlined />}
              src={_get(cusInfor, 'avatar.data')}
            />
          </Descriptions.Item>
          <Descriptions.Item label="ID">{cusInfor?._id || ' -- '}</Descriptions.Item>
          <Descriptions.Item label="Username">{splitInfor(cusInfor?.username) || '--'}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <span style={status === 'PENDING' ? {} : { cursor: 'pointer' }}>
              <Tag
                onClick={() => {
                  if (status !== 'PENDING') { // can not active user with status PENDING
                    showConfirmChangeStatus(status);
                  }
                }}
                color={USER_STATUS[status]?.color}>{USER_STATUS[status]?.value}</Tag>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Full name">{cusInfor?.fullName || '--'}</Descriptions.Item>
          <Descriptions.Item label="Phonetics">{cusInfor?.phonetic || '--'}</Descriptions.Item>
          <Descriptions.Item label="Gender">{gender}</Descriptions.Item>
          <Descriptions.Item label="Birthday">{birthday}</Descriptions.Item>
          <Descriptions.Item label="Email">
            {splitInfor(cusInfor?.email)}
            <VerifyEmailButton userId={cusInfor?._id} emailVerified={cusInfor?.emailVerified} />
          </Descriptions.Item>
          <Descriptions.Item label="Phone number">{cusInfor?.phone || '--'}</Descriptions.Item>
          <Descriptions.Item label="Current point">{cusInfor?.bookingPoint || '--'}</Descriptions.Item>
          <Descriptions.Item label="Current rank">{_get(cusInfor, 'rankName', 'Bronze')}</Descriptions.Item>
          <Descriptions.Item label="Created account date">{createdAt}</Descriptions.Item>
          <Descriptions.Item label="Last update Platform origin">{useUserLastCheckin(cusInfor)}</Descriptions.Item>
          <Descriptions.Item label="Invitation code">{cusInfor?.invitationCode || '--'}</Descriptions.Item>
        </Descriptions>
      </div>
      {EditCustomerInfoPopup}
    </Card>
  );
};

Profile.propTypes = {
  fetchCustomerProfile: PropTypes.func,
  profile: PropTypes.object,
  updateUserStatus: PropTypes.func,
  currentModalControl: PropTypes.any
};

export default Profile;