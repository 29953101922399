import {
  fetchAnnouncementListSuccess, toggleAnnouncementStatusSuccess, createAnnouncementSuccess,
  updateAnnouncementSuccess, updateAnnouncementFail,
  updateAnnouncementsPositionSuccess, updateAnnouncementsPositionFail
} from './actions';
import {
  FETCH_ANNOUNCEMENT_LIST, TOGGLE_ANNOUNCEMENT_STATUS, CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENTS_POSITION
} from './constants';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import { goBack, replace } from 'connected-react-router';
import request from 'utils/request';
import { message } from 'antd';

function* fetchAnnouncementList(action) {
  const filter = {...action.payload};
  try {
    filter.status = filter.status ? [filter.status] : [];
    filter.showOnRoles = filter.showOnRoles ? [filter.showOnRoles] : undefined;
    const { data } = yield call(request, 'announcement/', { ...filter, limit: 10 }, 'GET');

    yield put(fetchAnnouncementListSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}
function* toggleAnnouncementStatus(action) {
  const { id, status } = action.payload;
  try {
    const { data } = yield call(request, `announcement/${id}/toggleStatus`, { status }, 'PATCH');

    yield put(toggleAnnouncementStatusSuccess({ ...data, id, status }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* createAnnouncement(action) {
  try {
    yield call(request, 'announcement/create', action.payload, 'POST');
    yield put(createAnnouncementSuccess());
    message.success('成功しました！ (お知ら通知のリストにリダイレクト中)');
    yield put(replace('/dashboard/announcement'));
  } catch (e) {
    console.log(e);
  }
}

function* updateAnnouncement(action) {
  try {
    yield call(request, `announcement/${action.payload.objectId}`, action.payload, 'POST');
    yield put(updateAnnouncementSuccess());
    message.success('成功しました！ (お知ら通知のリストにリダイレクト中)');
    yield put(goBack());
  } catch (e) {
    yield put(updateAnnouncementFail());
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

function* updateAnnouncementsPos(action) {
  let { oldList, newList } = action?.payload;
  newList = newList.map((r, index) => ({ ...r, id: r.objectId, position: oldList[index].position }));

  try {
    yield put(fetchAnnouncementListSuccess({ list: newList })); // temp list
    yield call(request, 'announcement/position', { announcements: newList }, 'POST');
    yield put(updateAnnouncementsPositionSuccess({}, action.meta));
    message.success('成功しました！');
  } catch (e) {
    yield put(updateAnnouncementsPositionFail({}, action.meta));
    yield put(fetchAnnouncementListSuccess({ list: oldList })); // apply old list
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

export default function* watchAnnouncement() {
  yield takeEvery(FETCH_ANNOUNCEMENT_LIST, fetchAnnouncementList);
  yield takeLeading(TOGGLE_ANNOUNCEMENT_STATUS, toggleAnnouncementStatus);
  yield takeLeading(CREATE_ANNOUNCEMENT, createAnnouncement);
  yield takeLeading(UPDATE_ANNOUNCEMENT, updateAnnouncement);
  yield takeLeading(UPDATE_ANNOUNCEMENTS_POSITION, updateAnnouncementsPos);
}
