import { connect } from 'react-redux';

import { SetPopup } from 'providers/PopupProvider/actions';
import { makePayoutPopup } from 'providers/PopupProvider/selectors';

import { fetchNailistProfile } from 'providers/NailistProvider/actions';
import { makeNailistProfilefromDict } from 'providers/NailistProvider/selectors';

import Popup from './Popup';

const mapStateToProps = (state, ownProps) => ({
  profile: makeNailistProfilefromDict(state, ownProps)
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({ name: 'payout', show: false })),
  fetchNailistProfile: (id) => dispatch(fetchNailistProfile({ id, brief: true }))
});

const PopupLayer1 = connect(mapStateToProps, mapDispatchToProps)(Popup);
const mapStateToPropsLayer1 = (state) => ({
  data: makePayoutPopup(state)
});

export default connect(mapStateToPropsLayer1, null)(PopupLayer1);
