// Libraries
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Divider, Select, Modal, Cascader, Card } from 'antd';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';

// Components
import ImageField from 'components/ImageField';
import SalonEditPlaceOfThePin from './SalonEditPlaceOfThePin';
import BigSpin from 'components/BigSpin';
import SelectAreaForm from './SelectionAreasForm';
import pinMarker from 'assets/images/pin-marker.png';
import useChangeSalonEmail from './useChangeSalonEmail';

// Providers
import searchSalonLocation from 'utils/Api/googleMapAPI';
import {
  getAddressByPostCode,
  getAllProperties,
  updateSalonProfile,
  resetSalonProvider
} from 'providers/SalonProvider/actions';
import {
  getLineList,
  getPrefectureISOTable,
  getStationList,
  resetStationProvider
} from 'providers/StationProvider/actions';
// Utils
import './styles.scss';

const { TextArea } = Input;
const { Option } = Select;

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const hideFullScreenOptions = () => {
  return {
    fullscreenControl: false,
    zoomControl: false
  };
};

const SalonUpdateProfileForm = ({ salon, onCallback }) => {
  // languages
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const showInputPricePopup = useChangeSalonEmail(salon.id, salon?.listEmail?.filter(m => m.email !== salon?.operatorEmail));

  // Constants
  const latitude = _.get(salon, 'location.latLng.lat') || 35.652832;
  const longitude = _.get(salon, 'location.latLng.lng') || 139.839478;
  const station = _.get(salon, 'location.station');
  const stationInitValue = station?.lineId ? [`${station?.lineId}|${station?.lineName}`, `${station?.stationId}|${station?.stationName}`] : [];
  // State
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertiesSelected, setPropertiesSelected] = useState(_.get(salon, 'properties', []).map(item => item.id));
  const [isSalonAreaSelectModal, setIsSalonAreaSelectModal] = useState(false);
  const [lngValue, setLngValue] = useState({ lat: latitude, lng: longitude });
  const [openEditPlaceOfThePin, setOpenEditPlaceOfThePin] = useState(false);
  const [defaultLng, setDefaultLng] = useState({ lat: latitude, lng: longitude });
  const { prefectureISOTable, lineList } = useSelector((state) => state.station);
  const [isSelectedStation, setSelectedStation] = useState(stationInitValue?.length);
  const [isUnpublicAddress, setUnpublicAddress] = useState(salon?.location?.isPrivate);
  const _refFieldPostCode = useRef(null);
  const [currentPostCode, setCurrentPostCode] = useState(_.get(salon, 'location.postCode'));


  // Get prefecture ISO table and the line list first load page
  useEffect(() => {
    (async() => {
      const prefTableTmp = await dispatch(getPrefectureISOTable());
      await getLineListOptions(prefTableTmp);
      const prefectureCodes = prefTableTmp[form.getFieldValue('prefecture')]?.iso;
      const lineValue = `${station?.lineId}|${station?.lineName}`;
      await dispatch(getStationList({ prefectureCodes, lineValue }));
    })();
  }, []);
  
  useEffect(() => {
    form.validateFields();
    dispatch(getAllProperties())
      .then(result => {
        setProperties(result.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  // Reset provider
  useEffect(() => {
    return () => {
      const currentLocation = window.location.pathname;
      setTimeout(() => {
        const newLocation = window.location.pathname;
        if (currentLocation !== newLocation) {
          dispatch(resetSalonProvider());
        }
      }, 400);
    };
  }, []);

  const getLineListOptions = (prefTable = prefectureISOTable) => {
    const prefecture = prefTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    return dispatch(getLineList({ prefectureCodes }));
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  };

  const photosLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };



  const onChangeLineOption = (value, selectedOptions) => {
    if (value.length === 2) {
      setSelectedStation(true);
    } else {
      // behavior is the same Nailie App: Clear those field when select new or clear station
      form.setFieldsValue({ walkingTime: undefined, navigation: '', station: '' });
      setSelectedStation(false);
    }
  };
  // Just show the last item station Name
  const displayStationName = (label) => {
    return label[label.length - 1];
  };

  const loadData = async(selectedOptions) => {
    const prefecture = prefectureISOTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    const lineValue = selectedOptions[0]?.value;
    const target = _.keyBy(lineList, 'value');
    const targetOption = target[lineValue];
    targetOption.loading = true;
    await dispatch(getStationList({ prefectureCodes, lineValue }));
  };

  const renderPropertyIds = (properties, propertiesSelected, setPropertiesSelected, form) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
        {properties.map(item => {
          let styleSelected;
          const style = {
            height: 40,
            width: 40,
            borderRadius: '50%',
            borderStyle: 'solid',
            marginRight: 45,
            marginBottom: 8
          };

          // Selected Item Css
          if (propertiesSelected.includes(item.id)) {
            styleSelected = { filter: 'invert(30%) sepia(73%) saturate(2251%) hue-rotate(160deg) brightness(94%) contrast(104%)' };
          }

          return (
            <div key={item.id} style={{ marginRight: 24, textAlign: 'center', marginBottom: 24, cursor: 'pointer' }} onClick={() => {
              if (propertiesSelected.includes(item.id)) {
                // Remove
                form.setFields([
                  {
                    name: 'propertyIds',
                    value: form.getFieldsValue().propertyIds.filter(x => x.type !== item.id)
                  }
                ]);
                setPropertiesSelected(propertiesSelected.filter(element => element !== item.id));
              } else {
                // Add
                form.setFields([
                  {
                    name: 'propertyIds',
                    value: [...form.getFieldsValue().propertyIds, item.id]
                  }
                ]);
                setPropertiesSelected([...propertiesSelected, item.id]);
              }
            }}>
              <img
                style={{ ...style, ...styleSelected }}
                src={item.icon}
              />
              <div style={{ whiteSpace: 'nowrap', marginLeft: -45, ...styleSelected }} >{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };
  const getSalonLocation = () => {
    const salonAddress = '日本 〒' + form.getFieldValue('postCode') + form.getFieldValue('prefecture') + form.getFieldValue('cityOrTown') + form.getFieldValue('address') + form.getFieldValue('building');
    searchSalonLocation(salonAddress)
      .then(result => {
        setLngValue({ lat: result?.lat, lng: result?.lng });
        setDefaultLng({ lat: result?.lat, lng: result?.lng });
      });
  };

  const onSubmitSalonAccountValues = async(values) => {
    setLoading(true);
    try {
      const [lineId, lineName] = values?.station[0]?.split('|') || [];
      const [stationId, stationName] = values?.station[1]?.split('|') || [];
      const result = await dispatch(updateSalonProfile({
        ...values,
        salonId: _.get(salon, 'id'),
        phoneNumber: values.phone,
        latLng: lngValue,
        area: {
          id: values.regionId,
          name: values.regionName,
          prefecture: {
            id: values.prefectureId,
            name: values.prefectureName,
            city: {
              id: values.areaId,
              name: values.areaName
            }
          }
        },
        station: {
          stationId,
          stationName,
          lineId,
          lineName,
          description: values?.navigation ? values?.navigation.trim() : '',
          walkingTime: values?.walkingTime
        },
        isPrivate: values.isPrivate,
        logoUrl: values.logoUrl || '',
        photos: values.photos.map(item => item.objectId),
        propertyIds: propertiesSelected,
        description: values?.description ? values?.description.trim() : ''
      }));
      setDefaultLng({ ...lngValue });
      onCallback(result);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <Card className='salon-profile-tab'>
      <Form
        {...layout}
        form={form}
        name="updateProfile"
        onFinish={(values) => {
          form.validateFields();
          onSubmitSalonAccountValues(values);
        }
        }
        initialValues={{
          businessName: _.get(salon, 'businessName'),
          logoUrl: salon?.logoUrl || '',
          email: _.get(salon, 'operatorEmail'),
          phone: _.get(salon, 'operatorPhoneNumber'),

          regionId: _.get(salon, 'location.area.id'),
          regionName: _.get(salon, 'location.area.name'),
          prefectureId: _.get(salon, 'location.area.prefecture.id'),
          prefectureName: _.get(salon, 'location.area.prefecture.name'),
          areaId: _.get(salon, 'location.area.prefecture.city.id'),
          areaName: _.get(salon, 'location.area.prefecture.city.name'),

          station: stationInitValue,
          walkingTime: _.get(salon, 'location.station.walkingTime'),
          navigation: _.get(salon, 'location.station.description'),

          postCode: _.get(salon, 'location.postCode'),
          prefecture: _.get(salon, 'location.prefecture'),
          cityOrTown: _.get(salon, 'location.cityOrTown'),
          address: _.get(salon, 'location.address'),
          building: _.get(salon, 'location.building'),
          isPrivate: _.get(salon, 'location.isPrivate'),
          propertyIds: _.get(salon, 'properties', []).map(item => item.id),
          description: _.get(salon, 'description'),
          photos: _.get(salon, 'photos', []).map(item => {
            return {
              objectId: item,
              thumbSmall: item
            };
          })
        }}
      >
        <Form.Item
          name="logoUrl"
          label="ロゴ"
          extra="1枚あたり5MB以下、"
        >
          <ImageField salonId={salon?.id} />
        </Form.Item>
        <Form.Item
          name="businessName"
          label="サロン名"
          validateTrigger={'onBlur'}
          onKeyPress={(e) => {
            if (e.target.value.length >= 50) {
              e.preventDefault();
            }
          }}
          rules={[
            { required: true, message: 'サロン名を入力してください' },
            { min: 2, message: '1文字以上で入力してください'},
            { max: 50, message: '50文字以上で入力してください'},
            { whitespace: true, message: 'サロン名を入力してください' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="メールアドレス"
          extra={
            <div style={{ marginTop: 12 }}> メールアドレスの変更は
              <a
                onClick={showInputPricePopup}
                style={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}>
                こちら
              </a>
              から
            </div>
          }
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="phone"
          label="電話番号"
          validateTrigger={'onBlur'}
          rules={[
            { required: true, message: '電話番号を入力してください！' },
            { pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, message: '電話番号は無効です！' },
            { min: 10, message: '半角数字ハイフンなし、10桁または11桁で入力してください' },
            { max: 11, message: '半角数字ハイフンなし、10桁または11桁で入力してください' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="regionId" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="regionName" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="prefectureId" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="prefectureName" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="areaId" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="areaName" style={{ display: 'none' }}>
          <Input />
        </Form.Item>

        <Form.Item
          name="postCode"
          label="郵便番号"
          validateTrigger={'onBlur'}
          validateFirst={true}
          rules={[
            { required: true, message: '郵便番号を入力してください！'},
            { len: 7, message: '半角数字7桁ハイフンなしで入力してください.'}
          ]}
        >
          <Input
            ref={_refFieldPostCode}
            onKeyPress={(e) => {
              const key = e.which || e.key;
              if (e.target.value.length < 7) {
                if (key < 48 || key > 57) { e.preventDefault(); }
              } else e.preventDefault();
            }}
            onChange={(e) => {
              if (e.target.value.length === 7) {
                _refFieldPostCode.current.blur();
              }
            }}
            onBlur={(e) => {
              // observe postCode value  and prevent fetch data if it doesn't change
              setCurrentPostCode(e.target.value);
              if (e.target.value === currentPostCode) {
                return;
              }
              if (e.target.value !== '' && e.target.value !== undefined && e.target.value.length === 7) {
                dispatch(getAddressByPostCode({ postCode: e.target.value }))
                  .then(result => {
                    if (result.data.cityOrTown) {
                      form.setFieldsValue({ cityOrTown: result.data.cityOrTown });
                    }

                    if (result.data.prefecture) {
                      form.setFieldsValue(
                        {
                          prefecture: result.data.prefecture,
                          address: '',
                          building: '',
                          walkingTime: '',
                          navigation: '',
                          station: '',
                          areaId: '',
                          areaName: ''
                        });
                      setSelectedStation(false);
                      getLineListOptions(); // Get new line list with new prefecture
                    }
                  })
                  .catch(error => {
                    form.setFieldsValue({ cityOrTown: '' });
                    form.setFieldsValue({ prefecture: '' });
                    if (error?.data?.length > 0) {
                      let msg = '';
                      error.data.forEach((item) => {
                        msg = `${msg} ${item.message}. `;
                      });
                      form.setFields([
                        {
                          name: 'postCode',
                          errors: [msg]
                        }
                      ]);
                    } else {
                      form.setFields([
                        {
                          name: 'postCode',
                          errors: ['郵便番号の場所が見つかりません']
                        }
                      ]);
                    }
                  });
                // Render pin map base on postCode and set default Lat, Lng when user reset map
                getSalonLocation();
              } else if (e.target.value === '') {
                form.setFieldsValue(
                  {
                    prefecture: '',
                    cityOrTown: '',
                    address: '',
                    building: '',
                    walkingTime: '',
                    navigation: '',
                    station: '',
                    areaId: '',
                    areaName: ''
                  });
                setSelectedStation(false);
                dispatch(resetStationProvider()); // clear select line/station list when not input postcode
              }
            }} />
        </Form.Item>

        <Form.Item
          name="prefecture"
          label="都道府県"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="cityOrTown"
          label="市区町村"
          rules={[
            {
              required: true,
              message: '市区町村を入力してください！'
            },
            {
              whitespace: true,
              message: '市区町村を入力してください！'
            }
          ]}
          onBlur={() => getSalonLocation()}
        >
          <Input maxLength={50} />
        </Form.Item>

        <Form.Item
          name="address"
          label="番地"
          rules={[{
            required: true, message: '住所を入力してください！' }, {
            whitespace: true, message: '住所を入力してください！'
          }]}
          onBlur={() => getSalonLocation()}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="building"
          label="建物名等/部屋番号"
          onBlur={() => getSalonLocation()}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item
          label="マップ設定"
          extra={<div style={{ marginTop: 6 }}>マップをクリックするとピンの位置を変更できます</div>}
        >
          <a onClick={() => setOpenEditPlaceOfThePin(true)} >
            <div style={{ height: '35vh', width: '100%', position: 'relative', pointerEvents: 'none' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={defaultLng}
                center={lngValue}
                defaultZoom={16}
                options={hideFullScreenOptions}
              />
              <img src={pinMarker}
                style={{
                  height: 40,
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 0px)'
                }} />
            </div>
          </a>
        </Form.Item>

        {/* Private / public address */}
        <Form.Item
          name="isPrivate"
          label="サロン住所公開設定"
          rules={[
            {
              required: true,
              message: 'サロン住所公開を設定してください！'
            }
          ]}
          extra="サロン住所はお客様に公開するかしないかを選んでください"
        >
          <Select
            onChange={(value) => {
              setUnpublicAddress(value);
            }}
            style={{ width: '100%' }}
            placeholder="サロン住所公開設定"
          >
            <Select.Option value={false}>サロンの住所を常に表示</Select.Option>
            <Select.Option value={true}> 予約が確定したお客様にのみ公開</Select.Option>
          </Select>
        </Form.Item>

        {/* Salon area */}
        <Form.Item
          label="サロンエリア"
          name="areaName"
          rules={[{ required: true, message: 'サロンエリアを選択してください!' }]}
        >
          <div >
            <a onClick={() => setIsSalonAreaSelectModal(true)}>
              {form.getFieldsValue().areaName
                ? form.getFieldsValue().areaName
                : '選択してください'
              }
            </a>
          </div>
        </Form.Item>

        {/* Salon Station */}
        <div id="salon_station">
          <Form.Item
            label="最寄駅"
            validateTrigger={'onChange'}
            name="station"
            extra={<div style={{ marginTop: 16 }}>※最寄駅を設定していなくても半径1.5km圏内で自動検出されるため、検索結果には表示されます<br />※最寄駅がない場合は空欄のままにしてください</div>}
          >
            <Cascader
              options={lineList}
              onChange={onChangeLineOption}
              loadData={loadData}
              changeOnSelect
              displayRender={displayStationName}
              placeholder="選択してください"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>


        {/* Do not allow to select the Walking Time and the memo field if Nailist doesn't select a Station. */}
        <div>
          <Form.Item
            name="walkingTime"
            label="徒歩分数"
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="徒歩分数"
              disabled={!isSelectedStation}
            >
              {[...Array(61).keys()].map(m => <Option key={m} value={m}>{m} 分</Option>)}
            </Select>
          </Form.Item>
          {/* // Do not show this section if Nailist select “Unpublic” */}
          {!isUnpublicAddress &&
            <Form.Item
              name="navigation"
              label="道案内・アクセス"
            >
              <TextArea
                placeholder='道案内・アクセス'
                rows={3}
                showCount
                disabled={!isSelectedStation}
                maxLength={500} />
            </Form.Item>
          }
        </div>
        <h3 id="salon_properties">サロンの特徴</h3>
        <Divider style={{ marginTop: 15 }} />
        <Form.Item
          name="propertyIds"
          wrapperCol={{ xs: { span: 24, offset: 0 }, lg: { span: 20, offset: 2 }, xl: { span: 16, offset: 4 } }}
        >
          {renderPropertyIds(properties, propertiesSelected, setPropertiesSelected, form)}
        </Form.Item>
        <h3>サロンについて・注意事項</h3>
        <Divider style={{ marginTop: 15 }} />
        <Form.Item
          name="description"
          wrapperCol={{ xs: { span: 24, offset: 0 }, lg: { span: 20, offset: 2 }, xl: { span: 16, offset: 4 } }}
          colon={false}
          extra={<div style={{ marginTop: 16 }}>※LINEや電話番号など連絡先の掲載やSNS、他のサービスへの誘導はお控えください</div>}
        >
          <TextArea
            placeholder="サロンの情報をここに入力してください"
            rows={5}
            showCount
            maxLength={10000} />
        </Form.Item>
        <h3>サロンイメージ写真</h3>
        <Divider style={{ marginTop: 15 }} />
        <Form.Item
          {...photosLayout}
          name="photos"
          label={' '}
          colon={false}
          extra="1枚あたり5MB以下、最大3枚までの画像をアップロード可能です"
          rules={[]}
        >
          <ImageField maxLength={3} salonId={salon?.id} aspect={5 / 4} />
        </Form.Item>
        <Divider />
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              保存して公開する
            </Button>
          </Col>
        </Row>

        <Modal
          visible={isSalonAreaSelectModal}
          title="サロンエリアを選択する"
          onCancel={() => setIsSalonAreaSelectModal(false)}
          destroyOnClose={true}
          width={600}
          centered
          footer={null}
          maskClosable={false}
        >
          <SelectAreaForm
            prefecture={form.getFieldValue('prefecture')}
            salonArea={{
              regionId: _.get(salon, 'location.area.id'),
              regionName: _.get(salon, 'location.area.name'),
              prefectureId: _.get(salon, 'location.area.prefecture.id'),
              prefectureName: _.get(salon, 'location.area.prefecture.name'),
              areaId: form.getFieldValue('areaId'),
              areaName: form.getFieldValue('areaName')
            }}
            onCancel={() => setIsSalonAreaSelectModal(false)}
            onCallback={salonArea => {
              const { regionId, regionName, prefectureId, prefectureName, areaId, areaName } = salonArea;

              form.setFieldsValue({ regionId });
              form.setFieldsValue({ regionName });
              form.setFieldsValue({ prefectureId });
              form.setFieldsValue({ prefectureName });
              form.setFieldsValue({ areaId });
              form.setFieldsValue({ areaName });

              setIsSalonAreaSelectModal(false);
            }}
          />
        </Modal>
        <Modal
          visible={openEditPlaceOfThePin}
          title='ピンの場所を編集する'
          onCancel={() => setOpenEditPlaceOfThePin(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
          maskClosable={false}
        >
          {loading && <BigSpin />}
          <SalonEditPlaceOfThePin
            salon={salon}
            coordinateInitValue={lngValue}
            onCancel={() => setOpenEditPlaceOfThePin(false)}
            handleSetCoordination={(coordinatesData) => {
              const { coordinates } = coordinatesData;
              setLngValue({ lat: coordinates?.lat, lng: coordinates?.lng });
              // Select place of the pin and complete update profile
              setOpenEditPlaceOfThePin(false);
            }}
          />
        </Modal>
      </Form>

    </Card>
  );
};

SalonUpdateProfileForm.propTypes = {
  onCallback: PropTypes.func,
  salon: PropTypes.object
};

export default SalonUpdateProfileForm;
