import { connect } from 'react-redux';
import { resendReceipt } from 'providers/ReceiptProvider/actions';

import ResendReceiptEmailButton from './ResendReceiptEmailButton';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  resendReceipt: ({ bookingId }) => dispatch(resendReceipt({ bookingId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendReceiptEmailButton);