import _ from 'lodash';
import { message } from 'antd';
import { put, takeLeading, call, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  fetchPayoutsListSuccess,
  setPayoutLoading,
  completePayoutsSuccess,
  completePayoutFail,
  editBankFeeSuccess,
  editBankFeeFail,
  requestPayoutFileSuccess,
  fetchChangedPayoutsFail,
  fetchChangedPayoutSuccess,
  execChangePayoutSuccess,
  execChangePayoutFail,
  execChangeAllPayoutSuccess,
  execChangeAllPayoutFail
} from './actions';
import {
  FETCH_PAYOUTS_LIST,
  COMPLETE_PAYOUTS,
  COMPLETE_ALL_PAYOUTS,
  EDIT_BANK_FEE,
  REQUEST_PAYOUT_FILE,
  FETCH_CHANGED_PAYOUTS,
  EXEC_CHANGE_PAYOUT,
  EXEC_CHANGE_ALL_PAYOUT
} from './constants';

import request from 'utils/request';
import Validator from 'utils/validator';
import { message as Alert } from 'antd';

const PAYOUT_TYPE = {
  NAILIST: 'NAILIST',
  SALON: 'SALON',
  STAFF: 'STAFF'
};

function* fetchPayoutList(action) {
  try {
    yield put(setPayoutLoading({ loading: true }));

    const filter = action.payload;
    filter.limit = filter.limit || 12;
    filter.startDate = filter['date-range'][0];
    filter.endDate = filter['date-range'][1];
    filter.sort = { [filter.orderBy]: Number(filter.order) };
    delete filter['date-range'];
    delete filter.orderBy;
    delete filter.order;

    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, 'payout/getNailistsPayouts', filter)).data;
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = (yield call(request, 'salon-sales-report/payout/list', filter)).data;
        break;
      }
      case PAYOUT_TYPE.STAFF: {
        data = (yield call(request, 'salon-sales-report/payout/nailist/salon', filter)).data;
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(fetchPayoutsListSuccess(data));
  } catch (error) {
    console.log(error);
  }
  yield put(setPayoutLoading({ loading: false }));
}

function* completeAllPayouts(action) {
  try {
    yield put(setPayoutLoading({ loading: true }));

    const filter = action.payload;
    filter.startDate = filter['date-range'][0];
    filter.endDate = filter['date-range'][1];

    const body = _.pick(filter, [
      'startDate',
      'endDate',
      'nailistId',
      'transferDate'
    ]);

    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, 'payout/checkAllPayouts', body)).data;
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = (yield call(request, 'salon-sales-report/payout/check/all', body, 'PATCH')).data;
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(completePayoutsSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
  yield put(setPayoutLoading({ loading: false }));
}

function* completePayouts(action) {
  try {
    const body = {};
    body.payoutIds = _.get(action, 'payload.payoutIds');
    body.transferDate = _.get(action, 'payload.transferDate');

    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, 'payout/checkPayouts', body)).data;
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = (yield call(request, 'salon-sales-report/payout/check', body, 'PATCH')).data;
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(completePayoutsSuccess(data, action.meta));
  } catch (e) {
    yield put(completePayoutFail(e, action.meta));
  }
}

function* editBankFee(action) {
  try {
    const params = {
      payoutId: _.get(action, 'payload.payoutId'),
      bankFee: _.get(action, 'payload.bankFee')
    };

    if (!params.payoutId || !Validator.isPositiveInteger(params.bankFee)) {
      throw new Error('Invalid edit bank fee params');
    }

    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, 'payout/editBankFee', params)).data;
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = (yield call(
          request,
          'salon-sales-report/payout/bank-fee',
          params,
          'PUT'
        )).data;
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(
      editBankFeeSuccess(
        { bankFee: data, payoutId: params.payoutId },
        action.meta
      )
    );
  } catch (error) {
    console.log(error);
    yield put(editBankFeeFail({}, action.meta));
    message.error(error.message);
  }
}

function* requestPayoutFile(action) {
  try {
    const filter = action.payload;
    filter.startDate = filter['date-range'][0];
    filter.endDate = filter['date-range'][1];

    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    console.log(payoutType);
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = yield call(request, 'payout/exportPayoutSheet', filter, 'GET');
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = yield call(
          request,
          'salon-sales-report/payout/exportPayoutSheet',
          filter,
          'GET'
        );
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    switch (filter.forRole) {
      case 'BANK':
        saveFileCSV(data, `sales_${filter.startDate}_${filter.endDate}.csv`);
        break;
      default:
        saveFileXLSX(data, `sales_${filter.startDate}_${filter.endDate}.xlsx`);
    }

    yield put(requestPayoutFileSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function saveFileXLSX(data, name) {
  let a = document.createElement('a');
  a.href =
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
    data;
  a.download = name || 'data.xlsx';
  a.hidden = true;
  document.body.appendChild(a);
  a.innerHTML = '';
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 500);
}

function saveFileCSV(data, name) {
  let a = document.createElement('a');
  a.href = 'data:text/csv;base64,' + data;
  a.download = name || 'data.csv';
  a.hidden = true;
  document.body.appendChild(a);
  a.innerHTML = '';
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 500);
}

function* fetchChangedPayouts(action) {
  try {
    const periodId = _.get(action.payload, 'period');
    let data;

    const payoutType = _.get(action.payload, 'type');
    switch (payoutType) {
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, `payout/changes/type/nailist/period/${periodId}`, {}, 'GET')).data;
        break;
      }
      case PAYOUT_TYPE.SALON: {
        data = (yield call(request, `payout/changes/type/salon/period/${periodId}`, {}, 'GET')).data;
        break;
      }
      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(fetchChangedPayoutSuccess(data, action.meta));
  } catch (e) {
    yield put(fetchChangedPayoutsFail(e, action.meta));
  }
}

function* execChangePayoutHandler(action) {
  try {
    const { data } = (yield call(request, `payout/changes/exec`, {
      changesList: _.get(action, 'payload')
    }, 'POST'));
    yield put(execChangePayoutSuccess(data, action.meta));
    Alert.success('Updated successfully!');
  } catch (e) {
    yield put(execChangePayoutFail(e, action.meta));
  }
}

function* execChangeAllPayoutHandler(action) {
  try {
    const params = action.payload.map(({ periodId, nailistId, salon }) => ({
      periodId,
      [(nailistId && 'nailistId') || (salon._id && 'salonId')]: nailistId || salon._id
    }));
    const { data } = (yield call(request, `payout/changes/exec`, {
      changesList: params
    }, 'POST'));
    yield put(execChangeAllPayoutSuccess(data, action.meta));
    setTimeout(() => Alert.success('Updated successfully!'), 500);
  } catch (e) {
    yield put(execChangeAllPayoutFail(e, action.meta));
  }
}

export default function* watchPayouts() {
  yield takeLatest(FETCH_PAYOUTS_LIST, fetchPayoutList);
  yield takeLeading(COMPLETE_PAYOUTS, completePayouts);
  yield takeLeading(COMPLETE_ALL_PAYOUTS, completeAllPayouts);
  yield takeEvery(EDIT_BANK_FEE, editBankFee);
  yield takeLeading(REQUEST_PAYOUT_FILE, requestPayoutFile);
  yield takeLatest(FETCH_CHANGED_PAYOUTS, fetchChangedPayouts);
  yield takeEvery(EXEC_CHANGE_PAYOUT, execChangePayoutHandler);
  yield takeLeading(EXEC_CHANGE_ALL_PAYOUT, execChangeAllPayoutHandler);
}
