/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import { Avatar } from 'antd';
import BookingStatusTag from 'components/BookingStatusTag';
import BookingOriginTag from 'components/BookingOriginTag';
import { UserOutlined } from '@ant-design/icons';
import { addCommaToString } from 'utils/common';
import { Link } from 'react-router-dom';
import PAYMENT_PROVIDER from 'utils/constants/PAYMENT_PROVIDER';

const linkStyle = { display: 'block', fontSize: 12, wordBreak: 'break-all' };
const columnsTemplate = {
  bookingId: ({ newTab = false } = {}) => ({
    title: 'Booking ID',
    width: 130,
    dataIndex: '_id',
    key: '_id',
    render: (_id) => <Link target={ newTab ? '_blank' : undefined } to={`/dashboard/booking-management/list/${_id}`}>{_id}</Link>
  }),
  nailist: ({ newTab = false } = {}) => {
    const target = newTab ? '_blank' : undefined;
    console.log('🚀 ~ file: columnsTemplate.js:24 ~ target:', target)
    return {
      title: 'ネイリスト',
      width: 200,
      dataIndex: 'nailist',
      render: (nailist) => <div style={{ display: 'flex' }}>
        <Link target={target} to={`/dashboard/nailist/profile/${nailist._id}`}>
          <Avatar
            icon={<UserOutlined />}
            src={_get(nailist, 'avatarThumbnail')}
            alt="" />
        </Link>
        <div span={20} style={{ marginLeft: 8, flex: 1 }}>
          <Link target={target} to={`/dashboard/nailist/profile/${nailist._id}`} style={linkStyle}>{_get(nailist, 'username')}</Link>
          <Link target={target} to={`/dashboard/nailist/profile/${nailist._id}`} style={linkStyle}>{_get(nailist, 'phonetic')}</Link>
        </div>
      </div>,
      key: 'nailist'
    };
  },
  customer: ({ isLink, newTab = false } = {}) => ({
    title: 'カスタマー',
    width: 200,
    dataIndex: 'customer',
    render: (client) => {
      const customer = (
        <div style={{ display: 'flex' }}>
          <div style={linkStyle}>
            <Avatar
              icon={<UserOutlined />}
              src={_get(client, 'avatarThumbnail')}
              alt="" />
          </div>
          <div span={20} style={{ marginLeft: 8, flex: 1 }}>
            <div style={linkStyle}>
              {_get(client, 'username')}
            </div>
            <div style={linkStyle}>
              {_get(client, 'phonetic')}
            </div>
          </div>
        </div>
      );

      const target = newTab ? '_blank' : undefined;

      return isLink ? (
        <Link target={target} to={`/dashboard/customer/profile/${client._id}`} style={linkStyle}>{customer}</Link>
      ) : customer;
    },
    key: 'customer'
  }),
  coupon: {
    title: 'クーポン',
    width: 100,
    dataIndex: 'couponInfo',
    render: (couponInfo) => {
      if (couponInfo) {
        return (
          <>
            <div className="coupon-code align-center">{couponInfo.code}</div>
            <div className="coupon-price align-center">¥{addCommaToString(couponInfo.value)}</div>
          </>
        );
      }
      return <div className="align-center">無し</div>;
    },
    key: 'couponInfo'
  },
  treatmentDate: {
    title: '施術日',
    width: 140,
    dataIndex: 'bookingDate',
    render: (bookingDate) => bookingDate && moment(bookingDate).format('YYYY-MM-DD'),
    key: 'bookingDate',
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  requestedDate: {
    title: 'リクエスト日',
    width: 140,
    dataIndex: 'requestedDate',
    render: (requestedDate) => requestedDate && moment(requestedDate).format('YYYY-MM-DD'),
    key: 'requestedDate',
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  approvedDate: {
    title: '承認日',
    width: 140,
    dataIndex: 'confirmedDate',
    render: (confirmedDate) => confirmedDate && moment(confirmedDate).format('YYYY-MM-DD'),
    key: 'confirmedDate',
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  settlementId: {
    title: '加盟店取引ID',
    width: 140,
    render: (record) => record.atoneMerchant || record.veritransOrderId || _get(record, 'payment.chargeId', ''),
    key: 'ATONE/VERITRANS'
  },
  paymentCardProvider: {
    title: 'Payment',
    render: (record) => PAYMENT_PROVIDER[record.paymentCardProvider
      || _get(record, 'payment.paymentMethod') || record.paymentMethod],
    key: 'paymentCardProvider'
  },
  canceledDate: {
    title: 'キャンセル日',
    width: 140,
    dataIndex: 'canceledDate',
    render: (canceledDate) => canceledDate && moment(canceledDate).format('YYYY-MM-DD'),
    key: 'canceledDate',
    sorter: true
  },
  expiredDate: {
    title: '期限切れ',
    width: 140,
    dataIndex: 'expiredDate',
    sorter: true,
    render: (expiredDate) => expiredDate && moment(expiredDate).format('YYYY-MM-DD'),
    key: 'expiredDate'
  },
  point: {
    title: '利用ポイント',
    width: 100,
    dataIndex: 'bookingPointInfo',
    render: (bookingPointInfo) => `${_get(bookingPointInfo, 'usePoint', '0')} P`,
    key: 'bookingPointInfo'
  },
  totalFee: {
    title: '合計金額',
    width: 120,
    render: (record) => {
      const { penalty = 0, additionalPrice, price } = record;

      if (['CANCELED_REQUEST', 'DECLINED_REQUEST', 'NAILIE_CANCELED', 'NAILIST_CANCELED', 'UNDONE', 'EXPIRED']
        .includes(record.status)) {
        return '¥0';
      }
      if (['CANCELED_RESERVATION', 'DECLINED_RESERVATION', 'NOVISIT'].includes(record.status)) {
        return `¥${addCommaToString(Math.floor(record.price * penalty))}`;
      }
      return `¥${addCommaToString(additionalPrice || price)}`;
    },
    key: 'price'
  },
  memo: {
    title: '緊急事態の内容',
    width: 140,
    dataIndex: 'memo',
    key: 'memo',
    render: (memo) => <div className="memo-field">{memo}</div>
  },
  isChecked: {
    title: 'CHECK',
    with: 'auto',
    dataIndex: 'isChecked',
    key: 'isChecked',
    render: (isChecked) => (isChecked ? '対応済み' : '未対応')
  },
  status: {
    title: 'Status',
    width: 'auto',
    dataIndex: 'status',
    render: (status) => <BookingStatusTag status={status} />,
    key: 'status'
  },
  origin: {
    title: 'Origin',
    width: 'auto',
    dataIndex: 'origin',
    render: (origin) => <BookingOriginTag platform={origin} />,
    key: 'origin'
  },
  errorDate: {
    title: 'エラー日',
    dataIndex: ['payment', 'errorDate'],
    render: (errorDate) => errorDate && moment(errorDate).format('YYYY-MM-DD'),
    key: 'errorDate'
  },
  errorExpiredDate: {
    title: 'エラー期限',
    dataIndex: ['payment', 'expiredDate'],
    render: (errorExpiredDate) => errorExpiredDate && moment(errorExpiredDate).format('YYYY-MM-DD'),
    key: 'errorExpiredDate'
  },
  action: {
    title: 'Action',
    width: 'auto',
    key: 'Action',
    fixed: 'right'
  },
  percentOfPayment: {
    title: '% Of Pay',
    key: 'percentOfPayment',
    render: (record) => {
      if (['DONE', 'NOVISIT'].includes(record.status) && !record.paymentSuccess) {
        return '100%';
      }
      return '0%';
    }
  },
  atoneChargeId: {
    title: 'Atone Charge Id',
    dataIndex: ['payment'],
    render: (payment) => {
      const { chargeId, paymentMethod } = payment || {};

      if (paymentMethod !== 'ATONE') {
        return '';
      }
      return chargeId;
    },
    key: 'atoneIdChargeId'
  }
};

export default columnsTemplate;