import { connect } from 'react-redux';

import { submit } from 'redux-form';
import { SetPopup } from 'providers/PopupProvider/actions';
import { createSalon } from 'providers/SalonProvider/actions';
import { makeAnyPopup } from 'providers/PopupProvider/selectors'

import CreateSalonPopup from './CreateSalonPopup';

const mapStateToProps = (state) => ({
  isShown: makeAnyPopup(state, 'createSalon'),
});

const mapDispatchToProps = (dispatch) => ({
  createSalon: (salonInfo) => dispatch(createSalon(salonInfo)),
  submitForm: () => dispatch(submit('createSalonForm')),
  closePopup: () => dispatch(SetPopup({name: 'createSalon', show: false}))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSalonPopup);