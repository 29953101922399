// Checked
import _ from 'lodash';

export const makeBookingFeeSetting = (state) => {
  return _.get(state, 'setting.bookingFee');
};

export const makeBookingFeeSettingActionLoading = (state) => {
  return _.get(state, 'setting.actionLoading');
};
