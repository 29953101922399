import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Col, Row, Select, Space, Switch, Button } from 'antd';
import { useDispatch } from 'react-redux';

import { SLOT_SELECTIONS, initWeekDaySettingValue } from 'utils/scheduleAndWorkTime';
import { updateNailistWorkingTime } from 'providers/NailistProvider/actions';

const renderSlotsSelection = ({ forStartTime = true/*, max, min*/}) => {
  if (forStartTime === true) {
    return SLOT_SELECTIONS
      .slice(0, SLOT_SELECTIONS.length - 1)
      .map(slot => <Select.Option key={slot} value={slot} /*disabled={slot >= max}*/>{slot}</Select.Option>);
  } else {
    return SLOT_SELECTIONS
      .slice(1, SLOT_SELECTIONS.length)
      .map(slot => <Select.Option key={slot} value={slot} /*disabled={slot <= min}*/>{slot}</Select.Option>);
  }
};

const WeekdayComboInput = ({ label, name, form }) => {
  const formItemStyle = { width: '100%', marginBottom: '8px' }; // 8*2=16px

  const validator = ({ getFieldValue }) => ({ validator: () => {
    const startTime = getFieldValue(`${name}Start`);
    const endTime = getFieldValue(`${name}End`);

    if (!startTime || !endTime) {
      return Promise.resolve();
    }
    if (startTime >= endTime) {
      return Promise.reject('start time must be earlier end time!');
    }
    return Promise.resolve();
  }});

  const triggerValidate = () => form.validateFields([`${name}Start`, `${name}End`]);

  return (
    <Row justify="center">
      {/* start time */}
      <Col span={8}>
        <Form.Item shouldUpdate style={formItemStyle}>
          {() => 
            <Form.Item rules={[validator]} labelCol={{span: 12}} wrapperCol={{span: 12}} style={formItemStyle} label={label} name={`${name}Start`}>
              <Select onChange={triggerValidate} style={{ minWidth: 'unset'}} disabled={!form.getFieldValue(`${name}IsOpen`)}>
                {renderSlotsSelection({ forStartTime: true })}
              </Select>
            </Form.Item>
          }
        </Form.Item>
      </Col>

      {/* ~ */}
      <Col span={1}>
        <Space style={{display: 'flex', justifyContent: 'center', lineHeight: '32px' }}>
          ~
        </Space>
      </Col>

      {/* end time */}
      <Col span={4}>
        <Form.Item shouldUpdate style={formItemStyle}>
          {() => 
            <Form.Item rules={[validator]} wrapperCol={{span: 24}} style={formItemStyle} name={`${name}End`}>
              <Select onChange={triggerValidate} style={{ minWidth: 'unset'}} disabled={!form.getFieldValue(`${name}IsOpen`)}>
                {renderSlotsSelection({ forStartTime: false })}
              </Select>
            </Form.Item>
          }
        </Form.Item>
      </Col>

      {/* check mark */}
      <Col span={3}>
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Item wrapperCol={{span: 24}} style={{width: '100%'}} name={`${name}IsOpen`} valuePropName="checked">
            <Switch/>
          </Form.Item>
        </Space>
      </Col>
    </Row>
  );
};

WeekdayComboInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  form: PropTypes.any
};

const UpdateWorkTimeForm = ({ initialValues, submit }) => {
  const [form] = Form.useForm();

  return <Form
    style={{width: 800, margin: 'auto'}}
    form={form}
    initialValues={initialValues}
    onFinish={value => submit(value, form.isFieldsTouched)}
  >
    <WeekdayComboInput label="月曜日" name="mon" form={form}/>
    <WeekdayComboInput label="火曜日" name="tue" form={form}/>
    <WeekdayComboInput label="水曜日" name="wed" form={form}/>
    <WeekdayComboInput label="木曜日" name="thu" form={form}/>
    <WeekdayComboInput label="金曜日" name="fri" form={form}/>
    <WeekdayComboInput label="土曜日" name="sat" form={form}/>
    <WeekdayComboInput label="日曜日" name="sun" form={form}/>
    <Space align='center' direction='vertical' style={{width: '100%'}}>
      <Form.Item>
        <Button type="primary" htmlType="submit">保存</Button>
      </Form.Item>
    </Space>
  </Form>;
};

UpdateWorkTimeForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func
};

// Business hours = working time = weekday
const useEditWorkingTimePopupController = (data, userId, submitAll = false) => {  
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const submit = async (values, isFieldsTouched) => {
    await dispatch(updateNailistWorkingTime({
      userId, values,
      isFieldsTouched: submitAll ? () => true : isFieldsTouched}));
    setIsOpen(false);
  };

  const initialValues = useMemo(() => {
    return initWeekDaySettingValue(data);
  }, [data]);

  const Popup = <Modal
    title='営業時間を編集'
    visible={isOpen}
    destroyOnClose
    footer={null}
    onCancel={() => setIsOpen(false)}
    width={980}
  >
    <UpdateWorkTimeForm initialValues={initialValues} submit={submit}/>
  </Modal>;

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default useEditWorkingTimePopupController;