import PropTypes from 'prop-types';
import React from 'react';
import phoneSample from 'assets/images/phone-sample.png';

const TYPE_ANNOUNCE = {
  PROMO: 'クーポン',
  ANNOUNCEMENT: 'お知らせ',
  FEATURE: '新しい機能'
};

const ExampleAnnouncement = ({ imageId, title, content, types }) => {
  return (
    <div className="preview-container">
      <img src={phoneSample} />
      <div
        className="sample-cell"
        style={{ backgroundImage: imageId ? `url(${imageId.data})` : 'url(https://via.placeholder.com/686x400)' }}
      >
        <div className="announcement-cell-mask"></div>
        <div className="cell-container">
          <div className="announcement-title">{title}</div>
          <div className="announcement-detail">{content}</div>
          <span className="announcement-type">{TYPE_ANNOUNCE[types]}</span>
        </div>
      </div>
    </div >
  );
};

ExampleAnnouncement.propTypes = {
  imageId: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  types: PropTypes.string
};

export default ExampleAnnouncement;