import {
  GET_LINE_LIST,
  GET_LINE_LIST_FAIL,
  GET_LINE_LIST_SUCCESS,
  GET_PREFECTURE_ISO_TABLE,
  GET_PREFECTURE_ISO_TABLE_FAIL,
  GET_PREFECTURE_ISO_TABLE_SUCCESS,
  GET_STATION_LIST,
  GET_STATION_LIST_FAIL,
  GET_STATION_LIST_SUCCESS,
  RESET_STATION_PROVIDER,
} from './type';

// Get prefecture list
export function getPrefectureISOTable(payload = {}) {
  return { type: GET_PREFECTURE_ISO_TABLE, payload, meta: { thunk: true } };
}

export function getPrefectureISOTableSuccess(payload = {}, meta) {
  return { type: GET_PREFECTURE_ISO_TABLE_SUCCESS, payload, meta };
}

export function getPrefectureISOTableFail(payload = {}, meta) {
  return { type: GET_PREFECTURE_ISO_TABLE_FAIL, payload, meta, error: true };
}

// Get line list
export function getLineList(payload = {}) {
  return { type: GET_LINE_LIST, payload, meta: { thunk: true } };
}

export function getLineListSuccess(payload = {}, meta) {
  return { type: GET_LINE_LIST_SUCCESS, payload, meta };
}

export function getLineListFail(payload = {}, meta) {
  return { type: GET_LINE_LIST_FAIL, payload, meta, error: true };
}

// Get station list
export function getStationList(payload = {}) {
  return { type: GET_STATION_LIST, payload, meta: { thunk: true } };
}

export function getStationListSuccess(payload = {}, meta) {
  return { type: GET_STATION_LIST_SUCCESS, payload, meta };
}

export function getStationListFail(payload = {}, meta) {
  return { type: GET_STATION_LIST_FAIL, payload, meta, error: true };
}

export function resetStationProvider(payload = {}) {
  return { type: RESET_STATION_PROVIDER, payload };
}
