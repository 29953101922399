import React from 'react';
import { Select } from 'antd';

import { CHANGING_BK_STATUS, CHANGING_BK_STATUS_TEXT } from 'utils/constants/changingBKStatusEnum';

const { Option } = Select;

const OPTIONS = {
  DONE: <Option key={1} value={CHANGING_BK_STATUS.DONE}>施術完了</Option>,
  CANCELED_REQUEST: <Option key={2} disabled={true} value={CHANGING_BK_STATUS.CANCELED_REQUEST}>[カ]キャンセル(直前以外)</Option>,
  CANCELED_RESERVATION: <Option key={3} value={CHANGING_BK_STATUS.CANCELED_RESERVATION}>[カ]キャンセル（直前0%）</Option>,
  CANCELED_RESERVATION_50_PERCENT: <Option key={4} value={CHANGING_BK_STATUS.CANCELED_RESERVATION_50_PERCENT}>[カ]キャンセル（直前50%）</Option>,
  CANCELED_RESERVATION_100_PERCENT: <Option key={5} value={CHANGING_BK_STATUS.CANCELED_RESERVATION_100_PERCENT}>[カ]キャンセル（直前100%）</Option>,
  DECLINED_RESERVATION: <Option key={6} value={CHANGING_BK_STATUS.DECLINED_RESERVATION}>[ネ]キャンセル</Option>,
  UNDONE: <Option key={7} value={CHANGING_BK_STATUS.UNDONE}>緊急事態キャンセル</Option>,
  NOVISIT: <Option key={8} value={CHANGING_BK_STATUS.NOVISIT}>不来店（0%）</Option>,
  NOVISIT_50_PERCENT: <Option key={9} value={CHANGING_BK_STATUS.NOVISIT_50_PERCENT}>不来店（50%）</Option>,
  NOVISIT_100_PERCENT: <Option key={10} value={CHANGING_BK_STATUS.NOVISIT_100_PERCENT}>不来店（100%）</Option>
};

const STATUS_MAPPING = {
  DONE: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    OPTIONS.NOVISIT_50_PERCENT,
    OPTIONS.NOVISIT_100_PERCENT
  ],
  CANCELED_REQUEST: [
    // OPTIONS.DONE,
    // OPTIONS.CANCELED_REQUEST,
    // OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  CANCELED_RESERVATION: [
    // OPTIONS.DONE,
    // OPTIONS.CANCELED_REQUEST,
    // OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    // OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  CANCELED_RESERVATION_50_PERCENT: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  CANCELED_RESERVATION_100_PERCENT: [
    OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    OPTIONS.NOVISIT_50_PERCENT,
    OPTIONS.NOVISIT_100_PERCENT
  ],
  DECLINED_RESERVATION: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    // OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  NAILIST_CANCELED: [ /* clone of DECLINED_RESERVATION*/
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    // OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  UNDONE: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    // OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  NOVISIT: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    // OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    // OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  NOVISIT_50_PERCENT: [
    // OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    // OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    // OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ],
  NOVISIT_100_PERCENT: [
    OPTIONS.DONE,
    OPTIONS.CANCELED_REQUEST,
    OPTIONS.CANCELED_RESERVATION,
    OPTIONS.CANCELED_RESERVATION_50_PERCENT,
    OPTIONS.CANCELED_RESERVATION_100_PERCENT,
    OPTIONS.DECLINED_RESERVATION,
    OPTIONS.UNDONE,
    OPTIONS.NOVISIT,
    OPTIONS.NOVISIT_50_PERCENT,
    // OPTIONS.NOVISIT_100_PERCENT
  ]
};

export default STATUS_MAPPING;