/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Avatar, Descriptions, Tabs, Card, Spin, Space, Table, Tag, Tooltip, Button } from 'antd';
import { EyeOutlined, UserOutlined, CloseSquareOutlined, CheckSquareOutlined } from '@ant-design/icons';
import BookingStatusTag from 'components/BookingStatusTag';
import BookingOriginTag from 'components/BookingOriginTag';
import PageHeader from 'components/PageHeader';

import useTabController from './hooks/useTabController';
import ChangedHistoryTab from './ChangedHistoryTab';
import DirectMessage from './DirectMessage';
import Review from './Review';

import ChangeBookingStatusButton from 'components/Common/Booking/ChangePriceNStatus/ChangeBookingStatusButton';
import EditReceiptPriceButton from 'components/Common/Booking/ChangePriceNStatus/EditReceiptPriceButton';
import ResendReceiptEmailButton from 'components/Common/Booking/ChangePriceNStatus/ResendReceiptEmailButton';

import { fetchBookingDetail, resetBookingProvider } from 'providers/BookingProvider/actions';
import { addCommaToString } from 'utils/common';
import { OLD_ADMIN_DOMAIN } from 'utils/config';
import { BOOKING_STATUS } from 'utils/constants';

import {
  renderFinalAmount,
  renderPaymentAmount
} from 'utils/bookingDetailHelpers';

import './styles.scss';

const {
  REQUESTED,
  CONFIRMED,
  WAITING_DONE,
  EXPIRED,
  CANCELED_REQUEST,
  CANCELED_RESERVATION,
  DECLINED_REQUEST,
  DECLINED_RESERVATION,
  DONE,
  NOVISIT,
  NAILIST_CANCELED,
  UNDONE,
  NAILIE_CANCELED
} = BOOKING_STATUS;

const menuColumns = [
  {
    title: 'カテゴリー',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: 'メニュー名',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '所要時間',
    dataIndex: 'time',
    key: 'time',
    render: (time) => `${time} minutes`
  },
  {
    title: '金額',
    key: 'price',
    dataIndex: 'price',
    render: (price) => `¥${addCommaToString(price)}`
  }
];


const BookingDetail = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const { bookingId } = routeMatch.params;
  const { activeTab, onChangeTab} = useTabController();
  const [loading, setLoading] = useState(true);
  const bookingDetail = useSelector(state => state.booking.booking.detail) || {};
  const {
    slot,
    penalty,
    status,
    menuBookings = [],
    earnPointRate,
    additionalPrice,
    pointBonusRate = 1,
    price,
    atoneMerchant,
    veritransOrderId,
    requestedDate,
    confirmedDate,
    bookingDate,
    expiredDate,
    canceledDate,
    reason,
    memo,
    isChecked,
    spendTime,
    memoAdditionalPrice,
    updatedHistory,
    origin,
    userNote
  } = bookingDetail;

  const cancelPolicyName = _get(bookingDetail, 'cancelPolicy.name');
  const settlementId = atoneMerchant || veritransOrderId || _get(bookingDetail, 'payment.chargeId', '');
  const couponCode = _get(bookingDetail, 'couponInfo.code', '無し');
  const couponValue = _get(bookingDetail, 'couponInfo.value', 0);
  const usePoint = _get(bookingDetail, 'bookingPointInfo.usePoint', 0);

  useEffect(() => {
    (async() => {
      setLoading(true);
      await dispatch(fetchBookingDetail({ bookingId }));
      setLoading(false);
    })();

    return () => {
      dispatch(resetBookingProvider());
    };
  }, []);

  const renderDate = (date, dateFormat = 'YYYY-MM-DD', currentFormat) => {
    if (date) {
      return moment(date, currentFormat).format(dateFormat);
    }
    return '--';
  };

  const renderPaymentMethod = ({ paymentMethod, paymentCardProvider }) => {
    const tagColor = {
      'ATONE': 'blue',
      'STRIPE': 'purple',
      'VERITRANS': 'cyan'
    };
    if (paymentMethod === 'ONLINE') {
      return <>
        Online card payment {paymentCardProvider &&
          <Tag color={tagColor[paymentCardProvider]}>{paymentCardProvider}</Tag>}
      </>;
    }
    return <Tag color={tagColor[paymentMethod]}>{paymentMethod}</Tag>;
  };
  renderPaymentMethod.propTypes = {
    paymentMethod: PropTypes.string,
    paymentCardProvider: PropTypes.string
  };

  const renderCancelFeeDate = ({ cancelPolicy = {}, bookingDate }) => {
    if (cancelPolicy.name === 'HARD') {
      return moment(bookingDate).subtract(1, 'd').format('YYYY-MM-DD');
    } else if (cancelPolicy.name === 'NORMAL') {
      return moment(bookingDate).format('YYYY-MM-DD');
    } else return '';
  };

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Booking | Detail</title>
      </Helmet>
      <PageHeader
        title="Booking Detail"
        breadcrumb={PageHeader.Bread.bookingDetail(bookingId)}
        goBack={true}
      />
      <div className="page-container" id="booking-detail" style={{ display: 'block' }}>
        <Tabs defaultActiveKey="1" className="main-tabs" activeKey={activeTab || 'default-info'} onChange={onChangeTab}>
          <Tabs.TabPane tab="情報" key="default-info">
            <Spin spinning={loading}>
              <Card
                title="予約内容"
                extra={
                  <Space>
                    <ResendReceiptEmailButton bookingId={bookingId} disabled={_isEmpty(_get(bookingDetail, 'receipt'))}/>
                    <ChangeBookingStatusButton status={status} penalty={penalty} bookingId={bookingId}/>
                    <EditReceiptPriceButton screen='BOOKING_DETAIL' bookingId={bookingId} bookingData={{couponInfo: _get(bookingDetail, 'couponInfo'), bookingPointInfo: _get(bookingDetail, 'bookingPointInfo'), penalty}} disabled={status !== 'DONE'}/>
                    <Link to={`/dashboard/receipts/any/related/booking/${bookingId}/`}><Button>View receipts</Button></Link>
                  </Space>
                }
              >
                <Descriptions column={1} bordered contentStyle={{ textAlign: 'left', whiteSpace: 'pre-line' }} labelStyle={{ width: 200 }}>
                  <Descriptions.Item label="Booking ID:">
                    {bookingId}
                  </Descriptions.Item>
                  <Descriptions.Item label="Settlement ID:">{settlementId}</Descriptions.Item>
                  <Descriptions.Item label="支払方法:" >
                    {renderPaymentMethod(bookingDetail)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status:">
                    <BookingStatusTag status={status} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Origin:">
                    <BookingOriginTag platform={origin} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Nailist:">
                    <Link to={`/dashboard/nailist/profile/${_get(bookingDetail, 'nailist._id')}`}>
                      <Space>
                        <Avatar
                          size={40}
                          icon={<UserOutlined />}
                          src={_get(bookingDetail, 'nailist.avatarThumbnail')}
                        />
                        {_get(bookingDetail, 'nailist.username')}
                      </Space>
                    </Link>
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer:">
                    <Link to={`/dashboard/customer/profile/${_get(bookingDetail, 'customer._id')}`}>
                      <Space>
                        <Avatar
                          size={40}
                          icon={<UserOutlined />}
                          src={_get(bookingDetail, 'customer.avatarThumbnail')}
                        />
                        {_get(bookingDetail, 'customer.username')}
                      </Space>
                    </Link>
                  </Descriptions.Item>
                  <Descriptions.Item label="リクエスト日:">
                    {renderDate(requestedDate, 'YYYY-MM-DD HH:mm')}
                  </Descriptions.Item>

                  {![EXPIRED, REQUESTED, WAITING_DONE, CANCELED_REQUEST, DECLINED_REQUEST, DONE].includes(status) &&
                    <Descriptions.Item label="承認日:">{renderDate(confirmedDate)}</Descriptions.Item>}
                  <Descriptions.Item label="施術日:">{renderDate(bookingDate)}</Descriptions.Item>
                  {[EXPIRED].includes(status) &&
                    <Descriptions.Item label="期限切れ:">{renderDate(expiredDate)}</Descriptions.Item>}
                  {[NAILIST_CANCELED, CANCELED_REQUEST, DECLINED_REQUEST, CANCELED_RESERVATION, DECLINED_RESERVATION].includes(status) &&
                    <Descriptions.Item label="キャンセル日:">{renderDate(canceledDate)}</Descriptions.Item>}

                  {[CONFIRMED, CANCELED_RESERVATION].includes(status) && ['NORMAL', 'HARD'].includes(cancelPolicyName) &&
                    <Descriptions.Item label="Cancel Fee Date :">
                      {renderCancelFeeDate(bookingDetail)}
                    </Descriptions.Item>}
                  <Descriptions.Item label="Cancel Policy :" >{_get(bookingDetail, 'cancelPolicy.name', '--')}</Descriptions.Item>
                  {[NOVISIT, CANCELED_RESERVATION].includes(status) &&
                    <Descriptions.Item label="Cancel fee :" >{penalty * 100}%</Descriptions.Item>}

                  <Descriptions.Item label="開始時間:" >
                    {slot ? renderDate(slot.toString().padStart(4, '0'), 'HH:mm', 'HHmm') : '--'}
                  </Descriptions.Item>
                  <Descriptions.Item label="終了時間:" >
                    {slot ? moment(slot.toString().padStart(4, '0'), 'HHmm')
                      .add(spendTime, 'm')
                      .format('HH:mm') : '--'}
                  </Descriptions.Item>
                  <Descriptions.Item label="合計所要時間:" >{spendTime || '--'} minutes</Descriptions.Item>

                  <Descriptions.Item label="予約時の金額:" >¥{addCommaToString(price) || '--'}</Descriptions.Item>
                  {status !== REQUESTED && <Descriptions.Item label="最終金額:" >
                    {renderFinalAmount(bookingDetail)}
                  </Descriptions.Item>}
                  {memoAdditionalPrice &&
                    <Descriptions.Item label="金額変更メモ:" >{memoAdditionalPrice || '--'}</Descriptions.Item>}

                  <Descriptions.Item label="クーポンコード:" >
                    <div className={couponValue ? 'coupon-code' : ''}>{couponCode}</div>
                  </Descriptions.Item>
                  {couponValue && <Descriptions.Item label="クーポン金額:" >
                    <div className="coupon-price">¥{addCommaToString(couponValue)}</div>
                  </Descriptions.Item>}
                  {status === UNDONE && <>
                    <Descriptions.Item label="予約状況" >{reason}</Descriptions.Item>
                    <Descriptions.Item label="緊急事態の内容">{memo}</Descriptions.Item>
                    <Descriptions.Item label="Check">
                      {isChecked ? <CheckSquareOutlined /> : <CloseSquareOutlined />} 対応済み
                    </Descriptions.Item>
                  </>}

                  <Descriptions.Item label="利用ポイント:" >{usePoint} P</Descriptions.Item>
                  {<Descriptions.Item label="お客様お支払額:" >
                    {renderPaymentAmount(bookingDetail)}
                  </Descriptions.Item>}
                  {status === DONE && earnPointRate && <Descriptions.Item label="Point GET:" >
                    {Math.floor(earnPointRate * ((additionalPrice || price) - couponValue - usePoint) * pointBonusRate)} P
                  </Descriptions.Item>}
                  {userNote && <Descriptions.Item label="注意:" >
                    {userNote.trim()}
                  </Descriptions.Item>}
                </Descriptions>
              </Card>
              <Card title="メニュー">
                <div className="menu-list">
                  <Table
                    columns={menuColumns}
                    dataSource={menuBookings}
                    pagination={false}
                  />
                </div>
              </Card>
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane tab="編集履歴" key="updated-history">
            <ChangedHistoryTab updatedHistory={updatedHistory} currentData={bookingDetail}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="DM" key="DM">
            <DirectMessage />
          </Tabs.TabPane>
          {status === DONE && <Tabs.TabPane tab="Review" key="Review">
            <Review />
          </Tabs.TabPane>}
        </Tabs>
      </div>
    </Fragment>
  );
};

BookingDetail.propTypes = {
  booking: PropTypes.object,
  relatedReceipts: PropTypes.array,
  fetchRelatedReceiptList: PropTypes.func,
  fetchReceiptBookingDetail: PropTypes.func
};

export default BookingDetail;