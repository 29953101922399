import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Card } from 'antd';

import UnlinkPhoneForm from './UnlinkPhoneForm';

const UnlinkPhonePage = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Support</title>
      </Helmet>
      <PageHeader
        title="Support Operator"
        breadcrumb={null}
      />
      <div className="page-container" id="unlink-phone-page">
        <Card title='Unlink phone' style={{ width: '100%', height: 'fit-content' }}>
          <UnlinkPhoneForm />
        </Card>
      </div>
    </>
  );
};

export default UnlinkPhonePage;
