import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Select as AntSelect, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { getPeriodDate } from 'utils/common';
import { TextField, Select, RangePicker } from 'components/Form';

const { Option } = AntSelect;

const lastMonth = moment().add(-1, 'M');

const FilterForm = ({ handleSubmit, filters, formId, initialValues }) => {
  const range = {
    'Last period': getPeriodDate(
      moment().get('date') >= 16 ? moment().startOf('M') : lastMonth.endOf('M')
    ).map((p) => moment(p)),
    'Last Month': [
      getPeriodDate(lastMonth.startOf('M'))[0],
      getPeriodDate(lastMonth.endOf('M'))[1]
    ].map((p) => moment(p))
  };

  return (
    <Form layout="inline" onSubmit={handleSubmit} id={formId} initialValues={initialValues}>
      {_.includes(filters, 'keyword') && (
        <Field
          name="keyword"
          placeholder="Search By Name, Phone, Email..."
          component={TextField}
          allowClear
          suffix={<SearchOutlined />}
        />
      )}
      {_.includes(filters, 'date-range') && (
        <Field
          name="date-range"
          placeholder={['From date', 'To date']}
          component={(props) => <RangePicker ranges={range} {...props} />}
        />
      )}
      {_.includes(filters, 'subType') && (
        <Field name="subType" placeholder="Type" component={Select} className="type-select-field">
          <Option key="REQUEST" value="REQUEST">
            サロンマネージャーを利用申請したい
          </Option>
          <Option key="INFORMATION" value="INFORMATION">
            サロンマネージャーの詳細を知りたい
          </Option>
        </Field>
      )}
      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

FilterForm.propTypes = {
  handleSubmit: PropTypes.any,
  filters: PropTypes.array
};

export default FilterForm;
