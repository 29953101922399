import { message } from 'antd';
import { put, call, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import { getAccountNoBankSuccess } from './actions';
import { GET_ACCOUNT_NO_BANK } from './constants';

function* handleGetAccountNoBank(action) {
  try {
    const params = action.payload;
    const { data } = yield call(
      request,
      '/payout/getMissingBankInforAccountsOfPeriod',
      params,
      'POST'
    );
    yield put(getAccountNoBankSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

export default function* watchAccountNoBank() {
  yield takeLeading(GET_ACCOUNT_NO_BANK, handleGetAccountNoBank);
}
