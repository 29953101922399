import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import GoToNail from './GoToNail';
import Olympic from './Olympic';
import FriendInvitationList from './FriendInvitation/List';
import GoToNail2 from './GoToNail2';
import GotoNail3 from './GotoNail3';
import InstagramCoupon from './InstagramCoupon';

const CampaignInfo = () => (
  <>
    <Route
      exact
      path="/dashboard/campaign-info/instagram"
      component={InstagramCoupon}
    />
    <Route
      exact
      path="/dashboard/campaign-info/go-to-nail-3"
      component={GotoNail3}
    />
    <Route
      exact
      path="/dashboard/campaign-info/go-to-nail-2"
      component={GoToNail2}
    />
    <Route
      exact
      path="/dashboard/campaign-info/go-to-nail"
      component={GoToNail}
    />
    <Route
      exact
      path="/dashboard/campaign-info/friend-invitation"
      component={FriendInvitationList}
    />
    <Route
      exact
      path="/dashboard/campaign-info/olympic"
      component={Olympic}
    />
    <Route
      exact
      path="/dashboard/campaign-info"
      component={() => <Redirect to="/dashboard/campaign-info/go-to-nail" />}
    />
  </>
);

export default CampaignInfo;
