import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';

import 'antd/dist/antd.css';

import { PublicRoutes, PrivateRoutes } from './routes';
import { me } from 'providers/AuthProvider/actions';

const App = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(me());
  }, [token]);

  return (
    <div className="app-wrapper">
      <Helmet>
        <title>Nailie Dashboard</title>
      </Helmet>
      <Switch>
        <Route path="/dashboard" component={PrivateRoutes} />
        <Route component={PublicRoutes} />
      </Switch>
    </div>
  );
};

App.propTypes = {
  getCurrentUserRequest: PropTypes.func
};

export default App;
