import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Form, DatePicker, Select, Button, List } from 'antd';
import { CloudDownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  API_SERVER_URL
} from 'utils/config';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchExportBookingFilesList, requestBookingFile } from 'providers/BookingProvider/actions';
import { getPeriodDate } from 'utils/common';
import { useSelector } from 'react-redux';
import { useInterval } from 'hooks';
import './styles.scss';

const { Option } = Select;

const Content = () => {
  const { filesList } = useSelector(state => state.booking.exportBooking);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const now = moment();
  const lastPeriodDate = now.get('date') >= 16
    ? moment().startOf('M')
    : moment().add(-1, 'M').endOf('M');

  useEffect(() => {
    dispatch(fetchExportBookingFilesList());
  }, []);
  useInterval(() => dispatch(fetchExportBookingFilesList()), 5000);

  return (
    <Fragment>
      <Form
        layout="inline"
        form={form}
        className="search-bar"
        name="payoutChangedForm"
        initialValues={{
          currentDate: moment(lastPeriodDate, 'YYYY-MM'),
          period: now.get('date') > 15 ? '0' : '1',
          baseFilterField: 'bookingDate',
        }}
        onFinish={async values => {
          let searchDate = values.currentDate.clone();
          let [dateFrom, dateTo] = getPeriodDate(searchDate);
          if (values.period === '0') {
            searchDate = searchDate.startOf('month');
            [dateFrom, dateTo] = getPeriodDate(searchDate);
          } else if (values.period === '1') {
            searchDate = searchDate.endOf('month');
            [dateFrom, dateTo] = getPeriodDate(searchDate);
          } else if (values.period === 'full-month') {
            [dateFrom, dateTo] = [searchDate.startOf('month').toDate(), searchDate.endOf('month').toDate()];
          }

          await dispatch(requestBookingFile({
            dateFrom: moment(dateFrom).startOf('day').utc(true).format(),
            dateTo: moment.utc(dateTo).endOf('day').utc(true).format(),
            baseFilterField: values.baseFilterField,
          }));
          dispatch(fetchExportBookingFilesList());
        }}
      >
        <Form.Item name="currentDate">
          <DatePicker
            allowClear={false}
            picker="month"
          />
        </Form.Item>
        <Form.Item name="period">
          <Select
            style={{ width: 160 }}
          >
            <Option value={'0'}>First period</Option>
            <Option value={'1'}>Second period</Option>
            <Option value={'full-month'}>Full month</Option>
          </Select>
        </Form.Item>
        <Form.Item name="baseFilterField">
          <Select
            style={{ width: 160 }}
            allowClear={false}
          >
            <Option value={'bookingDate'}>施術日</Option>
            <Option value={'chargeDate'}>決済日</Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<CloudDownloadOutlined />}>Create CSV File</Button>
      </Form>
      <br />
      <List
        size="small"
        bordered
        dataSource={filesList}
        renderItem={item => <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a href={`${API_SERVER_URL}/booking-csv/${item.name}`} className={item.notReady ? 'inactive-link' : undefined} target='_blank' rel="noreferrer">
            <div style={{ textAlign: 'left' }}><CloudDownloadOutlined />{' '}{item.name}</div>
          </a>
          <span>{item.notReady ? <LoadingOutlined/> : moment(item.createdAt).locale('ja').fromNow()}</span>
        </List.Item>
        }
      />
    </Fragment>
    
  );
};

const useExportBookingModalControl = () => {
  const [ isOpen, setOpen ] = useState(false);

  const Popup = <Modal
    width={900}
    title='Export bookings'
    visible={isOpen}
    destroyOnClose
    onCancel={() => setOpen(false)}
    // okText="Save"
    okButtonProps={{ style: { display: 'none' } }}
  >
    <Content />
  </Modal>;

  const openPopup = () => setOpen(true);

  return [openPopup, Popup];
};

export default useExportBookingModalControl;
