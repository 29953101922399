// Checked
import produce from 'immer';

import {
  GET_BOOKING_FEE_SETTING_SUCCESS,
  SET_BOOKING_FEE_SETTING,
  SET_BOOKING_FEE_SETTING_SUCCESS
} from './constants';

const initialState = {
  bookingFee: [],
  actionLoading: false
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_FEE_SETTING_SUCCESS:
      state.bookingFee = action.payload.data;
      return state;

    case SET_BOOKING_FEE_SETTING:
      state.actionLoading = true;
      return state;

    case SET_BOOKING_FEE_SETTING_SUCCESS:
      state.actionLoading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
