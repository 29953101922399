import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BookingList from './BookingList';
import BookingDetail from './BookingDetail';

const RedirectToBookingList = () => (
  <Redirect to="/dashboard/booking-management/list" />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Booking</title>
      </Helmet>
      <Switch>
        <Route
          path="/dashboard/booking-management/list/:type(card-error)?"
          exact
          component={BookingList}
        />
        <Route
          path='/dashboard/booking-management/list/:bookingId/:activeTab?'
          exact
          component={BookingDetail}
        />
        <Route path="/dashboard/booking-management" component={RedirectToBookingList} />
      </Switch>
    </>
  );
};

export default Router;
