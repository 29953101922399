import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
import PageHeader from 'components/PageHeader';

import { Table, Card, Space, Button, Avatar, message, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { fetchRewardPointList, createRewardPoint } from 'providers/RewardPointsProvider/actions';
import {
  makeRewardPointList
} from 'providers/RewardPointsProvider/selectors';

import columnsTemplate from './Preset/columns';
import RewardPointsModal from './RewardPointsPopup';

import './styles.scss';

const colorDef = {
  SUCCESS: 'success',
  PENDING: 'gold',
  POINT_RECEIVED: 'blue'
};

const RewardPointsPage = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dataList = useSelector(makeRewardPointList);
  const [filterParams, setFilterParams] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({});

  const defaultFilter = {
    limit: 10,
    direction: 'descend',
    sortField: '_created_at',
    page: 1
  };

  const columns = [
    {
      ...columnsTemplate.createdAt,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: filterParams?.direction || defaultFilter.direction
    },
    columnsTemplate.id,
    {
      ...columnsTemplate.name,
      width: 200,
      render: (record) => (
        <Space align="center" size="middle">
          <Avatar
            icon={<UserOutlined />}
            src={_.get(record, 'userAvatar')}
          />
          <div>{_.get(record, 'userName')}</div>
        </Space>
      )
    },
    columnsTemplate.points,
    columnsTemplate.title,
    columnsTemplate.content,
    {
      ...columnsTemplate.status,
      render: (record) => <Tag color={colorDef[record]}>{record}</Tag>
    }
  ];

  useEffect(() => {
    setFilterParams(defaultFilter);
    dispatch(fetchRewardPointList({
      limit: 10,
      direction: 'descend',
      sortField: '_created_at',
      page: 1
    }));
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...filterParams,
      page: pagination.current,
      limit: pagination.pageSize,
      sortField: _.get(sorter, 'field'),
      direction: _.get(sorter, 'order', 'descend')
    };
    setCurrentPage(pagination.current);
    setFilterParams(parsed);
    dispatch(fetchRewardPointList({ ...parsed }));
  };

  const handleSubmit = (values) => {
    const params = {
      title: _.get(values, 'title', 'ポイントGET!!'),
      content: _.get(values, 'content', 'おめでとうございます')
    };
    setInitialFormValues({ ...values, ...params });
    dispatch(createRewardPoint({ ...values, ...params }))
      .then((res) => {
        if (_.isEqual(_.get(res, 'data.userName'), values.customer) ||
        _.isEqual(_.get(res, 'data.userId'), values.customer)) {
          onTableChange({ current: 1, pageSize: 10 }, {}, {
            columnKey: 'createdAt',
            field: '_created_at',
            order: 'descend'
          });
          message.success(`Add reward points to ${_.get(res, 'data.userName')} successful!`);
          handleCloseModal();
        }
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Reward points</title>
      </Helmet>
      <PageHeader
        title="Reward points"
        breadcrumb={PageHeader.Bread.rewardPoints}
      />
      <div className="page-container" id="reward-points" style={{ display: 'block' }}>
        <Card bordered={false}>
          <div className="reward-points-section" style={{ marginBottom: '16px', textAlign: 'right' }}>
            <Space>
              <Button type="primary" onClick={() => setShowModal(true)}>ポイントプレゼント</Button>
            </Space>
          </div>

          <Table
            columns={columns}
            dataSource={dataList.list}
            onChange={onTableChange}
            scroll={{ x: 'max-content' }}
            pagination={{
              total: dataList.total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: currentPage,
              showSizeChanger: false
            }}
            rowKey={(record) => record._id}
            loading={dataList.loading}
          />
        </Card>
      </div>
            
      {
        showModal && (
          <RewardPointsModal
            closeModal={handleCloseModal}
            onSubmit={handleSubmit}
          />
        )
      }
    </Fragment>
  );
};

export default RewardPointsPage;
