import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card } from 'antd';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import PageHeader from 'components/PageHeader';
import useTableControl from 'containers/Customer/hooks/useTableControl';
import { fetchNailistDisabledList } from 'providers/NailistProvider/actions';
import FilterForm from './Preset/FilterForm';
import columns from './Preset/columns';
import Rejoin from './Preset/rejoin';

const NailistDisabled = () => {
  const dispatch = useDispatch();
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const [reload, setReload] = useState(false);
  useEffect(() => {
    dispatch(fetchNailistDisabledList({ page: 1, limit: 10, ...search }));
  }, [reload, search]);
  const { loading, list, total } = useSelector(state => state.nailist.disabled);

  const nailistDisabledColumns = [
    columns._id,
    columns.createdDate,
    columns.username,
    columns.fullName,
    columns.email,
    columns.phone,
    {...columns.rejoin, 
      render: (record) => <Rejoin nailistId={record} setReload={setReload} reload={reload}/>}
  ];

  const handleFilterFormChange = ({ keyword = '' }) => {
    const values = { ...search, keyword: keyword.trim() };
    handleSubmitFilterForm(values);
  };

  return (
    <div>
      <PageHeader
        title="Disabled nailist"
        breadcrumb={PageHeader.Bread.nailistDisabled}
      />
      <div className="page-container" id="new-booking-list" style={{ display: 'block' }}>
        <Card>
          <FilterForm onSubmit={handleFilterFormChange} initialValues={_.pick(search, ['keyword'])} />
          <Table
            rowKey='_id'
            columns={nailistDisabledColumns}
            dataSource={list}
            scroll={{ x: 1000 }}
            onChange={handleTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            loading={loading}
          />
        </Card>

      </div>
    </div>
  );
};

export default NailistDisabled;