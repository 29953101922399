/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { Button, Card, Empty, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import multiIcon from 'assets/icons/multi.png';
import usePostDetail from '../hooks/usePostDetail';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNailistPostDetail, resetPostsProvider } from 'providers/NailistProvider/actions';
import { formatYen } from 'utils/stringFormat';
import './styles.scss';

const LIMIT_PAGE = 100;

const Posts = ({ nailistId, fetchNailistPosts }) => {
  const dispatch = useDispatch();
  // state
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePosts, setHasMorePost] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const listPosts = useSelector(state => state?.nailist.profile?.posts?.list);
  const [postDetail, setPostDetail] = useState({});

  useEffect(() => {
    setLoadingPosts(true);
    fetchNailistPosts({ id: nailistId, currentPage }).then((result) => {
      if (result?.length < LIMIT_PAGE) { // no more posts
        setHasMorePost(true);
      }
      setLoadingPosts(false);
    });
  }, [nailistId, currentPage]);

  const getPostDetail = (postId) => {
    dispatch(fetchNailistPostDetail({ postId })).then((data) => {
      if (data?.code === 500) {
        message.error('Post not found');
        setPostDetail({ ...data, deletedPostId: postId });
      } else {
        setPostDetail(data);
      }
    });
  };
  useEffect(() => {
    return () => dispatch(resetPostsProvider());
  }, []);

  const [openPostDetailPopup, PostDetailPopup] = usePostDetail(postDetail, nailistId, fetchNailistPosts, () => setPostDetail({}));

  if (_isEmpty(listPosts) && !loadingPosts) {
    return (
      <Card className="nailist-profile-tab" title="Nailist post">
        <Empty description={false} />
      </Card>
    );
  }
  return (
    <Card className="nailist-posts-tab" title="Nailist post" >
      {loadingPosts && <div
        id="big-spin"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin size="large" />
      </div>}
      <div className="posts-wrapper">
        <div className="posts-container">
          {
            listPosts.map(p => {
              const isMutipleImg = p?.nailImages?.length > 1 ? true : false;
              return (
                <div className="post-item" onClick={async() => {
                  await getPostDetail(p?.objectId);
                  await openPostDetailPopup();
                }}>
                  <img
                    className="post-img"
                    src={p?.nailImage}
                  />
                  {isMutipleImg && <img src={multiIcon} className="multi-icon" />}
                  {
                  p?.taggedMenuPrice > 0 && 
                    <span className="price-tag">{formatYen(p?.taggedMenuPrice)}</span>
                  }
                </div>
              );
            })
          }
        </div>

      </div>
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        {
          (listPosts?.length > 0 && !hasMorePosts) &&
          <Button
            type="primary"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            disabled={hasMorePosts}
            loading={loadingPosts}
          >LOAD MORE</Button>
        }
      </div>
      {PostDetailPopup}
    </Card>
  );
};

Posts.propTypes = {
  data: PropTypes.object.isRequired,
  nailistId: PropTypes.string.isRequired,
  fetchNailistPosts: PropTypes.func.isRequired
};

export default Posts;