import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Space, Divider } from 'antd';

import { TextField, RichText, TextArea } from 'components/Form';
import validate from './validate';

const EditQuestionPopup = ({
  closeModal,
  handleSubmit,
  actionLoading,
  initialValues
}) => {
  return (
    <Modal
      title={initialValues.title}
      visible={true}
      onOk={handleSubmit}
      confirmLoading={actionLoading}
      onCancel={closeModal}
      destroyOnClose={true}
      width={920}
    >
      <Form onSubmit={handleSubmit} className="edit-question-form edit-salon-ticket-form">
        <Space align='start' size="large" split={<Divider type="vertical" />}>
          <Space direction="vertical" className="space-content">
            <Field
              name="fullName"
              type="text"
              placeholder="Full name"
              component={TextField}
              label="Full name"
              readOnly={true}
            />
            <Field
              name="email"
              type="text"
              placeholder="email"
              component={TextField}
              label="Email"
              readOnly={true}
            />
            <Field
              name="question"
              placeholder="question"
              component={RichText}
              label="Question"
              readOnly={true}
            />
          </Space>
          <Space direction="vertical" className="space-content">
            <Field
              name="subject"
              placeholder="subject"
              component={TextField}
              label="Subject"
              required
            />
            <Field
              name="answer"
              placeholder="answer"
              component={TextArea}
              label="Answer"
              required
            />
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

EditQuestionPopup.propTypes = {
  closeModal: PropTypes.any,
  handleSubmit: PropTypes.any,
  actionLoading: PropTypes.any,
  applyFromMonth: PropTypes.any
};

export default connect((state) => {})(reduxForm({ form: 'editSalonTicketsForm', validate })(EditQuestionPopup));
