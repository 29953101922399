import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd';

import { countUnreadSalonTickets } from 'providers/SupportProvider/actions';
import { makeCountUnreadSalonTickets } from 'providers/SupportProvider/selectors';

const CountSalonTickets = ({ text }) => {
  const dispatch = useDispatch();
  const count = useSelector(makeCountUnreadSalonTickets);

  useEffect(() => {
    dispatch(countUnreadSalonTickets());}, []);
  
  return count > 0 ? (
    <Badge count={count} showZero>
      {text}
    </Badge>
  ) : <>{text}</>
}

export default CountSalonTickets;
