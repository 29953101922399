import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'components/PageHeader';
import moment from 'moment';

import PopupBannerForm from '../_Component/PopupBannerForm';
import { Redirect, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

moment.locale('ja');

const EditPopupBanner = () => {
  const { objectId } = useParams();
  const bannerDetail = useSelector(state => state.popupBanner?.search?.list?.filter(r => r.objectId === objectId)[0]);

  if (isEmpty(bannerDetail)) {
    return <Redirect to='/dashboard/popup-banner'></Redirect>;
  }

  const initialValues = {
    showOnRoles: bannerDetail.showOnRoles || [],
    rectBannerImage: bannerDetail.rectBannerImage ? { objectId: '', data: bannerDetail.rectBannerImage } : '',
    linkType: bannerDetail.linkTo.type || 'HTML', // linkType: 'IMAGE' || 'HTML'
    linkContentDetail: bannerDetail.linkTo.type === 'HTML' ? bannerDetail.linkTo.location : '',
    linkImage: bannerDetail.linkTo.type === 'IMAGE' ? { data: bannerDetail.linkTo.location, objectId: '1'} : '',
    startEndTime: bannerDetail.startTime?.iso && bannerDetail.endTime?.iso
      ? [moment(bannerDetail.startTime?.iso), moment(bannerDetail.endTime?.iso)]
      : undefined
  };

  const updateObjectId = bannerDetail.objectId;

  return (
    <div id="Edit-banner">
      <Helmet>
        <title>Nailie Dashboard | Edit Promotion Popup</title>
      </Helmet>
      <PageHeader
        title="Edit Promotion Popup"
        breadcrumb={PageHeader.Bread.editPromoPopupBanner}
        goBack={true}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <PopupBannerForm initialValues={initialValues} updateObjectId={updateObjectId}/>
      </div>
    </div>
  );
};

export default EditPopupBanner;