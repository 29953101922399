// Checked
import _ from 'lodash';

export const makeSalonSupportUnansweredTickets = (state) => {
  return _.get(state, 'support.supportSalon.dataUnanswered');
};
export const makeSalonSupportUnansweredLoading = (state) => {
  return _.get(state, 'support.supportSalon.dataUnanswered.loading');
};
export const makeSalonUnansweredFilter = (state) => {
  return _.get(state, 'support.supportSalon.filterUnanswered');
};
export const makeSalonSupportAnsweredTickets = (state) => {
  return _.get(state, 'support.supportSalon.dataAnswered');
};
export const makeSalonSupportAnsweredLoading = (state) => {
  return _.get(state, 'support.supportSalon.dataAnswered.loading');
};
export const makeSalonAnsweredFilter = (state) => {
  return _.get(state, 'support.supportSalon.filterAnswered');
};
export const makeCountUnreadSalonTickets = (state) => {
  return _.get(state, 'support.countSalon');
};

export const makeWebSupportUnansweredTickets = (state) => {
  return _.get(state, 'support.supportWeb.dataUnanswered');
};
export const makeWebSupportUnansweredLoading = (state) => {
  return _.get(state, 'support.supportWeb.dataUnanswered.loading');
};
export const makeWebUnansweredFilter = (state) => {
  return _.get(state, 'support.supportWeb.filterUnanswered');
};
export const makeWebSupportAnsweredTickets = (state) => {
  return _.get(state, 'support.supportWeb.dataAnswered');
};
export const makeWebSupportAnsweredLoading = (state) => {
  return _.get(state, 'support.supportWeb.dataAnswered.loading');
};
export const makeWebAnsweredFilter = (state) => {
  return _.get(state, 'support.supportWeb.filterAnswered');
};
export const makeCountUnreadWebTickets = (state) => {
  return _.get(state, 'support.countWeb');
};

export const makeAppSupportUnansweredTickets = (state) => {
  return _.get(state, 'support.supportApp.dataUnanswered');
};
export const makeAppSupportUnansweredLoading = (state) => {
  return _.get(state, 'support.supportApp.dataUnanswered.loading');
};
export const makeAppUnansweredFilter = (state) => {
  return _.get(state, 'support.supportApp.filterUnanswered');
};
export const makeAppSupportAnsweredTickets = (state) => {
  return _.get(state, 'support.supportApp.dataAnswered');
};
export const makeAppSupportAnsweredLoading = (state) => {
  return _.get(state, 'support.supportApp.dataAnswered.loading');
};
export const makeAppAnsweredFilter = (state) => {
  return _.get(state, 'support.supportApp.filterAnswered');
};
export const makeCountUnreadAppTickets = (state) => {
  return _.get(state, 'support.countApp');
};

export const makeVerificationSupportUnansweredTickets = (state) => {
  return _.get(state, 'support.supportVerification.dataUnanswered');
};
export const makeVerificationSupportUnansweredLoading = (state) => {
  return _.get(state, 'support.supportVerification.dataUnanswered.loading');
};
export const makeVerificationUnansweredFilter = (state) => {
  return _.get(state, 'support.supportVerification.filterUnanswered');
};
export const makeVerificationSupportAnsweredTickets = (state) => {
  return _.get(state, 'support.supportVerification.dataAnswered');
};
export const makeVerificationSupportAnsweredLoading = (state) => {
  return _.get(state, 'support.supportVerification.dataAnswered.loading');
};
export const makeVerificationAnsweredFilter = (state) => {
  return _.get(state, 'support.supportVerification.filterAnswered');
};
export const makeCountUnreadVerificationTickets = (state) => {
  return _.get(state, 'support.countVerification');
};

export const makeDeleteUnansweredTicket = (state) => {
  return _.get(state, 'support.deleteUnanswerTicket');
}

export const makeDeleteAnsweredTicket = (state) => {
  return _.get(state, 'support.deleteAnswerTicket');
}

export const makeStatusReplyTicket = (state) => {
  return _.get(state, 'support.replyTicket');
}
