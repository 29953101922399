import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import RichTextEditor from 'react-rte';

const RichText = (props) => {
  const {
    label,
    placeholder,
    input,
    meta: { touched, invalid, error },
    required,
    readOnly
  } = props;
  const [valueRichtext, setValueRichtext] = useState(RichTextEditor.createEmptyValue());
  // console.log('props: ', props);
  useEffect(() => {
    setValueRichtext(RichTextEditor.createValueFromString(input.value, 'html'));
  }, []);

  const handleChange = (value) => {
    setValueRichtext(value);
    input.onChange(value.toString('html'));
  }

  return (
    <Form.Item
      label={label}
      help={touched && invalid && error}
      validateStatus={touched && invalid && error && 'error'}
      required={required}
    >
      <RichTextEditor
        className="rich-text-item"
        toolbarClassName="rich-text-toolbar"
        editorClassName="rich-text-editor"
        // {...input}
        placeholder={placeholder}
        value={valueRichtext}
        onChange={handleChange}
        readOnly={readOnly}
      />
    </Form.Item>
  );
}

RichText.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool
};

export default RichText;
