import moment from 'moment';
import _get from 'lodash/get';

export function useUserLastCheckin(userObj) {
  let platform;
  let timeCheckin = 'before version 7.5.0';
  const lastCheckinWebAt = _get(userObj, 'lastCheckinWebAt');
  const lastCheckinAppAt = _get(userObj, 'lastCheckinAppAt');

  if (lastCheckinWebAt && (!lastCheckinAppAt || lastCheckinWebAt >= lastCheckinAppAt)) {
    platform = 'WEB';
    timeCheckin = moment(lastCheckinWebAt).format('YYYY-MM-DD HH:mm');
  } else {
    platform = 'APP';
    timeCheckin = lastCheckinAppAt ? moment(lastCheckinAppAt).format('YYYY-MM-DD HH:mm') : timeCheckin;
  }

  return `${timeCheckin} ~ [${platform}]`;
}