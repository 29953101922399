import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';

const { Option } = Select;

class SelectField extends React.PureComponent {
  handleChange = (e) => {
    const { input, onFormValueChange } = this.props;
    input.onChange(e || '');
    if (onFormValueChange) {
      onFormValueChange(e);
    }
  };

  render() {
    const {
      label,
      placeholder,
      input,
      options,
      disabled,
      showSearch,
      filterOption,
      allowClear,
      meta: { touched, invalid, error }
    } = this.props;

    const optionElements =
      this.props.children ||
      (options &&
        options.map((option) => (
          <Option key={option.objectId} value={option.objectId}>
            {option.name}
          </Option>
        )));

    return (
      <Form.Item
        label={label}
        help={touched && invalid && error}
        validateStatus={touched && invalid && error && 'error'}
      >
        <Select
          {...input}
          allowClear={allowClear}
          showSearch={showSearch}
          filterOption={filterOption}
          placeholder={placeholder}
          value={input.value || undefined}
          onChange={this.handleChange}
          onBlur={(e) => e.preventDefault()}
          disabled={disabled}
        >
          {optionElements}
        </Select>
      </Form.Item>
    );
  }
}

SelectField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  meta: PropTypes.object.isRequired,
  children: PropTypes.any,
  onFormValueChange: PropTypes.any,
  disabled: PropTypes.bool,
  showSearch: PropTypes.any,
  filterOption: PropTypes.any,
  allowClear: PropTypes.bool
};

export default SelectField;
