import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_RECEIPT_LIST_SUCCESS,
  FETCH_RELATED_RECEIPT_LIST_SUCCESS
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1
  },
  related: {
    list: []
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECEIPT_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;
    case FETCH_RELATED_RECEIPT_LIST_SUCCESS:
      state.related.list = _.get(action, 'payload.data');
      return state;
    default:
      return state;
  }
});

export default reducer;
