import React, { useEffect, useState } from 'react';
import { Button, Switch, Popconfirm, Image } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card } from 'antd';
import columnsTemplate from '../_Component/Preset/columnsTemplate';
import PageHeader from 'components/PageHeader';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import useTableControl from 'containers/Customer/hooks/useTableControl';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { fetchPopupBannerList, togglePopupBannerStatus, updatePopupBannersPosition } from 'providers/PopupBannerProvider/actions';
import { Link } from 'react-router-dom';

import FilterForm from '../_Component/Preset/FilterForm';

import './styles.scss';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const SortableItem = sortableElement((props) => <tr {...props} />);
const DragableBodyRow = ({ ...restProps }) => <SortableItem index={restProps['data-row-key']} {...restProps} />;

const BannerList = () => {
  const dispatch = useDispatch();
  const [updatingLoading, setUpdatingLoading] = useState(false);
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  useEffect(() => {
    dispatch(fetchPopupBannerList({ page: 1, limit: 10, ...search }));
  }, [search]);
  const { loading, list, total } = useSelector(state => state.popupBanner.search);

  const handleToggleBannerStatus = (id, status) => {
    dispatch(togglePopupBannerStatus({ id, status }));
  };

  let columns = [
    // ...(search.status === 'ACTIVE' || search.status === undefined ? [{
    //   width: 30,
    //   className: 'drag-visible',
    //   render: () => <div style={{ paddingLeft: 45 }}>{<DragHandle />}</div>
    // }] : []),
    columnsTemplate.id,
    {
      title: '画像',
      className: 'drag-visible',
      width: 160,
      render: (record) => (
        <Image
          shape="square"
          size="large"
          src={record?.rectBannerImage || record?.bannerImage}
          width={120}
        />
      )
    },
    columnsTemplate.target,
    columnsTemplate.createdAt,
    columnsTemplate.startEnd,
    columnsTemplate.status,
    {
      title: '',
      className: 'drag-visible',
      render: (record) => (
        <Popconfirm
          title ='Are you sure you want to change the status?'
          onConfirm={() => handleToggleBannerStatus(record?.objectId, record?.status)}
          okText="Yes"
          cancelText="No"
        >
          <Switch checked={record?.status === 'ACTIVE'} />
        </Popconfirm>
      )
    },
    ...(search.status === 'ACTIVE' || search.status === undefined ? [{
      key: 'action',
      className: 'drag-visible',
      title: '',
      render: (record) => (
        <Link to={'popup-banner/edit/' + record.objectId}>
          <Button type='primary'>
            Edit
          </Button>
        </Link>
      )
    }] : [])
  ];


  const onSortEnd = async (e) => {
    const { oldIndex, newIndex } = e;
    if (oldIndex !== newIndex) {
      setUpdatingLoading(true);
      const newList = arrayMoveImmutable(list, oldIndex, newIndex);
      try {
        await dispatch(updatePopupBannersPosition({ oldList: [...list], newList}));
      } finally {
        setUpdatingLoading(false);
      }
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  return (
    <div>
      <PageHeader
        title="Promotion Popup"
        breadcrumb={PageHeader.Bread.promoPopupBanner}
      />
      <div className="page-container" id="banner-list" style={{ display: 'block' }}>
        <Card style={{ marginBottom: 8 }}>
          <Link to={'/dashboard/popup-banner/create'}>
            <Button type='primary' style={{ background: '#70C28B', borderColor: 'green' }} icon={<PlusOutlined />}>Add new promotion popup</Button>
          </Link>
        </Card>
        <Card>
          <FilterForm
            onSubmit={handleSubmitFilterForm}
            initialValues={{
              status: search.status || 'ACTIVE',
              showOnRoles: search.showOnRoles
            }}
          />
          <Table
            columns={columns}
            dataSource={list.map((a, index) => ({ ...a, index, key: a.id }))}
            scroll={{ x: true }}
            onChange={handleTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            loading={loading || updatingLoading}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DragableBodyRow
              }
            }}
          />
        </Card>

      </div>
    </div>
  );
};

export default BannerList;