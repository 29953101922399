import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { uploadImage } from 'providers/SupportProvider/actions';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const toolbarContainer = [
  [{ 'header': 1 }, { 'header': 2 }], 
  ['bold', 'italic', 'underline'],
  [{'align': ''}, {'align': 'right'}, {'align': 'center'}, {'align': 'justify'}], 
  ['blockquote'], 
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['image', 'link'],
  ['clean']
];
function TextAreaField(props) {
  const dispatch = useDispatch();
  const { label, placeholder, input, type, min, max, allowClear, required, readOnly,
    meta: { touched, invalid, error }
  } = props;
  const quillRef = useRef(null);
  const [editorState, setEditorState] = useState(input.value);

  useEffect(() => {
    if (editorState !== input.value) setEditorState(input.value);
  }, [input.value]);

  const handleChange = _.debounce((content) => {input.onChange(content)}, 200);

  const imageHandler = () => {
    const quillEditor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      const formData = new FormData()
      formData.append('file', file)
      dispatch(uploadImage(formData))
        .then((res) => {
          const range = quillEditor.getSelection();
          const link = res.data
          quillEditor.insertEmbed(range.index, 'image', link)
        });
    }
  }

  return (
    <Form.Item
      label={label}
      help={touched && invalid && error}
      validateStatus={touched && invalid && error && 'error'}
      required={required}
    >
      <ReactQuill
        // {...input}
        ref={quillRef}
        placeholder={placeholder}
        theme="snow"
        className="rich-text-item"
        readOnly={readOnly}
        modules={{
          toolbar: {
            container: toolbarContainer,
            handlers: {
              'image': imageHandler
            }
          }
        }}
        formats={TextAreaField.formats}
        onChange={handleChange}
        defaultValue={editorState}
      />
    </Form.Item>
  );
}

TextAreaField.formats = [
  'header', 'bold', 'italic', 'underline', 'blockquote',
  'list', 'bullet', 'link', 'image', 'align'
]

TextAreaField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  suffix: PropTypes.any,
  allowClear: PropTypes.any,
  required: PropTypes.bool
};

export default TextAreaField;
