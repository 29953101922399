import { connect } from 'react-redux';
import { fetchCustomerList } from 'providers/CustomerProvider/actions';
import { makeSearchCustomerList } from 'providers/CustomerProvider/selectors';

import SearchCustomerPage from './SearchCustomerPage';

const mapStateToProps = (state) => ({
  list: makeSearchCustomerList(state).list,
  total: makeSearchCustomerList(state).total
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerList: (params) => dispatch(fetchCustomerList(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomerPage);