import produce from 'immer';

import { FETCH_ADMIN_OPERATOR_LIST, FETCH_ADMIN_OPERATOR_LIST_SUCCESS } from './constants';

const initialState = {
  loading: false,
  list: [],
  total: 0
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_OPERATOR_LIST:
      state.loading = true;
      return state;
    case FETCH_ADMIN_OPERATOR_LIST_SUCCESS: {
      const { data, total } = action.payload;
      state.loading = false;
      state.list = data;
      state.total = total;
      return state;
    }
    default:
      return state;
  }
});

export default reducer;
