import _ from 'lodash';
import moment from 'moment';
import qs from 'querystring';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, Typography, Table, Card, Button, Space, Avatar } from 'antd';
import { LinkOutlined, UserOutlined } from '@ant-design/icons';

import { formatYen } from 'utils/stringFormat';
import { getPeriodDate } from 'utils/common';

import BookingFee from 'components/BookingFee';
import PayoutStatus from 'components/Tag/PayoutStatus';
import PayoutPopup from 'components/Popup/PayoutPopup';
import SearchBar from '../components/SearchBar';

import '../styles.scss';

const { Text } = Typography;

const renderBookingValue = ({
  allBookingsNumber,
  uncompletedBookingsNumber,
  totalBookingValue
}) => {
  return (
    <Popover
      placement="rightTop"
      title={`Total bookings: ${allBookingsNumber}`}
      content={
        <Space direction="vertical">
          <Text>
            Charged bookings:{' '}
            <Text style={{ color: 'green' }} strong>
              {allBookingsNumber - Number(uncompletedBookingsNumber)}
            </Text>
          </Text>
          <Text>
            Uncompleted bookings:{' '}
            <Text type="warning" strong>
              {uncompletedBookingsNumber}
            </Text>
          </Text>
        </Space>
      }
    >
      <Text type="warning" underline>
        {formatYen(totalBookingValue)}
      </Text>
    </Popover>
  );
};

const renderCommission = (data) => {
  return (
    <Popover
      placement="rightTop"
      content={
        !data.bookingFeePercent ? null : (
          <Space direction="vertical">
            <Text>
              New Customer (
              {Math.round(
                (data.bookingFeePercent.firstBookingFee * 10000) / 100
              )}
              %):{' '}
              <Text style={{ color: 'green' }} strong>
                {data.bookingFeePercent.firstBookingFeeAmount}
              </Text>
            </Text>
            <Text>
              Repeated Customer (
              {Math.round((data.bookingFeePercent.bookingFee * 10000) / 100)}
              %):{' '}
              <Text type="warning" strong>
                {data.bookingFeePercent.bookingFeeAmount}
              </Text>
            </Text>
          </Space>
        )
      }
    >
      <Text type="danger" underline>
        {formatYen(-Number(data.nailieComission))}
      </Text>
    </Popover>
  );
};

class StaffPayoutPage extends React.PureComponent {
  static defaultOrderBy = 'objectId';
  static defaultOrder = 'descend';
  tableRef = null;

  updateSearchToState = () => {
    let { pathname, replace, search } = this.props;
    let reloaderFlag = false;

    if (_.has(this.props, 'nailistInfo._id') && !_.has(search, 'nailistId')) {
      search['nailistId'] = _.get(this.props, 'nailistInfo._id');
      reloaderFlag = true;
    }

    if (!_.get(search, 'orderBy') || !_.get(search, 'order')) {
      search.orderBy = 'totalBookingValue';
      search.order = -1;
    }

    if (!_.has(search, 'date-range') || !search['date-range']) {
      const lastPeriodDate =
        moment().get('date') >= 16
          ? moment().startOf('M')
          : moment().add(-1, 'M').endOf('M');
      if (_.has(search, 'nailistId')) {
        const range = [
          '2017-01-01',
          getPeriodDate(lastPeriodDate).map((date) =>
            moment(date).format('YYYY-MM-DD')
          )[1]
        ];
        search['date-range'] = range;
      } else {
        const range = getPeriodDate(lastPeriodDate).map((date) =>
          moment(date).format('YYYY-MM-DD')
        );
        search['date-range'] = range;
      }
      reloaderFlag = true;
    }

    if (reloaderFlag) {
      replace(`${pathname}?${qs.stringify(search)}`);
      return;
    }

    this.props.fetchPayoutsList({ ...search, limit: 500 });
  };

  onTableChange = (pagination, filters, sorter) => {
    const { pathname, push, search } = this.props;
    const orderBy = sorter.field;
    const order = sorter.order;
    const parsed = {
      ...search,
      page: pagination.current,
      orderBy,
      order
    };

    push(`${pathname}?${qs.stringify(parsed)}`);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { search, total, loading } = this.props;
    const currentPage = _.has(search, 'page')
      ? Number(_.get(search, 'page'))
      : 1;

    const columns = [
      {
        title: 'ID',
        width: 210,
        dataIndex: '_id',
        key: '_id'
      },
      {
        title: 'Salon name',
        key: 'salonName',
        width: 220,
        render: (record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              icon={<UserOutlined />}
              src={_.get(record, 'salon.logoUrl')}
            />
            <div style={{ marginLeft: '8px' }}>
              {_.get(record, 'salon.businessName', '--')}
            </div>
          </div>
        )
      },
      _.has(this.props, 'nailistInfo._id')
        ? {}
        : {
          title: 'ネイリストユーザーネーム',
          key: 'Nailist',
          dataIndex: 'nailist',
          width: 190,
          render: (record) => (
            <Link
              to={'/dashboard/nailist/profile/' + _.get(record, '_id')}
              target="_blank"
            >
              <div style={{ marginLeft: '8px' }}>
                <LinkOutlined />
                {' ' + _.get(record, 'name')}
              </div>
            </Link>
          )
        },
      {
        title: '売上',
        key: 'bookingValue',
        width: 120,
        className: 'column-money',
        render: (record) => renderBookingValue(record)
      },
      {
        title: 'Nailie予約手数料',
        className: 'column-money',
        key: 'commission',
        width: 130,
        render: (record) => renderCommission(record)
      },
      {
        title: '決済手数料',
        className: 'column-money',
        key: 'settlementFee',
        dataIndex: 'settlementFee',
        width: 130,
        render: (record) => (<Text type="danger" underline>-{formatYen(record)}</Text>)
      },
      {
        title: '小計',
        className: 'column-money',
        key: 'subTotal',
        width: 120,
        render: (record) => (
          <Text>
            {formatYen(
              (_.round(record.totalBookingValue) || 0) -
              (_.round(record.nailieComission) || 0) -
              (_.round(record.settlementFee) || 0)
            )}
          </Text>
        )
      },
      {
        title: '振込手数料',
        key: 'bankFee',
        dataIndex: 'bankFee',
        className: 'column-money',
        width: 120,
        render: (record) => (
          <Text style={{ color: 'red' }}>-{formatYen(record)}</Text>
        )
      },
      {
        title: '振込金額',
        dataIndex: 'transferAmount',
        key: 'transfer',
        className: 'column-money',
        width: 120,
        render: (record) => (
          <Text style={{ color: 'green' }}>{formatYen(record)}</Text>
        )
      },
      {
        title: '',
        dataIndex: '',
        key: 'fillout2',
        width: 36
      },
      {
        key: 'period',
        title: '振込サイクル',
        width: 200,
        dataIndex: 'period',
        render: (record) => (
          <Text>
            {record
              ? `${moment(record.startDate)
                .utc()
                .format('YYYY-MM-DD')} - ${moment(record.endDate)
                .utc()
                .format('YYYY-MM-DD')}`
              : ' -- '}
          </Text>
        )
      },
      {
        title: '',
        dataIndex: '',
        key: 'fillout',
        width: 'auto'
      },
      {
        title: '',
        key: 'Action',
        width: 120,
        fixed: 'right',
        render: (record) => (
          <Button onClick={() => this.props.openPayoutPopup(record)}>
            View
          </Button>
        )
      }
    ];

    return (
      <>
        <PayoutPopup />
        <div className="page-container" id="payout-page-search">
          <Card bordered={false}>
            <div className="search-section">
              <SearchBar filters={['date-range']} />
            </div>
          </Card>
          <Card bordered={false}>
            <div ref={(ref) => (this.tableRef = ref)} />
            <Table
              columns={columns}
              dataSource={this.props.payoutsList}
              scroll={{ x: 1000, y: '70vh' }}
              onChange={this.onTableChange}
              size="small"
              pagination={{
                total: total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 500,
                current: currentPage,
                showSizeChanger: false
              }}
              loading={loading}
            />
          </Card>
        </div>
      </>
    );
  }
}

StaffPayoutPage.propTypes = {
  fetchPayoutsList: PropTypes.func.isRequired,
  payoutsList: PropTypes.array,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  loading: PropTypes.bool,
  total: PropTypes.number,
  openPayoutPopup: PropTypes.func
};

export default StaffPayoutPage;
