import React from 'react';
import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import Content from './Content';

const PayoutChanged = (props) => {
  return (
    <Modal
      title="Changed payout"
      style={{ top: 36 }}
      visible={!!props.data}
      destroyOnClose={true}
      width={768}
      onCancel={props.closePopup}
      footer={null}
      closable={false}
      maskClosable={false}
      centered
      className="payout-changed-popup"
    >
      <Spin spinning={props.changeLoading}>
        <Content {...props} />
      </Spin>
    </Modal>
  );
};

PayoutChanged.propTypes = {
  data: PropTypes.any,
  closePopup: PropTypes.func
};

export default PayoutChanged;
