/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable space-before-function-paren */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Select, Space, Button, Input, Cascader, Divider} from 'antd';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import GoogleMapReact from 'google-map-react';
import {useDispatch, useSelector} from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

// components
import SalonEditPlaceOfThePin from 'containers/Salon/SalonDetailPage/SalonEditPlaceOfThePin';
import SelectAreaForm from 'containers/Salon/SalonDetailPage/SelectionAreasForm';
// providers
import {getSettingSalonProperties} from 'providers/SettingProvider/actions';
import { updateNailistProfileSalon, fetchNailistProfile } from 'providers/NailistProvider/actions';
import { getLineList, getPrefectureISOTable, getStationList, resetStationProvider } from 'providers/StationProvider/actions';
import {getAddressByPostCode, resetSalonProvider} from 'providers/SalonProvider/actions';
import searchSalonLocation from 'utils/Api/googleMapAPI';
import pinMarker from 'assets/images/pin-marker.png';

const {Option} = Select;
const {TextArea} = Input;
const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const hideFullScreenOptions = () => {
  return {
    fullscreenControl: false,
    zoomControl: false
  };
};

const UpdateNailistSalonProfileForm = ({initialValues, closePopUp, salon, nailistId}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Constants
  const longitude = _get(salon, 'geo.0');
  const latitude = _get(salon, 'geo.1');

  const [properties, setProperties] = useState([]);
  const [propertiesSelected, setPropertiesSelected] = useState(salon?.salonTypeIds || []);
  const [isSalonAreaSelectModal, setIsSalonAreaSelectModal] = useState(false);
  const [lngValue, setLngValue] = useState({lat: latitude, lng: longitude});
  const [openEditPlaceOfThePin, setOpenEditPlaceOfThePin] = useState(false);
  const [isUnpublicAddress, setUnpublicAddress] = useState(initialValues?.isPrivate);
  const _refFieldPostCode = useRef(null);
  const [defaultLng, setDefaultLng] = useState({lat: latitude, lng: longitude});
  const {prefectureISOTable, lineList} = useSelector((state) => state.station);
  const [isSelectedStation, setSelectedStation] = useState(!!salon?.salonLineId);
  const [currentPostCode, setCurrentPostCode] = useState(_get(salon, 'salonPostalCode'));
  const [prefectureCodeValue, setPrefectureCodeValue] = useState(salon?.area?.prefecture?.prefectureCode);
  const stationInitValue = initialValues?.stationId ? [`${salon?.salonStationName}`, `${initialValues?.station}駅`]:[];

  // Get prefecture ISO table and the line list first load page
  useEffect(() => {
    (async () => {
      const prefTableTmp = await dispatch(getPrefectureISOTable());
      await getLineListOptions(prefTableTmp);
      const prefectureCodes = prefTableTmp[form.getFieldValue('prefecture')]?.iso;
      const lineValue = `${salon?.salonLineId}|${salon?.salonLineName}`;
      await dispatch(getStationList({prefectureCodes, lineValue}));
    })();
  }, []);

  useEffect(() => {
    form.validateFields();
    dispatch(getSettingSalonProperties())
      .then((result) => {
        setProperties(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // Reset provider
  useEffect(() => {
    return () => {
      const currentLocation = window.location.pathname;
      setTimeout(() => {
        const newLocation = window.location.pathname;
        if (currentLocation !== newLocation) {
          dispatch(resetSalonProvider());
        }
      }, 400);
    };
  }, []);
  const renderPropertyIds = (properties, propertiesSelected, setPropertiesSelected, form) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        {properties.map((item) => {
          let styleSelected;
          const style = {
            height: 40,
            width: 40,
            borderRadius: '50%',
            borderStyle: 'solid',
            marginRight: 45,
            marginBottom: 8
          };

          // Selected Item Css
          if (propertiesSelected.includes(item._id)) {
            styleSelected = {
              filter: 'invert(30%) sepia(73%) saturate(2251%) hue-rotate(160deg) brightness(94%) contrast(104%)'
            };
          }

          return (
            <div
              key={item._id}
              style={{
                marginRight: 24,
                textAlign: 'center',
                marginBottom: 24,
                cursor: 'pointer'
              }}
              onClick={() => {
                if (propertiesSelected.includes(item._id)) {
                  // Remove
                  form.setFields([
                    {
                      name: 'propertyIds',
                      value: form.getFieldsValue().propertyIds.filter((x) => x.type !== item._id)
                    }
                  ]);
                  setPropertiesSelected(propertiesSelected.filter((element) => element !== item._id));
                } else {
                  // Add
                  form.setFields([
                    {
                      name: 'propertyIds',
                      value: [...form.getFieldsValue().propertyIds, item._id]
                    }
                  ]);
                  setPropertiesSelected([...propertiesSelected, item?._id]);
                }
              }}
            >
              <img style={{...style, ...styleSelected}} src={item?.icon} />
              <div style={{whiteSpace: 'nowrap', marginLeft: -45, ...styleSelected}}>{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };
  // load data three select station
  const loadData = async (selectedOptions) => {
    const prefecture = prefectureISOTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    const lineValue = selectedOptions[0]?.value;
    const target = _keyBy(lineList, 'value');
    const targetOption = target[lineValue];
    targetOption.loading = true;
    await dispatch(getStationList({prefectureCodes, lineValue}));
  };
  const getLineListOptions = (prefTable = prefectureISOTable) => {
    const prefecture = prefTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    setPrefectureCodeValue(prefectureCodes);
    return dispatch(getLineList({prefectureCodes}));
  };
  const getSalonLocation = () => {
    const salonAddress =
      '日本 〒' +
      form.getFieldValue('salonPostalCode') +
      form.getFieldValue('prefecture') +
      form.getFieldValue('cityOrTown') +
      form.getFieldValue('address') +
      form.getFieldValue('building');
    searchSalonLocation(salonAddress).then((result) => {
      setLngValue({lat: result?.lat, lng: result?.lng});
      setDefaultLng({lat: result?.lat, lng: result?.lng});
    });
  };
  const onChangeLineOption = (value, selectedOptions) => {
    if (value.length === 2) {
      setSelectedStation(true);
    } else {
      // behavior is the same Nailie App: Clear those field when select new or clear station
      form.setFieldsValue({walkingTime: '', navigation: '', station: ''});
      setSelectedStation(false);
    }
  };
  // Just show the last item station Name
  const displayStationName = (label) => {
    return label[label.length - 1];
  };

  const handleSubmit = async (values) => {
    const lineArr = values?.station[0]?.split('|');
    const stationArr = values?.station[1]?.split('|');
    const lineId = lineArr?.length > 1 ? lineArr[0] : undefined;
    const stationId = stationArr?.length > 1 ? stationArr[0] : undefined;
    const submitValues = {
      salonTown: values?.cityOrTown,
      salonName: values?.salonName,
      salonBuildingName: values?.building,
      salonAddressNumber: values?.address,
      salonLocation: values?.prefecture,
      salonAreaId: values?.areaId,
      phone: values?.phone,
      salonTypeIds: propertiesSelected,
      salonPostalCode: values?.salonPostalCode,
      salonDescription: values?.salonDescription,
      salonStationId: isSelectedStation ? stationId : '',
      salonLineId: isSelectedStation ? lineId : '',
      prefectureCode: prefectureCodeValue,
      salonStationWalkingMinutes: values?.walkingTime,
      salonStationNavigation: values?.navigation,
      isPrivate: values?.isPrivate,
      location: {
        latitude: lngValue?.lat,
        longitude: lngValue?.lng
      }
    };

    await dispatch(updateNailistProfileSalon({userId: nailistId, ...submitValues}));
    closePopUp();
    await dispatch(fetchNailistProfile({id: nailistId}));
  };

  return (
    <Form
      form={form}
      initialValues={{...initialValues, station: stationInitValue}}
      onFinish={handleSubmit}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
    >
      <Form.Item
        name='salonName'
        label='サロン名'
        validateTrigger={'onBlur'}
        onKeyPress={(e) => {
          if (e.target.value.length >= 50) {
            e.preventDefault();
          }
        }}
        rules={[
          {required: true, message: 'サロン名を入力してください'},
          {min: 2, message: '1文字以上で入力してください'},
          {max: 50, message: '50文字以上で入力してください'}
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="電話番号"
        validateTrigger={'onBlur'}
        rules={[
          { required: true, message: '電話番号を入力してください！' },
          { pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, message: '電話番号は無効です！' },
          { min: 10, message: '半角数字ハイフンなし、10桁または11桁で入力してください' },
          { max: 11, message: '半角数字ハイフンなし、10桁または11桁で入力してください' }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name='regionId' style={{display: 'none'}}>
        <Input />
      </Form.Item>
      <Form.Item name='regionName' style={{display: 'none'}}>
        <Input />
      </Form.Item>
      <Form.Item name='prefectureId' style={{display: 'none'}}>
        <Input />
      </Form.Item>
      <Form.Item name='prefectureName' style={{display: 'none'}}>
        <Input />
      </Form.Item>
      <Form.Item name='areaId' style={{display: 'none'}}>
        <Input />
      </Form.Item>
      <Form.Item name='areaName' style={{display: 'none'}}>
        <Input />
      </Form.Item>

      <Form.Item
        name='salonPostalCode'
        label='郵便番号'
        validateTrigger={'onBlur'}
        validateFirst={true}
        rules={[
          {required: true, message: '郵便番号を入力してください！'},
          {len: 7, message: '半角数字7桁ハイフンなしで入力してください.'}
        ]}
      >
        <Input
          ref={_refFieldPostCode}
          onKeyPress={(e) => {
            const key = e.which || e.key;
            if (e.target.value.length < 7) {
              if (key < 48 || key > 57) {
                e.preventDefault();
              }
            } else e.preventDefault();
          }}
          onChange={(e) => {
            if (e.target.value.length === 7) {
              _refFieldPostCode.current.blur();
            }
          }}
          onBlur={(e) => {
            // observe postCode value  and prevent fetch data if it doesn't change
            setCurrentPostCode(e.target.value);
            if (e.target.value === currentPostCode) {
              return;
            }
            if (e.target.value !== '' && e.target.value !== undefined && e.target.value.length === 7) {
              dispatch(getAddressByPostCode({postCode: e.target.value}))
                .then((result) => {
                  if (result.data.cityOrTown) {
                    form.setFieldsValue({cityOrTown: result.data.cityOrTown});
                  }

                  if (result.data.prefecture) {
                    form.setFieldsValue({
                      prefecture: result.data.prefecture,
                      address: '',
                      building: '',
                      walkingTime: '',
                      navigation: '',
                      station: '',
                      areaId: '',
                      areaName: ''
                    });
                    setSelectedStation(false);
                    getLineListOptions(); // Get new line list with new prefecture
                  }
                })
                .catch((error) => {
                  form.setFieldsValue({cityOrTown: ''});
                  form.setFieldsValue({prefecture: ''});
                  if (error?.data?.length > 0) {
                    let msg = '';
                    error.data.forEach((item) => {
                      msg = `${msg} ${item.message}. `;
                    });
                    form.setFields([
                      {
                        name: 'salonPostalCode',
                        errors: [msg]
                      }
                    ]);
                  } else {
                    form.setFields([
                      {
                        name: 'salonPostalCode',
                        errors: ['郵便番号の場所が見つかりません']
                      }
                    ]);
                  }
                });
              // Render pin map base on postCode and set default Lat, Lng when user reset map
              getSalonLocation();
            } else if (e.target.value === '') {
              form.setFieldsValue({
                prefecture: '',
                cityOrTown: '',
                address: '',
                building: '',
                walkingTime: '',
                navigation: '',
                station: '',
                areaId: '',
                areaName: ''
              });
              setSelectedStation(false);
              dispatch(resetStationProvider()); // clear select line/station list when not input postcode
            }
          }}
        />
      </Form.Item>

      <Form.Item name='prefecture' label='都道府県'>
        <Input disabled />
      </Form.Item>

      <Form.Item
        name='cityOrTown'
        label='市区町村'
        rules={[
          {
            required: true,
            message: '市区町村を入力してください！'
          },
          {
            whitespace: true,
            message: '市区町村を入力してください！'
          }
        ]}
        onBlur={() => getSalonLocation()}
      >
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item
        name='address'
        label='番地'
        rules={[{
          required: true, message: '住所を入力してください！'
        }, {
          whitespace: true,
          message: '住所を入力してください！'
        }]}
        onBlur={() => getSalonLocation()}
      >
        <Input />
      </Form.Item>

      <Form.Item name='building' label='建物名等/部屋番号' onBlur={() => getSalonLocation()}>
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item
        label='マップ設定'
        extra={<div style={{marginTop: 6}}>マップをクリックするとピンの位置を変更できます</div>}
      >
        <a onClick={() => setOpenEditPlaceOfThePin(true)}>
          <div
            style={{
              height: '35vh',
              width: '100%',
              position: 'relative',
              pointerEvents: 'none'
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{key: googleMapKey}}
              defaultCenter={defaultLng}
              center={lngValue}
              defaultZoom={16}
              options={hideFullScreenOptions}
            />
            <img
              src={pinMarker}
              style={{
                height: 40,
                position: 'absolute',
                bottom: '50%',
                left: '50%',
                transform: 'translate(-50%, 0px)'
              }}
            />
          </div>
        </a>
      </Form.Item>

      {/* Private / public address */}
      <Form.Item
        name='isPrivate'
        label='サロン住所公開設定'
        rules={[
          {
            required: true,
            message: 'サロン住所公開を設定してください！'
          }
        ]}
        extra='サロン住所はお客様に公開するかしないかを選んでください'
      >
        <Select
          onChange={(value) => {
            setUnpublicAddress(value);
          }}
          style={{width: '100%'}}
          placeholder='サロン住所公開設定'
        >
          <Select.Option value={false}>サロンの住所を常に表示</Select.Option>
          <Select.Option value={true}> 予約が確定したお客様にのみ公開</Select.Option>
        </Select>
      </Form.Item>

      {/* Salon area */}
      <Form.Item
        label='サロンエリア'
        name='areaName'
        rules={[{required: true, message: 'サロンエリアを選択してください!'}]}
      >
        <div>
          <a onClick={() => setIsSalonAreaSelectModal(true)}>
            {form.getFieldsValue().areaName ? form.getFieldsValue().areaName : '選択してください'}
          </a>
        </div>
      </Form.Item>

      {/* Salon Station */}
      <div id='salon_station'>
        <Form.Item
          label='最寄駅'
          validateTrigger={'onChange'}
          name='station'
          extra={
            <div style={{marginTop: 16}}>
              ※最寄駅を設定していなくても半径1.5km圏内で自動検出されるため、検索結果には表示されます
              <br />
              ※最寄駅がない場合は空欄のままにしてください
            </div>
          }
        >
          <Cascader
            options={lineList}
            onChange={onChangeLineOption}
            loadData={loadData}
            changeOnSelect
            displayRender={displayStationName}
            placeholder='選択してください'
            style={{width: '100%'}}
          />
        </Form.Item>
      </div>

      {/* Do not allow to select the Walking Time and the memo field if Nailist doesn't select a Station. */}
      <div>
        <Form.Item name='walkingTime' label='徒歩分数'>
          <Select allowClear style={{width: '100%'}} placeholder='徒歩分数' disabled={!isSelectedStation}>
            {[...Array(61).keys()].map((m) => (
              <Option key={m} value={m}>
                {m} 分
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* // Do not show this section if Nailist select “Unpublic” */}
        {!isUnpublicAddress && (
          <Form.Item name='navigation' label='道案内・アクセス'>
            <TextArea placeholder='道案内・アクセス' rows={3} showCount disabled={!isSelectedStation} maxLength={500} />
          </Form.Item>
        )}
      </div>
      <h3 id='salon_properties'>サロンの特徴</h3>
      <Divider style={{marginTop: 15}} />
      <Form.Item
        name='propertyIds'
        wrapperCol={{
          xs: {span: 24, offset: 0},
          lg: {span: 20, offset: 2},
          xl: {span: 16, offset: 4}
        }}
      >
        {renderPropertyIds(properties, propertiesSelected, setPropertiesSelected, form)}
      </Form.Item>
      <h3>サロンについて・注意事項</h3>
      <Divider style={{marginTop: 15}} />
      <Form.Item
        name='salonDescription'
        wrapperCol={{
          xs: {span: 24, offset: 0},
          lg: {span: 20, offset: 2},
          xl: {span: 16, offset: 4}
        }}
        colon={false}
        extra={
          <div style={{marginTop: 16}}>※LINEや電話番号など連絡先の掲載やSNS、他のサービスへの誘導はお控えください</div>
        }
      >
        <TextArea placeholder='サロンの情報をここに入力してください' rows={5} showCount maxLength={10000} />
      </Form.Item>

      <Space align='center' direction='vertical' style={{width: '100%'}}>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            保存
          </Button>
        </Form.Item>
      </Space>
      <Modal
        visible={openEditPlaceOfThePin}
        title='ピンの場所を編集する'
        onCancel={() => setOpenEditPlaceOfThePin(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        maskClosable={false}
      >
        <SalonEditPlaceOfThePin
          coordinateInitValue={lngValue}
          onCancel={() => setOpenEditPlaceOfThePin(false)}
          handleSetCoordination={(coordinatesData) => {
            const {coordinates} = coordinatesData;
            setLngValue({lat: coordinates?.lat, lng: coordinates?.lng});
            // Select place of the pin and complete update profile
            setOpenEditPlaceOfThePin(false);
          }}
        />
      </Modal>
      <Modal
        visible={isSalonAreaSelectModal}
        title='サロンエリアを選択する'
        onCancel={() => setIsSalonAreaSelectModal(false)}
        destroyOnClose={true}
        width={600}
        centered
        footer={null}
        maskClosable={false}
      >
        <SelectAreaForm
          prefecture={form.getFieldValue('prefecture')}
          salonArea={{
            regionId: _get(salon, 'area.prefecture.region._id'),
            regionName: _get(salon, 'area.prefecture.region.region'),
            prefectureId: _get(salon, 'area.prefecture._id'),
            prefectureName: _get(salon, 'area.prefecture.province'),
            areaId: form.getFieldValue('areaId'),
            areaName: form.getFieldValue('areaName')
          }}
          onCancel={() => setIsSalonAreaSelectModal(false)}
          onCallback={(salonArea) => {
            const {regionId, regionName, prefectureId, prefectureName, areaId, areaName} = salonArea;

            form.setFieldsValue({regionId});
            form.setFieldsValue({regionName});
            form.setFieldsValue({prefectureId});
            form.setFieldsValue({prefectureName});
            form.setFieldsValue({areaId});
            form.setFieldsValue({areaName});

            setIsSalonAreaSelectModal(false);
          }}
        />
      </Modal>
    </Form>
  );
};

UpdateNailistSalonProfileForm.propTypes = {
  initialValues: PropTypes.object,
  closePopUp: PropTypes.func,
  salon: PropTypes.object,
  nailistId: PropTypes.string,
  props: PropTypes.func
};

// 
const useEditNailistSalonProfile = (data = {}, nailistId, props) => {
  const [isOpen, setIsOpen] = useState(false);

  const initialValues = useMemo(() => {
    if (_isEmpty(data)) {
      return null;
    }

    return {
      salonPostalCode: data.profile?.salonPostalCode,
      salonName: data?.profile?.salonName,
      salonDescription: data?.profile?.salonDescription,
      prefecture: data?.profile?.salonLocation,
      cityOrTown: data?.profile?.salonTown,
      address: data?.profile?.salonAddressNumber,
      building: data?.profile?.salonBuildingName,
      phone: data?.profile?.phone,
      isPrivate: data?.profile?.isPrivate ? true : false,
      lineId: data?.profile?.salonLineId,
      lineName: data?.profile?.salonLineName,
      stationId: data?.profile?.salonStationId,
      station: data?.profile?.salonStationName,
      areaId: data?.profile?.area?._id,
      areaName: data?.profile?.area?.wards,
      propertyIds: data?.profile?.salonTypeIds || [],
      navigation: data?.profile?.salonStationNavigation,
      walkingTime: data?.profile?.salonStationWalkingMinutes
    };
  }, [data]);

  const Popup = (
    <Modal
      title='サロン情報を編集'
      visible={isOpen}
      destroyOnClose
      footer={null}
      onCancel={() => setIsOpen(false)}
      width={980}
    >
      {initialValues && (
        <UpdateNailistSalonProfileForm
          initialValues={initialValues}
          closePopUp={() => setIsOpen(false)}
          salon={data?.profile}
          nailistId={nailistId}
        />
      )}
    </Modal>
  );

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default useEditNailistSalonProfile;
