import React, { useEffect } from 'react';
import { Modal, Typography, Form, Button, Input, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { updateSalonEmail, fetchSalonProfile } from 'providers/SalonProvider/actions';

const { Text } = Typography;

let currentModalControl = null;

const handleCloseChangeEmailModal = () => {
  if (currentModalControl) {
    currentModalControl.destroy();
  }
};

const ChangeEmailForm = ({ form, submitForm, reloadData }) => {
  return (
    <Form
      form={form}
      name="change-email-modal-form"
      layout='horizontal'
      onFinish={async(values) => {
        await submitForm(values);
        handleCloseChangeEmailModal();
        await reloadData();
      }}
    >
      <div style={{ marginTop: 24, display: 'flex' }}>
        <Form.Item
          name="newEmail"
          style={{ width: 600, marginRight: 16 }}
          validateTrigger={'onBlur'}
          label='新しいメールアドレス'
          rules={[
            {
              required: true,
              message: 'メールアドレスを入力してください！'
            },
            {
              pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
              message: 'メールアドレスは無効です'
            }
          ]}
          normalize={(value) => value.trim()}
        >
          <Input style={{ marginRight: 8 }} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="clear" onClick={() => handleCloseChangeEmailModal()}>
              キャンセル
            </Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </Space>
        </Form.Item>
      </div>
      <div>
        <Text style={{ color: '#F02317', marginLeft: -32 }}>{'※メールアドレスを変更すると、ログイン時のメールアドレスも自動で新しいものに更新されます'}</Text>
      </div>
    </Form>
  );
};

const showInputPricePopup = (form, submitForm, reloadData) => {
  currentModalControl = Modal.confirm({
    title: 'メール アドレス 変更',
    content: <ChangeEmailForm form={form} submitForm={submitForm} reloadData={reloadData}/>,
    okButtonProps: { style: { display: 'none'} },
    cancelButtonProps: { style: { display: 'none'} },
    width: '800px',
    maskClosable: true
  });
};

/**
 * 
 * @param {String} salonId 
 * @param {Array<EmailObject>} listEmail this listEmail already excluded current operator email 
 * @returns 
 */
const useChangeSalonEmail = (salonId, listEmail) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const submitForm = ({ newEmail }) => dispatch(updateSalonEmail({ newEmail, salonId, listEmail }));
  const reloadData = () => dispatch(fetchSalonProfile({id: salonId}));

  useEffect(() => {
    return handleCloseChangeEmailModal;
  }, []);

  return () => showInputPricePopup(form, submitForm, reloadData);
};

export default useChangeSalonEmail;