import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';

const useTableControl = () => {
  let history = useHistory();
  const { location } = history;
  const { pathname, search } = location;
  const searchObj = useMemo(() => parseSearchString(search), [search]);

  useEffect(() => { // apply default sort order
    history.push(`${pathname}?${qs.stringify(searchObj)}`);
  }, []);

  // handle change from antd table
  const onTableChange = (pagination) => {
    const parsed = { ...searchObj, page: pagination.current };
    history.push(`${pathname}?${qs.stringify(parsed)}`);
  };

  // handle change from filter form submition
  const onSubmitFilterForm = (values) => {
    const parsed = { ...values, page: 1 };
    history.push(`${pathname}?${qs.stringify(parsed)}`);
  };

  return [searchObj, onTableChange, onSubmitFilterForm];
};

function parseSearchString(search) {
  return qs.parse(search.replace(/^\?/, ''));
}

export default useTableControl;