import {
  GET_FRIEND_INVITATION_LIST,
  GET_FRIEND_INVITATION_LIST_SUCCESS,
  EXPORT_FRIEND_INVITATION_LIST,
  EXPORT_FRIEND_INVITATION_LIST_SUCCESS,
  GET_GO_TO_NAIL_INFO,
  GET_GO_TO_NAIL_INFO_SUCCESS,
  GET_GO_TO_NAIL_LIST,
  GET_GO_TO_NAIL_LIST_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST,
  EXPORT_GO_TO_NAIL_LIST_SUCCESS,
  UPDATE_GO_TO_NAIL,
  UPDATE_GO_TO_NAIL_SUCCESS,
  GET_OLYMPIC_INFO,
  GET_OLYMPIC_INFO_SUCCESS,
  GET_OLYMPIC_LIST,
  GET_OLYMPIC_LIST_SUCCESS,
  EXPORT_OLYMPIC_LIST,
  EXPORT_OLYMPIC_LIST_SUCCESS,
  UPDATE_OLYMPIC,
  UPDATE_OLYMPIC_SUCCESS,
  GET_GO_TO_NAIL_INFO_2ND,
  GET_GO_TO_NAIL_INFO_2ND_SUCCESS,
  GET_GO_TO_NAIL_LIST_2ND,
  GET_GO_TO_NAIL_LIST_2ND_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST_2ND,
  EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS,
  UPDATE_GO_TO_NAIL_2ND,
  UPDATE_GO_TO_NAIL_2ND_SUCCESS,
  GET_GO_TO_NAIL_INFO_3RD,
  GET_GO_TO_NAIL_INFO_3RD_SUCCESS,
  GET_GO_TO_NAIL_LIST_3RD,
  GET_GO_TO_NAIL_LIST_3RD_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST_3RD,
  EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS,
  UPDATE_GO_TO_NAIL_3RD,
  UPDATE_GO_TO_NAIL_3RD_SUCCESS,
  GET_INSTAGRAM_COUPON_INFOR,
  GET_INSTAGRAM_COUPON_INFOR_SUCCESS,
  GET_INSTAGRAM_COUPON_LIST,
  GET_INSTAGRAM_COUPON_LIST_SUCCESS,
  EXPORT_INSTAGRAM_COUPON,
  EXPORT_INSTAGRAM_COUPON_SUCCESS,
  UPDATE_INSTAGRAM_COUPON,
  UPDATE_INSTAGRAM_COUPON_SUCCESS
} from './constants';

export function getFriendInvitationList(payload = {}) {
  return { type: GET_FRIEND_INVITATION_LIST, payload };
}

export function getFriendInvitationListSuccess(payload = {}) {
  return { type: GET_FRIEND_INVITATION_LIST_SUCCESS, payload };
}

export function exportFriendInvitationList(payload = {}) {
  return { type: EXPORT_FRIEND_INVITATION_LIST, payload };
}

export function exportFriendInvitationListSuccess(payload = {}) {
  return { type: EXPORT_FRIEND_INVITATION_LIST_SUCCESS, payload };
}

export function getGoToNailInfo(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO, payload };
}

export function getGoToNailInfoSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO_SUCCESS, payload };
}

export function getGoToNailList(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST, payload };
}

export function getGoToNailListSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST_SUCCESS, payload };
}

export function exportGoToNailList(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST, payload };
}

export function exportGoToNailListSuccess(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST_SUCCESS, payload };
}

export function updateGoToNail(payload = {}) {
  return { type: UPDATE_GO_TO_NAIL, payload, meta: { thunk: true } };
}

export function updateGoToNailSuccess(payload = {}, meta) {
  return { type: UPDATE_GO_TO_NAIL_SUCCESS, payload, meta };
}


export function getOlympicInfo(payload = {}) {
  return { type: GET_OLYMPIC_INFO, payload };
}

export function getOlympicInfoSuccess(payload = {}) {
  return { type: GET_OLYMPIC_INFO_SUCCESS, payload };
}

export function getOlympicList(payload = {}) {
  return { type: GET_OLYMPIC_LIST, payload };
}

export function getOlympicListSuccess(payload = {}) {
  return { type: GET_OLYMPIC_LIST_SUCCESS, payload };
}

export function exportOlympicList(payload = {}) {
  return { type: EXPORT_OLYMPIC_LIST, payload };
}

export function exportOlympicListSuccess(payload = {}) {
  return { type: EXPORT_OLYMPIC_LIST_SUCCESS, payload };
}

export function updateOlympic(payload = {}) {
  return { type: UPDATE_OLYMPIC, payload, meta: { thunk: true } };
}

export function updateOlympicSuccess(payload = {}, meta) {
  return { type: UPDATE_OLYMPIC_SUCCESS, payload, meta };
}

export function getGoToNailInfo2nd(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO_2ND, payload };
}

export function getGoToNailInfo2ndSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO_2ND_SUCCESS, payload };
}

export function getGoToNailList2nd(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST_2ND, payload };
}

export function getGoToNailList2ndSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST_2ND_SUCCESS, payload };
}

export function exportGoToNailList2nd(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST_2ND, payload };
}

export function exportGoToNailList2ndSuccess(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS, payload };
}

export function updateGoToNail2nd(payload = {}) {
  return { type: UPDATE_GO_TO_NAIL_2ND, payload, meta: { thunk: true } };
}

export function updateGoToNail2ndSuccess(payload = {}, meta) {
  return { type: UPDATE_GO_TO_NAIL_2ND_SUCCESS, payload, meta };
}
// goto nail 3
export function getGoToNailInfo3rd(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO_3RD, payload };
}

export function getGoToNailInfo3rdSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_INFO_3RD_SUCCESS, payload };
}

export function getGoToNailList3rd(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST_3RD, payload };
}

export function getGoToNailList3rdSuccess(payload = {}) {
  return { type: GET_GO_TO_NAIL_LIST_3RD_SUCCESS, payload };
}

export function exportGoToNailList3rd(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST_3RD, payload };
}

export function exportGoToNailList3rdSuccess(payload = {}) {
  return { type: EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS, payload };
}

export function updateGoToNail3rd(payload = {}) {
  return { type: UPDATE_GO_TO_NAIL_3RD, payload, meta: { thunk: true } };
}

export function updateGoToNail3rdSuccess(payload = {}, meta) {
  return { type: UPDATE_GO_TO_NAIL_3RD_SUCCESS, payload, meta };
}

// INSTAGRAM COUPON

export function getInstagramCouponInfor(payload = {}) {
  return { type: GET_INSTAGRAM_COUPON_INFOR, payload };
}

export function getInstagramCouponInforSuccess(payload = {}) {
  return { type: GET_INSTAGRAM_COUPON_INFOR_SUCCESS, payload };
}

export function getInstagramCouponList(payload = {}) {
  return { type: GET_INSTAGRAM_COUPON_LIST, payload };
}

export function getInstagramCouponListSuccess(payload = {}) {
  return { type: GET_INSTAGRAM_COUPON_LIST_SUCCESS, payload };
}

export function exportInstagramCoupon(payload = {}) {
  return { type: EXPORT_INSTAGRAM_COUPON, payload };
}

export function exportInstagramCouponSuccess(payload = {}) {
  return { type: EXPORT_INSTAGRAM_COUPON_SUCCESS, payload };
}

export function updateInstagramCoupon(payload = {}) {
  return { type: UPDATE_INSTAGRAM_COUPON, payload, meta: { thunk: true } };
}

export function updateInstagramCouponSuccess(payload = {}, meta) {
  return { type: UPDATE_INSTAGRAM_COUPON_SUCCESS, payload, meta };
}