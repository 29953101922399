import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'redux/store';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';

import App from 'containers/App/App';
import * as serviceWorker from './serviceWorker';

import 'assets/styles/theme.scss';

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
window.store = store;

const baseDom = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(baseDom, document.getElementById('root'));

history.listen((location, action) => {
  if (action !== 'POP' && action !== 'REPLACE') {
    window.scrollTo(0, 0);
  }
});

serviceWorker.register();
