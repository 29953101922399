import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Avatar, Image } from 'antd';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import _get from 'lodash/get';

const columns = {};

columns.userId = {
  key: 'userId',
  title: 'ユーザーID',
  render: (record) => {
    const { role, userId } = record;
    return (
      <Link target={'_blank'} to={`/dashboard/${role === 'NAILIST' ? 'nailist' : 'customer'}/profile/${userId}`}>{userId}</Link>
    );
  }
};

columns.reporter = {
  key: 'reporter',
  title: '通報された人',
  render: (record) => {
    const { role, userId, avatar, username, reporterId } = record;
    return (
      <Link target={'_blank'} to={`/dashboard/${role === 'NAILIST' ? 'nailist' : 'customer'}/profile/${reporterId || userId}`}>
        <Space>
          <Avatar icon={<UserOutlined />} src={avatar} />
          <div>{username}</div>
        </Space>
      </Link>
    );
  }
};

columns.count = {
  key: 'count',
  title: '通報数',
  dataIndex: 'count',
  sorter: true,
  // defaultSortOrder: 'descend'
};

columns.latestDate = {
  key: 'latestDate',
  title: '最新の通報',
  dataIndex: 'latestDate',
  render: (record) => moment(record).format('YYYY-MM-DD'),
  sorter: true
};

columns.view = {
  fixed: 'right',
  dataIndex: 'userId',
  render: (record) => <Link to={`/dashboard/support/report/${record}`}>View</Link>
};

columns.reason = {
  key: 'reason',
  title: '理由',
  dataIndex: 'reason',
  width: 500,
  render: (record) => <div style={{ wordBreak: 'break-all' }}>{record}</div>
};

columns.type = {
  key: 'type',
  title: '種類',
  dataIndex: 'type',
  render: (record) => <div style={{ whiteSpace: 'nowrap' }}>{record}</div>
};

columns.reportDate = {
  key: 'reportDate',
  title: '日時',
  dataIndex: 'reportDate',
  render: (record) => <div style={{ whiteSpace: 'nowrap' }}>{moment(record).format('YYYY-MM-DD')}</div>
};

columns.reportImage = {
  key: 'reportImage',
  title: '画像',
  dataIndex: 'reportImage',
  render: (record) => {
    const image = _get(record, '0.image');
    return image && <Image src={image} width={40} height={40} preview={{ mask: <EyeOutlined /> }} />;
  },
  width: 72
};

export default columns;
export const defaultOrder = {
  orderBy: 'latestDate',
  order: 'descend' 
};

export const defaultOrderDetailList = {
  orderBy: 'latestDate',
  order: 'descend' 
};
