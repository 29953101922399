/* eslint-disable react/display-name */
import _ from 'lodash';
import moment from 'moment';
import qs from 'querystring';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Table, Card, Tag, Avatar, Button, Space } from 'antd';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';

import { formatYen } from 'utils/stringFormat';
import FilterForm from './FilterForm';
import BigPageHeader from 'components/PageHeader';

const Bread = BigPageHeader.Bread;

const FriendInvitationList = ({ pathname, replace, search, ...props }) => {
  const [searchState, setSearchState] = useState({
    page: Number(search.p) || 1,
    limit: Number(search.l) || 10,
    keyword: search.kw,
    dateFrom: search.f || moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: search.t || moment().endOf('month').format('YYYY-MM-DD')
  });

  const getFriendInvitationList = () => {
    const params = _.pick(searchState, [
      'page',
      'limit',
      'keyword',
      'dateFrom',
      'dateTo'
    ]);
    props.getFriendInvitationList(params);
  };

  const handleExportCsv = () => {
    const params = _.pick(searchState, [
      'page',
      'limit',
      'keyword',
      'dateFrom',
      'dateTo'
    ]);
    props.exportFriendInvitationList(params);
  };

  useEffect(() => {
    getFriendInvitationList();
  }, []);

  useEffect(() => {
    getFriendInvitationList();
  }, [searchState]);

  const onFilterChange = (value) => {
    const { dateRange, keyword } = value;

    const parsed = {
      ...search,
      kw: keyword,
      p: 1
    };

    const dateFrom =
      dateRange && dateRange[0]
        ? moment(dateRange[0]).format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const dateTo =
      dateRange && dateRange[1]
        ? moment(dateRange[1]).format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
    parsed.f = dateFrom;
    parsed.t = dateTo;

    replace(`${pathname}?${qs.stringify(parsed)}`);

    setSearchState((prevState) => ({
      ...prevState,
      keyword,
      dateFrom,
      dateTo,
      page: 1
    }));
  };

  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...search,
      p: pagination.current,
      l: pagination.pageSize
    };

    replace(`${pathname}?${qs.stringify(parsed)}`);

    setSearchState((prevState) => ({
      ...prevState,
      page: Number(pagination.current),
      limit: Number(pagination.pageSize)
    }));
  };

  const filterFormInitialValues = { keyword: searchState.keyword };
  if (searchState.dateFrom && searchState.dateTo) {
    filterFormInitialValues.dateRange = [
      moment(searchState.dateFrom),
      moment(searchState.dateTo)
    ];
  }

  const columns = [
    {
      title: 'ユーザーネーム',
      key: 'username',
      dataIndex: 'user',
      render: (record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            icon={<UserOutlined />}
            src={_.get(record, 'avatar')}
            size="large"
          />
          <div style={{ marginLeft: '8px' }}>{_.get(record, 'username')}</div>
        </div>
      )
    },
    {
      title: 'Full Name',
      key: 'fullName',
      dataIndex: 'user',
      render: (record) => record.fullName
    },
    {
      title: 'Invitation code',
      key: 'invitationCode',
      dataIndex: 'user',
      render: (record) => <Tag color="blue">{record.invitationCode}</Tag>
    },
    {
      title: '招待回数',
      key: 'inviteeNumber',
      dataIndex: 'inviteeNumber'
    },
    {
      title: 'クーポン利用回数',
      key: 'inviteeUsedCouponNumber',
      dataIndex: 'inviteeUsedCouponNumber'
    },
    {
      title: 'クーポン割引金額',
      key: 'inviteeUsedCouponPrice',
      dataIndex: 'inviteeUsedCouponPrice',
      className: 'column-money',
      render: (record) => formatYen(record)
    },
    {
      title: 'ポイント獲得回数',
      key: 'inviteeDone1stBKNumber',
      dataIndex: 'inviteeDone1stBKNumber'
    },
    {
      title: '獲得ポイント',
      key: 'inviteeDone1stBKPrice',
      dataIndex: 'inviteeDone1stBKPrice',
      className: 'column-money',
      render: (record) => formatYen(record)
    }
  ];

  return (
    <>
      <Helmet>
        <title>Friend Invitation</title>
      </Helmet>
      <BigPageHeader
        title="Friend Invitation"
        breadcrumb={Bread.friendInvitation}
      />
      <Card bordered={false} className="styled-transparent">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card bordered={false}>
            <div className="search-section">
              <FilterForm
                onSubmit={onFilterChange}
                initialValues={filterFormInitialValues}
              />
              <div style={{ flexGrow: 1 }} />
              <Button
                loading={props.friendInvitationActionLoading}
                icon={<DownloadOutlined />}
                onClick={handleExportCsv}
              >
                データのエクスポート
              </Button>
            </div>
          </Card>
          <Card bordered={false}>
            <Table
              columns={columns}
              dataSource={props.friendInvitationList}
              scroll={{ x: true }}
              onChange={onTableChange}
              loading={props.friendInvitationLoading}
              size="small"
              pagination={{
                current: searchState.page,
                pageSize: searchState.limit,
                showSizeChanger: false,
                total: props.friendInvitationTotal,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
              }}
            />
          </Card>
        </Space>
      </Card>
    </>
  );
};

FriendInvitationList.propTypes = {
  getFriendInvitationList: PropTypes.func.isRequired,
  exportFriendInvitationList: PropTypes.func.isRequired,
  friendInvitationList: PropTypes.array,
  friendInvitationTotal: PropTypes.number,
  friendInvitationLoading: PropTypes.bool,
  friendInvitationActionLoading: PropTypes.bool,
  pathname: PropTypes.string,
  search: PropTypes.any,
  replace: PropTypes.func,
  location: PropTypes.any
};

export default FriendInvitationList;
