import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import csv from 'csv';
import { getSalonSharedTypeValue } from 'utils/common';
import { makeAnyPopup } from 'providers/PopupProvider/selectors';
import { SetPopup } from 'providers/PopupProvider/actions';
import { createSalon as createSalonAction } from 'providers/SalonProvider/actions';
import { Modal, Upload, Table, message, Image, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import _pick from 'lodash/pick';
import _identity from 'lodash/identity';
import _pickBy from 'lodash/pickBy';

import columnsTemplate from '../Preset/columns';
import sampleFileImage from '../Preset/sample.png';
import './styles.scss';

const { Dragger } = Upload;

/** Step 0: upload file */
const Step0 = ({ setCSVData }) => {
  return (
    <Dragger
      accept=".txt, .csv"
      showUploadList={false}
      beforeUpload={file => {
        try {
          const reader = new FileReader();

          reader.onload = e => {
            csv.parse(e.target.result, {
              columns: (header) => {
                const correctHeaders = ['name', 'salonSharedType', 'email', 'phone'];
                const test = header.every(i => correctHeaders.includes(i));
                if (!test) {
                  message.error('Please input correct column title (name, salonSharedType, email, phone)');
                  throw Error();
                }
                return header;
              }
            }, (err, data) => {
              if (data?.length === 0) {
                message.error('Please input the salon information value!');
              } else if (data) {
                const convertData = [];
                data.forEach(i => {
                  const data = _pick({
                    ...i,
                    businessName: i.name,
                    operatorEmail: i.email,
                    operatorPhoneNumber: i.phone
                  }, ['businessName', 'salonSharedType', 'operatorEmail', 'operatorPhoneNumber']);
                  const validData = _pickBy(data, _identity);
                  if (Object.keys(validData).length >= 1) {
                    convertData.push(data);
                  }
                });
                setCSVData(convertData);
              }
            });
          };
          reader.readAsText(file);

          // Prevent upload
          return false;
        } catch (err) {
          message.error("File error!")
        }
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
      </p>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Image
          src={sampleFileImage}
          alt="name-email-phone_in_file_csv.png"
          preview={false}
          width={520}
          style={{border: '1px solid LightGray', borderRadius: '8px'}}
        />
      </div>
    </Dragger>
  )
};

/** Step 1: render and upload */
const Step1 = ({ CSVData, setCSVData }) => {
  const dispatch = useDispatch();
  const createSalon = useCallback(
    (salonInfo) => dispatch(createSalonAction(salonInfo)),
    [dispatch]
  );

  const columns = [
    columnsTemplate.name,
    columnsTemplate.salonSharedType,
    columnsTemplate.phone,
    columnsTemplate.email,
    columnsTemplate.id
  ];

  useEffect(() => {
    const createMultipleSalon = async () => {
      for (let salonData of CSVData) {
        const { id, error, success } = await createSalon({
          businessName: salonData.businessName,
          salonSharedType: getSalonSharedTypeValue(salonData.salonSharedType.trim()),
          email: salonData.operatorEmail,
          phoneNumber: salonData.operatorPhoneNumber
        }).catch(err => err);

        salonData.id = id;
        salonData.error = error;
        salonData.success = success;
        setCSVData([...CSVData]);
      }
    };
    createMultipleSalon();
  }, []);

  return (
    <Fragment>
      <Table
        columns={columns}
        dataSource={CSVData}
        scroll={{ x: 800, y: '50vh' }}
        rowClassName={(record) => record.error ? 'data-row ant-typography ant-typography-danger' : (record.success ? 'data-row ant-typography ant-typography-success' : 'data-row') }
        size="small"
        pagination={false}
      />
      <Progress percent={(CSVData.filter((obj) => !!obj.id).length / CSVData.length * 100).toFixed(1)} status="active" />
    </Fragment>
  )
};

const ModalContentController = ({ }) => {
  const [CSVData, setCSVData] = useState(undefined);
  let step = [CSVData].findIndex((step) => !!step) + 1;

  return (
    <Fragment>
      {step === 0 && <Step0 setCSVData={setCSVData} />}
      {step === 1 && <Step1 setCSVData={setCSVData} CSVData={CSVData} />}
    </Fragment>
  )
}

const CreateMultiSalonPopup = ({ }) => {
  const dispatch = useDispatch();
  const isShown = useSelector((state) => makeAnyPopup(state, 'createMultiSalon'));
  const closePopup = useCallback(
    () => dispatch(SetPopup({ name: 'createMultiSalon', show: false })),
    [dispatch]
  );

  return (
    <Modal
      title="サロンアカウント作成"
      visible={isShown}
      onCancel={closePopup}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      width={720}
    >
      <ModalContentController />
    </Modal>
  )
}

export default CreateMultiSalonPopup;