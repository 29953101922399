import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDeletedUserBooking } from 'providers/DeletedAccountProvider/actions';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';
import BookingStatusTag from 'components/BookingStatusTag';
import columnsTemplate from '../../../containers/Booking/Preset/columnsTemplate';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

const PopupContent = (props) => {
  const dispatch = useDispatch();
  const { visible, data, loading } = props;

  useEffect(() => {
    dispatch(fetchDeletedUserBooking({ userId: visible}));
  }, []);

  const columns = [
    {
      title: 'Booking ID',
      dataIndex: '_id',
      key: '_id',
      render: (id) => {
        return (
          <Link
            to={`/dashboard/booking-management/list/${id}`}
          >
            <div style={{ marginLeft: '8px' }}>
              <LinkOutlined />
              {' ' + id}
            </div>
          </Link>
        );
      }

    },
    {
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      key: 'bookingDate',
      render: (bookingDate) => moment(bookingDate).format('YYYY-MM-DD')
    },
    {
      title: 'Charged Date',
      dataIndex: 'chargeDate',
      key: 'chargeDate',
      render: (chargeDate) => moment(chargeDate).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BookingStatusTag status={status} />
    },
    columnsTemplate.totalFee
  ];
  return (
    <div style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
      />
    </div>
  );
};

PopupContent.propTypes = {
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  data: PropTypes.array,
  loading: PropTypes.bool
};
export default PopupContent;