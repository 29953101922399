import _ from 'lodash';

export const makeBankList = (state) => {
  return _.get(state, 'bank.bankList');
};

export const makeBranchList = (state) => {
  return _.get(state, 'bank.branchList');
};

export const makeAccountTypeList = (state) => {
  return _.get(state, 'bank.accountTypeList');
};

export const makeBankDataLoading = (state) => {
  return _.get(state, 'bank.dataLoading');
};

export const makeBankActionLoading = (state) => {
  return _.get(state, 'bank.actionLoading');
};
