import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Tag, Card, Typography, Descriptions, Modal, Button} from 'antd';

import Bank from './Bank';

import './styles.scss';
import { USER_STATUS } from 'utils/constants';
import { getContentByStatusType, getStatusParam } from 'containers/Customer/CustomerDetail/getContentByActionType';
import ReasonDetailInput from 'containers/Customer/CustomerDetail/ReasonDetailInput';
import { HIDDEN_PROPS } from 'containers/Customer/CustomerDetail/CustomerDetail';
import useEditNailistInfo from '../hooks/useEditNailistInfo';
import useEditNailistSalonProfile from '../hooks/useEditNailistSalonProfile';

const {Paragraph} = Typography;
let currentModalControl = null;

const Profile =
(props) => {
  const bank = _.get(props, 'profile.bank', {});
  const info = _.get(props, 'profile.info', {});

  const userId = _.get(info, '_id');
  const isDeleted = _.get(info, 'isDeleted');
  const isOnlyEditMinimumLoginData = info.status === 'PENDING' || isDeleted || !props.profile?.info?.profile?.salonPostalCode;

  const [ openEditNailistInfoPopup, EditNailistInfoPopup] = useEditNailistInfo(props?.profile?.info, userId, isOnlyEditMinimumLoginData);
  const [openEditNailistInfoSalonPopup, EditNailistInfoSalonPopup] = useEditNailistSalonProfile(props?.profile?.info, userId);

  const birthday = info.birthday ? moment(info.birthday).format('YYYY-MM-DD') : ' -- ';
  const gender = info.gender === 1 ? 'FEMALE' : 'MALE';
  const salonName = _.get(info, 'profile.salonName', ' -- ');
  const postalCode = _.get(info, 'profile.salonPostalCode', ' -- ');
  const salonLocation = _.get(info, 'profile.salonLocation', ' -- ');
  const salonCity = _.get(info, 'profile.salonTown', ' -- ');
  const salonAddress = `${_.get(info, 'profile.salonLocation', ' -- ')} ${salonCity} ${_.get(info, 'profile.salonAddressNumber', '--')}`;
  const salonBuildingName = _.get(info, 'profile.salonBuildingName', ' -- ');
  const salonPhotos = _.get(info, 'profile.salonPhotos', []);
  const salonPhone = _.get(info, 'profile.phone', ' -- ');
  const salonStationName = _.get(info, 'profile.salonStationName', ' -- ');
  const salonStationWalkingMinutes = _.get(info, 'profile.salonStationWalkingMinutes', ' -- ');
  const salonStationNavigation = _.get(info, 'profile.salonStationNavigation', ' -- ');
  const salonSharedType = _.get(info, 'profile.salonSharedType');
  const canEditNailistSalonInfo = !_.get(info, 'CBStaffId') || (_.get(info, 'CBStaffId') && ['SHARED', 'FIVE_STAR'].includes(salonSharedType));

  const status = info.deleteType || info.status;

  const showConfirmChangeStatus = (statusAction) => {
    const { modalTitle, modalContent } = getContentByStatusType(statusAction);
    return new Promise((resolve) => {
      currentModalControl = Modal.confirm({
        title: modalTitle,
        width: '600px',
        okButtonProps: HIDDEN_PROPS,
        cancelButtonProps: HIDDEN_PROPS,
        content: <ReasonDetailInput
          statusAction={statusAction}
          close={() => currentModalControl.destroy()}
          modalContent={modalContent}
          submitStatusThenFetchData={() => {
            const { statusParam } = getStatusParam(statusAction);
            let reasonDetail = document.getElementById('reason_delete_user');

            if (statusAction === 'ACTIVE') {
              reasonDetail = reasonDetail?.value.trim();
            } else {
              reasonDetail = ' Active user';
            }
            return props.updateUserStatus({
              reason: '強制退会',
              userId: _.get(info, '_id'),
              role: 'nailist',
              status: statusParam,
              detail: reasonDetail
            }).then(() => {
              props.fetchNailistProfile({ id: _.get(info, '_id') });
              currentModalControl.destroy();
              resolve(true);
            }).catch(() => {
              currentModalControl.destroy();
            });
          }}
        />
      });
    });
  };

  return (
    <React.Fragment>
      <Card className="nailist-profile-tab" title="ユーザー情報" extra={<Button type='primary' disabled={!userId} onClick={openEditNailistInfoPopup}>Edit</Button>}>
        <Descriptions bordered column={1} size="small" labelStyle={{ width: 280 }} contentStyle={{ whiteSpace: 'pre-line'}}>
          <Descriptions.Item label="ふりがな">{info.phonetic || ' -- '}</Descriptions.Item>
          <Descriptions.Item label="性別">{gender}</Descriptions.Item>
          <Descriptions.Item label="誕生日">{birthday}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <span style={ status === 'PENDING' ? {} : { cursor: 'pointer'}}>
              <Tag
                color={USER_STATUS[status]?.color}
                onClick={() => {
                  if (status !== 'PENDING') { // can not active user with status PENDING
                    showConfirmChangeStatus(status);
                  }
                }}>{USER_STATUS[status]?.value}</Tag>
            </span>
          </Descriptions.Item>
        </Descriptions>
        {EditNailistInfoPopup}
      </Card>

      <Card 
        className='nailist-profile-tab' 
        title='サロン情報' 
        extra={<Button type='primary' onClick={openEditNailistInfoSalonPopup} disabled={!canEditNailistSalonInfo || !userId || isOnlyEditMinimumLoginData }> Edit </Button>}
      >
        <Descriptions bordered column={1} size="small" labelStyle={{ width: 280 }} contentStyle={{ whiteSpace: 'pre-line'}}>
          <Descriptions.Item label="サロン名">{salonName}</Descriptions.Item>
          <Descriptions.Item label="郵便番号">{postalCode}</Descriptions.Item>
          <Descriptions.Item label="市区町村">{salonCity}</Descriptions.Item>
          <Descriptions.Item label="建物名">{salonBuildingName}</Descriptions.Item>

          <Descriptions.Item label="電話番号">{salonPhone}</Descriptions.Item>
          <div style={{ boxShadow: 'inset 0 -1px 0 0 #f0f0f0' }}></div>
          <Descriptions.Item label="都道府県">{salonLocation}</Descriptions.Item>
          <Descriptions.Item label="サロン住所">{salonAddress}</Descriptions.Item>
          <Descriptions.Item label="最寄駅">{salonStationName}駅</Descriptions.Item>
          <Descriptions.Item label="徒歩分数">{salonStationWalkingMinutes}分</Descriptions.Item>
          <Descriptions.Item label="住所公開ステータス">{_.get(info, 'profile.isPrivate') === true ? '予約確定後に公開' : '公開'}</Descriptions.Item>
          <Descriptions.Item label="道案内・アクセス">{salonStationNavigation}</Descriptions.Item>
          <div style={{ boxShadow: 'inset 0 -1px 0 0 #f0f0f0' }}></div>
          <Descriptions.Item label="サロンイメージ写真">
            <div className="image-list">
              {salonPhotos.map((item) => (<img src={item.data} key={item.data} alt={item.data} />))}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="サロンについて・注意事項" span={2}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              {_.get(info, 'profile.salonDescription', ' -- ')}
            </Paragraph>
          </Descriptions.Item>
        </Descriptions>
        {EditNailistInfoSalonPopup}
      </Card>

      <Bank data={bank} />
    </React.Fragment>
  );
};

Profile.propTypes = {
  profile: PropTypes.object,
  fetchNailistProfile: PropTypes.func,
  updateUserStatus: PropTypes.func
};

export default Profile;
