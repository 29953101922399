import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Table, Card, Button } from 'antd';

import PageHeader from 'components/PageHeader';
//import columnsTemplate from 'Preset/columns';

import useTableControl from './hooks/useTableControl';
import FilterForm from './FilterForm';

import columnsTemplate from '../Preset/columnsTemplate';
const columns = [
  columnsTemplate.id,
  columnsTemplate.bookingId,
  columnsTemplate.nailist,
  columnsTemplate.customer,
  columnsTemplate.price,
  columnsTemplate.sentAt,
  columnsTemplate.cardProvider,
  {
    ...columnsTemplate.action,
    // eslint-disable-next-line react/display-name
    render: (record) => (
      <Link to={`/dashboard/receipts/${record._id}/related/booking/${_.get(record, 'emailParams.bookingId')}/`}>
        <Button>View</Button>
      </Link>
    )
  }
];

const ReceiptEmailList = ({ list, total, fetchReceiptList }) => {
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    (async() => {
      setLoading(true);
      await fetchReceiptList(search);
      setLoading(false);
    })();
  }, [search]);

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Receipt | Search</title>
      </Helmet>
      <PageHeader
        title="Receipt"
        breadcrumb={PageHeader.Bread.receipt}
      />
      <div className="page-container" id="receipt-list" style={{display: 'block'}}>
        <Card>
          <div className="search-section">
            <FilterForm
              onSubmit={handleSubmitFilterForm}
              initialValues={_.pick(search, ['keyword'])}
            />
          </div>
          <Table
            rowKey={'_id'}
            columns={columns}
            dataSource={list}
            scroll={{ x: 1000 }}
            onChange={handleTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row') }
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            loading={loading}
          />
        </Card>
      </div>
    </Fragment>
  );
};

ReceiptEmailList.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchReceiptList: PropTypes.func
};

export default ReceiptEmailList;