import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Timeline, Card, Empty, Typography } from 'antd';
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import BookingStatusTag from 'components/BookingStatusTag';
import BigSpin from 'components/BigSpin';

import { useDispatch } from 'react-redux';
import { fetchBookingDetail, updateBookingHistoryNote } from 'providers/BookingProvider/actions';

import { formatYen } from 'utils/stringFormat';
import { renderFinalAmount, renderPaymentAmount, calculateRewardPoint } from 'utils/bookingDetailHelpers';

import './style.scss';

const clkIco = <ClockCircleOutlined style={{ fontSize: '16px' }}/>;
const edtIco = <EditOutlined style={{ fontSize: '16px' }}/>;

function isNoneFalsy(value) {
  return value || value === 0;
}

/*eslint-disable */
const BookingDetailInTimeLine = ({
  finalPrice, // booking.get('additionalPrice') || booking.get('price'),
  customerPrice, // customerPrice,
  status, // booking.get('status'),
  usedCoupon, // (booking.get('couponInfo') || {}).code,
  usedPoint, // (booking.get('bookingPointInfo') || {}).usePoint,
  awardedPoint, // Math.floor(customerPrice * BOOKING_POINT_EARN_RATE * pointBonusRate),
  /*eslint-enable */
}) => {
  return (
    <div>
      <u>予約内容:</u>
      <ul>
        {status && <li>status: {<BookingStatusTag status={status}/>}</li>}
        {isNoneFalsy(finalPrice) && <li>最終金額: {<b>{ _.isNumber(finalPrice) ? formatYen(finalPrice) : finalPrice }</b>}</li>}
        {isNoneFalsy(customerPrice) && <li>お客様お支払額: { _.isNumber(customerPrice) ? formatYen(customerPrice) : customerPrice }</li>}
        {isNoneFalsy(usedPoint) && <li>Used Point: {usedPoint}P</li>}
        {isNoneFalsy(usedCoupon) && <li>クーポン: <span className='coupon-code'>{usedCoupon}</span></li>}
        {isNoneFalsy(awardedPoint) && <li>Point GET: <span className='point-get'>{awardedPoint}P</span></li>}
      </ul>
    </div>
  );
};

const EditNote = ({note, historyId, updatedAt, bookingId, setLoading = () =>{}}) => {
  const dispatch = useDispatch();

  const updatedAtISO = moment(new Date(updatedAt.iso)).format('YYYY-MM-DD HH:mm');
  const defaultEditNote = `Edited at ${updatedAtISO}`;

  return (
    <Fragment>
      <u>メモ</u>{': '}
      <Typography.Paragraph
        editable={{
          maxLength: 1000,
          onChange: async (value) => {
            setLoading(true);
            await dispatch(updateBookingHistoryNote({ bookingId, objectId: historyId, note: value }));
            await dispatch(fetchBookingDetail({ bookingId }));
            setLoading(false);
          }
        }}
        style={{ display: 'inline' }}
      >
        {note || defaultEditNote}
      </Typography.Paragraph>
    </Fragment>
  );
};

const ChangedHistoryTab = ({updatedHistory, currentData: bookingDetail = {}}) => {
  const [loading, setLoading] = useState(false);
  
  return (
    <Card title='編集履歴'>
      {
        _.isEmpty(updatedHistory) ? <Empty/> :
          <Timeline mode='left' reverse={true} id='booking-timeline'>
            {
              _.flatten(updatedHistory.map(h => {
                const updatedAtISO = moment(new Date(h.updatedAt.iso)).format('YYYY-MM-DD ~ HH:mm');
                return [
                  <Timeline.Item key={h.objectId + 'd'} label={`Until ${updatedAtISO}`}>{BookingDetailInTimeLine(h)}</Timeline.Item>,
                  <Timeline.Item key={h.objectId + 'f'} label={''} dot={<div></div>}>{' '}</Timeline.Item>,
                  <Timeline.Item key={h.objectId} /*label={`Edited at ${updatedAt}`}*/ dot={edtIco} color='red'>
                    <EditNote note={h.note} historyId={h.objectId} updatedAt={h.updatedAt} bookingId={bookingDetail._id} setLoading={setLoading}/>
                  </Timeline.Item>
                ];
              })).concat(
                <Timeline.Item key={'current'} label={'今'} dot={clkIco} color='green'>
                  {BookingDetailInTimeLine({
                    finalPrice: renderFinalAmount(bookingDetail),
                    customerPrice: renderPaymentAmount(bookingDetail),
                    status: bookingDetail.status,
                    usedCoupon: (_.get(bookingDetail, 'couponInfo') || {}).code,
                    usedPoint: (_.get(bookingDetail, 'bookingPointInfo') || {}).usePoint,
                    awardedPoint: bookingDetail.status === 'DONE' && calculateRewardPoint(bookingDetail)
                    // Math.floor(customerPrice * BOOKING_POINT_EARN_RATE * pointBonusRate),
                  })}
                </Timeline.Item>
              )
            }
          </Timeline>
      }
      {loading && <BigSpin/>}
    </Card>
  );
};

ChangedHistoryTab.propTypes = {
  updatedHistory: PropTypes.array,
  currentData: PropTypes.object
};

export default ChangedHistoryTab;
