import {
  fetchRelatedReceiptList,
  fetchReceiptListSuccess,
  fetchRelatedReceiptListSuccess,
  fetchRenderReceiptEmailSuccess,
  changeReceiptBookingPriceSuccess,
  resendReceiptSuccess,
  resendReceiptFail
} from './actions';
import { fetchReceiptBookingDetail, fetchBookingDetail } from 'providers/BookingProvider/actions';
import {
  FETCH_RECEIPT_LIST,
  FETCH_RELATED_RECEIPT_LIST,
  FETCH_RENDER_RECEIPT,
  CHANGE_RECEIPT_BOOKING_PRICE,
  RESEND_RECEIPT_EMAIL
} from './constants';
import _ from 'lodash';
import { message } from 'antd';
import { put, takeLeading, takeEvery, call, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';

function* fetchReceiptList(action) {
  try {
    const { keyword, ...filter } = action.payload;
    const query = { keyword: keyword ? _.trim(keyword) : undefined, ...filter};
    const { data } = yield call(request, 'receipts/list', query, 'GET');

    yield put(fetchReceiptListSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchRelatedReceiptListSaga(action) {
  try {
    const { receiptId, bookingId } = action.payload;
    const { data } = yield call(request, `receipts/${receiptId}/related/booking/${bookingId}`, {}, 'GET');

    yield put(fetchRelatedReceiptListSuccess({ data }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchRenderReceiptEmail(action) {
  try {
    const { receiptId } = action.payload;
    const { data } = yield call(request, `receipts/${receiptId}/render`, {}, 'GET');

    yield put(fetchRenderReceiptEmailSuccess(_.get(data, 'result', ''), action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* changeReceiptBookingPrice(action) {
  const { receiptId = 'any', bookingId, finalPrice, customerPrice, screen = '' } = action.payload;
  try {
    // server removed "price" param 
    const { data } = yield call(request, `receipts/${receiptId}/related/booking/${bookingId}/price`, { finalPrice, customerPrice }, 'PUT');

    yield put(changeReceiptBookingPriceSuccess(_.get(data, 'result', ''), action.meta));
    yield put(fetchRelatedReceiptList({ receiptId, bookingId }));
  } catch (e) {
    message.error(_.get(e, 'error', 'Error occur!!!'));
  }
  if (screen === 'BOOKING_DETAIL') {
    yield put(fetchBookingDetail({ bookingId }));
  } else {
    yield put(fetchReceiptBookingDetail({ bookingId }));
  }
}

function* resendReceipt(action) {
  const { bookingId } = action.payload;
  try {
    yield call(request, `receipts/any/related/booking/${bookingId}/resend`, { bookingId }, 'PUT');
    yield put(resendReceiptSuccess({}, action.meta));
    yield put(fetchRelatedReceiptList({ receiptId: 'latest', bookingId }));
    message.success('送信しています!');
  } catch (e) {
    message.error(_.get(e, 'error', 'Error occur!!!'));
    yield put(resendReceiptFail({}), action.meta);
  }
}

export default function* watchReceipt() {
  yield takeLatest(FETCH_RECEIPT_LIST, fetchReceiptList);
  yield takeLatest(FETCH_RELATED_RECEIPT_LIST, fetchRelatedReceiptListSaga);
  yield takeEvery(FETCH_RENDER_RECEIPT, fetchRenderReceiptEmail);
  yield takeEvery(CHANGE_RECEIPT_BOOKING_PRICE, changeReceiptBookingPrice);
  yield takeLeading(RESEND_RECEIPT_EMAIL, resendReceipt);
}
