export const FETCH_CUSTOMER_LIST = 'FETCH_CUSTOMER_LIST';
export const FETCH_CUSTOMER_LIST_SUCCESS = 'FETCH_CUSTOMER_LIST_SUCCESS';
export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE';
export const FETCH_CUSTOMER_PROFILE_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SUCCESS';
export const UNSET_CUSTOMER_PROFILE = 'UNSET_CUSTOMER_PROFILE';

export const FETCH_CUSTOMER_COUPON_LISTS = 'FETCH_CUSTOMER_COUPON_LISTS';
export const FETCH_CUSTOMER_COUPON_LISTS_SUCCESS = 'FETCH_CUSTOMER_COUPON_LISTS_SUCCESS';

export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO';
export const UPDATE_CUSTOMER_INFO_SUCCESS = 'UPDATE_CUSTOMER_INFO_SUCCESS';
export const UPDATE_CUSTOMER_INFO_FAIL = 'UPDATE_CUSTOMER_INFO_FAIL';

export const VERIFY_CUSTOMER_EMAIL = 'VERIFY_CUSTOMER_EMAIL';
export const VERIFY_CUSTOMER_EMAIL_SUCCESS = 'VERIFY_CUSTOMER_EMAIL_SUCCESS';

export const FETCH_CUSTOMER_POINT_INFO = 'FETCH_CUSTOMER_POINT_INFO';
export const FETCH_CUSTOMER_POINT_INFO_SUCCESS = 'FETCH_CUSTOMER_POINT_INFO_SUCCESS';

export const FETCH_CUSTOMER_POINT_HISTORY = 'FETCH_CUSTOMER_POINT_HISTORY';
export const FETCH_CUSTOMER_POINT_HISTORY_SUCCESS = 'FETCH_CUSTOMER_POINT_HISTORY_SUCCESS';