import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';

import { SetPopup } from 'providers/PopupProvider/actions';
import { fetchSalonList } from 'providers/SalonProvider/actions';
import { makeSearchSalonList } from 'providers/SalonProvider/selectors';

import { makeSelectLocationSearch, makeSelectLocationPathname } from 'providers/RouterProvider/selectors';

import SearchNailistPage from './SalonSearchList';

const mapStateToProps = (state) => ({
  list: makeSearchSalonList(state).list,
  total: makeSearchSalonList(state).total,
  loading: makeSearchSalonList(state).loading,
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state),
});


const mapDispatchToProps = (dispatch) => ({
  fetchSalonList: (params) => dispatch(fetchSalonList(params)),
  push: (path) => dispatch(push(path)),
  replace: (path) => dispatch(replace(path)),
  openCreateSalonForm: () => dispatch(SetPopup({name: 'createSalon', show: true})),
  openCreateMultiSalonForm: () => dispatch(SetPopup({name: 'createMultiSalon', show: true})),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchNailistPage);