/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React from 'react';
import { Tag } from 'antd';
import { USER_STATUS } from 'utils/constants';
import moment from 'moment';


const columnsTemplate = {
  id: {
    title: 'ID',
    dataIndex: 'objectId',
    className: 'drag-visible',
    width: 220,
    key: 'objectId'
  },
  target: {
    title: '表示対象',
    dataIndex: 'showOnRoles',
    className: 'drag-visible',
    key: 'showOnRoles',
    render: showOnRoles => (showOnRoles || []).join(',  ')
  },
  status: {
    title: 'ステータス',
    dataIndex: 'status',
    className: 'drag-visible',
    width: 120,
    key: 'status',
    render: (record) => (
      <Tag color={USER_STATUS[record]?.color}>{USER_STATUS[record]?.value}</Tag>
    )
  },
  startEnd: {
    title: 'Start-End At (JST)',
    className: 'drag-visible',
    key: 'startEnd',
    width: 250,
    render: (record) => {
      if (!record.startTime && !record.endTime) {
        return '';
      }
      const startTime = record.startTime ? moment(record.startTime.iso).utcOffset('+0900').format('YYYY-MM-DD HH:mm') : '';
      const endTime = record.endTime ? moment(record.endTime.iso).utcOffset('+0900').format('YYYY-MM-DD HH:mm') : '';

      return <div style={{ whiteSpace: 'pre' }}>{`from: ${startTime}\nto: ${endTime}`}</div>;
    }
  },
  createdAt: {
    title: '作成日',
    dataIndex: 'createdAt',
    className: 'drag-visible',
    width: 180,
    key: 'createdAt',
    render: (record) => <span style={{ whiteSpace: 'nowrap' }}>{moment(record?.iso).format('HH:mm YYYY-MM-DD')}</span>
  }
};

export default columnsTemplate;