import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import BankForm from './BankForm';

import './styles.scss';

const BankPopup = ({
  data,
  closePopup,
  submitForm,
  getBankList,
  getBranchList,
  getAccountTypeList,
  fetchNailistBankInfo,
  bankList,
  branchList,
  accountTypeList,
  updateBankAccount,
  actionLoading
}) => {
  useEffect(() => {
    getBankList({ page: 1, limit: 2000 });
    getAccountTypeList({ page: 1, limit: 2000 });
  }, []);

  const bankId = _.get(data, 'bankBranch.bank._id');
  useEffect(() => {
    bankId && getBranchList({ bankId: bankId, page: 1, limit: 3000 });
  }, [bankId]);

  const handleBankChange = (bankId) => {
    getBranchList({ bankId: bankId, page: 1, limit: 1000 });
  };

  return (
    <Modal
      title="口座情報"
      width={768}
      style={{ top: 36 }}
      destroyOnClose={true}
      visible={!!data}
      onCancel={closePopup}
      onOk={submitForm}
      confirmLoading={actionLoading}
    >
      <BankForm
        initialValues={{
          ..._.pick(data, [
            'firstName',
            'lastName',
            'accountHolder',
            'accountNumber',
            'type'
          ]),
          branchId: _.get(data, 'bankBranch._id'),
          bankId: _.get(data, 'bankBranch.bank._id'),
          accountTypeId: _.get(data, 'accountType._id'),
          photo: _.get(data, 'photo')
        }}
        onBankChange={handleBankChange}
        onSubmit={async(params) => {
          const result = await updateBankAccount({
            ...params,
            userId: data.userId
          });
          if (!result.error) {
            fetchNailistBankInfo({
              id: data.userId
            });
            closePopup();
          }
        }}
        bankOptions={_.map(bankList.data, (o) => ({
          objectId: o._id,
          name: `${o.bankCode} - ${o.bankName}`
        }))}
        branchOptions={_.map(branchList.data, (o) => ({
          objectId: o._id,
          name: `${o.branchCode} - ${o.branchName}`
        }))}
        accountTypeOptions={_.map(accountTypeList, (o) => ({
          objectId: o._id,
          name: o.type
        }))}
      />
    </Modal>
  );
};

BankPopup.propTypes = {
  data: PropTypes.any,
  closePopup: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  getBankList: PropTypes.func,
  getBranchList: PropTypes.func,
  getAccountTypeList: PropTypes.func,
  bankList: PropTypes.any,
  branchList: PropTypes.any,
  accountTypeList: PropTypes.any,
  updateBankAccount: PropTypes.func,
  actionLoading: PropTypes.bool,
  fetchNailistBankInfo: PropTypes.func
};

export default BankPopup;
