export const GET_FRIEND_INVITATION_LIST = 'GET_FRIEND_INVITATION_LIST';
export const GET_FRIEND_INVITATION_LIST_SUCCESS = 'GET_FRIEND_INVITATION_LIST_SUCCESS';

export const EXPORT_FRIEND_INVITATION_LIST = 'EXPORT_FRIEND_INVITATION_LIST';
export const EXPORT_FRIEND_INVITATION_LIST_SUCCESS = 'EXPORT_FRIEND_INVITATION_LIST_SUCCESS';

export const GET_GO_TO_NAIL_INFO = 'GET_GO_TO_NAIL_INFO';
export const GET_GO_TO_NAIL_INFO_SUCCESS = 'GET_GO_TO_NAIL_INFO_SUCCESS';

export const GET_GO_TO_NAIL_LIST = 'GET_GO_TO_NAIL_LIST';
export const GET_GO_TO_NAIL_LIST_SUCCESS = 'GET_GO_TO_NAIL_LIST_SUCCESS';

export const EXPORT_GO_TO_NAIL_LIST = 'EXPORT_GO_TO_NAIL_LIST';
export const EXPORT_GO_TO_NAIL_LIST_SUCCESS = 'EXPORT_GO_TO_NAIL_LIST_SUCCESS';

export const UPDATE_GO_TO_NAIL = 'UPDATE_GO_TO_NAIL';
export const UPDATE_GO_TO_NAIL_SUCCESS = 'UPDATE_GO_TO_NAIL_SUCCESS';

export const GET_OLYMPIC_INFO = 'GET_OLYMPIC_INFO';
export const GET_OLYMPIC_INFO_SUCCESS = 'GET_OLYMPIC_INFO_SUCCESS';

export const GET_OLYMPIC_LIST = 'GET_OLYMPIC_LIST';
export const GET_OLYMPIC_LIST_SUCCESS = 'GET_OLYMPIC_LIST_SUCCESS';

export const EXPORT_OLYMPIC_LIST = 'EXPORT_OLYMPIC_LIST';
export const EXPORT_OLYMPIC_LIST_SUCCESS = 'EXPORT_OLYMPIC_LIST_SUCCESS';

export const UPDATE_OLYMPIC = 'UPDATE_OLYMPIC';
export const UPDATE_OLYMPIC_SUCCESS = 'UPDATE_OLYMPIC_SUCCESS';

export const GET_GO_TO_NAIL_INFO_2ND = 'GET_GO_TO_NAIL_INFO_2ND';
export const GET_GO_TO_NAIL_INFO_2ND_SUCCESS = 'GET_GO_TO_NAIL_INFO_2ND_SUCCESS';

export const GET_GO_TO_NAIL_LIST_2ND = 'GET_GO_TO_NAIL_LIST_2ND';
export const GET_GO_TO_NAIL_LIST_2ND_SUCCESS = 'GET_GO_TO_NAIL_LIST_2ND_SUCCESS';

export const EXPORT_GO_TO_NAIL_LIST_2ND = 'EXPORT_GO_TO_NAIL_LIST_2ND';
export const EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS = 'EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS';

export const UPDATE_GO_TO_NAIL_2ND = 'UPDATE_GO_TO_NAIL_2ND';
export const UPDATE_GO_TO_NAIL_2ND_SUCCESS = 'UPDATE_GO_TO_NAIL_2ND_SUCCESS';

// GO TO NAIL 3
export const GET_GO_TO_NAIL_INFO_3RD = 'GET_GO_TO_NAIL_INFO_3RD';
export const GET_GO_TO_NAIL_INFO_3RD_SUCCESS = 'GET_GO_TO_NAIL_INFO_3RD_SUCCESS';

export const GET_GO_TO_NAIL_LIST_3RD = 'GET_GO_TO_NAIL_LIST_3RD';
export const GET_GO_TO_NAIL_LIST_3RD_SUCCESS = 'GET_GO_TO_NAIL_LIST_3RD_SUCCESS';

export const EXPORT_GO_TO_NAIL_LIST_3RD = 'EXPORT_GO_TO_NAIL_LIST_3RD';
export const EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS = 'EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS';

export const UPDATE_GO_TO_NAIL_3RD = 'UPDATE_GO_TO_NAIL_3RD';
export const UPDATE_GO_TO_NAIL_3RD_SUCCESS = 'UPDATE_GO_TO_NAIL_3RD_SUCCESS';

// INSTAGRAM COUPON
export const GET_INSTAGRAM_COUPON_INFOR = 'GET_INSTAGRAM_COUPON_INFOR';
export const GET_INSTAGRAM_COUPON_INFOR_SUCCESS = 'GET_INSTAGRAM_COUPON_INFOR_SUCCESS';

export const GET_INSTAGRAM_COUPON_LIST = 'GET_INSTAGRAM_COUPON_LIST';
export const GET_INSTAGRAM_COUPON_LIST_SUCCESS = 'GET_INSTAGRAM_COUPON_LIST_SUCCESS';

export const EXPORT_INSTAGRAM_COUPON = 'EXPORT_INSTAGRAM_COUPON';
export const EXPORT_INSTAGRAM_COUPON_SUCCESS = 'EXPORT_INSTAGRAM_COUPON_SUCCESS';

export const UPDATE_INSTAGRAM_COUPON = 'UPDATE_INSTAGRAM_COUPON';
export const UPDATE_INSTAGRAM_COUPON_SUCCESS = 'UPDATE_INSTAGRAM_COUPON_SUCCESS';

