import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import _get from 'lodash/get';
import { Avatar, Descriptions, Typography, Card, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import pinMarker from 'assets/images/pin-marker.png';
import { SALON_PROPERTIES } from 'utils/constants';
const { Title } = Typography;

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const defaultLang = { lat: 35.652832, lng: 139.839478 }
const PinMarker = () => (<img id='pinMark' src={pinMarker} />);
const hideFullScreenOptions = () => ({ fullscreenControl: false });

class Profile extends React.Component {
  render() {
    const { logoUrl, businessName, operatorEmail, operatorPhoneNumber, location, description, properties, photos } = _get(this.props, 'profile.info', {});
    const renderSalonArea = `${_get(location, 'area.name', '--')}・${_get(location, 'area.prefecture.name', '--')}・${_get(location, 'area.prefecture.city.name', '--')}`
    const propertiesSelected = (properties || []).map(item => item?.type);
    const addressMode = _get(location, 'isPrivate') === true ? "予約が確定したお客様にのみ公開" : "サロンの住所を常に表示"
    const renderPropertyIds = (propertiesSelected) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
          { SALON_PROPERTIES.map(item => {
            let styleSelected;
            const style = { height: 40, width: 40, borderRadius: '50%', borderStyle: 'solid', marginRight: 45, marginBottom: 8 };

            // Selected Item Css
            if (propertiesSelected.includes(item?.type)) {
              styleSelected = { filter: 'invert(30%) sepia(73%) saturate(2251%) hue-rotate(160deg) brightness(94%) contrast(104%)' };
            }

            return (
              <div key={item.id} style={{ marginRight: 24, textAlign: 'center', marginBottom: 24 }}>
                <img
                  style={{ ...style, ...styleSelected }}
                  src={item.icon}
                />
                <div style={{ whiteSpace: 'nowrap', marginLeft: -45, ...styleSelected }} >{item.name}</div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <React.Fragment>
        <Card className="salon-profile-tab" title="サロン情報">
          <div id="salon-profile">
            <Descriptions column={1} bordered >
              <Descriptions.Item label="ロゴ:">
                <Avatar shape="square" src={logoUrl} size={90} icon={<UserOutlined />} />
              </Descriptions.Item>
              <Descriptions.Item label="サロン名:"><Title level={5}>{businessName}</Title></Descriptions.Item>
              <Descriptions.Item label="メールアドレス:">{operatorEmail}</Descriptions.Item>
              <Descriptions.Item label="電話番号:">{operatorPhoneNumber}</Descriptions.Item>
              <Descriptions.Item label="サロンエリア:" contentStyle={{ color: '#2db7f5' }}>{renderSalonArea}</Descriptions.Item>
              <Descriptions.Item label="郵便番号:">{_get(location, 'postCode', '----')}</Descriptions.Item>
              <Descriptions.Item label="都道府県:">{_get(location, 'prefecture', '--')}</Descriptions.Item>
              <Descriptions.Item label="市区町村:">{_get(location, 'cityOrTown', '--')}</Descriptions.Item>
              <Descriptions.Item label="番地:">{_get(location, 'address', '--')}</Descriptions.Item>
              <Descriptions.Item label="建物名等/部屋番号:" >{_get(location, 'building', '--')}</Descriptions.Item>
              <Descriptions.Item label="サロン住所公開設定:" >{addressMode}</Descriptions.Item>
              <Descriptions.Item label=""><div style={{ width: '800px' }} /></Descriptions.Item>
            </Descriptions>
            <div className="salon-location">
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={defaultLang}
                center={_get(location, 'latLng')}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                options={hideFullScreenOptions}
              >
                <PinMarker
                  // Latitude and longitude coordinates default is Tokyo (35.658581, 139.745438.)
                  lat={_get(location, 'latLng.lat', 35.652832)}
                  lng={_get(location, 'latLng.lng', 139.839478)}
                />
              </GoogleMapReact>
            </div>

            <div>
              <h4 className="salon-label">サロンの特徴</h4>
              <Divider style={{ marginTop: 15 }} />
              {renderPropertyIds(propertiesSelected)}
            </div>

            <div>
              <h4 className="salon-label">サロンについて・注意事項</h4>
              <Divider style={{ marginTop: 15 }} />
              {<div className="salon-description">{description}</div> || '--'}
            </div>

            <h4 className="salon-label">サロンイメージ写真</h4>
            <Divider style={{ marginTop: 15 }} />
            <div id="salon-photos">
              <Avatar.Group>
                {(photos || []).map(url => (<Avatar shape="square" size={100} src={url} icon={<UserOutlined />}></Avatar>))}
              </Avatar.Group>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.object
};

export default Profile;
