import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReceiptEmailList from './ReceiptEmailList';
import ReceiptEmailDetail from './ReceiptEmailDetail';

const RECEIPT_ROOT = '/dashboard/receipts/list';

const RedirectToReceiptList = () => (
  <Redirect to={RECEIPT_ROOT} />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Receipt</title>
      </Helmet>
      <Switch>
        <Route
          path={RECEIPT_ROOT}
          exact
          component={ReceiptEmailList}
        />
        <Route
          path='/dashboard/receipts/:receiptId/related/booking/:bookingId'
          exact
          component={ReceiptEmailDetail}
        />
        <Route path="/dashboard/receipts" component={RedirectToReceiptList} />
      </Switch>
    </>
  );
};

export default Router;
