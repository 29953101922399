export const FETCH_POPUP_BANNER_LIST = '@popup_banner/FETCH_POPUP_BANNER_LIST';
export const FETCH_POPUP_BANNER_LIST_SUCCESS = '@popup_banner/FETCH_POPUP_BANNER_LIST_SUCCESS';

export const TOGGLE_POPUP_BANNER_STATUS = '@popup_banner/TOGGLE_POPUP_BANNER_STATUS';
export const TOGGLE_POPUP_BANNER_STATUS_SUCCESS = '@popup_banner/TOGGLE_POPUP_BANNER_STATUS_SUCCESS';

export const CREATE_POPUP_BANNER = '@popup_banner/CREATE_POPUP_BANNER';
export const CREATE_POPUP_BANNER_SUCCESS = '@popup_banner/CREATE_POPUP_BANNER_SUCCESS';

export const UPDATE_POPUP_BANNER = '@popup_banner/UPDATE_POPUP_BANNER';
export const UPDATE_POPUP_BANNER_SUCCESS = '@popup_banner/UPDATE_POPUP_BANNER_SUCCESS';
export const UPDATE_POPUP_BANNER_FAIL = '@popup_banner/UPDATE_POPUP_BANNER_FAIL';

export const UPDATE_POPUP_BANNERS_POSITION = '@popup_banner/UPDATE_POPUP_BANNERS_POSITION';
export const UPDATE_POPUP_BANNERS_POSITION_SUCCESS = '@popup_banner/UPDATE_POPUP_BANNERS_POSITION_SUCCESS';
export const UPDATE_POPUP_BANNERS_POSITION_FAIL = '@popup_banner/UPDATE_POPUP_BANNERS_POSITION_FAIL';
