import produce from 'immer';

import {
  FETCH_DELETED_ACCOUNT_LIST,
  FETCH_DELETED_ACCOUNT_LIST_SUCCESS,
  FETCH_DELETED_ACCOUNT_LIST_ERROR,
  EXPORT_DELETED_ACCOUNT_CSV,
  EXPORT_DELETED_ACCOUNT_CSV_SUCCESS,
  EXPORT_DELETED_ACCOUNT_CSV_ERROR,
  FETCH_DELETED_USER_BOOKING,
  FETCH_DELETED_USER_BOOKING_SUCCESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  list: [],
  total: 0,
  isExporting: false,
  deletedUserBookingList: {},
  isLoadingUserBooking: false
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELETED_ACCOUNT_LIST:
      state.loading = true;
      return state;

    case FETCH_DELETED_ACCOUNT_LIST_SUCCESS:
      state.loading = false;
      state.list = action.payload.data.data;
      state.total = action.payload.data.total;

      return state;

    case FETCH_DELETED_ACCOUNT_LIST_ERROR:
      state.loading = false;
      return state;

    case EXPORT_DELETED_ACCOUNT_CSV:
      state.isExporting = true;
      return state;

    case EXPORT_DELETED_ACCOUNT_CSV_SUCCESS:
    case EXPORT_DELETED_ACCOUNT_CSV_ERROR:
      state.isExporting = false;
      return state;

    case FETCH_DELETED_USER_BOOKING:
      state.isLoadingUserBooking = true;
      return state;

    case FETCH_DELETED_USER_BOOKING_SUCCESS:
      state.isLoadingUserBooking = false;
      state.deletedUserBookingList[action.payload.userId] = action.payload.data;
      return state;
    case UPDATE_USER_STATUS:
      state.loading = true;
      return state;

    case UPDATE_USER_STATUS_SUCCESS:
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
