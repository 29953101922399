const SB_point = /^[0-9\b]+$/;

const validate = (values) => {
  const errors = {};
  if (!values.customer) {
    errors.customer = 'Required';
  }
  if (!values.point) {
    errors.point = 'Required';
  } else if (Number(values.point) > 10000) {
    errors.point = 'Invalid! Maximum 10000.';
  } else if (!SB_point.test(values.point)) {
    errors.point = 'Only enter numeric characters';
  }
  return errors;
};

export default validate;
