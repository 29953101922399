import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_PAYOUT_DETAIL_BOOKING_LIST,
  FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS,
  FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS,
  RESET_BOOKING_PROVIDER,
  FETCH_BOOKING_LIST,
  FETCH_BOOKING_LIST_SUCCESS,
  FETCH_BOOKING_DETAIL_SUCCESS,
  FETCH_BOOKING_DETAIL,
  FETCH_CARD_ERROR_BOOKING_LIST,
  FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS,
  EXPORT_BOOKING_CSV,
  EXPORT_BOOKING_CSV_SUCCESS,
  EXPORT_BOOKING_CSV_ERROR,
  COMPLETE_BOOKING,
  COMPLETE_BOOKING_SUCCESS,
  COMPLETE_BOOKING_ERROR,
  NOTIFY_ERROR_BOOKING,
  NOTIFY_ERROR_BOOKING_SUCCESS,
  NOTIFY_ERROR_BOOKING_ERROR,
  ON_ERROR,
  FETCH_BOOKING_MESSAGE_LIST,
  FETCH_BOOKING_MESSAGE_LIST_SUCCESS,
  FETCH_BOOKING_REVIEW,
  FETCH_BOOKING_REVIEW_SUCCESS,
  DELETE_BOOKING_REVIEW_SUCCESS,
  FETCH_BOOKING_SCHEDULE,
  FETCH_BOOKING_SCHEDULE_SUCCESS,
  OPEN_SCHEDULE_POPUP_LIST,
  CLOSE_SCHEDULE_POPUP_LIST,
  FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS
} from './constants';

const initialState = {
  payoutDetail: {
    list: []
  },
  receiptDetail: {},
  booking: {
    page: 1,
    list: [],
    total: 0,
    isLoading: true,
    detail: {}
  },
  cardErrorBooking: {
    list: [],
    total: 0,
    isLoading: true
  },
  isExporting: false,
  notifiedCustomerIds: [],
  message: {
    isLoading: true,
    messages: [],
    meta: {}
  },
  review: {
    isLoading: true,
    review: {}
  },
  schedule: {
    schedule: {},
    isLoading: false,
    bookingPopupControl: null // null || { treatmentDate, type }
  },
  exportBooking: {
    filesList: []
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYOUT_DETAIL_BOOKING_LIST:
      state.payoutDetail.list = [];
      return state;

    case FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS:
      state.payoutDetail.list = _.get(action, 'payload.data', []);
      return state;

    case FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS:
      state.receiptDetail = _.get(action, 'payload.data', {});
      return state;

    case FETCH_BOOKING_LIST:
      state.booking.isLoading = true;
      return state;

    case FETCH_BOOKING_LIST_SUCCESS:
      state.booking.page = _.get(action, 'payload.data.page', 1);
      state.booking.list = _.get(action, 'payload.data.data', []);
      state.booking.total = _.get(action, 'payload.data.total', 0);
      state.booking.isLoading = false;
      return state;

    case FETCH_CARD_ERROR_BOOKING_LIST:
    case COMPLETE_BOOKING:
      state.cardErrorBooking.isLoading = true;
      return state;

    case FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS:
      state.cardErrorBooking.list = _.get(action, 'payload.data.data', []);
      state.cardErrorBooking.total = _.get(action, 'payload.data.total', 0);
      state.cardErrorBooking.isLoading = false;
      return state;

    case EXPORT_BOOKING_CSV:
      state.isExporting = true;
      return state;

    case EXPORT_BOOKING_CSV_SUCCESS:
      state.isExporting = false;
      return state;

    case EXPORT_BOOKING_CSV_ERROR:
      state.isExporting = false;
      return state;

    case NOTIFY_ERROR_BOOKING: {
      const { ids = [] } = action.payload;
      state.notifiedCustomerIds = state.notifiedCustomerIds.concat(ids);
      return state;
    }

    case NOTIFY_ERROR_BOOKING_SUCCESS: {
      const { ids = [] } = action.payload;
      state.notifiedCustomerIds = state.notifiedCustomerIds.filter(id => !ids.includes(id));
      return state;
    }

    case NOTIFY_ERROR_BOOKING_ERROR: {
      const { ids = [] } = action.payload;
      state.notifiedCustomerIds = state.notifiedCustomerIds.filter(id => !ids.includes(id));
      return state;
    }

    case COMPLETE_BOOKING_SUCCESS:
    case COMPLETE_BOOKING_ERROR: {
      state.cardErrorBooking.isLoading = false;
      return state;
    }

    case ON_ERROR:
      state.booking.isLoading = false;
      return state;

    case FETCH_BOOKING_DETAIL:
      // state.booking.detail = {};
      return state;

    case FETCH_BOOKING_DETAIL_SUCCESS:
      state.booking.detail = _.get(action, 'payload.data', {});
      return state;

    case RESET_BOOKING_PROVIDER:
      return initialState;

    case FETCH_BOOKING_MESSAGE_LIST:
      state.message.isLoading = true;
      return state;

    case FETCH_BOOKING_MESSAGE_LIST_SUCCESS:
      state.message.isLoading = false;
      state.message.messages = [..._.get(action, 'payload.data.messages', []), ...state.message.messages];
      state.message.meta = { ...state.message.meta, ..._.get(action, 'payload.data.meta', {}) };
      return state;

    case FETCH_BOOKING_REVIEW:
      state.review.isLoading = true;
      return state;

    case FETCH_BOOKING_REVIEW_SUCCESS:
      state.review.isLoading = false;
      state.review.review = _.get(action, 'payload.data', {});
      return state;

    case DELETE_BOOKING_REVIEW_SUCCESS:
      state.review.review = _.get(action, 'payload.data', {});
      return state;

    case FETCH_BOOKING_SCHEDULE:
      state.schedule.isLoading = true;
      return state;

    case FETCH_BOOKING_SCHEDULE_SUCCESS:
      state.schedule.isLoading = false;
      state.schedule.schedule = _.get(action, 'payload.data', {});
      return state;

    case OPEN_SCHEDULE_POPUP_LIST:
      state.schedule.bookingPopupControl = _.get(action, 'payload', {});
      return state;
    case CLOSE_SCHEDULE_POPUP_LIST:
      state.schedule.bookingPopupControl = null;
      return state;

    case FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS:
      state.exportBooking.filesList = _.get(action, 'payload', []);
      return state;

    default:
      return state;
  }
});

export default reducer;
