import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _, { isNumber } from 'lodash';
import { Button, Form, Modal, InputNumber } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { formatYen } from 'utils/stringFormat';

let currentModalControl = null;


const PRICE_INPUT_ELEM_ID = 'new_total_price_receipt';
function getPriceInputElem() {
  return document.getElementById(PRICE_INPUT_ELEM_ID);
}
function readPriceInput() {
  return getPriceInputElem().value;
}

function calcShowingCustomerPrice(point, coupon, penalty = 0) {
  const priceInput = getPriceInputElem() || { value: '' };
  const newPrice = priceInput.value === '' ? NaN : Number(priceInput.value);

  if (!_.isNaN(newPrice)) { 
    const minusPoint = _.get(point, 'usePoint', 0);
    const minusCoupon = _.get(coupon, 'value', 0);

    const subTotal = newPrice - minusPoint - minusCoupon;
    const penaltyAmount = Math.ceil(subTotal * penalty); // -math.cel === math floor of final result

    return Math.max(subTotal - penaltyAmount, 0);
  }

  return 'calculating...';
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value + 1); // update the state to force render
}

const PriceInput = ({ point = null, coupon = null, penalty = 0, required = true }) => {
  const forceUpdate = useForceUpdate();
  const newCustomerPrice = calcShowingCustomerPrice(point, coupon, penalty);

  return (
    <Form validateTrigger={['onBlur', 'onChange']}>
      <br/>
      <Form.Item 
        style={{marginBottom: 0}}
        label="Edit booking price:"
        name="price"
        rules={[{ required: required, message: 'Required a number!' }]}
      >
        <InputNumber
          id={PRICE_INPUT_ELEM_ID}
          style={{ width: '340px' }}
          min={0} // max={100000}
          onChange={() => setTimeout(forceUpdate, 10)}
          onKeyPress={(e) => {
            const key = e.which || e.key;
            if (e.target.value.length < 7) {
              if (key < 48 || key > 57) { e.preventDefault(); }
            } else e.preventDefault();
            setTimeout(forceUpdate, 10); // 10ms
          }}
        />
      </Form.Item>
      { coupon && 
        <div>
          <br/>
          Coupon: <b>[{coupon.code}]</b> -{formatYen(coupon.value)}
        </div>
      }

      { point && 
        <div>
          <br/>
          Point: <b>[{point.usePoint}P]</b> -{formatYen(point.usePoint)}
        </div>
      }

      { penalty && 
        ( 
          <div>
            <br/>
            Penalty: <b>[{Math.round(penalty * 100)}%]</b>
          </div>
        ) ||
        undefined
      }

      <br/>
      New Customer Price (price in receipt email): {
        !isNumber(newCustomerPrice) ? newCustomerPrice : <b>{formatYen(newCustomerPrice)}</b>
      }

    </Form>
  );
};

const showConfirmPricePopup = () => {
  return new Promise((resolve, reject) => {
    currentModalControl = Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure want to re-send a changed price receipt?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => resolve(),
      onCancel: () => reject(),
      width: '600px'
    });
  });
};

const showInputPricePopup = ({ point, coupon, penalty }) => {
  return new Promise((resolve, reject) => {
    currentModalControl = Modal.confirm({
      title: 'Change amount of price',
      content: <PriceInput point={point} coupon={coupon} penalty={penalty}/>,
      okText: 'Save',
      cancelText: 'Cancel',
      onOk: async() => {
        const priceInput = getPriceInputElem();
        const newPrice = priceInput.value === '' ? NaN : Number(priceInput.value);
        const customerPrice = Number(calcShowingCustomerPrice(point, coupon, penalty));

        // trigger validate field
        priceInput.focus();
        priceInput.blur();

        if (!_.isNaN(newPrice) && !_.isNaN(customerPrice)) { 
          return resolve([Number(newPrice), customerPrice]);
        }

        //prevent close modal
        throw 'form error';
      },
      onCancel: () => reject(),
      width: '600px'
    });
  });
};

const EditReceiptPriceButton = ({ bookingId, changeReceiptBookingPrice, bookingData = {}, disabled = false, screen }) => {
  useEffect(() => {
    return () => {
      if (currentModalControl) {
        currentModalControl.destroy();
      }
    };
  }, []);

  return (
    <Button
      icon={<EditOutlined/>}
      type="primary"
      onClick={async() => {
        const [newPrice, customerPrice] = await showInputPricePopup({ coupon: bookingData.couponInfo, point: bookingData.bookingPointInfo, penalty: bookingData.penalty });
        await showConfirmPricePopup();
        await changeReceiptBookingPrice({ bookingId, finalPrice: newPrice, customerPrice, screen });
      }}
      disabled={disabled}
    >
      Edit amount of price
    </Button>
  );
};

EditReceiptPriceButton.propTypes = {
  bookingId: PropTypes.string,
  changeReceiptBookingPrice: PropTypes.func,
  bookingData: PropTypes.any,
  screen: PropTypes.any,
  disabled: PropTypes.bool
};

export default EditReceiptPriceButton;

export {
  PriceInput,
  readPriceInput
};