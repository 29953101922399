// Checked
import produce from 'immer';
import _ from 'lodash';

import {
  DELETE_SUPPORT_TICKET,
  DELETE_ANSWERED_TICKET_SUCCESS,
  DELETE_ANSWERED_TICKET_FAILED,
  DELETE_UNANSWERED_TICKET_SUCCESS,
  DELETE_UNANSWERED_TICKET_FAILED,
  SET_IS_READ_TICKETS,
  REPLY_TICKET_FAILED,
  REPLY_TICKET_SUCCESS,
  UPLOAD_IMAGE_SUCCESS,

  GET_SUPPORT_FOR_SALON_ANSWERED,
  GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS,
  SET_SALON_ANSWERED_LOADING,
  SET_FILTER_SALON_ANSWERED,
  GET_SUPPORT_FOR_SALON_UNANSWERED,
  GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS,
  SET_SALON_UNANSWERED_LOADING,
  SET_FILTER_SALON_UNANSWERED,
  COUNT_UNREAD_SALON_TICKETS,
  COUNT_UNREAD_SALON_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_WEB_ANSWERED,
  GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS,
  SET_WEB_ANSWERED_LOADING,
  SET_FILTER_WEB_ANSWERED,
  GET_SUPPORT_FOR_WEB_UNANSWERED,
  GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS,
  SET_WEB_UNANSWERED_LOADING,
  SET_FILTER_WEB_UNANSWERED,
  COUNT_UNREAD_WEB_TICKETS,
  COUNT_UNREAD_WEB_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_APP_ANSWERED,
  GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS,
  SET_APP_ANSWERED_LOADING,
  SET_FILTER_APP_ANSWERED,
  GET_SUPPORT_FOR_APP_UNANSWERED,
  GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS,
  SET_APP_UNANSWERED_LOADING,
  SET_FILTER_APP_UNANSWERED,
  COUNT_UNREAD_APP_TICKETS,
  COUNT_UNREAD_APP_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_VERIFICATION_ANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS,
  SET_VERIFICATION_ANSWERED_LOADING,
  SET_FILTER_VERIFICATION_ANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_UNANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS,
  SET_VERIFICATION_UNANSWERED_LOADING,
  SET_FILTER_VERIFICATION_UNANSWERED,
  COUNT_UNREAD_VERIFICATION_TICKETS,
  COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS,

  GET_REPORT_LIST,
  GET_REPORT_LIST_BY_USER,
  GET_REPORT_LIST_SUCCESS
} from './constants';

const initialState = {
  supportWeb: {
    dataAnswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    dataUnanswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    filterAnswered: {},
    filterUnanswered: {},
  },
  supportSalon: {
    dataAnswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    dataUnanswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    filterAnswered: {},
    filterUnanswered: {},
  },
  supportApp: {
    dataAnswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    dataUnanswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    filterAnswered: {},
    filterUnanswered: {},
  },
  supportVerification: {
    dataAnswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    dataUnanswered: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    filterAnswered: {},
    filterUnanswered: {},
  },

  actionLoading: false,
  
  searchAnswered: {
    web: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    },
    salon: {
      list: [],
      total: 0,
      lastPage: 1,
      page: 1,
      loading: false
    }
  },
  detail: {},
  countSalon: 0,
  countWeb: 0,
  countApp: 0,
  countVerification: 0,
  deletedAnsweredTicket: false,
  deleteUnanswerTicket: false,
  replyTicket: false,
  image: null,
  report: {
    list: [],
    total: 0,
    loading: false
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_FOR_SALON_UNANSWERED:
      state.supportSalon.dataUnanswered.loading = true;
      state.supportWeb.dataUnanswered.page = 1;
      state.supportWeb.filterUnanswered = {};
      return state;
    case SET_SALON_UNANSWERED_LOADING:
      state.supportSalon.dataUnanswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS:
      state.supportSalon.dataUnanswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_SALON_UNANSWERED: 
      state.supportSalon.filterUnanswered = _.get(action, 'payload');
      return state;
    case GET_SUPPORT_FOR_SALON_ANSWERED:
      state.supportSalon.dataAnswered.loading = true;
      state.supportWeb.dataAnswered.page = 1;
      state.supportWeb.filterAnswered = {};
      return state;
    case SET_SALON_ANSWERED_LOADING:
      state.supportSalon.dataAnswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS:
      state.supportSalon.dataAnswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_SALON_ANSWERED: 
      state.supportSalon.filterAnswered = _.get(action, 'payload');
      return state;
    case COUNT_UNREAD_SALON_TICKETS:
      state.countSalon = 0;
      return state;
    case COUNT_UNREAD_SALON_TICKETS_SUCCESS:
      state.countSalon = _.get(action, 'payload.count');
      return state;
    case SET_IS_READ_TICKETS:
      return state;
    case GET_SUPPORT_FOR_WEB_UNANSWERED:
      state.supportWeb.dataUnanswered.loading = true;
      state.supportSalon.dataUnanswered.page = 1;
      state.supportSalon.filterUnanswered = {};
      return state;
    case SET_WEB_UNANSWERED_LOADING:
      state.supportWeb.dataUnanswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS:
      state.supportWeb.dataUnanswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_WEB_UNANSWERED: 
      state.supportWeb.filterUnanswered = _.get(action, 'payload');
      return state;
    case GET_SUPPORT_FOR_WEB_ANSWERED:
      state.supportWeb.dataAnswered.loading = true;
      state.supportSalon.dataAnswered.page = 1;
      state.supportSalon.filterAnswered = {};
      return state;
    case SET_WEB_ANSWERED_LOADING:
      state.supportWeb.dataAnswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS:
      state.supportWeb.dataAnswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_WEB_ANSWERED: 
      state.supportWeb.filterAnswered = _.get(action, 'payload');
      return state;
    case COUNT_UNREAD_WEB_TICKETS:
      state.countWeb = 0;
      return state;
    case COUNT_UNREAD_WEB_TICKETS_SUCCESS:
      state.countWeb = _.get(action, 'payload.count');
      return state;
    case DELETE_SUPPORT_TICKET:
      state.deleteUnanswerTicket = false;
      state.deletedAnsweredTicket = false;
      return state;
    case DELETE_UNANSWERED_TICKET_SUCCESS:
      state.deleteUnanswerTicket = true;
      return state;
    case DELETE_UNANSWERED_TICKET_FAILED:
      state.deleteUnanswerTicket = false;
      return state;
    case DELETE_ANSWERED_TICKET_SUCCESS:
      state.deletedAnsweredTicket = true;
      return state;
    case DELETE_ANSWERED_TICKET_FAILED:
      state.deletedAnsweredTicket = false;
      return state;
    case REPLY_TICKET_SUCCESS:
      state.replyTicket = true;
      return state;
    case REPLY_TICKET_FAILED:
      state.replyTicket = false;
      return state;
    case UPLOAD_IMAGE_SUCCESS:
      state.image = _.get(action, 'payload.data');
      return state;
    case GET_SUPPORT_FOR_APP_UNANSWERED:
      state.supportApp.dataUnanswered.loading = true;
      state.supportApp.dataUnanswered.page = 1;
      state.supportApp.filterUnanswered = {};
      return state;
    case SET_APP_UNANSWERED_LOADING:
      state.supportApp.dataUnanswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS:
      state.supportApp.dataUnanswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_APP_UNANSWERED: 
      state.supportApp.filterUnanswered = _.get(action, 'payload');
      return state;
    case GET_SUPPORT_FOR_APP_ANSWERED:
      state.supportApp.dataAnswered.loading = true;
      state.supportApp.dataAnswered.page = 1;
      state.supportApp.filterAnswered = {};
      return state;
    case SET_APP_ANSWERED_LOADING:
      state.supportApp.dataAnswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS:
      state.supportApp.dataAnswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_APP_ANSWERED: 
      state.supportApp.filterAnswered = _.get(action, 'payload');
      return state;
    case COUNT_UNREAD_APP_TICKETS:
      state.countApp = 0;
      return state;
    case COUNT_UNREAD_APP_TICKETS_SUCCESS:
      state.countApp = _.get(action, 'payload.count');
      return state;
    case GET_SUPPORT_FOR_VERIFICATION_UNANSWERED:
      state.supportVerification.dataUnanswered.loading = true;
      state.supportVerification.dataUnanswered.page = 1;
      state.supportVerification.filterUnanswered = {};
      return state;
    case SET_VERIFICATION_UNANSWERED_LOADING:
      state.supportVerification.dataUnanswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS:
      state.supportVerification.dataUnanswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_VERIFICATION_UNANSWERED: 
      state.supportVerification.filterUnanswered = _.get(action, 'payload');
      return state;
    case GET_SUPPORT_FOR_VERIFICATION_ANSWERED:
      state.supportVerification.dataAnswered.loading = true;
      state.supportVerification.dataAnswered.page = 1;
      state.supportVerification.filterAnswered = {};
      return state;
    case SET_VERIFICATION_ANSWERED_LOADING:
      state.supportVerification.dataAnswered.loading = true;
      return state;
    case GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS:
      state.supportVerification.dataAnswered = {
        list: _.get(action, 'payload.data', []).map((payout) => ({
          ...payout,
          key: payout._id
        })),
        total: _.get(action, 'payload.total'),
        lastPage: _.get(action, 'payload.lastPage'),
        page: _.get(action, 'payload.page'),
        loading: false
      };
      return state;
    case SET_FILTER_VERIFICATION_ANSWERED: 
      state.supportVerification.filterAnswered = _.get(action, 'payload');
      return state;
    case COUNT_UNREAD_VERIFICATION_TICKETS:
      state.countVerification = 0;
      return state;
    case COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS:
      state.countVerification = _.get(action, 'payload.count');
      return state;
    case GET_REPORT_LIST_SUCCESS:
      state.report.list = _.get(action, 'payload.data', []);
      state.report.total = _.get(action, 'payload.total', 0);
      state.report.loading = false;
      return state;
    case GET_REPORT_LIST:
    case GET_REPORT_LIST_BY_USER:
      state.report.loading = true;
      return state;
    default:
      return state;
  }
});

export default reducer;
