import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber } from 'antd';

class TextField extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      input,
      type,
      min,
      max,
      suffix,
      allowClear,
      required,
      readOnly,
      defaultValue,
      meta: { touched, invalid, error }
    } = this.props;

    return (
      <Form.Item
        label={label}
        help={touched && invalid && error}
        validateStatus={touched && invalid && error && 'error'}
        required={required}
      >
        {
          type === 'number' && (
            <InputNumber
              {...input}
              placeholder={placeholder}
              value={input.value}
              min={min}
              max={max}
              defaultValue={defaultValue}
              formatter={value => value}
              parser={value => value.replace(/\s?|(,*)/g, '')}
              readOnly={readOnly}
            />
          )
        }

        {
          type !== 'number' && (
            <Input
              {...input}
              allowClear={allowClear}
              placeholder={placeholder}
              type={type}
              value={input.value}
              min={min}
              maxLength={max}
              suffix={suffix}
              readOnly={readOnly}
            />
          )
        }
      </Form.Item>
    );
  }
}

TextField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  suffix: PropTypes.any,
  allowClear: PropTypes.any,
  required: PropTypes.bool
};

export default TextField;
