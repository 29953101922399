import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';

class RadioField extends React.PureComponent {
  handleChange = (e) => {
    const { input, onFormValueChange } = this.props;
    input.onChange(e);
    if (onFormValueChange) {
      onFormValueChange(e);
    }
  };

  render() {
    const {
      label,
      input,
      options,
      meta: { touched, invalid, error }
    } = this.props;

    return (
      <Form.Item
        label={label}
        help={touched && invalid && error}
        validateStatus={touched && invalid && error && 'error'}
      >
        <Radio.Group
          {...input}
          value={input.value}
          onChange={this.handleChange}
        >
          {_.map(options, (obj) => (
            <Radio.Button disabled={obj.disabled} value={obj.value}>
              {obj.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }
}

RadioField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onFormValueChange: PropTypes.func
};

export default RadioField;
