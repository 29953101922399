import React, { useState } from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { actionNailistRejoin} from '../../../../providers/NailistProvider/actions';
import PropTypes from 'prop-types';

const Rejoin = ({ nailistId, setReload, reload }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const handleOk = async() => {
    setConfirmLoading(true);
    try {
      await dispatch(actionNailistRejoin({ nailistId}));
    } catch (error) {}
    
    setConfirmLoading(false);
    setReload(!reload);
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
          Rejoin
      </Button>
      <Modal
        title="Rejoin Nailist"
        visible={open}
        onOk={handleOk}
        width={300}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{
          textAlign: 'center'
        }}
      >
        <WarningOutlined style={{
          fontSize: '4rem',
          color: '#ffc800'
        }}/>
        <p></p>
        <p style={{
          fontSize: '15px',
          fontWeight: '600'
        }}>Are you sure?</p>
      </Modal>
    </>
  );
};
Rejoin.propTypes = {
  nailistId: PropTypes.string,
  setReload: PropTypes.func,
  reload: PropTypes.bool
};
export default Rejoin;