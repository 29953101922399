// import _ from 'lodash';
import moment from 'moment';
const columns = {};

columns.id = {
  title: 'サロンID',
  width: 180,
  dataIndex: 'id',
  key: 'id',
  //sorter: true,
  //sortOrder: _.get(search, 'orderBy') === '_id' && _.get(search, 'order')
};
columns.createdAt = {
  title: 'アカウント作成日',
  width: 200,
  dataIndex: 'createdAt',
  render: (record) =>
    /*_.has(record) && */ moment(record).format('HH:mm YYYY-MM-DD'),
  key: 'createdAt',
  // sorter: true,
  // sortOrder:
  //   _.get(search, 'orderBy') === '_created_at' && _.get(search, 'order')
};
columns.name = {
  title: 'サロン名',
  dataIndex: 'businessName',
  key: 'businessName',
  width: 160
};
columns.salonSharedType = {
  title: 'サロンタイプ',
  dataIndex: 'salonSharedType',
  key: 'salonSharedType',
  width: 180
};
columns.phone = {
  title: '電話番号',
  dataIndex: 'operatorPhoneNumber',
  key: 'operatorPhoneNumber',
  width: 160
};
columns.email = {
  title: 'メールアドレス',
  dataIndex: 'operatorEmail',
  key: 'operatorEmail',
  width: 260
};

export default columns;
