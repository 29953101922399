import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, message } from 'antd';
import Content from './Content';
import moment from 'moment';
import { _sleep } from 'utils/common';

class popup extends Component {
  dateField = moment();
  state = { loading: false };

  submit = () => {
    this.setState({ loading: true });
    const { action, filter, callback, payoutType } = this.props.data;

    this.props[action]({
      ...filter,
      payoutType,
      transferDate: this.dateField.toDate()
    })
      .then(callback)
      .then(() => this.setState({ loading: false }))
      .then(this.props.closePopup)
      .catch(async(e) => {
        message.error(e.data);
        await _sleep(2000);
        callback();
        this.props.closePopup();
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Modal
        title="Complete Payout"
        style={{ top: 36 }}
        visible={!!this.props.data}
        destroyOnClose={true}
        onCancel={this.props.closePopup}
        onOk={this.submit}
        width={'fit-content'}
        centered
        className="complete-payout-popup"
      >
        <Spin spinning={this.state.loading}>
          <Content
            {...this.props}
            updateDataField={(mDate) => (this.dateField = mDate)}
          />
        </Spin>
      </Modal>
    );
  }
}

popup.propTypes = {
  data: PropTypes.any,
  closePopup: PropTypes.func
};

export default popup;
