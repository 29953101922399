import {
  FETCH_RECEIPT_LIST,
  FETCH_RECEIPT_LIST_SUCCESS,
  FETCH_RELATED_RECEIPT_LIST,
  FETCH_RELATED_RECEIPT_LIST_SUCCESS,
  FETCH_RENDER_RECEIPT,
  FETCH_RENDER_RECEIPT_SUCCESS,
  CHANGE_RECEIPT_BOOKING_PRICE,
  CHANGE_RECEIPT_BOOKING_PRICE_SUCCESS,
  RESEND_RECEIPT_EMAIL,
  RESEND_RECEIPT_EMAIL_SUCCESS,
  RESEND_RECEIPT_EMAIL_FAIL
} from './constants';

export function fetchReceiptList(payload = {}) {
  return { type: FETCH_RECEIPT_LIST, payload, meta: { thunk: true } };
}
export function fetchReceiptListSuccess(payload = {}, meta) {
  return { type: FETCH_RECEIPT_LIST_SUCCESS, payload, meta };
}

export function fetchRelatedReceiptList(payload = {}) {
  return { type: FETCH_RELATED_RECEIPT_LIST, payload, meta: { thunk: true } };
}
export function fetchRelatedReceiptListSuccess(payload = {}, meta) {
  return { type: FETCH_RELATED_RECEIPT_LIST_SUCCESS, payload, meta };
}

export function fetchRenderReceiptEmail(payload = {}) {
  return { type: FETCH_RENDER_RECEIPT, payload, meta: { thunk: true } };
}
export function fetchRenderReceiptEmailSuccess(payload = {}, meta) {
  return { type: FETCH_RENDER_RECEIPT_SUCCESS, payload, meta };
}

export function changeReceiptBookingPrice(payload = {}) {
  return { type: CHANGE_RECEIPT_BOOKING_PRICE, payload, meta: { thunk: true } };
}
export function changeReceiptBookingPriceSuccess(payload = {}, meta) {
  return { type: CHANGE_RECEIPT_BOOKING_PRICE_SUCCESS, payload, meta };
}

export function resendReceipt(payload = {}) {
  return { type: RESEND_RECEIPT_EMAIL, payload, meta: { thunk: true } };
}
export function resendReceiptSuccess(payload = {}, meta) {
  return { type: RESEND_RECEIPT_EMAIL_SUCCESS, payload, meta };
}
export function resendReceiptFail(payload = {}, meta) {
  return { type: RESEND_RECEIPT_EMAIL_FAIL, payload, meta, error: true };
}
