import {
  FETCH_DELETED_ACCOUNT_LIST,
  FETCH_DELETED_ACCOUNT_LIST_SUCCESS,
  FETCH_DELETED_ACCOUNT_LIST_ERROR,
  FETCH_DELETED_USER_BOOKING,
  FETCH_DELETED_USER_BOOKING_SUCCESS,
  FETCH_DELETED_USER_BOOKING_ERROR,
  EXPORT_DELETED_ACCOUNT_CSV,
  EXPORT_DELETED_ACCOUNT_CSV_SUCCESS,
  EXPORT_DELETED_ACCOUNT_CSV_ERROR,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERROR,
  EARLY_RELEASE_ACCOUNT,
  EARLY_RELEASE_ACCOUNT_SUCCESS,
  EARLY_RELEASE_ACCOUNT_ERROR
} from './constants';

export const fetchDeletedAccountList = (payload) => ({
  type: FETCH_DELETED_ACCOUNT_LIST,
  payload,
  meta: { thunk: true }
});

export const fetchDeletedAccountListSuccess = (payload, meta) => ({
  type: FETCH_DELETED_ACCOUNT_LIST_SUCCESS,
  payload,
  meta
});

export const fetchDeletedAccountListError = (payload, meta) => ({
  type: FETCH_DELETED_ACCOUNT_LIST_ERROR,
  payload,
  meta,
  error: true,
});

export const fetchDeletedUserBooking = (payload) => ({
  type: FETCH_DELETED_USER_BOOKING,
  payload
});

export const fetchDeletedUserBookingSuccess = (payload) => ({
  type: FETCH_DELETED_USER_BOOKING_SUCCESS,
  payload
});

export const fetchDeletedUserBookingError = (payload) => ({
  type: FETCH_DELETED_USER_BOOKING_ERROR,
  payload
});

export const exportDeletedAccountCsv = (payload) => ({
  type: EXPORT_DELETED_ACCOUNT_CSV,
  payload
});

export const exportDeletedAccountCsvSuccess = (payload) => ({
  type: EXPORT_DELETED_ACCOUNT_CSV_SUCCESS,
  payload
});

export const exportDeletedAccountCsvError = (payload) => ({
  type: EXPORT_DELETED_ACCOUNT_CSV_ERROR,
  payload
});

export const updateUserStatus = (payload) => ({
  type: UPDATE_USER_STATUS,
  payload, meta: {
    thunk: true
  }
});

export const updateUserStatusSuccess = (payload = {}, meta) => ({
  type: UPDATE_USER_STATUS_SUCCESS,
  payload,
  meta
});

export const updateUserStatusError = (payload = {}, meta) => ({
  type: UPDATE_USER_STATUS_ERROR,
  payload,
  meta,
  error: true
});


export const earlyReleaseAccount = (payload) => ({
  type: EARLY_RELEASE_ACCOUNT,
  payload, meta: {
    thunk: true
  }
});
export const earlyReleaseAccountSuccess = (payload = {}, meta) => ({
  type: EARLY_RELEASE_ACCOUNT_SUCCESS,
  payload,
  meta
});
export const earlyReleaseAccountError = (payload = {}, meta) => ({
  type: EARLY_RELEASE_ACCOUNT_ERROR,
  payload,
  meta,
  error: true
});