import _ from 'lodash';
import moment from 'moment';
import qs from 'querystring';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import FilterForm from './FilterForm';

class SearchBar extends React.PureComponent {
  state = {};
  onSubmit = ({ ...values }) => {
    const dateRange = _.get(values, 'date-range');
    if (_.isArray(dateRange)) {
      values['date-range'] = dateRange.map((element) =>
        element.format('YYYY-MM-DD')
      );
    }

    const queryString = qs.stringify(values);
    this.props.history.push({
      pathname: window.location.pathname,
      search: `${queryString}`
    });
  };

  updateSearchToState = () => {
    let search = this.props.location.search;
    search = search.replace(/^\?/, '');
    search = qs.parse(search);
    const dateRange = _.get(search, 'date-range');
    if (_.isArray(dateRange)) {
      search['date-range'] = dateRange.map(
        (element) => element && moment(element)
      );
    }

    this.setState({ search });
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { search } = this.state;
    if (!search) {
      return null;
    }

    delete search.page;
    return (
      <FilterForm
        onSubmit={this.onSubmit}
        initialValues={search}
        filters={this.props.filters}
      />
    );
  }
}

SearchBar.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  filters: PropTypes.array
};

export default withRouter(SearchBar);
