import {
  FETCH_SALON_LIST,
  FETCH_SALON_LIST_SUCCESS,
  CREATE_SALON,
  CREATE_SALON_SUCCESS,
  CREATE_SALON_FAIL,
  SET_SALON_SEARCH_LOADING,
  FETCH_SALON_PROFILE,
  FETCH_SALON_PROFILE_SUCCESS,
  UNSET_SALON_PROFILE,
  UNLINK_STAFF,
  UNLINK_STAFF_SUCCESS,
  UNLINK_STAFF_FAIL,
  UPLOAD_SALON_IMAGE,
  UPLOAD_SALON_IMAGE_SUCCESS,
  UPLOAD_SALON_IMAGE_FAIL,
  UPDATE_SALON_PROFILE,
  UPDATE_SALON_PROFILE_SUCCESS,
  UPDATE_SALON_PROFILE_FAIL,
  GET_ADDRESS_BY_POST_CODE,
  GET_ADDRESS_BY_POST_CODE_SUCCESS,
  GET_ADDRESS_BY_POST_CODE_FAIL,
  GET_ALL_AREAS,
  GET_ALL_AREAS_SUCCESS,
  GET_ALL_AREAS_FAIL,
  GET_ALL_PROPERTIES,
  GET_ALL_PROPERTIES_SUCCESS,
  GET_ALL_PROPERTIES_FAIL,
  UPDATE_SALON_EMAIL,
  UPDATE_SALON_EMAIL_SUCCESS,
  UPDATE_SALON_EMAIL_FAIL,
  RESET_SALON_PROVIDER
} from './constants';

export function fetchSalonList(payload = {}) {
  return { type: FETCH_SALON_LIST, payload };
}

export function fetchSalonListSuccess(payload = {}) {
  return { type: FETCH_SALON_LIST_SUCCESS, payload };
}

export function createSalon(payload = {}) {
  return { type: CREATE_SALON, payload, meta: { thunk: true } };
}

export function createSalonSuccess(payload = {}, meta) {
  return { type: CREATE_SALON_SUCCESS, payload, meta };
}

export function createSalonFail(payload = {}, meta) {
  return { type: CREATE_SALON_FAIL, payload, meta, error: true };
}


export function setSearchSalonLoading(payload = { loading: false }) {
  return { type: SET_SALON_SEARCH_LOADING, payload };
}

export function fetchSalonProfile(payload = {}) {
  return { type: FETCH_SALON_PROFILE , payload };
}

export function fetchSalonProfileSuccess(payload = {}) {
  return { type: FETCH_SALON_PROFILE_SUCCESS, payload };
}

export function unsetSalonProfile(payload = {}) {
  return { type: UNSET_SALON_PROFILE, payload };
}
export function unlinkStaff(payload = {}) {
  return { type: UNLINK_STAFF, payload, meta: { thunk: true } };
}
export function unlinkStaffSuccess(payload = {}, meta) {
  return { type: UNLINK_STAFF_SUCCESS, payload, meta };
}
export function unlinkStaffFail(payload = {}, meta) {
  return { type: UNLINK_STAFF_FAIL, payload, meta, error: true };
}

//Upload salon Image
export function uploadSalonImage(payload = {}) {
  return { type: UPLOAD_SALON_IMAGE, payload, meta: { thunk: true } };
}
export function uploadSalonImageSuccess(payload = {}, meta) {
  return { type: UPLOAD_SALON_IMAGE_SUCCESS, payload, meta };
}
export function uploadSalonImageFail(payload = {}, meta) {
  return { type: UPLOAD_SALON_IMAGE_FAIL, payload, meta, error: true };
}

//UPDATE SALON PROFILE
export function updateSalonProfile(payload = {}) {
  return { type: UPDATE_SALON_PROFILE, payload, meta: { thunk: true } };
}
export function updateSalonProfileSuccess(payload = {}, meta) {
  return { type: UPDATE_SALON_PROFILE_SUCCESS, payload, meta };
}
export function updateSalonProfileFail(payload = {}, meta) {
  return { type: UPDATE_SALON_PROFILE_FAIL, payload, meta, error: true };
}

//UPDATE SALON EMAIL
export function updateSalonEmail(payload = {}) {
  return { type: UPDATE_SALON_EMAIL, payload, meta: { thunk: true } };
}
export function updateSalonEmailSuccess(payload = {}, meta) {
  return { type: UPDATE_SALON_EMAIL_SUCCESS, payload, meta };
}
export function updateSalonEmailFail(payload = {}, meta) {
  return { type: UPDATE_SALON_EMAIL_FAIL, payload, meta, error: true };
}

export function getAddressByPostCode(payload = {}) {
  return { type: GET_ADDRESS_BY_POST_CODE, payload, meta: { thunk: true } };
}
export function getAddressByPostCodeSuccess(payload = {}, meta) {
  return { type: GET_ADDRESS_BY_POST_CODE_SUCCESS, payload, meta };
}
export function getAddressByPostCodeFail(payload = {}, meta) {
  return { type: GET_ADDRESS_BY_POST_CODE_FAIL, payload, meta, error: true };
}

export function getAllAreas(payload = {}) {
  return { type: GET_ALL_AREAS, payload, meta: { thunk: true } };
}
export function getAllAreasSuccess(payload = {}, meta) {
  return { type: GET_ALL_AREAS_SUCCESS, payload, meta };
}
export function getAllAreasFail(payload = {}, meta) {
  return { type: GET_ALL_AREAS_FAIL, payload, meta, error: true };
}


export function getAllProperties(payload = {}) {
  return { type: GET_ALL_PROPERTIES, payload, meta: { thunk: true } };
}
export function getAllPropertiesSuccess(payload = {}, meta) {
  return { type: GET_ALL_PROPERTIES_SUCCESS, payload, meta };
}
export function getAllPropertiesFail(payload = {}, meta) {
  return { type: GET_ALL_PROPERTIES_FAIL, payload, meta, error: true };
}

export function resetSalonProvider() {
  return { type: RESET_SALON_PROVIDER };
}