import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import CustomerBookingTable from 'containers/Booking/BookingList/GeneralTab';

const CustomerBooking = ({ customerId }) => {
  // const history = useHistory();
  // const trimQueryString = () => {
  //   history.replace({
  //     search: null
  //   });
  // };

  // useEffect(() => {
  //   trimQueryString();

  //   return trimQueryString;
  // }, []);

  return (
    <CustomerBookingTable customerId={customerId} />
  );
};

CustomerBooking.propTypes = {
  customerId: PropTypes.string.isRequired
};

export default CustomerBooking;
