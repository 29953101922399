import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Card, Descriptions, Empty, Image, Button } from 'antd';

import BankPopup from 'components/Popup/BankPopup';
import { BANK_TYPE } from 'utils/constants';

import '../styles.scss';

const Bank = ({ data, openBankPopup }) => {
  const renderContentList = (bankInfo) => {
    if (_.isEmpty(bankInfo)) {
      return <Empty description={false} />;
    }

    const imgSrc =
      _.get(bankInfo, 'photo.data') || _.get(bankInfo, 'salonPhotoUrl');
    const bankType = _.get(bankInfo, 'type');

    return (
      <Descriptions
        bordered
        column={2}
        size="small"
        extra={
          [BANK_TYPE.PERSONAL, BANK_TYPE.COMPANY].includes(bankType) && (
            <Button
              type="primary"
              onClick={() => {
                openBankPopup({ ...bankInfo, userId: _.get(data, '_id') });
              }}
            >
              Edit
            </Button>
          )
        }
      >
        <Descriptions.Item label="銀行名">
          {_.get(bankInfo, 'bankBranch.bank.bankName')}
        </Descriptions.Item>
        <Descriptions.Item label="口座種別">
          {_.get(bankInfo, 'accountType.type')}
        </Descriptions.Item>
        <Descriptions.Item label="支店名">
          {_.get(bankInfo, 'bankBranch.branchName')}
        </Descriptions.Item>
        <Descriptions.Item label="口座番号">
          {_.get(bankInfo, 'accountNumber')}
        </Descriptions.Item>
        <Descriptions.Item label="支店番号">
          {_.get(bankInfo, 'bankBranch.branchCode')}
        </Descriptions.Item>
        <Descriptions.Item label="口座名義">
          {_.get(bankInfo, 'accountHolder')
            ? _.get(bankInfo, 'accountHolder')
            : _.get(bankInfo, 'firstName')}{' '}
          {_.get(bankInfo, 'lastName')}
        </Descriptions.Item>
        <Descriptions.Item label="個人/法人">{bankType}</Descriptions.Item>
        <Descriptions.Item label="通帳画像のチェック">
          {(_.get(bankInfo, 'isApproved') === true && (
            <Tag color="green">APPROVED</Tag>
          )) || <Tag color="red">UNAPPROVED</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="通帳の写真">
          {imgSrc && <Image width={200} src={imgSrc} />}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const [currentKey, setCurrentKey] = useState();

  const bankTypes = _.keys(_.omit(data, ['_id']));

  useEffect(() => {
    setCurrentKey(bankTypes[0]);
  }, [data]);

  return (
    <>
      <BankPopup />
      <Card
        className="nailist-profile-tab"
        title="銀行振り込み先情報"
        tabList={_.map(bankTypes, (value) => ({
          key: value,
          tab: value
        }))}
        activeTabKey={currentKey}
        onTabChange={(key) => {
          setCurrentKey(key);
        }}
        extra={
          _.intersection([BANK_TYPE.COMPANY, BANK_TYPE.PERSONAL], bankTypes)
            .length < 1
            ? [
              <Button
                key="create-bank"
                type="primary"
                onClick={() => {
                  openBankPopup({ userId: _.get(data, '_id') });
                }}
              >
                  Create
              </Button>
            ]
            : []
        }
      >
        {renderContentList(data[currentKey])}
      </Card>
    </>
  );
};

Bank.propTypes = {
  data: PropTypes.object.isRequired,
  openBankPopup: PropTypes.func.isRequired
};

export default Bank;
