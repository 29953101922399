import { connect } from 'react-redux';

import { makeRelatedReceiptList } from 'providers/ReceiptProvider/selectors';
import { makeReceiptDetailBooking } from 'providers/BookingProvider/selectors';

import { fetchRelatedReceiptList } from 'providers/ReceiptProvider/actions';
import { fetchReceiptBookingDetail } from 'providers/BookingProvider/actions';

import ReceiptEmailDetail from './ReceiptEmailDetail';

const mapStateToProps = (state) => ({
  booking: makeReceiptDetailBooking(state),
  relatedReceipts: makeRelatedReceiptList(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchRelatedReceiptList: ({ receiptId, bookingId }) => dispatch(fetchRelatedReceiptList({ receiptId, bookingId })),
  fetchReceiptBookingDetail: ({ bookingId }) => dispatch(fetchReceiptBookingDetail({ bookingId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptEmailDetail);