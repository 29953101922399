import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_SALON_LIST_SUCCESS,
  SET_SALON_SEARCH_LOADING,
  CREATE_SALON_SUCCESS,
  FETCH_SALON_PROFILE_SUCCESS,
  RESET_SALON_PROVIDER
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  profile: {
    info: {},
    prefectureISOTable: [],
    lineList: [],
    stationList: []
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALON_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.pagination.total');
      state.search.lastPage = _.get(action, 'payload.pagination.lastPage');
      return state;
    case SET_SALON_SEARCH_LOADING:
      state.search.loading = _.get(action, 'payload.loading');
      return state;
    case CREATE_SALON_SUCCESS:
      state.search.list = [_.get(action, 'payload'), ...state.search.list];
      state.search.list = state.search.list.slice(0, 10);
      return state;
    case FETCH_SALON_PROFILE_SUCCESS:
      state.profile.info = _.get(action, 'payload');
      return state;

    case RESET_SALON_PROVIDER:
      // state.profile.prefectureISOTable = state.profile.prefectureISOTable;
      return {
        ...initialState,
        search: state.search
      };

    
    default:
      return state;
  }
});

export default reducer;
