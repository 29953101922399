/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment';
import { Tag, Space, Avatar, Typography } from 'antd';
import { USER_STATUS } from 'utils/constants';
import _get from 'lodash/get';
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const columnsTemplate = {
  id: {
    title: 'Customer ID',
    dataIndex: '_id',
    key: 'userId',
    width: 150
  },
  createdAt: {
    title: 'Created date',
    dataIndex: '_created_at',
    render: (createdAt) => moment(createdAt).format('HH:mm YYYY-MM-DD'),
    key: 'createdAt',
    width: 160
  },
  status: {
    title: 'ステータス',
    dataIndex: 'status',
    key: 'status',
    render: (record) => (
      <Tag color={USER_STATUS[record]?.color}>{USER_STATUS[record]?.value}</Tag>
    ),
    width: 120
  },
  usernameEmail: {
    title: 'Username - Contact info',
    key: 'username',
    render: (record) => (
      <Space align="center" size="middle">
        <Avatar
          icon={<UserOutlined />}
          src={_get(record, 'avatar.thumbnail')}
          size="large"
        />
        <div>
          <div><b>{_get(record, 'username')}</b></div>
          <Paragraph copyable={{ icon: <MailOutlined/> }} style={{marginBottom: 0}}>{_get(record, 'email')}</Paragraph>
          {_get(record, 'phone') && <Paragraph copyable={{ icon: <PhoneOutlined/> }} style={{marginBottom: 0}}>{_get(record, 'phone')}</Paragraph>}
        </div>
      </Space>
    ),
    width: 330
  },
  invitationCode: {
    title: 'Invitation Code',
    key: 'invitationCode',
    render: (record) => (
      <Paragraph copyable>{_get(record, 'invitationCode')}</Paragraph>
    ),
    width: 120
  }
};

export default columnsTemplate;