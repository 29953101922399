import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'components/PageHeader';

import BannerForm from '../_Component/BannerForm';


const CreateBanner = () => {
  return (
    <div id="create-banner">
      <Helmet>
        <title>Nailie Dashboard | Create Banner</title>
      </Helmet>
      <PageHeader
        title="Create Banner"
        breadcrumb={PageHeader.Bread.createBanner}
        goBack={true}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <BannerForm/>
      </div>
    </div>
  );
};

export default CreateBanner;