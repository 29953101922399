import moment from 'moment';
import _ from 'lodash';
import { put, takeLeading, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { message as Alert } from 'antd';
import { formatBookingData, formatCardErrorBookingData } from 'utils/bookingDetailHelpers';
import { convertJSONToCSV, saveFileCSV } from 'utils/common';
import { CSV_CARD_ERROR_BOOKING_STRUCTURE } from 'utils/constants';
import request from 'utils/request';
import {
  fetchPayoutDetailBookingListSuccess,
  fetchReceiptBookingDetailSuccess,
  fetchBookingListSuccess,
  fetchCardErrorBookingListSuccess,
  fetchCardErrorBookingListError,
  fetchBookingDetailSuccess,
  onError,
  changeBookingStatusSuccess,
  changeBookingStatusFail,
  updateBookingHistoryNoteSuccess,
  updateBookingHistoryNoteFail,
  exportBookingCSVSuccess,
  exportBookingCSVError,
  notifyErrorBookingSuccess,
  notifyErrorBookingError,
  completeBookingSuccess,
  completeBookingError,
  fetchBookingMessageListSuccess,
  fetchBookingReview as fetchBookingReviewRequest,
  fetchBookingReviewSuccess,
  deleteBookingReviewSuccess,
  editBookingReviewSuccess,
  fetchBookingScheduleSuccess,
  requestBookingFileSuccess,
  requestBookingFileFail,
  fetchExportBookingFilesListSuccess,
  fetchExportBookingFilesListFail,
} from './actions';
import {
  FETCH_PAYOUT_DETAIL_BOOKING_LIST, FETCH_RECEIPT_BOOKING_DETAIL, FETCH_BOOKING_LIST, CHANGE_BOOKING_STATUS,
  FETCH_BOOKING_DETAIL, UPDATE_CHANGED_BOOKING_HISTORY_NOTE, FETCH_CARD_ERROR_BOOKING_LIST, COMPLETE_BOOKING,
  NOTIFY_ERROR_BOOKING, EXPORT_BOOKING_CSV, FETCH_BOOKING_MESSAGE_LIST,
  FETCH_BOOKING_REVIEW, DELETE_BOOKING_REVIEW, EDIT_BOOKING_REVIEW, FETCH_BOOKING_SCHEDULE,
  REQUEST_EXPORT_BOOKING_FILE, FETCH_EXPORT_BOOKING_FILES_LIST
} from './constants';

const PAYOUT_TYPE = {
  NAILIST: 'NAILIST',
  SALON: 'SALON',
  STAFF: 'STAFF',
  CURRENT_PERIOD_NAILIST: 'CURRENT_PERIOD_NAILIST',
  CURRENT_PERIOD_SALON: 'CURRENT_PERIOD_SALON'
};

function* fetchPayoutDetailBookingList(action) {
  try {
    let data;

    const payoutType = _.get(action.payload, 'payoutType');
    switch (payoutType) {
      case PAYOUT_TYPE.STAFF:
      case PAYOUT_TYPE.NAILIST: {
        data = (yield call(request, 'bookings/getBookingList', {
          incomeId: _.get(action.payload, 'incomeId')
        })).data;
        break;
      }

      case PAYOUT_TYPE.SALON: {
        data = (yield call(
          request,
          'salon-sales-report/payout/salon/bookings',
          {
            salonPayoutId: _.get(action.payload, 'incomeId')
          }
        )).data;
        break;
      }

      case PAYOUT_TYPE.CURRENT_PERIOD_NAILIST: {
        data = (yield call(request, 'bookings/getBookingList', {
          incomeId: `${
            moment().format('YYMM') + Math.floor(moment().get('date') / 16)
          }.${action.payload.nailistId}`,
          currentPeriodFor: 'NAILIST'
        })).data;
        break;
      }

      case PAYOUT_TYPE.CURRENT_PERIOD_SALON: {
        data = (yield call(
          request,
          'salon-sales-report/payout/salon/bookings',
          {
            incomeId: `${
              moment().format('YYMM') + Math.floor(moment().get('date') / 16)
            }.${action.payload.nailistId}`,
            currentPeriodFor: 'SALON'
          }
        )).data;
        break;
      }

      default: {
        throw new Error('Invalid PAYOUT_TYPE request');
      }
    }

    yield put(fetchPayoutDetailBookingListSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* fetchReceiptBookingDetail(action) {
  try {
    const { bookingId } = action.payload;
    let { data } = yield call(request, `bookings/${bookingId}`, {}, 'GET');
    data = formatBookingData(data);

    yield put(fetchReceiptBookingDetailSuccess({ data }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchBookingDetail(action) {
  try {
    const { bookingId } = action.payload;
    let { data } = yield call(request, `bookings/${bookingId}/full`, {}, 'GET');
    data = formatBookingData(data);

    yield put(fetchBookingDetailSuccess({ data }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchBookingList(action) {
  try {
    const params = _.pick(action.payload, [
      'page', 'perPage', 'keyword', 'type', 'order', 'orderBy', 'treatmentDate', 'origin', 'nailistId', 'customerId']);
    if (!params.type && !params.orderBy) {
      params.orderBy = 'bookingDate';
      params.order = 'descend';
    }
    if (params.keyword) {
      params.keyword = params.keyword.trim();
    }
    let { data } = yield call(request, 'bookings', params, 'GET');
    data.data = data.data.map(formatBookingData);

    yield put(fetchBookingListSuccess({ data }, action.meta));
  } catch (e) {
    yield put(onError());
  }
}

function* handleFetchCardErrorBookingList(action) {
  try {
    const params = _.pick(action.payload, 
      ['page', 'perPage', 'keyword', 'type', 'order', 'orderBy', 'treatmentDate', 'origin']);
    if (!params.type && !params.orderBy) {
      params.orderBy = 'bookingDate';
      params.order = 'descend';
    }
    if (params.keyword) {
      params.keyword = params.keyword.trim();
    }
    let { data } = yield call(request, 'bookings/failedAfterTreatment', params, 'GET');
    data.data = data.data.map(formatBookingData);
    yield put(fetchCardErrorBookingListSuccess({ data }));
  } catch (e) {
    yield put(onError());
    yield put(fetchCardErrorBookingListError());
  }
}

function* changeBookingStatus(action) {
  try {
    const { bookingId, status } = _.pick(action.payload, ['bookingId', 'status']);
    const { data } = yield call(request, `bookings/${bookingId}/status`, { status }, 'POST');

    yield put(changeBookingStatusSuccess({ data }, action.meta));
  } catch (e) {
    yield put(changeBookingStatusFail(e.error, action.meta));
    Alert.error(e.error);
  }
}

function* updateBookingHistoryNote(action) {
  try {
    const { bookingId = 'any', objectId, note } = action.payload;
    const { data } = yield call(request, `bookings/${bookingId}/updated-history/${objectId}`, { note }, 'POST');

    yield put(updateBookingHistoryNoteSuccess({ data }, action.meta));
  } catch (e) {
    yield put(updateBookingHistoryNoteFail(e.error, action.meta));
    Alert.error(e.error);
  }
}

function* handleCompleteCardErrorBooking(action) {
  const params = _.pick(action.payload, ['bookingId', 'userNote']);
  if (_.isEmpty(params.userNote)) {
    delete params.userNote;
  }
  try {
    yield call(request, 'bookings/completeBookingCardError', params, 'POST');
    yield put(completeBookingSuccess(params, action.meta));
  } catch (e) {
    yield put(completeBookingError(params, action.meta));
    Alert.error(e.error);
  }
}

function* handleNotifyErrorBooking(action) {
  const { ids } = _.pick(action.payload, ['ids']);
  try {
    yield all(ids.map(id => call(request, 'bookings/sendPaymentErrorEmailToCustomer', { bookingId: id }, 'POST')));
    yield put(notifyErrorBookingSuccess({ ids }, action.meta));
    Alert.success('Sent');
  } catch (e) {
    yield put(notifyErrorBookingError({ ids }));
    Alert.error(e.error);
  }
}

function* handleExportCSV(action) {
  try {
    let page = 1;
    let lastPage;
    let list = [];
    const params = _.pick(action.payload, 
      ['page', 'perPage', 'keyword', 'type', 'order', 'orderBy', 'treatmentDate', 'origin']);
    if (!params.type && !params.orderBy) {
      params.orderBy = 'bookingDate';
      params.order = 'descend';
    }
    if (params.keyword) {
      params.keyword = params.keyword.trim();
    }

    while (!lastPage || page <= lastPage) {
      let { data } = yield call(request, 'bookings/failedAfterTreatment', { ...params, page, perPage: 100 }, 'GET');
      list = list.concat(data.data);
      page++;
      lastPage = data.lastPage || 1;
    }
    yield put(exportBookingCSVSuccess());

    if (!_.isEmpty(list)) {
      const csvContent = convertJSONToCSV(formatCardErrorBookingData(list), CSV_CARD_ERROR_BOOKING_STRUCTURE);
      saveFileCSV(csvContent, 'card_error_booking.csv');
    }
  } catch (e) {
    yield put(exportBookingCSVError(e.error));
    Alert.error(e.error);
  }
}

function* fetchBookingMessageList(action) {
  try {
    const { nailistId, clientId, ...params } = action.payload;
    const { data } = yield call(request, `bookings/messages/from/${nailistId}/with/${clientId}`, params, 'GET');

    yield put(fetchBookingMessageListSuccess({ data }, action.meta));
  } catch (e) {
    yield put(onError());
  }
}

function* fetchBookingReview(action) {
  try {
    const { data } = yield call(request, `bookings/${action.payload}/review`, {}, 'GET');

    yield put(fetchBookingReviewSuccess({ data }, action.meta));
  } catch (e) {
    yield put(onError());
  }
}

function* deleteBookingReview(action) {
  try {
    const { data } = yield call(request, `bookings/${action.payload}/review`, {}, 'DELETE');

    yield put(deleteBookingReviewSuccess({ data }, action.meta));
    Alert.success('Deleted!');
  } catch (e) {
    yield put(onError());
  }
}

function* editBookingReview(action) {
  try {
    const { bookingId, ...values } = action.payload;
    yield call(request, `bookings/${bookingId}/review`, values, 'PUT');

    yield put(editBookingReviewSuccess({}, action.meta));
    yield put(fetchBookingReviewRequest(bookingId));
    Alert.success('updated!');
  } catch (e) {
    yield put(onError());
  }
}

function* fetchBookingSchedule(action) {
  try {
    const { data } = yield call(request, `bookings/schedule/${action.payload}`, {}, 'GET');
    yield put(fetchBookingScheduleSuccess({ data }, action.meta));
  } catch (e) {
    yield put(onError());
  }
}

function* handleRequestBookingFile(action) {
  const { dateFrom, dateTo, baseFilterField = 'bookingDate' } = action.payload;
  try {
    const { data } = yield call(request, 'bookings/requestCSV', { dateFrom, dateTo, baseFilterField }, 'PUT');
    yield put(requestBookingFileSuccess({ data }, action.meta));
  } catch (e) {
    yield put(onError());
    yield put(requestBookingFileFail({}, action.meta));
  }
}

function* handleFetchExportBookingFilesList(action) {
  try {
    const { data } = yield call(request, 'bookings/getListBookingCSV', undefined, 'GET');
    yield put(fetchExportBookingFilesListSuccess(data, action.meta));
  } catch (e) {
    yield put(onError());
    yield put(fetchExportBookingFilesListFail({}, action.meta));
  }
}

export default function* watchPayouts() {
  yield takeLeading(CHANGE_BOOKING_STATUS, changeBookingStatus);
  yield takeLeading(FETCH_PAYOUT_DETAIL_BOOKING_LIST, fetchPayoutDetailBookingList);
  yield takeLeading(FETCH_RECEIPT_BOOKING_DETAIL, fetchReceiptBookingDetail);
  yield takeLatest(FETCH_BOOKING_LIST, fetchBookingList);
  yield takeEvery(FETCH_BOOKING_DETAIL, fetchBookingDetail);
  yield takeLatest(UPDATE_CHANGED_BOOKING_HISTORY_NOTE, updateBookingHistoryNote);
  yield takeLatest(FETCH_CARD_ERROR_BOOKING_LIST, handleFetchCardErrorBookingList);
  yield takeLeading(COMPLETE_BOOKING, handleCompleteCardErrorBooking);
  yield takeEvery(NOTIFY_ERROR_BOOKING, handleNotifyErrorBooking);
  yield takeLeading(EXPORT_BOOKING_CSV, handleExportCSV);
  yield takeLeading(FETCH_BOOKING_MESSAGE_LIST, fetchBookingMessageList);
  yield takeLeading(FETCH_BOOKING_REVIEW, fetchBookingReview);
  yield takeLeading(DELETE_BOOKING_REVIEW, deleteBookingReview);
  yield takeLeading(EDIT_BOOKING_REVIEW, editBookingReview);
  yield takeLeading(FETCH_BOOKING_SCHEDULE, fetchBookingSchedule);
  yield takeLeading(REQUEST_EXPORT_BOOKING_FILE, handleRequestBookingFile);
  yield takeLeading(FETCH_EXPORT_BOOKING_FILES_LIST, handleFetchExportBookingFilesList);
}
