import { connect } from 'react-redux';
import { SetPopup } from 'providers/PopupProvider/actions';

import Bank from './Bank';

const mapDispatchToProps = (dispatch) => ({
  openBankPopup: (show) => dispatch(SetPopup({ name: 'bankPopup', show }))
});

export default connect(null, mapDispatchToProps)(Bank);
