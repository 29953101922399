import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { generatePath } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Avatar, Tabs, Descriptions, Tag } from 'antd';
import { StarTwoTone, UserOutlined, CheckCircleTwoTone } from '@ant-design/icons';

// import WIPIcon from 'components/WIPIcon';
import BigPageHeader from 'components/PageHeader';
import Menu from './Menu';
import Sales from './Sales';
import Profile from './Profile';
import Insight from './Insight';
import WorkingTime from './WorkingTime';
import Booking from './Booking';

import VerifyEmailButton from 'containers/Nailist/components/VerifyEmailButton';
import './styles.scss';
import { splitInfor } from 'utils/common';
import Posts from './Posts';

class NailistDetail extends React.PureComponent {
  componentDidMount() {
    this.props.fetchNailistProfile({
      id: _.get(this.props, 'match.params.id')
    });
    this.props.fetchNailistBankInfo({
      id: _.get(this.props, 'match.params.id')
    });
    this.props.fetchNailistMenu(_.get(this.props, 'match.params.id'));
    this.props.fetchNailistCategoryList(_.get(this.props, 'match.params.id'));
  }

  componentWillUnmount() {
    this.props.unsetNalistProfile();
  }

  onChangeTab = (tabKey) => {
    const routeMatch = this.props.match;
    const newPath = generatePath(routeMatch.path, { ...routeMatch.params, activeTab: tabKey });
    this.props.history.replace(newPath);
  }

  render() {
    const { TabPane } = Tabs;
    const { info = {}, menu = [], earning = {} } = this.props.profile;
    const isExpireCheckin = _.get(info, 'profile.isExpireCheckin');
    const canEditMEnu = !_.get(info, 'CBStaffId') || (_.get(info, 'CBStaffId') && ['SHARED', 'FIVE_STAR'].includes(_.get(info, 'profile.salonSharedType')));
    const hideMenuButton = !canEditMEnu;

    const star = _.get(info, 'reviewInfo.totalAverage');
    const isPremium = _.get(info, 'isMark');
    const createdAt =
      info._created_at && moment(info._created_at).format('YYYY-MM-DD HH:mm');
    const AvatarJSX = (
      <Avatar
        size={64}
        icon={<UserOutlined />}
        src={_.get(info, 'avatar.thumbNail')}
      />
    );

    const pageTitle = `Nailie Dashboard | Nailist ${
      info.username && `| ${info.username}`
    }`;
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <BigPageHeader
          goBack={true}
          title={splitInfor(_.get(info, 'username', '...'))}
          tags={[
            isPremium && <CheckCircleTwoTone style={{translate: '-8px 0'}}/>,
            star && (
              <Tag>
                <StarTwoTone twoToneColor="#fcba03" /> {star}
              </Tag>
            ),
            _.get(info, 'CBStaffId') && <Tag color="purple">Salon staff</Tag>
          ]}
          breadcrumb={BigPageHeader.Bread.nailistProfile(info)}
        >
          <div id="nailist-detail-page-header">
            <div className="basic-profile">
              <div className="left-block">
                {/* Temporarily hide this avatar*/}
                {/* {info.isMark ? (
                  <Badge
                    className="premium-badge"
                    count={'✔️'}
                    title={'premium'}
                  >
                    {AvatarJSX}
                  </Badge>
                ) : (
                  AvatarJSX
                )} */}
              </div>
              <div className="right-block">
                <Descriptions>
                  <Descriptions.Item label="ユーザーネーム">
                    {splitInfor(info.username)}
                  </Descriptions.Item>
                  <Descriptions.Item label="姓名">
                    {info.fullName}
                  </Descriptions.Item>
                  <Descriptions.Item label="アカウント作成日" span={2}>
                    {createdAt}
                  </Descriptions.Item>
                  <Descriptions.Item label="ネイリストID">
                    {info._id}
                  </Descriptions.Item>
                  <Descriptions.Item label="メールアドレス">
                    {splitInfor(info.email)}
                    <VerifyEmailButton userId={info._id} emailVerified={info.emailVerified}/>
                  </Descriptions.Item>
                  <Descriptions.Item label="電話番号">
                    {info.phone}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        </BigPageHeader>
        <div className="page-container" id="nailist-detail-page">
          <Tabs activeKey={this.props.match.params.activeTab || 'profile'} destroyInactiveTabPane onChange={this.onChangeTab}>
            <TabPane tab="プロフィール" key="profile">
              <Profile
                fetchNailistProfile={this.props.fetchNailistProfile}
                updateUserStatus={this.props.updateUserStatus}
                profile={{ ...this.props.profile}} />
            </TabPane>
            <TabPane tab="デザイン" key="posts">
              <Posts
                nailistId={_.get(this.props, 'match.params.id')}
                fetchNailistPosts={this.props.fetchNailistPosts}
              />
            </TabPane>
            <TabPane tab="メニュー" key="menu">
              <Menu data={menu.filter(e => e.type !== 'RECOMMEND')} nailistId={info._id}
                hideMenuButton={hideMenuButton} />
            </TabPane>
            <TabPane tab="スケジュール" key="schedule">
              <WorkingTime data={_.get(info, 'profile.weekdays')} nailistId={info._id} disableEdit={_.has(info, 'CBStaffId')} hasSchedule={_.get(info, 'profile.hasSchedule')} minCanBookingHour={_.get(info, 'configOptions.minCanBookingHour', 1)}/>
            </TabPane>
            <TabPane tab="ネイリストインサイト" key="insight">
              <Insight
                nailistId={_.get(this.props, 'match.params.id')}
                data={this.props.nailistInsight}
                fetchNailistInsight={this.props.fetchNailistInsight} />
            </TabPane>
            <TabPane tab="売上" key="earning">
              <Sales
                data={earning}
                getNailistCurrentBalance={() =>
                  this.props.fetchNailistCurrentBalance(
                    _.get(this.props, 'match.params.id')
                  )
                }
                nailistInfo={info}
                openPayoutPopup={this.props.openPayoutPopup}
              />
            </TabPane>
            <TabPane
              tab="予約"
              key="booking"
            >
              <Booking nailistId={_.get(this.props, 'match.params.id')}/>
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
}

NailistDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  fetchNailistProfile: PropTypes.func.isRequired,
  fetchNailistPosts: PropTypes.func.isRequired,
  fetchNailistBankInfo: PropTypes.func.isRequired,
  unsetNalistProfile: PropTypes.func.isRequired,
  fetchNailistCurrentBalance: PropTypes.func.isRequired,
  fetchNailistCategoryList: PropTypes.func.isRequired,
  fetchNailistMenu: PropTypes.func.isRequired,
  profile: PropTypes.object,
  openPayoutPopup: PropTypes.func,
  fetchNailistInsight: PropTypes.func,
  nailistInsight: PropTypes.object,
  updateUserStatus: PropTypes.func
};

export default NailistDetail;
