import { connect } from 'react-redux';

import {
  SetPopup
} from 'providers/PopupProvider/actions';
import { makeCompletePayoutPopup } from 'providers/PopupProvider/selectors';
import { completePayouts, completeAllPayouts } from 'providers/PayoutProvider/actions';

import Popup from './Popup';

const mapStateToProps = (state) => ({
  data: makeCompletePayoutPopup(state)
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({name: 'completePayout', show: false})),
  completePayouts: (filter) => dispatch(completePayouts(filter)),
  completeAllPayouts: (filter) => dispatch(completeAllPayouts(filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);