// Checked
import {
  DELETE_SUPPORT_TICKET,
  DELETE_UNANSWERED_TICKET_SUCCESS,
  DELETE_UNANSWERED_TICKET_FAILED,
  DELETE_ANSWERED_TICKET_SUCCESS,
  DELETE_ANSWERED_TICKET_FAILED,
  SET_IS_READ_TICKETS,
  REPLY_TICKET,
  REPLY_TICKET_SUCCESS,
  REPLY_TICKET_FAILED,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,

  GET_SUPPORT_FOR_SALON_ANSWERED,
  GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS,
  GET_SUPPORT_FOR_SALON_UNANSWERED,
  GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS,
  SET_SALON_ANSWERED_LOADING,
  SET_SALON_UNANSWERED_LOADING,
  SET_FILTER_SALON_ANSWERED,
  SET_FILTER_SALON_UNANSWERED,
  COUNT_UNREAD_SALON_TICKETS,
  COUNT_UNREAD_SALON_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_WEB_ANSWERED,
  GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS,
  GET_SUPPORT_FOR_WEB_UNANSWERED,
  GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS,
  SET_WEB_ANSWERED_LOADING,
  SET_WEB_UNANSWERED_LOADING,
  SET_FILTER_WEB_ANSWERED,
  SET_FILTER_WEB_UNANSWERED,
  COUNT_UNREAD_WEB_TICKETS,
  COUNT_UNREAD_WEB_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_APP_ANSWERED,
  GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS,
  GET_SUPPORT_FOR_APP_UNANSWERED,
  GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS,
  SET_APP_ANSWERED_LOADING,
  SET_APP_UNANSWERED_LOADING,
  SET_FILTER_APP_ANSWERED,
  SET_FILTER_APP_UNANSWERED,
  COUNT_UNREAD_APP_TICKETS,
  COUNT_UNREAD_APP_TICKETS_SUCCESS,

  GET_SUPPORT_FOR_VERIFICATION_ANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS,
  GET_SUPPORT_FOR_VERIFICATION_UNANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS,
  SET_VERIFICATION_ANSWERED_LOADING,
  SET_VERIFICATION_UNANSWERED_LOADING,
  SET_FILTER_VERIFICATION_ANSWERED,
  SET_FILTER_VERIFICATION_UNANSWERED,
  COUNT_UNREAD_VERIFICATION_TICKETS,
  COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS,

  GET_REPORT_LIST,
  GET_REPORT_LIST_SUCCESS,
  GET_REPORT_LIST_BY_USER,

  UNLINK_PHONE_AUTH,
  UNLINK_PHONE_AUTH_FAIL,
  UNLINK_PHONE_AUTH_SUCCESS
} from './constants';

export function getSupportForSalonAnswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_SALON_ANSWERED, payload };
}

export function getSupportForSalonAnsweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_SALON_ANSWERED_SUCCESS, payload };
}

export function getSupportForSalonUnanswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_SALON_UNANSWERED, payload };
}

export function getSupportForSalonUnansweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_SALON_UNANSWERED_SUCCESS, payload };
}

export function setGetSupportForSalonAnsweredLoading(payload = { loading: false }) {
  return { type: SET_SALON_ANSWERED_LOADING, payload };
}

export function setGetSupportForSalonUnansweredLoading(payload = { loading: false }) {
  return { type: SET_SALON_UNANSWERED_LOADING, payload };
}

export function setFilterSalonUnanswered(payload = {}) {
  return { type: SET_FILTER_SALON_UNANSWERED, payload };
}

export function setFilterSalonAnswered(payload = {}) {
  return { type: SET_FILTER_SALON_ANSWERED, payload };
}

export function countUnreadSalonTickets(payload = {}) {
  return { type: COUNT_UNREAD_SALON_TICKETS, payload };
}

export function countUnreadSalonTicketsSuccess(payload = {}) {
  return { type: COUNT_UNREAD_SALON_TICKETS_SUCCESS, payload };
}

export function deleteSupportTicket(payload = {}) {
  return { type: DELETE_SUPPORT_TICKET, payload};
}

export function deleteUnansweredTicketSuccess(payload = {}) {
  return { type: DELETE_UNANSWERED_TICKET_SUCCESS, payload };
}

export function deleteUnansweredTicketFail(payload = {}) {
  return { type: DELETE_UNANSWERED_TICKET_FAILED, payload, error: true };
}

export function deleteAnsweredTicketSuccess(payload = {}) {
  return { type: DELETE_ANSWERED_TICKET_SUCCESS, payload };
}

export function deleteAnsweredTicketFail(payload = {}) {
  return { type: DELETE_ANSWERED_TICKET_FAILED, payload, error: true };
}

export function setIsReadTickets(payload) {
  return { type: SET_IS_READ_TICKETS, payload };
}

export function replyTicket(payload = {loading: false}) {
  return { type: REPLY_TICKET, payload };
}

export function replyTicketSuccess(payload = {}) {
  return { type: REPLY_TICKET_SUCCESS, payload };
}

export function replyTicketFail(payload = {}) {
  return { type: REPLY_TICKET_FAILED, payload, error: true };
}

export function getSupportForWebAnswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_WEB_ANSWERED, payload };
}

export function getSupportForWebAnsweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_WEB_ANSWERED_SUCCESS, payload };
}

export function getSupportForWebUnanswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_WEB_UNANSWERED, payload };
}

export function getSupportForWebUnansweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_WEB_UNANSWERED_SUCCESS, payload };
}

export function setGetSupportForWebAnsweredLoading(payload = { loading: false }) {
  return { type: SET_WEB_ANSWERED_LOADING, payload };
}

export function setGetSupportForWebUnansweredLoading(payload = { loading: false }) {
  return { type: SET_WEB_UNANSWERED_LOADING, payload };
}

export function setFilterWebUnanswered(payload = {}) {
  return { type: SET_FILTER_WEB_UNANSWERED, payload };
}

export function setFilterWebAnswered(payload = {}) {
  return { type: SET_FILTER_WEB_ANSWERED, payload };
}

export function countUnreadWebTickets(payload = {}) {
  return { type: COUNT_UNREAD_WEB_TICKETS, payload };
}

export function countUnreadWebTicketsSuccess(payload = {}) {
  return { type: COUNT_UNREAD_WEB_TICKETS_SUCCESS, payload };
}

export function uploadImage(payload = {}) {
  return {
    type: UPLOAD_IMAGE,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function uploadImageSuccess(payload = {}, meta) {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload,
    meta
  };
} 

export function getSupportForAppAnswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_APP_ANSWERED, payload };
}
export function getSupportForAppAnsweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_APP_ANSWERED_SUCCESS, payload };
}
export function getSupportForAppUnanswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_APP_UNANSWERED, payload };
}
export function getSupportForAppUnansweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_APP_UNANSWERED_SUCCESS, payload };
}
export function setGetSupportForAppAnsweredLoading(payload = { loading: false }) {
  return { type: SET_APP_ANSWERED_LOADING, payload };
}
export function setGetSupportForAppUnansweredLoading(payload = { loading: false }) {
  return { type: SET_APP_UNANSWERED_LOADING, payload };
}
export function setFilterAppUnanswered(payload = {}) {
  return { type: SET_FILTER_APP_UNANSWERED, payload };
}
export function setFilterAppAnswered(payload = {}) {
  return { type: SET_FILTER_APP_ANSWERED, payload };
}
export function countUnreadAppTickets(payload = {}) {
  return { type: COUNT_UNREAD_APP_TICKETS, payload };
}
export function countUnreadAppTicketsSuccess(payload = {}) {
  return { type: COUNT_UNREAD_APP_TICKETS_SUCCESS, payload };
}

export function getSupportForVerificationAnswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_VERIFICATION_ANSWERED, payload };
}
export function getSupportForVerificationAnsweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_VERIFICATION_ANSWERED_SUCCESS, payload };
}
export function getSupportForVerificationUnanswered (payload = {}) {
  return { type: GET_SUPPORT_FOR_VERIFICATION_UNANSWERED, payload };
}
export function getSupportForVerificationUnansweredSuccess(payload = {}) {
  return { type: GET_SUPPORT_FOR_VERIFICATION_UNANSWERED_SUCCESS, payload };
}
export function setGetSupportForVerificationAnsweredLoading(payload = { loading: false }) {
  return { type: SET_VERIFICATION_ANSWERED_LOADING, payload };
}
export function setGetSupportForVerificationUnansweredLoading(payload = { loading: false }) {
  return { type: SET_VERIFICATION_UNANSWERED_LOADING, payload };
}
export function setFilterVerificationUnanswered(payload = {}) {
  return { type: SET_FILTER_VERIFICATION_UNANSWERED, payload };
}
export function setFilterVerificationAnswered(payload = {}) {
  return { type: SET_FILTER_VERIFICATION_ANSWERED, payload };
}
export function countUnreadVerificationTickets(payload = {}) {
  return { type: COUNT_UNREAD_VERIFICATION_TICKETS, payload };
}
export function countUnreadVerificationTicketsSuccess(payload = {}) {
  return { type: COUNT_UNREAD_VERIFICATION_TICKETS_SUCCESS, payload };
}

export function getReportList(payload = {}) {
  return { type: GET_REPORT_LIST, payload };
}
export function getReportListSuccess(payload = {}) {
  return { type: GET_REPORT_LIST_SUCCESS, payload };
}
export function getReportListByUser(payload = {}) {
  return { type: GET_REPORT_LIST_BY_USER, payload };
}

export function unlinkPhoneAuth(payload = {}) {
  return { 
    type: UNLINK_PHONE_AUTH,
    payload,
    meta: {
      thunk: true
    }
  };
}
export function unlinkPhoneAuthSuccess(payload = {}, meta) {
  return { type: UNLINK_PHONE_AUTH_SUCCESS, payload, meta };
}
export function unlinkPhoneAuthFail(payload = {}, meta, error) {
  return { type: UNLINK_PHONE_AUTH_FAIL, payload, meta, error: error };
}