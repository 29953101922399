export const FETCH_BANNER_LIST = '@banner/FETCH_BANNER_LIST';
export const FETCH_BANNER_LIST_SUCCESS = '@banner/FETCH_BANNER_LIST_SUCCESS';

export const TOGGLE_BANNER_STATUS = '@banner/TOGGLE_BANNER_STATUS';
export const TOGGLE_BANNER_STATUS_SUCCESS = '@banner/TOGGLE_BANNER_STATUS_SUCCESS';

export const CREATE_BANNER = '@banner/CREATE_BANNER';
export const CREATE_BANNER_SUCCESS = '@banner/CREATE_BANNER_SUCCESS';

export const UPDATE_BANNER = '@banner/UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = '@banner/UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAIL = '@banner/UPDATE_BANNER_FAIL';

export const UPDATE_BANNERS_POSITION = '@banner/UPDATE_BANNERS_POSITION';
export const UPDATE_BANNERS_POSITION_SUCCESS = '@banner/UPDATE_BANNERS_POSITION_SUCCESS';
export const UPDATE_BANNERS_POSITION_FAIL = '@banner/UPDATE_BANNERS_POSITION_FAIL';
