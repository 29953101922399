import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import NewSearchNailistPage from './NailistListPages/NewSearchNailistPage';
import NailistDashboardPage from './NailistListPages/NailistDashboardPage';
import NailistDetailPage from './NailistDetailPage';
import NailistDisabledPage from './NailistDisabledPage';
import './styles.scss';

class Nailist extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Route
          path="/dashboard/nailist/search"
          exact
          component={NewSearchNailistPage}
        />
        <Route
          path="/dashboard/nailist/profile/:id/:activeTab?"
          exact
          component={NailistDetailPage}
        />
        <Route
          path="/dashboard/nailist-disabled"
          exact
          component={NailistDisabledPage}
        />
        <Route
          path="/dashboard/nailist"
          exact
          component={NailistDashboardPage}
        />
      </React.Fragment>
    );
  }
}

Nailist.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default Nailist;
