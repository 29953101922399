import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Modal } from 'antd';
import _ from 'lodash';

import PageHeader from 'components/PageHeader';
import { useRouteMatch } from 'react-router-dom';

import ReceiptEmailBooking from './ReceiptEmailBooking/ReceiptEmailBooking';
import ReceiptEmailRelatedList from './ReceiptEmailRelatedList/ReceiptEmailRelatedList';

const ReceiptEmailDetail = ({
  booking,
  relatedReceipts,
  fetchRelatedReceiptList,
  fetchReceiptBookingDetail
}) => {
  const routeMatch = useRouteMatch();
  const { receiptId, bookingId } = routeMatch.params;
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    (async() => {
      setLoading(true);
      const [{ data: relatedReceiptsData }] = await Promise.all([fetchRelatedReceiptList({ receiptId, bookingId }), fetchReceiptBookingDetail({ bookingId })]);
      setLoading(false);

      if (_.isEmpty(relatedReceiptsData)) {
        Modal.warning({
          title: 'There is no receipt on this booking!',
          okText: 'Cancel'
        });
      }
    })();
    
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Receipt | Detail</title>
      </Helmet>
      <PageHeader
        title="Receipt Detail"
        breadcrumb={PageHeader.Bread.receiptDetail(receiptId)}
        goBack={true}
      />
      <div className="page-container" id="receipt-list" style={{display: 'block'}}>
        <ReceiptEmailBooking loading={loading} booking={booking} receipt={relatedReceipts[0]}/>
        <br/>
        <ReceiptEmailRelatedList relatedReceipts={relatedReceipts} receiptId={receiptId}/>
      </div>
    </Fragment>
  );
};

ReceiptEmailDetail.propTypes = {
  booking: PropTypes.object,
  relatedReceipts: PropTypes.array,
  fetchRelatedReceiptList: PropTypes.func,
  fetchReceiptBookingDetail: PropTypes.func
};

export default ReceiptEmailDetail;