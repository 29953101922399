import React, { useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Table, Card, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { fetchAdminOperatorList, deleteAdminOperator } from 'providers/UserProvider/actions';
import PageHeader from 'components/PageHeader';
import columnsTemplate from './Preset/columns';
import FilterForm from './Preset/FilterForm';
import useTableControl from './hooks/useTableControl';
import useManageAccountPopupController from './hooks/useManageAccount';

const AdminOperatorPage = () => {
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const dispatch = useDispatch();
  const { loading, list, total } = useSelector(state => state.user);
  const { me } = useSelector(state => state.auth);
  const [openManageAccountPopup, ManageAccountPopup] = useManageAccountPopupController();

  useEffect(() => {
    dispatch(fetchAdminOperatorList(search));
  }, [search]);

  if (me.role !== 'ADMIN') {
    dispatch(replace('/'));
    return null;
  }

  const deleteAccount = (id) => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure to delete this account?',
      onOk: () => {
        dispatch(deleteAdminOperator(id));
      }
    });
  };

  const columns = [
    columnsTemplate.id,
    columnsTemplate.createdAt,
    columnsTemplate.username,
    // columnsTemplate.name,
    columnsTemplate.phone,
    columnsTemplate.email,
    columnsTemplate.status,
    {
      fixed: 'right',
      render: (record) =>
        <>
          <Button danger type="link" onClick={() => deleteAccount({ id: record._id, ...search })}>
            削除
          </Button>
          <Button type="link" onClick={() => openManageAccountPopup(record)}>
            編集
          </Button>
        </>
    }
  ];

  const handleFilterFormChange = ({ keyword = '', status }) => {
    const values = { ...search, keyword: keyword.trim(), status };
    if (!values.keyword) {
      delete values.keyword;
    }
    if (!values.status) {
      delete values.status;
    }
    handleSubmitFilterForm(values);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Admin & Operators</title>
      </Helmet>
      <PageHeader
        title="Admin & Operators"
        breadcrumb={PageHeader.Bread.adminOperator}
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={openManageAccountPopup}>Create account</Button>
        }
      />
      <div className="page-container" style={{ display: 'block' }}>
        <Card bordered={false}>
          <div style={{ marginBottom: 16 }}>
            <FilterForm onSubmit={handleFilterFormChange} />
          </div>
          <Table
            columns={columns}
            dataSource={list}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
            pagination={{
              total: total,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            rowKey={(record) => record._id}
            loading={loading}
          />
        </Card>
      </div>

      {ManageAccountPopup}
    </Fragment>
  );
};

export default AdminOperatorPage;
