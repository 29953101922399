import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import { BOOKING_STATUS } from 'utils/constants';
import { formatYen } from 'utils/stringFormat';
import moment from 'moment';

const {
  REQUESTED,
  EXPIRED,
  CANCELED_REQUEST,
  CANCELED_RESERVATION,
  DECLINED_REQUEST,
  DECLINED_RESERVATION,
  DONE,
  NOVISIT,
  NAILIST_CANCELED,
  UNDONE,
  NAILIE_CANCELED
} = BOOKING_STATUS;

/**
 * Only CANCELED_RESERVATION and NOVISIT has status; default of CANCELED_RESERVATION is 0; default of NOVISIT is 1
 */
export const formatPenalty = (penalty, status) => {
  if (![NOVISIT, CANCELED_RESERVATION].includes(status)) {
    return undefined;
  }
  if (status === NOVISIT && !_isNumber(penalty)) {
    return 1;
  }
  if (status === CANCELED_RESERVATION && !_isNumber(penalty)) {
    return 0;
  }
  return penalty;
};

export const renderPaymentAmount = ({ payment = {}, status, bookingPointInfo, additionalPrice, price, couponInfo, penalty }) => {
  penalty = formatPenalty(penalty, status);
  // if (!_isUndefined(payment.amount)) {
  //   return formatYen(_get(payment, 'amount'));
  // }

  const couponValue = _get(couponInfo, 'value', 0);
  const usePoint = _get(bookingPointInfo, 'usePoint', 0);

  if ([EXPIRED, UNDONE, CANCELED_REQUEST, DECLINED_REQUEST, DECLINED_RESERVATION].includes(status)) {
    return '¥0';
  }
  if ([CANCELED_RESERVATION, NOVISIT].includes(status)) {
    return formatYen(Math.floor(price * penalty));
  }
  if ([REQUESTED, DONE].includes(status)) {
    return formatYen((additionalPrice || price) - couponValue - usePoint);
  }
  return '';
};

export const renderFinalAmount = ({ status, price, penalty, additionalPrice }) => {
  penalty = formatPenalty(penalty, status);

  if ([EXPIRED, CANCELED_REQUEST, DECLINED_REQUEST, UNDONE,
    NAILIST_CANCELED, NAILIE_CANCELED, DECLINED_RESERVATION].includes(status)) {
    return '¥0';
  }
  if ([CANCELED_RESERVATION, NOVISIT].includes(status)) {
    return formatYen(Math.floor(price * penalty));
  }
  if ([DONE].includes(status)) {
    return formatYen(additionalPrice || price);
  }
};

export const calculateRewardPoint = ({ status, earnPointRate, price, additionalPrice, pointBonusRate = 1, couponInfo, bookingPointInfo}) => {
  if (status !== DONE) {
    return 0;
  }

  const couponValue = _get(couponInfo, 'value', 0);
  const usePoint = _get(bookingPointInfo, 'usePoint', 0);

  return Math.floor(earnPointRate * ((additionalPrice || price) - couponValue - usePoint) * pointBonusRate);
};

export const formatBookingData = (booking) => {
  return {
    ...booking,
    penalty: formatPenalty(booking.penalty, booking.status)
  };
};

export const formatCardErrorBookingData = (bookings = []) => {
  return bookings.map(booking => {
    const { penalty = 0, additionalPrice, price, status, paymentSuccess,
      bookingDate, atoneMerchant, veritransOrderId } = booking;

    let formatBooking = { ...booking };
    const errorDate = _get(booking, 'payment.errorDate');
    const errorExpiredDate = _get(booking, 'payment.expiredDate');

    let totalFee;
    let percentOfPayment;
    if ([CANCELED_REQUEST, DECLINED_REQUEST, NAILIE_CANCELED, NAILIST_CANCELED].includes(status)) {
      totalFee = '¥0';
    } else if ([CANCELED_RESERVATION, DECLINED_RESERVATION, NOVISIT].includes(status)) {
      totalFee = `¥${(Math.floor(price * penalty))}`;
    } else totalFee = `¥${(additionalPrice || price)}`;

    if ([DONE, NOVISIT].includes(status) && !paymentSuccess) {
      percentOfPayment = '100%';
    } else {
      percentOfPayment = '0%';
    }

    return {
      objectId: formatBooking._id,
      nailist: _get(booking, 'nailist.username'),
      customer: _get(booking, 'customer.username'),
      errorDate: errorDate ? moment(errorDate).format('YYYY-MM-DD') : '',
      errorExpiredDate: errorExpiredDate ? moment(errorExpiredDate).format('YYYY-MM-DD') : '',
      bookingDate: moment(bookingDate).format('YYYY-MM-DD'),
      point: `${_get(booking, 'bookingPointInfo.usePoint', '0')} P`,
      percentOfPayment,
      totalFee,
      status: 'CARD_ERROR',
      origin: formatBooking.origin || 'App',
      paymentMethod: formatBooking?.paymentCardProvider || formatBooking?.paymentMethod,
      settlementId: atoneMerchant || veritransOrderId || _get(booking, 'payment.chargeId', '')
    };
  });
};