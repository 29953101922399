import {
  FETCH_BANNER_LIST,
  FETCH_BANNER_LIST_SUCCESS,
  TOGGLE_BANNER_STATUS,
  TOGGLE_BANNER_STATUS_SUCCESS,
  CREATE_BANNER,
  CREATE_BANNER_SUCCESS,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  UPDATE_BANNERS_POSITION,
  UPDATE_BANNERS_POSITION_SUCCESS,
  UPDATE_BANNERS_POSITION_FAIL
} from './constants';

export function fetchBannerList(payload = {}) {
  return { type: FETCH_BANNER_LIST, payload, meta: { thunk: true } };
}
export function fetchBannerListSuccess(payload = {}, meta) {
  return { type: FETCH_BANNER_LIST_SUCCESS, payload, meta };
}
export function toggleBannerStatus(payload = {}) {
  return { type: TOGGLE_BANNER_STATUS, payload, meta: { thunk: true } };
}
export function toggleBannerStatusSuccess(payload = {}, meta) {
  return { type: TOGGLE_BANNER_STATUS_SUCCESS, payload, meta };
}

export function createBanner(payload = {}) {
  return { type: CREATE_BANNER, payload, meta: { thunk: true } };
}
export function createBannerSuccess(payload = {}, meta) {
  return { type: CREATE_BANNER_SUCCESS, payload, meta };
}

export function updateBanner(payload = {}) {
  return { type: UPDATE_BANNER, payload, meta: { thunk: true } };
}
export function updateBannerSuccess(payload = {}, meta) {
  return { type: UPDATE_BANNER_SUCCESS, payload, meta };
}
export function updateBannerFail(error, meta) {
  return { type: UPDATE_BANNER_FAIL, error, meta };
}


export function updateBannersPosition(payload = {}) {
  return { type: UPDATE_BANNERS_POSITION, payload, meta: { thunk: true } };
}
export function updateBannersPositionSuccess(payload = {}, meta) {
  return { type: UPDATE_BANNERS_POSITION_SUCCESS, payload, meta };
}
export function updateBannersPositionFail(error, meta) {
  return { type: UPDATE_BANNERS_POSITION_FAIL, error, meta };
}
