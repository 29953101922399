import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Table, Card, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import PageHeader from 'components/PageHeader';

import useTableControl from '../hooks/useTableControl';
import FilterForm from '../Preset/FilterForm';

import columnsTemplate from '../Preset/columnsTemplate';
import moment from 'moment';
const columns = [
  columnsTemplate.id,
  columnsTemplate.createdAt,
  columnsTemplate.usernameEmail,
  {
    title: 'Customer Name',
    key: 'name',
    render: (record) => (
      <div>
        <div>{_get(record, 'fullName')}</div>
        <div>{_get(record, 'phonetic')}</div>
      </div>
    ),
    width: 200
  },
  columnsTemplate.status,
  columnsTemplate.invitationCode,
  {
    title: '',
    dataIndex: '',
    key: 'Action',
    width: 120,
    fixed: 'right',
    render: (record) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Link to={`/dashboard/customer/profile/${record._id}`}>
            <Button type="primary" icon={<EyeOutlined />} />
          </Link>
        </div>
      );
    }
  }
];

const CustomerList = ({ list, total, fetchCustomerList }) => {
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const [loading, setLoading] = useState(false);
  const dateFrom = _get(search, 'dateRange[0]') ? moment(_get(search, 'dateRange[0]')) : '';
  const dateTo = _get(search, 'dateRange[1]') ? moment(_get(search, 'dateRange[1]')) : '';
  
  const handleFilterFormChange = (values) => {
    const orderParams = {};
    orderParams.dateRange = [_get(values, 'dateRange[0]') ? values?.dateRange[0].format('YYYY-MM-DD') : '',
      _get(values, 'dateRange[1]') ? values?.dateRange[1].format('YYYY-MM-DD') : ''];

    handleSubmitFilterForm({
      ...values,
      ...orderParams
    });
  };
  useEffect(() => {
    (async() => {
      setLoading(true);
      await fetchCustomerList(search);
      setLoading(false);
    })();
  }, [search]);
  

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Customer | Search</title>
      </Helmet>
      <PageHeader
        title="Customer"
        breadcrumb={PageHeader.Bread.customer}
      />
      <div className="page-container" id="customer-list" style={{ display: 'block' }}>
        <Card>
          <div style={{ marginBottom: 16 }}>
            <FilterForm
              onSubmit={handleFilterFormChange}
              initialValues={{
                keyword: _get(search, 'keyword'),
                dateRange: [dateFrom, dateTo],
                status: _get(search, 'status', 'ACTIVE')
              }}
            />
          </div>
          <Table
            rowKey={'_id'}
            columns={columns}
            dataSource={list}
            scroll={{ x: 1000 }}
            onChange={handleTableChange}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            loading={loading}
          />
        </Card>
      </div>
    </Fragment>
  );
};

CustomerList.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchCustomerList: PropTypes.func
};

export default CustomerList;