import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card, Form, Button, Popconfirm } from 'antd';
import useTableControl from '../hooks/useTableControl';
import FilterForm from './FilterForm';
import { exportDeletedAccountCsv, fetchDeletedAccountList, earlyReleaseAccount } from 'providers/DeletedAccountProvider/actions';
import columnsTemplate from '../Preset/columnsTemplate';
import PageHeader from 'components/PageHeader';
import _ from 'lodash';
import moment from 'moment';
import { LinkOutlined, ExportOutlined, EyeOutlined } from '@ant-design/icons';
import UserRecentReservationPopup from 'components/Popup/UserRecentReservationPopup';
import { Link } from 'react-router-dom';

const DeletedAccount = () => {
  const [searchParams, handleTableChange, handleSubmitFilterForm, renewSearchObject] = useTableControl({});
  const dispatch = useDispatch();
  const [openUserBookingList, setOpenUserBookingList] = useState(false);
  const dateFrom = _.get(searchParams, 'dateRange[0]') ? moment(_.get(searchParams, 'dateRange[0]')) : '';
  const dateTo = _.get(searchParams, 'dateRange[1]') ? moment(_.get(searchParams, 'dateRange[1]')) : '';

  useEffect(() => {
    setOpenUserBookingList();
    dispatch(fetchDeletedAccountList({ page: 1, limit: 10, ...searchParams }));
  }, [searchParams]);

  const earlyRelease = (userId, blackListedId) => dispatch(earlyReleaseAccount({userId, id: blackListedId})).finally(renewSearchObject);

  const list = useSelector(state => state.deletedAccount.list);
  const total = useSelector(state => state.deletedAccount.total);
  const isLoading = useSelector(state => state.deletedAccount.loading);
  const isLoadingUserBooking = useSelector(state => state.deletedAccount.isLoadingUserBooking);
  const selectedUserBooking = useSelector(state => state.deletedAccount.deletedUserBookingList)[openUserBookingList];
  const isExporting = useSelector(state => state.deletedAccount.isExporting);

  let columns = [
    {
      title: 'ID',
      key: 'id',
      width: 190,
      render: (record) => {
        const userRole = record?.role.toLowerCase();
        return (
          <Link
            to={`/dashboard/${userRole}/profile/` + _.get(record, '_id')}
          >
            <div style={{ marginLeft: '8px' }}>
              <LinkOutlined />
              {' ' + _.get(record, '_id')}
            </div>
          </Link>
        );
      }
    },
    columnsTemplate.username,
    columnsTemplate.role,
    columnsTemplate.email,
    columnsTemplate.phone,
    columnsTemplate.deletedAt,
    columnsTemplate.status,
    columnsTemplate.reason,
    columnsTemplate.detail,
    {
      key: 'bookingList',
      width: 200,
      fixed: 'right',
      render: (record) =>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <Link>
            <a onClick={() => setOpenUserBookingList(_.get(record, '_id'))}><EyeOutlined/>{' Booking'}</a>
          </Link>
          <Popconfirm title="Confirm?" onConfirm={() => earlyRelease(record?._id, record?.blackListed[0]?._id)} >
            <Button type='default' disabled={!record?.blackListed[0]}>
              Release
            </Button>
          </Popconfirm>
        </div>
    }
  ];


  const handleFilterFormChange = (values) => {
    const orderParams = {};
    if (values.type !== searchParams.type) {
      orderParams.orderBy = 'bookingDate';
      orderParams.order = 'descend';
    }
    orderParams.dateRange = [_.get(values, 'dateRange[0]') ? values?.dateRange[0].format('YYYY-MM-DD') : '',
      _.get(values, 'dateRange[1]') ? values?.dateRange[1].format('YYYY-MM-DD') : ''];
    handleSubmitFilterForm({
      ...values,
      ...orderParams
    });
  };

  return (
    <div>
      <Helmet>
        <title>Nailie Dashboard | Deleted Account | Search</title>
      </Helmet>
      <PageHeader
        title="Deleted account"
        breadcrumb={PageHeader.Bread.deletedAccount}
      />
      <div className="page-container" id="new-booking-list" style={{ display: 'block' }}>
        <Card>
          <div className="search-section flex justify-between" style={{ marginBottom: 16}}>
            <FilterForm
              onSubmit={handleFilterFormChange}
              initialValues={{
                keyword: _.get(searchParams, 'keyword'),
                dateRange: [dateFrom, dateTo],
                role: _.get(searchParams, 'role'),
                status: _.get(searchParams, 'status')
              }}
            />

            <Form layout='inline' style={{ alignSelf: 'flex-start', justifyContent: 'flex-end'}}>
              <Form.Item>
                <Button
                  type='primary'
                  icon={<ExportOutlined />}
                  style={{ marginBottom: 8 }}
                  loading={isExporting}
                  disabled={total === 0}
                  onClick={() => dispatch(exportDeletedAccountCsv({...searchParams }))}
                >
                  Export CSV
                </Button>
              </Form.Item>
            </Form>

          </div>
          <Table
            rowKey='_id'
            columns={columns}
            dataSource={list}
            scroll={{ x: 1000 }}
            onChange={handleTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(searchParams.page) || 1,
              showSizeChanger: false
            }}
            loading={isLoading}
          />
          <UserRecentReservationPopup
            loading={isLoadingUserBooking}
            visible={openUserBookingList}
            data={selectedUserBooking}
            closePopup={() => setOpenUserBookingList()} />
        </Card>
      </div>
    </div>
  );
};

export default DeletedAccount;