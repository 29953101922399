import { all } from 'redux-saga/effects';

import watchNailist from 'providers/NailistProvider/saga';
import watchPayouts from 'providers/PayoutProvider/saga';
import watchAuth from 'providers/AuthProvider/saga';
import watchBooking from 'providers/BookingProvider/saga';
import watchSetting from 'providers/SettingProvider/saga';
import watchSalon from 'providers/SalonProvider/saga';
import watchReceipt from 'providers/ReceiptProvider/saga';
import watchBank from 'providers/BankProvider/saga';
import watchFriendInvitation from 'providers/FriendInvitationProvider/saga';
import watchAccountNoBank from 'providers/AccountNoBankProvider/saga';
import watchSupport from 'providers/SupportProvider/saga';
import watchRewardPoint from 'providers/RewardPointsProvider/saga';
import watchDeletedAccount from 'providers/DeletedAccountProvider/saga';
import WatchCustomer from 'providers/CustomerProvider/saga';
import WatchStation from 'providers/StationProvider/saga';
import watchUser from 'providers/UserProvider/saga';
import watchAnnouncement from 'providers/AnnouncementProvider/saga';
import watchPopupBanner from 'providers/PopupBannerProvider/saga';
import watchBanner from 'providers/BannerProvider/saga';
import watchBookingOnSchedule from 'providers/BookingOnScheduleProvider/saga';

export default function* rootSaga() {
  yield all([
    watchNailist(),
    watchPayouts(),
    watchAuth(),
    watchBooking(),
    watchSetting(),
    watchSalon(),
    watchReceipt(),
    watchBank(),
    watchFriendInvitation(),
    watchAccountNoBank(),
    watchSupport(),
    watchRewardPoint(),
    watchDeletedAccount(),
    WatchCustomer(),
    WatchStation(),
    watchUser(),
    watchAnnouncement(),
    watchPopupBanner(),
    watchBanner(),
    watchBookingOnSchedule()
  ]);
}
