import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, Select as AntSelect } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { RangePicker, TextField, Select } from 'components/Form';
const { Option } = AntSelect;


const FilterForm = ({ handleSubmit }) => {
  return (
    <Form layout="inline" onSubmit={handleSubmit} >
      <Field
        name="keyword"
        placeholder="Search by userID, username,..."
        component={TextField}
        allowClear
        className="search-input"
        suffix={<SearchOutlined />}
      />
      <Field
        name="dateRange"
        placeholder={['From date', 'To date']}
        component={RangePicker}
      />
      
      <Field name="role" placeholder="Role" component={Select} allowClear >
        <Option value="NAILIST">NAILIST</Option>
        <Option value="CUSTOMER">CUSTOMER</Option>
      </Field>

      <Field name="status" placeholder="Status" component={Select} allowClear >
        <Option value="REQUEST_DELETE">DELETED</Option>
        <Option value="PERMANENT_DELETED">FORCED DELETION</Option>
        <Option value="INACTIVE">INACTIVE</Option>
      </Field>
      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

FilterForm.propTypes = {
  handleSubmit: PropTypes.any
};

export default reduxForm({
  form: 'deleted-customer-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);
