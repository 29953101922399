import { connect } from 'react-redux';

import { fetchNailistList } from 'providers/NailistProvider/actions';
import { makeSearchNailistList, makeSearchNalistTotal } from 'providers/NailistProvider/selectors';

import NewSearchNailistPage from './NewSearchNailistPage';

const mapStateToProps = (state) => ({
  list: makeSearchNailistList(state),
  total: makeSearchNalistTotal(state)
});


const mapDispatchToProps = (dispatch) => ({
  fetchNailistList: (params) => dispatch(fetchNailistList(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSearchNailistPage);