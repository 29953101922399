import { connect } from 'react-redux';

import { SetPopup } from 'providers/PopupProvider/actions';
import { makeAnyPopup } from 'providers/PopupProvider/selectors';

import { getAccountNoBank } from 'providers/AccountNoBankProvider/actions';
import {
  makeAccountNoBankList,
  makeAccountNoBankTotal,
  makeAccountNoBankLoading
} from 'providers/AccountNoBankProvider/selectors';

import Popup from './Popup';

const mapStateToProps = (state) => ({
  accountNoBankList: makeAccountNoBankList(state),
  accountNoBankTotal: makeAccountNoBankTotal(state),
  accountNoBankLoading: makeAccountNoBankLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({ name: 'accountNoBank', show: false })),
  getAccountNoBank: (data) => dispatch(getAccountNoBank(data))
});

const PopupLayer1 = connect(mapStateToProps, mapDispatchToProps)(Popup);
const mapStateToPropsLayer1 = (state) => ({
  data: makeAnyPopup(state, 'accountNoBank')
});

export default connect(mapStateToPropsLayer1, null)(PopupLayer1);
