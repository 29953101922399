import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';

import { SetPopup } from 'providers/PopupProvider/actions';
import {
  fetchPayoutsList,
  requestPayoutFile
} from 'providers/PayoutProvider/actions';

import {
  makeSearchPayoutsList,
  makeSearchPayoutTotal,
  makePayoutLoading,
} from 'providers/PayoutProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import NailistPayout from './NailistPayout';

const mapStateToProps = (state) => ({
  payoutsList: makeSearchPayoutsList(state),
  total: makeSearchPayoutTotal(state),
  loading: makePayoutLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayoutsList: (params) => dispatch(fetchPayoutsList({ payoutType: 'NAILIST', ...params })),
  push: (path) => dispatch(push(path)),
  replace: (path) => dispatch(replace(path)),
  openPayoutPopup: (show) => dispatch(SetPopup({ name: 'payout', show: { payoutType: 'NAILIST', ...show } })),
  openPayoutChangedPopup: (show) => dispatch(SetPopup({ name: 'payoutChanged', show: { payoutType: 'NAILIST', ...show } })),
  openCompletePayoutPopup: (show) => dispatch(SetPopup({ name: 'completePayout', show: { payoutType: 'NAILIST', ...show } })),
  openNailistNoBankPopup: () => dispatch(SetPopup({ name: 'accountNoBank', show: { accountType: 'NAILIST' } })),
  requestPayoutFile: (filter) => dispatch(requestPayoutFile({ payoutType: 'NAILIST', ...filter }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NailistPayout);
