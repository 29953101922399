import {
  FETCH_BOOKING_SCHEDULE,
  FETCH_BOOKING_SCHEDULE_SUCCESS,
  FETCH_BOOKING_SCHEDULE_FAIL,
  OPEN_SCHEDULE_POPUP_LIST,
  CLOSE_SCHEDULE_POPUP_LIST
} from './constants';

export function fetchBookingSchedule(payload = {}) {
  return { type: FETCH_BOOKING_SCHEDULE, payload, meta: { thunk: true } };
}

export function fetchBookingScheduleSuccess(payload = {}, meta) {
  return { type: FETCH_BOOKING_SCHEDULE_SUCCESS, payload, meta };
}

export function fetchBookingScheduleFail(meta) {
  return { type: FETCH_BOOKING_SCHEDULE_FAIL, meta, error: true };
}

export function openSchedulePopup(payload = {}) {
  return { type: OPEN_SCHEDULE_POPUP_LIST, payload };
}
export function closeSchedulePopup() {
  return { type: CLOSE_SCHEDULE_POPUP_LIST };
}