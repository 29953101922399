import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { RangePicker } from 'components/Form';

const FilterForm = ({ initialValues, handleSubmit }) => {
  return (
    <Form layout="inline" onSubmit={handleSubmit} initialValues={initialValues}>
      <Field
        name="dateRange"
        placeholder={['From date', 'To date']}
        component={RangePicker}
      />

      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

FilterForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.any
};

export default reduxForm({
  form: 'friend-invitation-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);
