import {
  fetchCustomerCouponListsSuccess,
  fetchCustomerListSuccess, fetchCustomerProfileSuccess,
  updateCustomerInfoSuccess,
  updateCustomerInfoFail,
  verifyCustomerEmailSuccess,
  fetchCustomerPointInfoSuccess,
  fetchCustomerPointHistorySuccess
} from './actions';
import {
  FETCH_CUSTOMER_COUPON_LISTS,
  FETCH_CUSTOMER_LIST, FETCH_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_INFO,
  VERIFY_CUSTOMER_EMAIL,
  FETCH_CUSTOMER_POINT_INFO,
  FETCH_CUSTOMER_POINT_HISTORY
} from './constants';
import _ from 'lodash';
import { put, takeLeading, call, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { message } from 'antd';

function* fetchCustomerList(action) {
  try {
    const { keyword, ...filter } = action.payload;
    const query = {
      keyword: keyword ? _.trim(keyword) : undefined,
      orderBy: action.payload.orderBy || '_created_at',
      order: action.payload.order || 'descend',
      status: action.payload.status || 'ACTIVE',
      ...filter
    };

    if (!_.get(query, 'dateRange[0]')) {
      delete query.dateRange;
    }

    const { data } = yield call(request, 'customers', query, 'POST');

    yield put(fetchCustomerListSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}
function* fetchCustomerProfile(action) {
  try {
    const { data } = yield call(request, `customers/${action.payload}`, {}, 'GET');
    yield put(fetchCustomerProfileSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCustomerCouponLists(action) {
  try {
    const { data } = yield call(request, `customers/${action.payload}/coupons`, {}, 'GET');
    yield put(fetchCustomerCouponListsSuccess(data, action.meta));
  } catch (e) {
    message.error(e);
  }
}

function* updateCustomerInfo(action) {
  try {
    const userId = _.get(action, 'payload.userId');
    const values = action.payload;
    delete values.userId;

    yield call(request, `customers/${userId}/info`, values, 'POST');
    yield put(updateCustomerInfoSuccess({ ...values }, action.meta));
    message.success('updated!');
  } catch (error) {
    message.error(error.error || error.message || error.data?.username || error.data?.email || 'Something went wrong');
    yield put(updateCustomerInfoFail(error, action.meta));
  }
}

function* verifyCustomerEmail(action) {
  try {
    const userId = _.get(action, 'payload.userId');

    yield call(request, `customers/${userId}/verify-email`, {}, 'PATCH');
    yield put(verifyCustomerEmailSuccess({userId}));
    message.success('Verified!');
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
  }
}

function* fetchCustomerPointInfo(action) {
  try {
    const { data } = yield call(request, `/customers/${action.payload}/pointInfo`, {}, 'GET');
    yield put(fetchCustomerPointInfoSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCustomerPointHistory(action) {
  try {
    const { customerId, ...filter} = action.payload;
    const { data } = yield call(request, `/customers/${customerId}/point-history`, filter, 'GET');

    yield put(fetchCustomerPointHistorySuccess({ data }));
  } catch (e) {
    console.log(e);
  }
}

export default function* watchCustomer() {
  yield takeLatest(FETCH_CUSTOMER_LIST, fetchCustomerList);
  yield takeLeading(FETCH_CUSTOMER_PROFILE, fetchCustomerProfile);
  yield takeLeading(FETCH_CUSTOMER_COUPON_LISTS, fetchCustomerCouponLists);
  yield takeLeading(UPDATE_CUSTOMER_INFO, updateCustomerInfo);
  yield takeLeading(VERIFY_CUSTOMER_EMAIL, verifyCustomerEmail);
  yield takeLeading(FETCH_CUSTOMER_POINT_INFO, fetchCustomerPointInfo);
  yield takeLatest(FETCH_CUSTOMER_POINT_HISTORY, fetchCustomerPointHistory);
}
