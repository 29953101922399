export function formatWorkTime(open, close, isOff) {
  if (isOff) {
    return 'Closed';
  }
  
  let openTime = ('0000' + open).slice(-4);
  let closeTime = ('0000' + close).slice(-4);

  openTime = openTime && (openTime.slice(0, 2) + ':' + openTime.slice(2));
  closeTime = closeTime && (closeTime.slice(0, 2) + ':' + closeTime.slice(2));

  return `${openTime} ~ ${closeTime}`;
}

const JPCurrency = {
  style: 'currency',
  currency: 'JPY'
};
export function formatYen(input) {
  return Number(input || 0).toLocaleString('jp-JP', JPCurrency);
}