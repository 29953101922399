
import React from 'react';
import { Tooltip, Popconfirm } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { verifyNailistEmail } from 'providers/NailistProvider/actions';

const ICON_STYLE = { alignSelf: 'center', marginLeft: 4 };

const VerifyEmailButton = ({ userId, emailVerified = false }) => {
  const dispatch = useDispatch();

  if (emailVerified === true) {
    return <Tooltip placement="topLeft" title="Verified!" color="green">
      <CheckCircleTwoTone twoToneColor="#52c41a" style={ICON_STYLE}/>
    </Tooltip>;
  }

  return (
    <Popconfirm
      placement="bottomRight"
      title="Are you sure about verifying Email for this account?"
      onConfirm={() => dispatch(verifyNailistEmail({userId}))}
    >
      <WarningTwoTone twoToneColor="#E4A11B" style={ICON_STYLE}/>
    </Popconfirm>
  );
};

export default VerifyEmailButton;