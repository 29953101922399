import _ from 'lodash';
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import Content from './Content';

import './styles.scss';

const PayoutPopup = (props) => {
  return (
    <Modal
      title={_.get(props, 'data.popupTitle', 'Payout Detail')}
      style={{ top: 36 }}
      width={768}
      visible={!!props.data}
      destroyOnClose={true}
      className="payout-popup"
      onCancel={props.closePopup}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Content {...props} />
    </Modal>
  );
};

PayoutPopup.propTypes = {
  data: PropTypes.any,
  profile: PropTypes.any,
  closePopup: PropTypes.func,
  popupTitle: PropTypes.any
};

export default PayoutPopup;
