export const FETCH_PAYOUT_DETAIL_BOOKING_LIST = 'FETCH_PAYOUT_DETAIL_BOOKING_LIST';
export const FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS = 'FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS';
export const FETCH_RECEIPT_BOOKING_DETAIL = 'FETCH_RECEIPT_BOOKING_DETAIL';
export const FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS = 'FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS';
export const RESET_BOOKING_PROVIDER = 'RESET_BOOKING_PROVIDER';

// Booking tab
export const FETCH_BOOKING_LIST = '@BookingProvider/FETCH_BOOKING_LIST';
export const FETCH_BOOKING_LIST_SUCCESS = '@BookingProvider/FETCH_BOOKING_LIST_SUCCESS';

export const FETCH_BOOKING_DETAIL = '@BookingProvider/FETCH_BOOKING_DETAIL';
export const FETCH_BOOKING_DETAIL_SUCCESS = '@BookingProvider/FETCH_BOOKING_DETAIL_SUCCESS';
export const ON_ERROR = '@BookingProvider/ON_ERROR';

export const CHANGE_BOOKING_STATUS = '@BookingProvider/CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_STATUS_SUCCESS = '@BookingProvider/CHANGE_BOOKING_STATUS_SUCCESS';
export const CHANGE_BOOKING_STATUS_FAIL = '@BookingProvider/CHANGE_BOOKING_STATUS_FAIL';

export const UPDATE_CHANGED_BOOKING_HISTORY_NOTE = '@BookingProvider/UPDATE_CHANGED_BOOKING_HISTORY_NOTE';
export const UPDATE_CHANGED_BOOKING_HISTORY_NOTE_SUCCESS = '@BookingProvider/UPDATE_CHANGED_BOOKING_HISTORY_NOTE_SUCCESS';
export const UPDATE_CHANGED_BOOKING_HISTORY_NOTE_FAIL = '@BookingProvider/UPDATE_CHANGED_BOOKING_HISTORY_NOTE_FAIL';

export const FETCH_CARD_ERROR_BOOKING_LIST = '@BookingProvider/FETCH_CARD_ERROR_BOOKING_LIST';
export const FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS = '@BookingProvider/FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS';
export const FETCH_CARD_ERROR_BOOKING_LIST_ERROR = '@BookingProvider/FETCH_CARD_ERROR_BOOKING_LIST_ERROR';

export const COMPLETE_BOOKING = '@BookingProvider/COMPLETE_BOOKING';
export const COMPLETE_BOOKING_SUCCESS = '@BookingProvider/COMPLETE_BOOKING_SUCCESS';
export const COMPLETE_BOOKING_ERROR = '@BookingProvider/COMPLETE_BOOKING_ERROR';

export const NOTIFY_ERROR_BOOKING = '@BookingProvider/NOTIFY_ERROR_BOOKING';
export const NOTIFY_ERROR_BOOKING_SUCCESS = '@BookingProvider/NOTIFY_ERROR_BOOKING_SUCCESS';
export const NOTIFY_ERROR_BOOKING_ERROR = '@BookingProvider/NOTIFY_ERROR_BOOKING_ERROR';

export const EXPORT_BOOKING_CSV = '@BookingProvider/EXPORT_BOOKING_CSV';
export const EXPORT_BOOKING_CSV_SUCCESS = '@BookingProvider/EXPORT_BOOKING_CSV_SUCCESS';
export const EXPORT_BOOKING_CSV_ERROR = '@BookingProvider/EXPORT_BOOKING_CSV_ERROR';

export const FETCH_BOOKING_MESSAGE_LIST = '@BookingProvider/FETCH_BOOKING_MESSAGE_LIST';
export const FETCH_BOOKING_MESSAGE_LIST_SUCCESS = '@BookingProvider/FETCH_BOOKING_MESSAGE_LIST_SUCCESS';

export const FETCH_BOOKING_REVIEW = '@BookingProvider/FETCH_BOOKING_REVIEW';
export const FETCH_BOOKING_REVIEW_SUCCESS = '@BookingProvider/FETCH_BOOKING_REVIEW_SUCCESS';

export const DELETE_BOOKING_REVIEW = '@BookingProvider/DELETE_BOOKING_REVIEW';
export const DELETE_BOOKING_REVIEW_SUCCESS = '@BookingProvider/DELETE_BOOKING_REVIEW_SUCCESS';

export const EDIT_BOOKING_REVIEW = '@BookingProvider/EDIT_BOOKING_REVIEW';
export const EDIT_BOOKING_REVIEW_SUCCESS = '@BookingProvider/EDIT_BOOKING_REVIEW_SUCCESS';

export const FETCH_BOOKING_SCHEDULE = '@BookingProvider/FETCH_BOOKING_SCHEDULE';
export const FETCH_BOOKING_SCHEDULE_SUCCESS = '@BookingProvider/FETCH_BOOKING_SCHEDULE_SUCCESS';

export const OPEN_SCHEDULE_POPUP_LIST = '@BookingProvider/OPEN_SCHEDULE_POPUP_LIST';
export const CLOSE_SCHEDULE_POPUP_LIST = '@BookingProvider/CLOSE_SCHEDULE_POPUP_LIST';

export const FETCH_EXPORT_BOOKING_FILES_LIST = '@BookingProvider/FETCH_EXPORT_BOOKING_FILES_LIST';
export const FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS = '@BookingProvider/FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS';
export const FETCH_EXPORT_BOOKING_FILES_LIST_ERROR = '@BookingProvider/FETCH_EXPORT_BOOKING_FILES_LIST_ERROR';

export const REQUEST_EXPORT_BOOKING_FILE = '@BookingProvider/REQUEST_EXPORT_BOOKING_FILE';
export const REQUEST_EXPORT_BOOKING_FILE_SUCCESS = '@BookingProvider/REQUEST_EXPORT_BOOKING_FILE_SUCCESS';
export const REQUEST_EXPORT_BOOKING_FILE_ERROR = '@BookingProvider/REQUEST_EXPORT_BOOKING_FILE_ERROR';

export const EXPORT_BOOKING_FILE = '@BookingProvider/EXPORT_BOOKING_FILE';
export const EXPORT_BOOKING_FILE_SUCCESS = '@BookingProvider/EXPORT_BOOKING_FILE_SUCCESS';
export const EXPORT_BOOKING_FILE_ERROR = '@BookingProvider/EXPORT_BOOKING_FILE_ERROR';