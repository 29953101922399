import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';

import PageHeader from 'components/PageHeader';
import Profile from './Profile';
import Booking from './Booking';
import FriendInvitation from './FriendInvitation';
import PointDetail from './PointDetail';
import { useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';
import './styles.scss';
import { splitInfor } from 'utils/common.js';

import useTabController from '../hooks/useTabController';
import Coupon from './CouponList/CouponList';

let currentModalControl = null;

export const HIDDEN_PROPS = { style: { display: 'none' } };
const CustomerDetail = ({
  profile,
  unsetCustomerProfile,
  fetchCustomerProfile,
  updateUserStatus,
  friendInvitationLoading,
  friendInvitationList,
  getFriendInvitationList,
  pointInfo,
  fetchCustomerPointInfo,
  fetchCustomerPointInfoSuccess,
  pointHistory,
  fetchCustomerPointHistory,
  fetchCustomerPointHistorySuccess
}) => {
  const { TabPane } = Tabs;
  const routeMatch = useRouteMatch();
  const { customerId } = routeMatch.params;
  const cusInfor = _get(profile, 'info');
  const { activeTab, onChangeTab } = useTabController();

  useEffect(() => {
    (async() => {
      await fetchCustomerProfile(customerId);
    })();

  }, [customerId]);

  useEffect(() => {
    return () => {
      if (currentModalControl) {
        currentModalControl.destroy();
      }
      unsetCustomerProfile();
      fetchCustomerPointInfoSuccess({});
      fetchCustomerPointHistorySuccess({ loading: false, list: [], total: 0 });
    };
  }, []);

  return (
    <div id="customer-detail">
      <Helmet>
        <title>Nailie Dashboard | Customer | Detail</title>
      </Helmet>
      <PageHeader
        title={splitInfor(_get(cusInfor, 'username', '...'))}
        breadcrumb={PageHeader.Bread.customerProfile(cusInfor)}
        goBack={true}
      />
      <Tabs activeKey={activeTab || 'profile'} destroyInactiveTabPane onChange={onChangeTab}>
        <TabPane tab="プロフィール" key="profile">
          <Profile
            fetchCustomerProfile={fetchCustomerProfile}
            updateUserStatus={updateUserStatus}
            profile={profile}
            currentModalControl={currentModalControl}
          />
        </TabPane>
        <TabPane tab="予約" key="booking"><Booking customerId={customerId} /></TabPane>
        <TabPane tab="クーポン" key="coupon"><Coupon customerId={customerId} /></TabPane>
        <TabPane tab="Friend Invitation" key="friendInvitation">
          <FriendInvitation
            userId={customerId}
            friendInvitationLoading={friendInvitationLoading}
            friendInvitationList={friendInvitationList}
            getFriendInvitationList={getFriendInvitationList}
          />
        </TabPane>
        <TabPane tab="Point detail" key="point-detail">
          <PointDetail customerId={customerId} pointInfo={pointInfo} pointHistory={pointHistory}
            fetchCustomerPointInfo={fetchCustomerPointInfo}
            fetchCustomerPointHistory={fetchCustomerPointHistory} />
        </TabPane>
      </Tabs>
    </div>
  );
};

CustomerDetail.propTypes = {
  unsetCustomerProfile: PropTypes.func,
  fetchCustomerProfile: PropTypes.func,
  profile: PropTypes.object,
  updateUserStatus: PropTypes.func,
  getFriendInvitationList: PropTypes.func.isRequired,
  friendInvitationList: PropTypes.array,
  friendInvitationLoading: PropTypes.bool,
  pointInfo: PropTypes.object,
  fetchCustomerPointInfo: PropTypes.func,
  fetchCustomerPointInfoSuccess: PropTypes.func,
  pointHistory: PropTypes.object,
  fetchCustomerPointHistory: PropTypes.func,
  fetchCustomerPointHistorySuccess: PropTypes.func
};

export default CustomerDetail;