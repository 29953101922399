import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { ThunderboltOutlined } from '@ant-design/icons';

const QuickRecalcIncomeButton = ({ nailistId, fetchNailistCurrentBalance, recalculateIncome }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleReloadCurrentRevenue = async() => {
    setIsLoading(true);
    await recalculateIncome({ nailistId });
    await fetchNailistCurrentBalance(nailistId);
    setIsLoading(false);
  };

  return (
    <Button
      type="primary"
      loading={isLoading}
      icon={<ThunderboltOutlined />}
      onClick={handleReloadCurrentRevenue}
    >
      今すぐ再計算
    </Button>
  );
};

QuickRecalcIncomeButton.propTypes = {
  nailistId: PropTypes.string,
  fetchNailistCurrentBalance: PropTypes.func,
  recalculateIncome: PropTypes.func
};

export default QuickRecalcIncomeButton;
