import _ from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';

import BreadcrumbConst from 'components/Breadcrumb/Bread';
import NotFoundPage from 'containers/NotFoundPage';
import LoginPage from 'containers/LoginPage';
import Nailist from 'containers/Nailist';
import Salon from 'containers/Salon';
import ReceiptEmail from 'containers/ReceiptEmail';
import Payout from 'containers/Payout';
import Customer from 'containers/Customer';
import BookingFee from 'containers/SettingPage/BookingFee';
import CampaignInfo from 'containers/CampaignInfo';
import SupportWeb from 'containers/SupportPage/Web';
import SupportSalon from 'containers/SupportPage/Salon';
import RewardPoints from 'containers/RewardPointsPage';
import SupportApp from 'containers/SupportPage/App';
import SupportVerification from 'containers/SupportPage/Verification';
import Report from 'containers/SupportPage/Report';
import DeletedAccount from 'containers/DeletedAccount/DeletedAccountList';
import Booking from 'containers/Booking';
import AdminOperator from 'containers/AdminOperator';
import Announcement from 'containers/AnnouncementPage';
import PopupBanner from 'containers/PopupBannerPage';
import Banner from 'containers/BannerPage';
import Schedule from 'containers/Schedule';
import UnlinkPhonePage from 'containers/UnlinkPhonePage';
import NailistDisabled from 'containers/Nailist/NailistDisabledPage';
// import AnnouncementList from 'containers/AnnouncementPage/AnnouncementList';
// import CreateAnnouncement from 'containers/AnnouncementPage/CreateAnnouncement';

const RedirectToDashboard = () => <Redirect to="/dashboard/nailist" />;

const indexRoutes = [
  { path: '/login', component: LoginPage, requireLogin: false },
  { path: '/change-password', component: NotFoundPage, requireLogin: true },
  {
    path: '/',
    component: RedirectToDashboard,
    requireLogin: true,
    exact: true
  },
  {
    path: '/nailist',
    component: Nailist,
    requireLogin: true
  },
  {
    path: '/nailist-disabled',
    component: NailistDisabled,
    requireLogin: true
  },
  {
    path: '/customer',
    component: Customer,
    requireLogin: true
  },
  {
    path: '/admin-operator',
    component: AdminOperator,
    requireLogin: true
  },
  {
    path: '/salons',
    component: Salon,
    requireLogin: true
  },
  {
    path: '/receipts',
    component: ReceiptEmail,
    requireLogin: true
  },
  {
    path: '/booking-management',
    component: Booking,
    requireLogin: true
  },
  {
    path: '/schedule',
    component: Schedule,
    requireLogin: true
  },
  {
    path: '/payout',
    component: Payout,
    requireLogin: true
  },
  {
    path: '/coupon',
    component: RedirectToDashboard,
    requireLogin: true
  },
  {
    path: '/deleted-account',
    component: DeletedAccount,
    requireLogin: DeletedAccount
  },
  {
    path: '/support/app',
    component: SupportApp,
    requireLogin: true
  },
  {
    path: '/support/web/webContact',
    component: SupportWeb,
    requireLogin: true
  },
  {
    path: '/support/web/salonContact',
    component: SupportSalon,
    requireLogin: true
  },
  {
    path: '/support/verification',
    component: SupportVerification,
    requireLogin: true
  },
  {
    path: '/support/report/:id',
    component: Report,
    requireLogin: true
  },
  {
    path: '/support/report',
    component: Report,
    requireLogin: true
  },
  {
    path: '/support/unlink-phone',
    component: UnlinkPhonePage,
    requireLogin: true
  },
  {
    path: '/support',
    component: RedirectToDashboard,
    requireLogin: true
  },
  {
    path: '/campaign-info',
    component: CampaignInfo,
    requireLogin: true
  },
  {
    path: '/setting/booking-fee',
    component: BookingFee,
    requireLogin: true
  },
  {
    path: '/popup-banner',
    component: PopupBanner,
    requireLogin: true
  },
  {
    path: '/banner',
    component: Banner,
    requireLogin: true
  },
  {
    path: '/announcement',
    component: Announcement,
    requireLogin: true
  },
  {
    path: '/setting',
    component: RedirectToDashboard,
    requireLogin: true
  },
  {
    path: '/reward-points',
    component: RewardPoints,
    requireLogin: true
  },
  { path: '', component: RedirectToDashboard }
];

indexRoutes.map((route) => {
  if (route.breadcrumb) {
    route.breadcrumb = _.get(BreadcrumbConst, route.breadcrumb);
  }
  return route;
});

export default indexRoutes;
