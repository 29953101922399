import moment from 'moment';

export const 
  getPeriodDate = (inputDateTime) => {
    const date = moment(inputDateTime).get('date');
    let startDateTime;
    let EndDateTime;
    if (date < 16) {
      startDateTime = moment(inputDateTime).startOf('month').toDate();
      EndDateTime = moment(startDateTime).set('date', 15).endOf('day').toDate();
    } else {
      startDateTime = moment(inputDateTime).set('date', 16).startOf('day').toDate();
      EndDateTime = moment(startDateTime).endOf('month').toDate();
    }
  
    return [startDateTime, EndDateTime];
  };

export const _sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function convertToArrayOfObjects(data) {
  let keys = data.shift(),
    i = 0, k = 0,
    obj = null,
    output = [];

  for (i = 0; i < data.length; i++) {
    obj = {};

    for (k = 0; k < keys.length; k++) {
      obj[keys[k]] = data[i][k];
    }

    output.push(obj);
  }

  return output;
}

export function defaultPeriodId() {
  const now = moment();
  const lastPeriodDate = now.get('date') >= 16
    ? moment().startOf('M')
    : moment().add(-1, 'M').endOf('M');
  const yy = lastPeriodDate.format('YY');
  const mm = lastPeriodDate.format('MM');
  const defaultPeriod = now.get('date') > 15 ? '0' : '1';
  const defaultSearch = `${yy}${mm}${defaultPeriod}`;
  return defaultSearch;
}

export const addCommaToString = (value) => {
  if (['number', 'string'].indexOf(typeof value) < 0) {
    return '';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getSalonSharedTypeValue = (value) => {
  const REG_A_VALUES = ['通常-A型'];
  const REG_B_VALUES = ['通常-B型'];
  const SHARED_VALUES = ['シェア型'];
  const FSS_VALUES = ['Five Star Studio'];
  if (REG_A_VALUES.includes(value)) {
    return 'REG_A';
  } else if (REG_B_VALUES.includes(value)) {
    return 'REG_B';
  } else if (SHARED_VALUES.includes(value)) {
    return 'SHARED';
  } else if (FSS_VALUES.includes(value)) {
    return 'FIVE_STAR';
  }
};

export const convertJSONToCSV = (objArray, csvStructure) => {
  let headers = Object.keys(csvStructure)
    .map(key => {
      return csvStructure[key];
    })
    .join(',');
  let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let csvStr = headers + '\r\n';

  for (let i = 0; i < array.length; i++) {
    let csvLine = '';
    for (let key in csvStructure) {
      if (csvLine !== '') {
        csvLine += ',';
      }
      csvLine += array[i][key] ? `"${array[i][key]}"` : '';
    }
    csvStr += csvLine + '\r\n';
  }
  return csvStr;
};

export const saveFileCSV = (csvContent, csvFileName) => {
  const exportedFilename = csvFileName;
  const BOM = '\uFEFF';
  csvContent = BOM + csvContent;
  const blob = new Blob([csvContent], {
    type: `text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csvContent)}`
  });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const splitInfor = (value) => {
  if (value) {
    const tempValue = value.split('|');
    const strLength = tempValue?.length;
    const result = tempValue?.length > 1 ? tempValue[strLength - 1] : tempValue[0];
    return result;
  } else {
    return value;
  }
};

export function JSONclone(jsonObj) { return JSON.parse(JSON.stringify(jsonObj)); }