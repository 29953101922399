import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Tooltip } from 'antd';

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const ltMaxLength = !this.props.maxLength ? true : value.length <= this.props.maxLength;
    const reg = /^-?\d*(\.\d*)?$/;
    if (ltMaxLength && ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')) {
      this.props.input.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props.input;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { meta: { touched, invalid, error }, label, placeholder, allowClear, input, required } = this.props;
    const { value } = input;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      '番号'
    );

    return (
      <Form.Item
        label={label}
        help={touched && invalid && error}
        validateStatus={touched && invalid && error && 'error'}
        required={required}
      >
        <Tooltip
          trigger={['focus']}
          title={title}
          placement="topLeft"
          overlayClassName="numeric-input"
        >
          <Input
            {...this.props}
            value={value}
            onChange={this.onChange}
            placeholder={placeholder}
            allowClear={allowClear}
            onBlur={this.onBlur}
            maxLength={this.props.maxLength}
          />
        </Tooltip>
      </Form.Item>
    );
  }
}

NumericInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  suffix: PropTypes.any,
  allowClear: PropTypes.any,
  maxLength: PropTypes.number
};

export default NumericInput;
