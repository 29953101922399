import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'querystring';
import localstore from 'utils/localstore';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import _ from 'lodash';

import { Table, Card, Space, Button, Typography } from 'antd';

import PageHeader from 'components/PageHeader';
import CreateSalonPopup from './../CreateSalonPopup';
import CreateMultiSalonPopup from './../CreateMultiSalonPopup';
import columnsTemplate from '../Preset/columns';

import './styles.scss';
import FilterForm from '../Filter/FilterForm';
import { SALON_SHARED_TYPES } from 'utils/constants';
const { Text } = Typography;

class SalonSearchPage extends React.PureComponent {
  static defaultOrderBy = 'objectId';
  static defaultOrder = 'descend';
  static limit = 10;

  onSubmit = ({ ...values }) => {
    const queryString = qs.stringify(values);
    this.props.history.push({
      pathname: window.location.pathname,
      search: `${queryString}`
    });
  };

  updateSearchToState = () => {
    let { pathname, replace, search } = this.props;

    if (!_.has(search, 'orderBy') || !_.has(search, 'order')) {
      const localNailistMeta = localstore.get('salonList') || {};
      search.orderBy =
        localNailistMeta.orderBy || SalonSearchPage.defaultOrderBy;
      search.order = localNailistMeta.order || SalonSearchPage.defaultOrder;

      replace(`${pathname}?${qs.stringify(search)}`);
      return;
    } else {
      localstore.set('salonList', {
        orderBy: search.orderBy,
        order: search.order
      });
    }
  
    if (_.isEmpty(search.keyword)) {
      delete search.keyword;
    };
    
    if (_.has(search, 'date-range')) {
      search['date-range'] = search['date-range'].map(
        (element) => element && moment(element)
      );
    }

    this.props.fetchSalonList({...search, limit: SalonSearchPage.limit});
  };

  onTableChange = (pagination, filters, sorter) => {
    const { pathname, push, search } = this.props;
    const orderBy = sorter.field;
    const order = sorter.order;
    const parsed = {
      ...search,
      page: pagination.current,
      orderBy,
      order
    };

    push(`${pathname}?${qs.stringify(parsed)}`);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { list, search, total, loading } = this.props;
    const currentPage = _.has(search, 'page')
      ? Number(_.get(search, 'page'))
      : 1;

    const columns = [
      {...columnsTemplate.id, fixed: 'left'},
      columnsTemplate.createdAt,
      columnsTemplate.name,
      {
        ...columnsTemplate.salonSharedType,
        render: (record) => (
          <Text>{SALON_SHARED_TYPES[record]?.shortenedText || '通常A'}</Text>
        )
      },
      columnsTemplate.phone,
      columnsTemplate.email,
      {
        title: '',
        key: 'Action',
        width: 120,
        fixed: 'right',
        render: (record) => (
          <Link to={`/dashboard/salons/list/profile/${_.get(record, 'id')}`}>
            <Button>View</Button>
          </Link>
        )
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Nailie Dashboard | Salon | Search</title>
        </Helmet>
        <PageHeader
          title="Salon"
          breadcrumb={PageHeader.Bread.salon}
        />
        <div className="page-container" id="salon-page-search">
          <Card>
            <div className="search-section">
              <FilterForm
                filters={['keyword']}
                onSubmit={this.onSubmit}
                initialValues={_.omit(search, ['page', 'order', 'orderBy'])}
              />
              <Space>
                <Button onClick={this.props.openCreateMultiSalonForm}>CSVでサロンアカウント作成</Button>
                <Button type="primary" onClick={this.props.openCreateSalonForm}>サロンアカウント作成</Button>
              </Space>
            </div>
            <Table
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={this.onTableChange}
              rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row') }
              pagination={{
                total: total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 10,
                current: currentPage,
                showSizeChanger: false
              }}
              loading={loading}
            />
          </Card>
        </div>

        <CreateSalonPopup/>
        <CreateMultiSalonPopup/>
      </Fragment>
    );
  }
}

SalonSearchPage.propTypes = {
  fetchSalonList: PropTypes.func.isRequired,
  openCreateSalonForm: PropTypes.func,
  list: PropTypes.array,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  loading: PropTypes.bool,
  total: PropTypes.number
};

export default SalonSearchPage;
