import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import renderHTML from 'react-render-html';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Card, Button, Modal } from 'antd';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import { getSupportForAppAnswered, deleteSupportTicket } from 'providers/SupportProvider/actions';
import {
  makeAppSupportAnsweredTickets,
  makeAppSupportAnsweredLoading,
  makeAppAnsweredFilter,
  makeDeleteAnsweredTicket
} from 'providers/SupportProvider/selectors';

import columnsTemplate from './Preset/columns';
import FilterForm from './Filter/AnsweredFilterForm';

const { confirm } = Modal;

const AnsweredTickets = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const data = useSelector(makeAppSupportAnsweredTickets);
  const loading = useSelector(makeAppSupportAnsweredLoading);
  const deletedTicket = useSelector(makeDeleteAnsweredTicket);
  const filterForm = useSelector(makeAppAnsweredFilter);

  const [search, setSearch] = useState({
    page: Number(data.page) || 1,
    limit: Number(data.limit) || 5,
    dateFrom: _get(data, 'dateFrom'),
    dateTo: _get(data, 'dateTo') || moment().endOf('month').format('YYYY-MM-DD'),
    orderBy: _get(data, 'sortField') || '_created_at',
    order: _get(data, 'direction') || 'descend'
  });
  const [currentPage, setCurrentPage] = useState(
    _has(data, 'page') ? Number(_get(data, 'page')) : 1);
  
  useEffect(() => {
    dispatch(getSupportForAppAnswered({
      ...search,
      supportType: 'APP'
    }));
  }, []);
  
  useEffect(() => {
    if (filterForm.dateFrom && filterForm.dateTo) {
      filterForm["date-range"] = [
        moment(filterForm.dateFrom),
        moment(filterForm.dateTo)
      ];
    }
    setSearch({
      ...search,
      ...filterForm
    })
  }, [filterForm]);

  useEffect(() => {
    if (deletedTicket) {
      dispatch(getSupportForAppAnswered({
        ...search,
        page: 1,
        supportType: 'APP'
      }));
    }
  }, [deletedTicket]);

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (record) => (<p className="question-field">{renderHTML(record)}</p>),
      width: 280,
      sorter: true
    },
    columnsTemplate.username,
    columnsTemplate.name,
    columnsTemplate.phone,
    columnsTemplate.email,
    columnsTemplate.createdAt,
    {
      title: '',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            icon={<EditOutlined />}
            theme="filled"
            onClick={() => {
              handleEdit({
                title: 'Edit question',
                ticket: record,
                search: {...search, supportType: 'APP'}
              })
            }}
          />
          <Button
            type="primary"
            danger
            theme="filled"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record._id)}
          />
        </div>
      )
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...search,
      page: pagination.current,
      limit: pagination.pageSize,
      orderBy: sorter.field,
      order: sorter.order
    };
    setCurrentPage(Number(pagination.current));
    dispatch(getSupportForAppAnswered({
      ...parsed,
      supportType: 'APP'
    }));
  };

  const showDeleteConfirm = (objectId) => {
    confirm({
      title: 'Are you sure ?',
      icon: <ExclamationCircleOutlined />,
      content: 'This question will be deleted permanently and cannot be recovered. Are you sure?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteSupportTicket({ objectId: objectId, type: 'APP', isAnswered: true, filter: search }))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const filterData = (params) => {
    let filterData = {
      ...search,
      ...params,
      page: 1
    }
    if (!_get(params, 'date-range')) {
      filterData = _omit(filterData, ['date-range', 'dataFrom', 'dataTo']);
    }
    if (_isEmpty(_get(params, 'keyword'))) {
      filterData = _omit(filterData, ['keyword']);
    }
    setSearch(filterData);
    setCurrentPage(1);
    dispatch(getSupportForAppAnswered({ ...filterData, supportType: 'APP' }));
  }

  return (
    <Card bordered={false} className="styled-transparent support-for-salon-block">
        <Card bordered={false} title="Answered Question">
          <div className="search-section">
            <FilterForm
              filters={['keyword', 'date-range']}
              placeholder='FullName, Telephone, Email'
              onSubmit={filterData}
              initialValues={search}
            />
          </div>
        </Card>
        <Card bordered={false}>
          <Table
            columns={columns}
            dataSource={data.list}
            expandable={{
              expandedRowRender: record => <div className="reply-field">
                <p><b>Subject:</b>{record.subject}</p>
                <p>
                  <b>Answer:</b>
                  <p>{renderHTML(record.answer)}</p>
                </p>
              </div>,
              rowExpandable: record => record.fullName !== 'Not Expandable',
            }}
            scroll={{ x: 1000 }}
            onChange={onTableChange}
            rowClassName={(record) => (record.isRead ? 'data-row active-row' : 'data-row') }
            pagination={{
              total: data.total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: search.limit,
              current: currentPage,
              showSizeChanger: false
            }}
            loading={loading}
          />
        </Card>
      </Card>
  )
}

export default AnsweredTickets;
