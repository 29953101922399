import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Col, Row, Select, Space, Switch, Button, Input, InputNumber, Tooltip } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useDispatch } from 'react-redux';

import { fetchNailistProfile, updateNailistInfo } from 'providers/NailistProvider/actions';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import moment from 'moment';

const WHITE_SPACE_ERROR = { pattern: /^[^\s]+(\s+[^\s]+)*$/, message: 'invalid format' };
const FURIGANA_ERROR = { pattern: /^[ぁ-ん]+(\s+[ぁ-ん]+)*$/, message: 'ひらがなで入力してください' };

const USERNAME_ERROR = {
  pattern: /^[a-zA-Z0-9_.]{0,30}$/,
  message: 'more than 1 and less than 30 characters, only alphabet, number, . and _'
};

const UpdateNailistInfoForm = ({ initialValues, submit, isLoginInfoOnly = false }) => {
  const [form] = Form.useForm();

  return <Form
    // style={{ width: 800, margin: 'auto' }}
    form={ form }
    initialValues={initialValues}
    onFinish={(values) => {
      form.validateFields();
      submit(values);
    }}
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 12 }}
  >
    <Form.Item label='ユーザーネーム' name='username' rules={[{ required: true }, USERNAME_ERROR]}>
      <Input maxLength={30}/>
    </Form.Item>

    <Form.Item label='パスワード' name='password'>
      <Input.Password placeholder='●●●●●●●●●●'/>
    </Form.Item>
    <Form.Item label='パスワードの確認' name='passwordConfirm' hasFeedback
      rules={[
        ({ getFieldValue }) => ({
          validator(rule, value) {
            const password = getFieldValue('password');
            if ((!value && !password) || password === value) {
              return Promise.resolve();
            }
            return Promise.reject('The two passwords that you entered do not match!');
          }
        })
      ]}
    >
      <Input.Password placeholder='●●●●●●●●●●'/>
    </Form.Item>

    <Tooltip title="New email will need to be verified!" >
      <Form.Item label='メールアドレス' name='email' rules={[{ type: 'email' }, { required: true }]}>
        <Input maxLength={255}/>
      </Form.Item>
    </Tooltip>

    {isLoginInfoOnly === true ? null : <>
      <Form.Item label='姓名' name='fullName' rules={[{ required: true }, WHITE_SPACE_ERROR]}>
        <Input maxLength={30}/>
      </Form.Item>

      <Form.Item label='ふりがな' name='phonetic' rules={[{ required: true }, FURIGANA_ERROR]}>
        <Input maxLength={30}/>
      </Form.Item>

      {/* <Form.Item label='電話番号' name='phone' rules={[{ pattern: /^(\d{10}|\d{11})$/, message: 'invalid phone number' }, { required: true }]}>
        <Input/>
      </Form.Item> */}

      <Form.Item label='誕生日' name='birthday'
        rules={[
          () => ({ validator: (rule, value) => {
            if (!value || value == '____/__/__') {
              return Promise.resolve(true);
            }

            const valueInMoment = moment(value, 'YYYY/MM/DD', true);
            if (!valueInMoment.isValid()) {
              return Promise.reject(new Error('invalid date'));
            }
            const age = moment().startOf('D').diff(valueInMoment, 'years', true);
            if (age < 16) {
              return Promise.reject(new Error('nailist must be 16+'));
            }
            return Promise.resolve(true);
          }})
        ]}
      >
        <MaskedInput mask={'0000/00/00'} placeholder='YYYY/MM/DD'/>
      </Form.Item>

      <Form.Item label='性別' name='gender'>
        <Select>
          <Select.Option value={1}>FEMALE</Select.Option>
          <Select.Option value={2}>MALE</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label='Premium' name='isMark' valuePropName="checked">
        <Switch/>
      </Form.Item>
    </>}

    <Space align='center' direction='vertical' style={{width: '100%'}}>
      <Form.Item>
        <Button type="primary" htmlType="submit">保存</Button>
      </Form.Item>
    </Space>
  </Form>;
};

UpdateNailistInfoForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func,
  isLoginInfoOnly: PropTypes.bool
};

// Business hours = working time = weekday
const useEditNailistInfo = (data = {}, nailistId, isLoginInfoOnly = false) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    if (_isEmpty(data)) {
      return null;
    }

    return {
      username: data.username,
      fullName: data.fullName,
      phonetic: data.phonetic,
      email: data.email,
      // phone: data.phone,
      birthday: data.birthday ? moment(data.birthday).format('YYYY/MM/DD') : undefined,
      gender: data.gender,
      isMark: data.isMark
    };
  }, [data]);

  const submit = async (values) => {
    const difference = Object.keys(values).filter(k => values[k] !== initialValues[k]);

    await dispatch(updateNailistInfo({userId: nailistId, ..._pick(values, difference) }));
    setIsOpen(false);
    dispatch(fetchNailistProfile({id: nailistId}));
  };
  
  const Popup = <Modal
    title='ユーザー情報を編集'
    visible={isOpen}
    destroyOnClose
    footer={null}
    onCancel={() => setIsOpen(false)}
    width={980}
  >
    {initialValues && <UpdateNailistInfoForm initialValues={initialValues} submit={submit} isLoginInfoOnly={isLoginInfoOnly}/>}
  </Modal>;

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default useEditNailistInfo;