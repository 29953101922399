import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BannerList from './BannerList';
import CreateBanner from './CreateBanner';
import EditBanner from './EditBanner';

const BANNER_ROOT = '/dashboard/banner';

const RedirectToBannerList = () => (
  <Redirect to={BANNER_ROOT} />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | App Banner</title>
      </Helmet>
      <Switch>
        <Route
          path={BANNER_ROOT}
          exact
          component={BannerList}
        />
        <Route
          path={BANNER_ROOT + '/create'}
          exact
          component={CreateBanner}
        />
        <Route
          path={BANNER_ROOT + '/edit/:objectId'}
          exact
          component={EditBanner}
        />
        <Route path={BANNER_ROOT} component={RedirectToBannerList} />
      </Switch>
    </>
  );
};

export default Router;
