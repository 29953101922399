const UtilValidate = {};

UtilValidate.validUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }

  const pattern = new RegExp('^(https?:\\/\\/)' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return pattern.test(url);
};

UtilValidate.validEmail = (email) => {
  if (typeof email !== 'string') {
    return false;
  }
  const pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
  return pattern.test(email);
};

UtilValidate.isPositiveInteger = (n) => {
  return n >>> 0 === parseFloat(n);
};

UtilValidate.validCharKatakana = (string) => {
  const pattern = /^[\u30a0-\u30ff\uff65-\uff9f]+$/;
  return pattern.test(string);
};

export default UtilValidate;
