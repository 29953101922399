import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SearchCustomerPage from './CustomerSearchList';
import CustomerDetail from './CustomerDetail';

const CUSTOMER_ROOT = '/dashboard/customer';

const RedirectToCustomerList = () => (
  <Redirect to={CUSTOMER_ROOT} />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Customer</title>
      </Helmet>
      <Switch>
        <Route
          path={CUSTOMER_ROOT}
          exact
          component={SearchCustomerPage}
        />
        <Route
          path='/dashboard/customer/profile/:customerId/:activeTab?'
          exact
          component={CustomerDetail}
        />
        <Route path="/dashboard/customer" component={RedirectToCustomerList} />
      </Switch>
    </>
  );
};

export default Router;
