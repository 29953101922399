import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd';

import {
  countUnreadAppTickets,
  countUnreadWebTickets,
  countUnreadSalonTickets,
  countUnreadVerificationTickets
} from 'providers/SupportProvider/actions';
import {
  makeCountUnreadAppTickets,
  makeCountUnreadWebTickets,
  makeCountUnreadSalonTickets,
  makeCountUnreadVerificationTickets
} from 'providers/SupportProvider/selectors';

const CountTickets = ({ text }) => {
  const dispatch = useDispatch();
  const countApp = useSelector(makeCountUnreadAppTickets);
  const countWeb = useSelector(makeCountUnreadWebTickets);
  const countSalon = useSelector(makeCountUnreadSalonTickets);
  const countVerification = useSelector(makeCountUnreadVerificationTickets);
  const count = countApp + countWeb + countSalon + countVerification;

  useEffect(() => {
    dispatch(countUnreadAppTickets());
    dispatch(countUnreadWebTickets());
    dispatch(countUnreadSalonTickets());
    dispatch(countUnreadVerificationTickets());
  }, []);
  
  return count > 0 ? (
    <Badge count={count} style={{right: -16}} showZero>
      <div style={{width: '130%'}}>{text}</div>
    </Badge>
  ) : <>{text}</>
}

export default CountTickets;
