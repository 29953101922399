import {
  FETCH_PAYOUT_DETAIL_BOOKING_LIST,
  FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS,
  FETCH_RECEIPT_BOOKING_DETAIL,
  FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS,
  RESET_BOOKING_PROVIDER,
  FETCH_BOOKING_LIST,
  FETCH_BOOKING_LIST_SUCCESS,
  FETCH_BOOKING_DETAIL,
  FETCH_BOOKING_DETAIL_SUCCESS,
  ON_ERROR,
  CHANGE_BOOKING_STATUS,
  CHANGE_BOOKING_STATUS_SUCCESS,
  CHANGE_BOOKING_STATUS_FAIL,
  UPDATE_CHANGED_BOOKING_HISTORY_NOTE,
  UPDATE_CHANGED_BOOKING_HISTORY_NOTE_SUCCESS,
  UPDATE_CHANGED_BOOKING_HISTORY_NOTE_FAIL,
  FETCH_CARD_ERROR_BOOKING_LIST,
  FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS,
  FETCH_CARD_ERROR_BOOKING_LIST_ERROR,
  COMPLETE_BOOKING,
  COMPLETE_BOOKING_SUCCESS,
  COMPLETE_BOOKING_ERROR,
  NOTIFY_ERROR_BOOKING,
  NOTIFY_ERROR_BOOKING_SUCCESS,
  NOTIFY_ERROR_BOOKING_ERROR,
  EXPORT_BOOKING_CSV,
  EXPORT_BOOKING_CSV_SUCCESS,
  EXPORT_BOOKING_CSV_ERROR,
  FETCH_BOOKING_MESSAGE_LIST,
  FETCH_BOOKING_MESSAGE_LIST_SUCCESS,
  FETCH_BOOKING_REVIEW,
  FETCH_BOOKING_REVIEW_SUCCESS,
  DELETE_BOOKING_REVIEW,
  DELETE_BOOKING_REVIEW_SUCCESS,
  EDIT_BOOKING_REVIEW,
  EDIT_BOOKING_REVIEW_SUCCESS,
  FETCH_BOOKING_SCHEDULE,
  FETCH_BOOKING_SCHEDULE_SUCCESS,
  OPEN_SCHEDULE_POPUP_LIST,
  CLOSE_SCHEDULE_POPUP_LIST,
  EXPORT_BOOKING_FILE,
  EXPORT_BOOKING_FILE_ERROR,
  EXPORT_BOOKING_FILE_SUCCESS,
  FETCH_EXPORT_BOOKING_FILES_LIST,
  FETCH_EXPORT_BOOKING_FILES_LIST_ERROR,
  FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS,
  REQUEST_EXPORT_BOOKING_FILE,
  REQUEST_EXPORT_BOOKING_FILE_ERROR,
  REQUEST_EXPORT_BOOKING_FILE_SUCCESS,
} from './constants';

export function fetchPayoutDetailBookingList(payload = {}) {
  return { type: FETCH_PAYOUT_DETAIL_BOOKING_LIST, payload };
}

export function fetchPayoutDetailBookingListSuccess(payload = {}) {
  return { type: FETCH_PAYOUT_DETAIL_BOOKING_LIST_SUCCESS, payload };
}

export function fetchReceiptBookingDetail(payload = {}) {
  return { type: FETCH_RECEIPT_BOOKING_DETAIL, payload };
}

export function fetchReceiptBookingDetailSuccess(payload = {}) {
  return { type: FETCH_RECEIPT_BOOKING_DETAIL_SUCCESS, payload };
}

export function resetBookingProvider(payload = {}) {
  return { type: RESET_BOOKING_PROVIDER, payload };
}

export function fetchBookingList(payload = {}) {
  return { type: FETCH_BOOKING_LIST, payload };
}

export function fetchBookingListSuccess(payload = {}) {
  return { type: FETCH_BOOKING_LIST_SUCCESS, payload };
}

export function fetchBookingDetail(payload = {}) {
  return {
    type: FETCH_BOOKING_DETAIL,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function fetchBookingDetailSuccess(payload = {}, meta) {
  return { type: FETCH_BOOKING_DETAIL_SUCCESS, payload, meta };
}

export function onError() {
  return { type: ON_ERROR };
}


export function changeBookingStatus(payload = {}) {
  return {
    type: CHANGE_BOOKING_STATUS,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function changeBookingStatusSuccess(payload = {}, meta) {
  return { type: CHANGE_BOOKING_STATUS_SUCCESS, payload, meta };
}

export function changeBookingStatusFail(payload = {}, meta) {
  return { type: CHANGE_BOOKING_STATUS_FAIL, payload, meta, error: true };
}


export function updateBookingHistoryNote(payload = {}) {
  return {
    type: UPDATE_CHANGED_BOOKING_HISTORY_NOTE,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function updateBookingHistoryNoteSuccess(payload = {}, meta) {
  return { type: UPDATE_CHANGED_BOOKING_HISTORY_NOTE_SUCCESS, payload, meta };
}

export function updateBookingHistoryNoteFail(payload = {}, meta) {
  return { type: UPDATE_CHANGED_BOOKING_HISTORY_NOTE_FAIL, payload, meta, error: true };
}

export const fetchCardErrorBookingList = (payload = {}) => ({
  type: FETCH_CARD_ERROR_BOOKING_LIST,
  payload
});

export const fetchCardErrorBookingListSuccess = (payload = {}) => ({
  type: FETCH_CARD_ERROR_BOOKING_LIST_SUCCESS,
  payload
});

export const fetchCardErrorBookingListError = (payload = {}) => ({
  type: FETCH_CARD_ERROR_BOOKING_LIST_ERROR,
  payload
});

export const completeBooking = (payload = {}) => ({
  type: COMPLETE_BOOKING,
  payload,
  meta: {
    thunk: true
  }
});

export const completeBookingSuccess = (payload = {}, meta) => ({
  type: COMPLETE_BOOKING_SUCCESS,
  payload,
  meta
});

export const completeBookingError = (payload = {}, meta) => ({
  type: COMPLETE_BOOKING_ERROR,
  payload,
  meta,
  error: true
});

export const notifyErrorBooking = (payload = {}) => ({
  type: NOTIFY_ERROR_BOOKING,
  payload,
  meta: {
    thunk: true
  }
});

export const notifyErrorBookingSuccess = (payload = {}, meta) => ({
  type: NOTIFY_ERROR_BOOKING_SUCCESS,
  payload,
  meta
});

export const notifyErrorBookingError = (payload = {}, meta) => ({
  type: NOTIFY_ERROR_BOOKING_ERROR,
  payload,
  meta,
  error: true
});

export const exportBookingCSV = (payload = {}) => ({
  type: EXPORT_BOOKING_CSV,
  payload
});

export const exportBookingCSVSuccess = (payload = {}) => ({
  type: EXPORT_BOOKING_CSV_SUCCESS,
  payload
});

export const exportBookingCSVError = (payload = {}) => ({
  type: EXPORT_BOOKING_CSV_ERROR,
  payload
});

export function fetchBookingMessageList(payload = {}) {
  return { type: FETCH_BOOKING_MESSAGE_LIST, payload, meta: { thunk: true } };
}

export function fetchBookingMessageListSuccess(payload = {}, meta) {
  return { type: FETCH_BOOKING_MESSAGE_LIST_SUCCESS, payload, meta };
}

export function fetchBookingReview(payload = {}) {
  return { type: FETCH_BOOKING_REVIEW, payload, meta: { thunk: true } };
}

export function fetchBookingReviewSuccess(payload = {}, meta) {
  return { type: FETCH_BOOKING_REVIEW_SUCCESS, payload, meta };
}

export function deleteBookingReview(payload = {}) {
  return { type: DELETE_BOOKING_REVIEW, payload, meta: { thunk: true } };
}

export function deleteBookingReviewSuccess(payload = {}, meta) {
  return { type: DELETE_BOOKING_REVIEW_SUCCESS, payload, meta };
}

export function editBookingReview(payload = {}) {
  return { type: EDIT_BOOKING_REVIEW, payload, meta: { thunk: true } };
}

export function editBookingReviewSuccess(payload = {}, meta) {
  return { type: EDIT_BOOKING_REVIEW_SUCCESS, payload, meta };
}

export function fetchBookingSchedule(payload = {}) {
  return { type: FETCH_BOOKING_SCHEDULE, payload, meta: { thunk: true } };
}

export function fetchBookingScheduleSuccess(payload = {}, meta) {
  return { type: FETCH_BOOKING_SCHEDULE_SUCCESS, payload, meta };
}

export function openSchedulePopup(payload = {}) {
  return { type: OPEN_SCHEDULE_POPUP_LIST, payload };
}
export function closeSchedulePopup() {
  return { type: CLOSE_SCHEDULE_POPUP_LIST };
}

export function requestBookingFile(payload = {}) {
  return { type: REQUEST_EXPORT_BOOKING_FILE, payload, meta: { thunk: true } };
}
export function requestBookingFileSuccess(payload = {}, meta) {
  return { type: REQUEST_EXPORT_BOOKING_FILE_SUCCESS, payload, meta };
}
export function requestBookingFileFail(payload = {}, meta) {
  return {
    type: REQUEST_EXPORT_BOOKING_FILE_ERROR,
    payload,
    meta,
    error: true
  };
}

export function fetchExportBookingFilesList(payload = {}) {
  return { type: FETCH_EXPORT_BOOKING_FILES_LIST, payload, meta: { thunk: true } };
}
export function fetchExportBookingFilesListSuccess(payload = {}, meta) {
  return { type: FETCH_EXPORT_BOOKING_FILES_LIST_SUCCESS, payload, meta };
}
export function fetchExportBookingFilesListFail(payload = {}, meta) {
  return {
    type: FETCH_EXPORT_BOOKING_FILES_LIST_ERROR,
    payload,
    meta,
    error: true
  };
}