import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'components/PageHeader';

import AnnouncementForm from '../_Component/AnnouncementForm';


const CreateAnnouncement = () => {
  return (
    <div id="create-announcement">
      <Helmet>
        <title>Nailie Dashboard | Create Announcement</title>
      </Helmet>
      <PageHeader
        title="Create Announcement"
        breadcrumb={PageHeader.Bread.createAnnouncement}
        goBack={true}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <AnnouncementForm/>
      </div>
    </div>
  );
};

export default CreateAnnouncement;