import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, Popconfirm } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { verifyCustomerEmail } from 'providers/CustomerProvider/actions';

const ICON_STYLE = { alignSelf: 'center', marginLeft: 4 };

const VerifyEmailButton = ({ userId, emailVerified = false }) => {
  const dispatch = useDispatch();

  if (emailVerified === true) {
    return <Tooltip placement="topLeft" title="Verified!" color="green">
      <CheckCircleTwoTone twoToneColor="#52c41a" style={ICON_STYLE} />
    </Tooltip>;
  }

  return (
    <Popconfirm
      placement="bottomRight"
      title="Are you sure about verifying Email for this account?"
      onConfirm={() => dispatch(verifyCustomerEmail({ userId }))}
    >
      <WarningTwoTone twoToneColor="#E4A11B" style={ICON_STYLE} />
    </Popconfirm>
  );
};

VerifyEmailButton.propTypes = {
  userId: PropTypes.string,
  emailVerified: PropTypes.bool
};

export default VerifyEmailButton;