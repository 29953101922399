import _ from 'lodash';
import { message } from 'antd';
import { put, call, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import {
  getFriendInvitationListSuccess,
  exportFriendInvitationListSuccess,
  getGoToNailInfoSuccess,
  getGoToNailListSuccess,
  exportGoToNailListSuccess,
  updateGoToNailSuccess,
  getOlympicInfoSuccess,
  getOlympicListSuccess,
  exportOlympicListSuccess,
  updateOlympicSuccess,
  getGoToNailInfo2ndSuccess,
  getGoToNailList2ndSuccess,
  exportGoToNailList2ndSuccess,
  updateGoToNail2ndSuccess,
  exportGoToNailList3rdSuccess,
  getGoToNailList3rdSuccess,
  getGoToNailInfo3rdSuccess,
  updateGoToNail3rdSuccess,
  getInstagramCouponInforSuccess,
  getInstagramCouponListSuccess,
  exportInstagramCouponSuccess,
  updateInstagramCouponSuccess
} from './actions';
import {
  GET_FRIEND_INVITATION_LIST,
  EXPORT_FRIEND_INVITATION_LIST,
  GET_GO_TO_NAIL_INFO,
  GET_GO_TO_NAIL_LIST,
  EXPORT_GO_TO_NAIL_LIST,
  UPDATE_GO_TO_NAIL,
  GET_OLYMPIC_INFO,
  GET_OLYMPIC_LIST,
  EXPORT_OLYMPIC_LIST,
  UPDATE_OLYMPIC,
  GET_GO_TO_NAIL_INFO_2ND,
  GET_GO_TO_NAIL_LIST_2ND,
  EXPORT_GO_TO_NAIL_LIST_2ND,
  UPDATE_GO_TO_NAIL_2ND,
  UPDATE_GO_TO_NAIL_3RD,
  EXPORT_GO_TO_NAIL_LIST_3RD,
  GET_GO_TO_NAIL_LIST_3RD,
  GET_GO_TO_NAIL_INFO_3RD,
  GET_INSTAGRAM_COUPON_INFOR,
  GET_INSTAGRAM_COUPON_LIST,
  EXPORT_INSTAGRAM_COUPON,
  UPDATE_INSTAGRAM_COUPON
} from './constants';

function* handleGetInvitationList(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/friend-invitation', params, 'GET');
    yield put(getFriendInvitationListSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function saveFileCSV(data, name) {
  let a = document.createElement('a');
  a.href = 'data:text/csv;base64,' + data;
  a.download = name || 'data.csv';
  a.hidden = true;
  document.body.appendChild(a);
  a.innerHTML = '';
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 500);
}

function* handleExportInvitationList(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/friend-invitation', params, 'GET');
    saveFileCSV(data, `invitation_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportFriendInvitationListSuccess());
  } catch (error) {
    yield put(exportFriendInvitationListSuccess());
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleExportGoToNailList(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/GOTONAIL/list', params, 'GET');
    saveFileCSV(data, `go_to_nail_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportGoToNailListSuccess());
  } catch (error) {
    yield put(exportGoToNailListSuccess());
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleExportOlympicList(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/OLYMPIC/list', params, 'GET');
    saveFileCSV(data, `olympic_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportOlympicListSuccess());
  } catch (error) {
    yield put(exportOlympicListSuccess());
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailList(action) {
  try {
    const params = _.omit(action.payload, ['orderBy', 'order']);
    const orderBy = _.get(action.payload, 'orderBy');
    const order = _.get(action.payload, 'order');
    if (orderBy && order) {
      params.sortField = orderBy; 
      params.direction = order === 'ascend' ? 'asc' : 'desc';
    }
    const { data } = yield call(request, 'campaign/GOTONAIL/list', params, 'GET');
    yield put(getGoToNailListSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailInfo(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL/info', params, 'GET');
    yield put(getGoToNailInfoSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleUpdateGoToNail(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL/update', params, 'POST');
    yield put(updateGoToNailSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateGoToNailSuccess({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetOlympicList(action) {
  try {
    const params = _.omit(action.payload, ['orderBy', 'order']);
    const orderBy = _.get(action.payload, 'orderBy');
    const order = _.get(action.payload, 'order');
    if (orderBy && order) {
      params.sortField = orderBy; 
      params.direction = order === 'ascend' ? 'asc' : 'desc';
    }
    const { data } = yield call(request, 'campaign/OLYMPIC/list', params, 'GET');
    yield put(getOlympicListSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetOlympicInfo(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/OLYMPIC/info', params, 'GET');
    yield put(getOlympicInfoSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleUpdateOlympic(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/OLYMPIC/update', params, 'POST');
    yield put(updateOlympicSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateOlympicSuccess({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleExportGoToNailList2nd(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/GOTONAIL2/list', params, 'GET');
    saveFileCSV(data, `go_to_nail_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportGoToNailListSuccess());
  } catch (error) {
    yield put(exportGoToNailList2ndSuccess());
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailList2nd(action) {
  try {
    const params = _.omit(action.payload, ['orderBy', 'order']);
    const orderBy = _.get(action.payload, 'orderBy');
    const order = _.get(action.payload, 'order');
    if (orderBy && order) {
      params.sortField = orderBy; 
      params.direction = order === 'ascend' ? 'asc' : 'desc';
    }
    const { data } = yield call(request, 'campaign/GOTONAIL2/list', params, 'GET');
    yield put(getGoToNailList2ndSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailInfo2nd(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL2/info', params, 'GET');
    yield put(getGoToNailInfo2ndSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleUpdateGoToNail2nd(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL2/update', params, 'POST');
    yield put(updateGoToNailSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateGoToNail2ndSuccess({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleExportGoToNailList3rd(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/GOTONAIL3/list', params, 'GET');
    saveFileCSV(data, `go_to_nail_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportGoToNailListSuccess());
  } catch (error) {
    yield put(exportGoToNailList3rdSuccess);
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailList3rd(action) {
  try {
    const params = _.omit(action.payload, ['orderBy', 'order']);
    const orderBy = _.get(action.payload, 'orderBy');
    const order = _.get(action.payload, 'order');
    if (orderBy && order) {
      params.sortField = orderBy;
      params.direction = order === 'ascend' ? 'asc' : 'desc';
    }
    const { data } = yield call(request, 'campaign/GOTONAIL3/list', params, 'GET');
    yield put(getGoToNailList3rdSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetGoToNailInfo3rd(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL3/info', params, 'GET');
    yield put(getGoToNailInfo3rdSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleUpdateGoToNail3rd(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/GOTONAIL3/update', params, 'POST');
    yield put(updateGoToNailSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateGoToNail3rdSuccess({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}
function* handleGetInstagramCouponInfor(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/InstagramCampaign/info', params, 'GET');
    yield put(getInstagramCouponInforSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

function* handleGetInstagramCouponList(action) {
  try {
    const params = _.omit(action.payload, ['orderBy', 'order']);
    const orderBy = _.get(action.payload, 'orderBy');
    const order = _.get(action.payload, 'order');
    if (orderBy && order) {
      params.sortField = orderBy;
      params.direction = order === 'ascend' ? 'asc' : 'desc';
    }
    const { data } = yield call(request, 'campaign/InstagramCampaign/list', params, 'GET');
    yield put(getInstagramCouponListSuccess({ data }));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}
function* handleExportInstagramCouponList(action) {
  try {
    const params = { ...action.payload, forCSV: true };
    const data = yield call(request, 'campaign/InstagramCampaign/list', params, 'GET');
    saveFileCSV(data, `instagram_campaign_statistic_${params.dateFrom}_${params.dateTo}.csv`);
    yield put(exportInstagramCouponSuccess());
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}
function* handleUpdateInstagramCampaign(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'campaign/InstagramCampaign/update', params, 'POST');
    yield put(updateInstagramCouponSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateInstagramCouponSuccess({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong');
    console.log(error);
  }
}

export default function* watchFriendInvitation() {
  yield takeLeading(GET_FRIEND_INVITATION_LIST, handleGetInvitationList);
  yield takeLeading(EXPORT_FRIEND_INVITATION_LIST, handleExportInvitationList);
  yield takeLeading(GET_GO_TO_NAIL_INFO, handleGetGoToNailInfo);
  yield takeLeading(GET_GO_TO_NAIL_LIST, handleGetGoToNailList);
  yield takeLeading(EXPORT_GO_TO_NAIL_LIST, handleExportGoToNailList);
  yield takeLeading(UPDATE_GO_TO_NAIL, handleUpdateGoToNail);
  yield takeLeading(GET_OLYMPIC_INFO, handleGetOlympicInfo);
  yield takeLeading(GET_OLYMPIC_LIST, handleGetOlympicList);
  yield takeLeading(EXPORT_OLYMPIC_LIST, handleExportOlympicList);
  yield takeLeading(UPDATE_OLYMPIC, handleUpdateOlympic);
  yield takeLeading(GET_GO_TO_NAIL_INFO_2ND, handleGetGoToNailInfo2nd);
  yield takeLeading(GET_GO_TO_NAIL_LIST_2ND, handleGetGoToNailList2nd);
  yield takeLeading(EXPORT_GO_TO_NAIL_LIST_2ND, handleExportGoToNailList2nd);
  yield takeLeading(UPDATE_GO_TO_NAIL_2ND, handleUpdateGoToNail2nd);
  yield takeLeading(GET_GO_TO_NAIL_INFO_3RD, handleGetGoToNailInfo3rd);
  yield takeLeading(GET_GO_TO_NAIL_LIST_3RD, handleGetGoToNailList3rd);
  yield takeLeading(EXPORT_GO_TO_NAIL_LIST_3RD, handleExportGoToNailList3rd);
  yield takeLeading(UPDATE_GO_TO_NAIL_3RD, handleUpdateGoToNail3rd);
  yield takeLeading(GET_INSTAGRAM_COUPON_INFOR, handleGetInstagramCouponInfor);
  yield takeLeading(GET_INSTAGRAM_COUPON_LIST, handleGetInstagramCouponList);
  yield takeLeading(EXPORT_INSTAGRAM_COUPON, handleExportInstagramCouponList);
  yield takeLeading(UPDATE_INSTAGRAM_COUPON, handleUpdateInstagramCampaign);
}
