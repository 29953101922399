import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { TextField, Select, DatePicker } from 'components/Form';
import BookingStatusTag from 'components/BookingStatusTag';
import BookingOriginTag from 'components/BookingOriginTag';
import { BOOKING_STATUS, BOOKING_ORIGIN } from 'utils/constants';

const FilterForm = ({ handleSubmit }) => {
  return (
    <Form layout="inline" onSubmit={handleSubmit}>
      <Field
        name="keyword"
        placeholder="Booking ID, settlement/merchant ID, nailist/customer info,..."
        component={TextField}
        allowClear
        className="search-input"
        suffix={<SearchOutlined />}
      />
      <Field
        name="type"
        options={Object.values(BOOKING_STATUS)
          .map(value => ({ name: <BookingStatusTag status={value} />, objectId: value }))}
        placeholder="Status"
        component={Select}
        allowClear
        suffix={<SearchOutlined />}
      />
      <Field
        name="treatmentDate"
        placeholder="Treatment Date"
        component={DatePicker}
        allowClear
      />
      <Field
        name="origin"
        options={Object.values(BOOKING_ORIGIN)
          .map(value => ({ name: <BookingOriginTag platform={value} />, objectId: value }))}
        placeholder="Platform"
        component={Select}
        allowClear
        suffix={<SearchOutlined />}
      />
      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

FilterForm.propTypes = {
  handleSubmit: PropTypes.any
};

export default reduxForm({
  form: 'booking-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);
