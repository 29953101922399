import { message } from 'antd';
import { put, call, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import { BANK_TYPE } from 'utils/constants';
import {
  getBankListSuccess,
  getBranchListSuccess,
  getAccountTypeListSuccess,
  updateBankAccountSuccess,
  updateBankAccountFailed
} from './actions';
import {
  GET_BANK_LIST,
  GET_BRANCH_LIST,
  GET_ACCOUNT_TYPE_LIST,
  UPDATE_BANK_ACCOUNT
} from './constants';

function* handleGetBankList(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'banks', params, 'GET');
    yield put(getBankListSuccess({ data }));
  } catch (error) {
    console.log(error);
  }
}

function* handleGetBranchList(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'banks/branchs', params, 'POST');
    yield put(getBranchListSuccess({ data }));
  } catch (error) {
    console.log(error);
  }
}

function* handleGetAccountTypeList(action) {
  try {
    const params = action.payload;
    const { data } = yield call(request, 'banks/accountTypes', params, 'GET');
    yield put(getAccountTypeListSuccess({ data }));
  } catch (error) {
    console.log(error);
  }
}

function* handleUpdateBankAccount(action) {
  try {
    const params = action.payload;
    if (params.type === BANK_TYPE.PERSONAL) {
      delete params.accountHolder;
      delete params.photo;
    }
    if (params.type === BANK_TYPE.COMPANY) {
      delete params.firstName;
      delete params.lastName;
    }

    const photo = params.photo;
    if (photo && photo._id) {
      params.photoId = photo._id;
      delete params.photo;
    } else if (photo && photo.data) {
      params.photo = photo.data;
    }

    const { data } = yield call(request, 'banks/update', params, 'POST');
    yield put(updateBankAccountSuccess({ data }, action.meta));
  } catch (error) {
    yield put(updateBankAccountFailed({ error }, action.meta));
    message.error(error.error || error.message || 'Something went wrong, please try again');
  }
}

export default function* watchSetting() {
  yield takeLeading(GET_BANK_LIST, handleGetBankList);
  yield takeLeading(GET_BRANCH_LIST, handleGetBranchList);
  yield takeLeading(GET_ACCOUNT_TYPE_LIST, handleGetAccountTypeList);
  yield takeLeading(UPDATE_BANK_ACCOUNT, handleUpdateBankAccount);
}
