const validate = (values) => {
  const errors = {};

  if (!values.bookingFee) {
    errors.bookingFee = 'Required';
  } else if (values.bookingFee < 0 || values.errors > 100) {
    errors.bookingFee = 'Invalid';
  }
  if (!values.firstBookingFee) {
    errors.firstBookingFee = 'Required';
  } else if (values.firstBookingFee < 0 || values.firstBookingFee > 100) {
    errors.firstBookingFee = 'Invalid';
  }
  if (!values.applyFrom) {
    errors.applyFrom = 'Required';
  }
  return errors;
};

export default validate;
