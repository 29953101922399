import { connect } from 'react-redux';
import { fetchNailistCurrentBalance, recalculateIncome } from 'providers/NailistProvider/actions';

import QuickRecalcIncomeButton from './QuickRecalcIncomeButton';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  fetchNailistCurrentBalance: (params) => dispatch(fetchNailistCurrentBalance(params)),
  recalculateIncome: ({ nailistId }) => dispatch(recalculateIncome({nailistId}))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickRecalcIncomeButton);