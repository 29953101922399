import _ from 'lodash';

export const makeFriendInvitationList = (state) => {
  return _.get(state, 'friendInvitation.friendInvitationList.data');
};

export const makeFriendInvitationTotal = (state) => {
  return _.get(state, 'friendInvitation.friendInvitationList.total');
};

export const makeFriendInvitationLoading = (state) => {
  return _.get(state, 'friendInvitation.dataLoading');
};

export const makeFriendInvitationActionLoading = (state) => {
  return _.get(state, 'friendInvitation.actionLoading');
};

export const makeGoToNailInfo = (state) => {
  return _.get(state, 'friendInvitation.goToNailInfo');
};

export const makeGoToNailList = (state) => {
  return _.get(state, 'friendInvitation.goToNailList.data');
};

export const makeGoToNailTotal = (state) => {
  return _.get(state, 'friendInvitation.goToNailList.total');
};

export const makeOlympicInfo = (state) => {
  return _.get(state, 'friendInvitation.olympicInfo');
};

export const makeOlympicList = (state) => {
  return _.get(state, 'friendInvitation.olympicList.data');
};

export const makeOlympicTotal = (state) => {
  return _.get(state, 'friendInvitation.olympicList.total');
};

export const makeGoToNailInfo2nd = (state) => {
  return _.get(state, 'friendInvitation.goToNailInfo2');
};

export const makeGoToNailList2nd = (state) => {
  return _.get(state, 'friendInvitation.goToNailList2.data');
};

export const makeGoToNailTotal2nd = (state) => {
  return _.get(state, 'friendInvitation.goToNailList2.total');
};

export const makeGoToNailInfo3rd = (state) => {
  return _.get(state, 'friendInvitation.goToNailInfo3');
};

export const makeGoToNailList3rd = (state) => {
  return _.get(state, 'friendInvitation.goToNailList3.data');
};

export const makeGoToNailTotal3rd = (state) => {
  return _.get(state, 'friendInvitation.goToNailList3.total');
};
export const makeInstagramCouponInfor = (state) => {
  return _.get(state, 'friendInvitation.instagramCouponInfor');
};

export const makeInstagramCouponList = (state) => {
  return _.get(state, 'friendInvitation.instagramCouponList.data');
};

export const makeInstagramCouponTotal = (state) => {
  return _.get(state, 'friendInvitation.instagramCouponList.total');
};