import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StarTwoTone, UserOutlined } from '@ant-design/icons';
import {
  Badge,
  Descriptions,
  Avatar,
  PageHeader,
  Tag,
  Divider,
  Tabs
} from 'antd';

import { formatYen } from 'utils/stringFormat';

import PayoutStatus from 'components/Tag/PayoutStatus';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import TabCurrentPeriod1 from './TabCurrentPeriod1';
import TabCurrentPeriod2 from './TabCurrentPeriod2';

const { TabPane } = Tabs;

const PAYOUT_TYPE = {
  NAILIST: 'NAILIST',
  SALON: 'SALON',
  STAFF: 'STAFF'
};

class Content extends Component {
  renderNailistInfor = (data, info) => {
    if (
      !info ||
      ![PAYOUT_TYPE.NAILIST, PAYOUT_TYPE.STAFF].includes(_.get(data, 'payoutType'))
    ) {
      return null;
    }

    const createdAt =
      info._created_at && moment(info._created_at).format('YYYY-MM-DD HH:mm');
    const star = _.get(info, 'reviewInfo.totalAverage');
    const AvatarJSX = (
      <Avatar
        size={80}
        icon={<UserOutlined />}
        src={_.get(info, 'avatar.thumbNail')}
      />
    );
    const salonAddress = `${_.get(info, 'profile.salonLocation')} ${_.get(
      info,
      'profile.salonTown'
    )} ${_.get(info, 'profile.salonAddressNumber')}`;
    const salonName = _.get(info, 'profile.salonName');

    return (
      <div className="basic-profile">
        <div className="left-block">
          {info.isMark ? (
            <Badge className="premium-badge" count={'✔️'} title={'premium'}>
              {AvatarJSX}
            </Badge>
          ) : (
            AvatarJSX
          )}
        </div>
        <div className="right-block">
          <PageHeader
            title={_.get(info, 'username', '...')}
            tags={
              star && (
                <Tag>
                  <StarTwoTone twoToneColor="#fcba03" /> {star}
                </Tag>
              )
            }
          />
          <Descriptions>
            <Descriptions.Item label="姓名">{info.fullName}</Descriptions.Item>
            <Descriptions.Item label="アカウント作成日" span={2}>
              {createdAt}
            </Descriptions.Item>
            <Descriptions.Item label="ネイリストID">
              {info._id}
            </Descriptions.Item>
            <Descriptions.Item label="メールアドレス">
              {info.email}
            </Descriptions.Item>
            <Descriptions.Item label="電話番号">{info.phone}</Descriptions.Item>
            <Descriptions.Item label="サロン名">{salonName}</Descriptions.Item>
            <Descriptions.Item label="サロン住所">
              {salonAddress}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  };

  renderSalonInfor = (data, info) => {
    if (!info || _.get(data, 'payoutType') !== PAYOUT_TYPE.SALON) {
      return null;
    }

    return (
      <div className="basic-profile">
        <div className="left-block">
          <Avatar
            size={80}
            icon={<UserOutlined />}
            src={_.get(info, 'logoUrl')}
          />
        </div>
        <div className="right-block">
          <PageHeader title={_.get(info, 'businessName', ' -- ')} />
          <Descriptions column={3}>
            <Descriptions.Item label="Phone">
              {_.get(info, 'operatorPhoneNumber', ' -- ')}
            </Descriptions.Item>
            <Descriptions.Item label="Salon Address" span={2}>
              {`${_.get(info, 'location.address')} ${_.get(
                info,
                'location.cityOrTown'
              )} ${_.get(info, 'location.prefecture')}`}
            </Descriptions.Item>
            <Descriptions.Item label="Nailist Number">
              {_.get(info, 'staffsCount', ' -- ')}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  };

  componentDidMount() {
    const { data } = this.props;
    const nailistId = _.get(data, 'nailistId');

    if (
      [PAYOUT_TYPE.NAILIST, PAYOUT_TYPE.STAFF].includes(_.get(data, 'payoutType')) &&
      _.isEmpty(this.props.profile)
    ) {
      this.props.fetchNailistProfile(nailistId);
    }
  }

  renderBankingInfor = (data) => {
    if (_.get(data, 'payoutCurrentPeriod')) {
      return null;
    }

    const bankingInfo = _.get(data, 'bankingInfo');
    const { period } = data;

    if (_.get(data, 'payoutType') === PAYOUT_TYPE.STAFF) {
      return (
        <Descriptions column={2}>
          <Descriptions.Item label="Payment cycle">
            {(period &&
              `${moment(period.startDate).utc().format('YYYY-MM-DD')} - ${moment(
                period.endDate
              )
                .utc()
                .format('YYYY-MM-DD')}`) ||
              ' -- '}
          </Descriptions.Item>
          <Descriptions.Item label="振込金額">
            {formatYen(data.transferAmount)}
          </Descriptions.Item>
          <Descriptions.Item label="Charged booking">
            {data.allBookingsNumber}
          </Descriptions.Item>
        </Descriptions>
      );
    }

    return (
      <Descriptions column={2}>
        <Descriptions.Item label="Payment cycle">
          {(period &&
            `${moment(period.startDate).utc().format('YYYY-MM-DD')} - ${moment(
              period.endDate
            )
              .utc()
              .format('YYYY-MM-DD')}`) ||
            ' -- '}
        </Descriptions.Item>
        <Descriptions.Item label="振込金額">
          {formatYen(data.transferAmount)}
        </Descriptions.Item>
        <Descriptions.Item label="ステータス">
          <PayoutStatus
            status={data.status}
            error={_.isEmpty(bankingInfo) ? 'Missing banking info' : ''}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Charged booking">
          {data.allBookingsNumber}
        </Descriptions.Item>
        <Descriptions.Item label="銀行名">
          {_.get(bankingInfo, 'bankBranch.bank.bankName', '- -')}
        </Descriptions.Item>
        <Descriptions.Item label="口座番号">
          {_.get(bankingInfo, 'accountNumber', '- -')}
        </Descriptions.Item>
        <Descriptions.Item label="振り込み日">
          {data.transferDate
            ? moment(data.transferDate).format('YYYY-MM-DD')
            : '--:-- --/--/--'}
        </Descriptions.Item>
        <Descriptions.Item label="口座名義">
          {_.get(bankingInfo, 'accountHolder') ||
            `${_.get(bankingInfo, 'lastName', '-')} ${_.get(
              bankingInfo,
              'firstName',
              '-'
            )}`}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  renderTabs = (data, profile) => {
    if (!_.get(data, 'payoutCurrentPeriod')) {
      if (_.get(data, 'payoutType') === PAYOUT_TYPE.STAFF) {
        return <Tab2 payoutData={data} />;
      }
      return (
        <Tabs
          onChange={() => {}}
          type="card"
          destroyInactiveTabPane
          defaultActiveKey={
            _.get(data, 'payoutCurrentPeriod') &&
            _.get(profile, 'info.CBStaffId') &&
            '2'
          }
        >
          <TabPane tab="Detail" key="1">
            <Tab1 payoutData={data} />
          </TabPane>
          <TabPane tab="予約" key="2">
            {data && <Tab2 payoutData={data} />}
          </TabPane>
        </Tabs>
      );
    }
    
    if (_.get(data, 'payoutCurrentPeriod')) {
      return (
        <Tabs
          onChange={() => {}}
          type="card"
          destroyInactiveTabPane
          defaultActiveKey={
            _.get(data, 'payoutCurrentPeriod') &&
            _.get(profile, 'info.CBStaffId') &&
            '2'
          }
        >
          <TabPane tab="Nailist" key="1">
            {data && <TabCurrentPeriod1 payoutData={data} />}
          </TabPane>
          <TabPane tab="Salon" key="2">
            {data && <TabCurrentPeriod2 payoutData={data} />}
          </TabPane>
        </Tabs>
      );
    }
    
    return null;    
  }

  render() {
    const { data, profile } = this.props;

    return (
      <div>
        <div id="nailist-detail-page-header">
          {this.renderNailistInfor(data, _.get(profile, 'info'))}
          {this.renderSalonInfor(data, _.get(data, 'salon'))}
          <Divider className="pop-div" />
          {this.renderBankingInfor(data)}
          {this.renderTabs(data, profile)}
        </div>
      </div>
    );
  }
}

Content.defaultProps = {
  profile: {}
};

Content.propTypes = {
  profile: PropTypes.object,
  data: PropTypes.any,
  fetchNailistProfile: PropTypes.func
};

export default Content;
