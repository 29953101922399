import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select, Radio, Input, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { createNailistMenu, editNailistMenu } from 'providers/NailistProvider/actions';
import CommonImageField from 'components/CommonImageField';

const ManageMenuForm = ({ initialValues, submit }) => {
  const [form] = Form.useForm();
  const { categories } = useSelector(state => state.nailist);
  const checkIsOptionalOffMenuCategory = (cateId) => {
    return categories.find(i => i?.objectId === cateId && i.type === 'OPTION');
  };

  const triggerValidate = () => form.validateFields(['categoryId', 'price']);
  
  return <Form
    form={form}
    initialValues={initialValues}
    onFinish={(values) => {
      form.validateFields();
      submit(values);
    }}
    labelCol={{ span: 10 }}
    wrapperCol={{ span: 14 }}
    id="ManageMenuForm"
    colon={false}
  >
    <Form.Item
      shouldUpdate
      label='メニューカテゴリー'
      rules={[{ required: true, message: 'カテゴリーを選択してください' }]}
      style={{ marginBottom: -8 }}
    >
      {() =>
        <Form.Item
          name='categoryId'
          rules={[{ required: true, message: 'カテゴリーを選択してください' }]}
        >
          <Select onChange={() => {
            const price = form.getFieldValue('price');
            if (price) {
              triggerValidate();
            }
          }}>
            {_.sortBy(categories, 'position').map(({ objectId, categoryJA, code }) =>
              <Select.Option key={code} value={objectId}>{categoryJA}</Select.Option>
            )}
          </Select>
        </Form.Item>
      }
    </Form.Item>
  

    <Form.Item label=' ' name='repeatType' rules={[{ required: true }]}>
      <Radio.Group>
        <Radio value="NEW">新規限定</Radio>
        <Radio value="REPEATER">再来限定</Radio>
        <Radio value="ALL">全員OK</Radio>
      </Radio.Group>
    </Form.Item>

    <Form.Item label='メニュー名' name='title' rules={[{ required: true, message: 'メニュー名を入力してください（最大40文字）' }]}>
      <Input maxLength={40} />
    </Form.Item>

    <Form.Item label='所要時間 (分)' name='time' rules={[{ required: true, message: '所要時間を選択してください' }]}>
      <Select>
        {Array.from(Array(24).keys()).map((index) => {
          const duration = (index + 1) * 10;
          return <Select.Option key={duration} value={duration}>{duration}</Select.Option>;
        })}
      </Select>
    </Form.Item>

    <Form.Item label='価格（税込）' name='price'
      rules={[
        {
          required: true,
          message: '税込価格を入力してください'
        },
        () => ({
          validator: (rule, value) => {
            const categoryID = form.getFieldValue('categoryId');
            const isOffMenu = checkIsOptionalOffMenuCategory(categoryID);
            if (!categoryID) {
              return Promise.resolve(true); // validate when submit empty form, fallback to other rules
            }
            if (value === undefined && !categoryID) {
              return undefined; // skip validate when select categoryId bt not fill the price yet
            }
            if (value === null) {
              return Promise.resolve(true);
            }

            if (!isOffMenu && value < 100) {
              return Promise.reject(new Error('100円以下は入力できません'));
            }
            if (isOffMenu && value < 0) {
              return Promise.reject(new Error('0円以下は入力できません'));
            }
            return Promise.resolve(true);
          }
        })
      ]}
    >
      <InputNumber
        max={99999}
        onKeyPress={(e) => {
          const key = e.which || e.key;
          if (e.target.value.length < 7) {
            if (key < 48 || key > 57) { e.preventDefault(); }
          } else e.preventDefault();
        }}
      />
    </Form.Item>

    <Form.Item label='メニュー説明 (任意)' name='detail'>
      <Input.TextArea maxLength={100} />
    </Form.Item>

    <Form.Item label='メニュー画像' name='imageId' extra="1枚あたり5MB以下">
      <CommonImageField aspect={1} />
    </Form.Item>
  </Form>;
};

ManageMenuForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func
};

const useManageMenu = (nailistId) => {
  const [isOpen, setIsOpen] = useState(null);
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const { categoryId, repeatType = 'ALL', title, time, price, imageId, image, code } = isOpen || {};
    return {
      categoryId,
      repeatType,
      title,
      time,
      price,
      imageId: image ? { objectId: imageId, data: image } : ''
    };
  }, [isOpen]);

  const submit = async(values) => {
    if (values.imageId) {
      values.imageId = values.imageId.objectId;
    }
    if (typeof values.categoryId === 'object') {
      values.categoryId = values.categoryId.objectId;
    }
    if (isOpen.objectId) {
      await dispatch(editNailistMenu({ nailistId, menuId: isOpen.objectId, ...values }));
    } else {
      await dispatch(createNailistMenu({ nailistId, ...values }));
    }
    setIsOpen(null);
  };

  const Popup = <Modal
    title={`メニューを${(isOpen || {}).objectId ? '編集' : '追加'}`}
    visible={isOpen}
    destroyOnClose
    cancelText="キャンセル"
    onCancel={() => setIsOpen(null)}
    okText="保存"
    okButtonProps={{ form: 'ManageMenuForm', htmlType: 'submit' }}
    width={560}
  >
    <ManageMenuForm initialValues={initialValues} submit={submit} />
  </Modal>;

  const openPopup = (menu) => setIsOpen(menu || true);

  return [openPopup, Popup];
};

export default useManageMenu;