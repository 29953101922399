import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_NAILIST_LIST_SUCCESS,
  SET_LOADING_SEARCH_NAILIST,
  FETCH_NAILIST_PROFILE_SUCCESS,
  UNSET_NALIST_PROFILE,
  FETCH_NAILIST_POSTS_SUCCESS,
  DELETE_POST_SUCCESS,
  RESET_POSTS_PROVIDER,
  FETCH_NAILIST_BANK_INFO_SUCCESS,
  FETCH_NAILIST_CATEGORY_LIST_SUCCESS,
  FETCH_NAILIST_MENU_SUCCESS,
  CHANGE_NAILIST_MENU_POSITION_SUCCESS,
  FETCH_NAILIST_COUNTS_SUCCESS,
  FETCH_NEWEST_NAILIST_LIST_SUCCESS,
  FETCH_NAILIST_CURRENT_BALANCE_SUCCESS,
  FETCH_BRIEF_NAILIST_PROFILE_SUCCESS,
  SET_LOADING_NAILIST_JOIN_SALON,
  FETCH_NAILIST_JOIN_SALON_SUCCESS,
  FETCH_NAILIST_INSIGHT_SUCCESS,
  UPDATE_NAILIST_WORKING_TIME_SUCCESS,
  FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS,
  VERIFY_NAILIST_EMAIL_SUCCESS,
  FORCE_DELETE_POST,
  FETCH_NAILIST_DISABLED_LIST_SUCCESS,
  FETCH_NAILIST_DISABLED_LIST} from './constants';

const initialState = {
  disabled: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  search: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  profile: {
    info: {},
    bank: {},
    menu: [],
    earning: {},
    posts: {
      list: [],
      detail: {}
    }
  },
  count: {
    _new: 0,
    seniority: 0,
    premium: 0,
    inactive: 0,
    __fetchedTimes: 0
  },
  schedule: {
    key: 0
  },
  hashedDictionary: {},
  new: {
    list: []
  },
  nailistInSalon: {
    loading: false,
    data: [],
    pagination: {
      total: 0
    }
  },
  nailistInsight: {},
  categories: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_NAILIST_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;
    case FETCH_NEWEST_NAILIST_LIST_SUCCESS:
      state.new.list = _.get(action, 'payload.data');
      return state;
    case SET_LOADING_SEARCH_NAILIST:
      state.search.loading = _.get(action, 'payload.loading');
      return state;
    case FETCH_NAILIST_PROFILE_SUCCESS:
      state.profile.info = _.get(action, 'payload');
      return state;
    case FETCH_NAILIST_BANK_INFO_SUCCESS:
      state.profile.bank = _.get(action, 'payload');
      return state;
    case FETCH_NAILIST_CATEGORY_LIST_SUCCESS:
      state.categories = _.get(action, 'payload', []);
      return state;
    case FETCH_NAILIST_MENU_SUCCESS:
      state.profile.menu = _.get(action, 'payload', []);
      return state;
    case CHANGE_NAILIST_MENU_POSITION_SUCCESS: {
      const { categoryId, menus } = _.get(action, 'payload', {});
      state.profile.menu = state.profile.menu.map((category) => {
        if (category.objectId === categoryId) {
          return { ...category, menus };
        }
        return category;
      });
      return state;
    }
    case FETCH_NAILIST_COUNTS_SUCCESS:
      state.count = _.merge({}, state.count, _.get(action, 'payload', {}));
      state.count.__fetchedTimes++;
      return state;
    case FETCH_NAILIST_CURRENT_BALANCE_SUCCESS:
      state.profile.earning = _.merge(
        {},
        state.profile.earning,
        _.get(action, 'payload', {})
      );
      return state;
    case FETCH_BRIEF_NAILIST_PROFILE_SUCCESS:
      _.set(
        state.hashedDictionary,
        `${_.get(action, 'payload._id')}.info`,
        action.payload
      );
      return state;
    case UNSET_NALIST_PROFILE:
      state.profile = initialState.profile;
      state.nailistInsight = initialState.nailistInsight;
      return state;
    case SET_LOADING_NAILIST_JOIN_SALON:
      state.nailistInSalon.loading = _.get(action, 'payload.loading');
      return state;
    case FETCH_NAILIST_JOIN_SALON_SUCCESS:
      state.nailistInSalon = _.get(action, 'payload');
      return state;

    case FETCH_NAILIST_INSIGHT_SUCCESS:
      state.nailistInsight = _.get(action, 'payload');
      return state;
    case FETCH_NAILIST_POSTS_SUCCESS:
      if (state.profile.posts.list?.length === 0) {
        state.profile.posts.list = _.get(action, 'payload');
      } else {
        state.profile.posts.list = state.profile.posts.list.concat(_.get(action, 'payload'));
      }
      return state;
    case DELETE_POST_SUCCESS:
      state.profile.posts.list = state.profile.posts.list.filter((p => p?.objectId !== action?.payload?.postId));
      return state;
    case FORCE_DELETE_POST: // delete posts for case nailist deleted in APP before Admin
      state.profile.posts.list = state.profile.posts.list.filter((p => p?.objectId !== action?.payload));
      return state;

    case UPDATE_NAILIST_WORKING_TIME_SUCCESS:
      if (state.profile?.info?._id === _.get(action, 'payload.userId') && state?.profile?.info?.profile) {
        state.profile.info.profile.weekdays = _.get(action, 'payload.weekdays');
        state.profile.info.profile.hasSchedule = true;

        state.schedule.key++;
      }
      return state;

    case FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS:
      state.schedule = _.get(action, 'payload');
      return state;

    case VERIFY_NAILIST_EMAIL_SUCCESS:
      if (state.profile?.info?._id === _.get(action, 'payload.userId')) {
        state.profile.info.emailVerified = true;
      }
      return state;

    case RESET_POSTS_PROVIDER:
      state.profile.posts.list = []; // reset nailist posts list
      return state;

    case FETCH_NAILIST_DISABLED_LIST:
      state.disabled.loading = true;
      return state;

    case FETCH_NAILIST_DISABLED_LIST_SUCCESS:
      state.disabled.loading = false;
      state.disabled.list = _.get(action, 'payload.data');
      state.disabled.total = _.get(action, 'payload.total');
      return state;

    default:
      return state;
  }
});

export default reducer;
