import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  getInstagramCouponInfor,
  getInstagramCouponList,
  exportInstagramCoupon,
  updateInstagramCoupon
} from 'providers/FriendInvitationProvider/actions';
import {
  makeFriendInvitationLoading,
  makeFriendInvitationActionLoading,
  makeInstagramCouponList,
  makeInstagramCouponTotal,
  makeInstagramCouponInfor
} from 'providers/FriendInvitationProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import InstagramCoupon from './InstagramCouponList';

const mapStateToProps = (state) => ({
  instagramCouponInfor: makeInstagramCouponInfor(state),
  instagramCouponList: makeInstagramCouponList(state),
  instagramCouponTotal: makeInstagramCouponTotal(state),
  goToNailDataLoading: makeFriendInvitationLoading(state),
  goToNailActionLoading: makeFriendInvitationActionLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  getInstagramCouponInfor: (params) => dispatch(getInstagramCouponInfor(params)),
  getInstagramCouponList: (params) => dispatch(getInstagramCouponList(params)),
  exportInstagramCouponList: (params) => dispatch(exportInstagramCoupon(params)),
  updateInstagramCoupon: (params) => dispatch(updateInstagramCoupon(params)),
  replace: (path) => dispatch(replace(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(InstagramCoupon);
