export const FETCH_ANNOUNCEMENT_LIST = '@announcement/FETCH_ANNOUNCEMENT_LIST';
export const FETCH_ANNOUNCEMENT_LIST_SUCCESS = '@announcement/FETCH_ANNOUNCEMENT_LIST_SUCCESS';

export const TOGGLE_ANNOUNCEMENT_STATUS = '@announcement/TOGGLE_ANNOUNCEMENT_STATUS';
export const TOGGLE_ANNOUNCEMENT_STATUS_SUCCESS = '@announcement/TOGGLE_ANNOUNCEMENT_STATUS_SUCCESS';

export const CREATE_ANNOUNCEMENT = '@announcement/CREATE_ANNOUNCEMENT';
export const CREATE_ANNOUNCEMENT_SUCCESS = '@announcement/CREATE_ANNOUNCEMENT_SUCCESS';

export const UPDATE_ANNOUNCEMENT = '@announcement/UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_SUCCESS = '@announcement/UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAIL = '@announcement/UPDATE_ANNOUNCEMENT_FAIL';

export const UPDATE_ANNOUNCEMENTS_POSITION = '@announcement/UPDATE_ANNOUNCEMENTS_POSITION';
export const UPDATE_ANNOUNCEMENTS_POSITION_SUCCESS = '@announcement/UPDATE_ANNOUNCEMENTS_POSITION_SUCCESS';
export const UPDATE_ANNOUNCEMENTS_POSITION_FAIL = '@announcement/UPDATE_ANNOUNCEMENTS_POSITION_FAIL';
