import { useMemo, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import qs from 'querystring';

const useTableControl = ({ defaultOrder = {} } = {}) => {
  const [renewCounter, setRenewCounter] = useState(0);

  let history = useHistory();
  const searchObj = useMemo(() => parseSearchString(history.location.search), [history.location.search, renewCounter]);

  useEffect(() => { // apply default sort order
    const { orderBy, order } = defaultOrder;
    if (!searchObj.orderBy && !searchObj.order && orderBy && order) {
      const parsed = { ...searchObj, orderBy, order};
      history.push(`${history.location.pathname}?${qs.stringify(parsed)}`);
    }
  }, []);

  // handle change from antd table
  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...searchObj,
      page: pagination.current,
      orderBy: sorter.field,
      order: sorter.order
    };

    history.push(`${history.location.pathname}?${qs.stringify(parsed)}`);
  };

  // handle change from filterform submition
  const onSubmitFilterForm = (values) => {
    const parsed = {
      ...searchObj,
      ...values,
      page: 1 // reset page 1
    };

    history.push(`${history.location.pathname}?${qs.stringify(parsed)}`);
  };

  const renewSearchObj = () => {
    setRenewCounter(renewCounter + 1);
  };

  return [
    searchObj,
    onTableChange,
    onSubmitFilterForm,
    renewSearchObj
  ];
};

function parseSearchString(search) {
  return qs.parse(search.replace(/^\?/, ''));
}

export default useTableControl;