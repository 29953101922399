import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select, Space, Button, Input, Tooltip } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useDispatch } from 'react-redux';

import { fetchCustomerProfile, updateCustomerInfo } from 'providers/CustomerProvider/actions';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import moment from 'moment';
import CommonImageField from 'components/CommonImageField';
import _ from 'lodash';

const WHITE_SPACE_ERROR = { pattern: /^[^\s]+(\s+[^\s]+)*$/, message: 'invalid format' };
const FURIGANA_ERROR = { pattern: /^[ぁ-ん]+(\s+[ぁ-ん]+)*$/, message: 'ひらがなで入力してください' };

const USERNAME_ERROR = {
  pattern: /^[a-zA-Z0-9_.]{0,30}$/,
  message: 'more than 1 and less than 30 characters, only alphabet, number, . and _'
};

const UpdateCustomerInfoForm = ({ initialValues, submit, isLoginInfoOnly = false }) => {
  const [form] = Form.useForm();

  return <Form
    form={form}
    initialValues={initialValues}
    onFinish={(values) => {
      form.validateFields();
      submit(values);
    }}
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 12 }}
  >
    {isLoginInfoOnly === true ? null :
      <Form.Item name="avatar" label="Avatar" extra="1枚あたり5MB以下、">
        <CommonImageField aspect={1} />
      </Form.Item>
    }

    <Form.Item label='ユーザーネーム' name='username' rules={[{ required: true }, USERNAME_ERROR]}>
      <Input maxLength={30} />
    </Form.Item>

    <Form.Item label='パスワード' name='password'>
      <Input.Password placeholder='●●●●●●●●●●' />
    </Form.Item>
    <Form.Item label='パスワードの確認' name='passwordConfirm' hasFeedback
      rules={[
        ({ getFieldValue }) => ({
          validator(rule, value) {
            const password = getFieldValue('password');
            if ((!value && !password) || password === value) {
              return Promise.resolve();
            }
            return Promise.reject('The two passwords that you entered do not match!');
          }
        })
      ]}
    >
      <Input.Password placeholder='●●●●●●●●●●' />
    </Form.Item>

    <Tooltip title="New email will need to be verified!" >
      <Form.Item label='メールアドレス' name='email' rules={[{ type: 'email' }, { required: true }]}>
        <Input maxLength={255} />
      </Form.Item>
    </Tooltip>

    {isLoginInfoOnly === true ? null : <>
      <Form.Item label='姓名' name='fullName' rules={[WHITE_SPACE_ERROR]}>
        <Input maxLength={30} />
      </Form.Item>

      <Form.Item label='ふりがな' name='phonetic' rules={[FURIGANA_ERROR]}>
        <Input maxLength={30} />
      </Form.Item>

      <Form.Item label='誕生日' name='birthday'
        rules={[
          () => ({
            validator: (rule, value) => {
              if (!value || value == '____/__/__') {
                return Promise.resolve(true);
              }

              const valueInMoment = moment(value, 'YYYY/MM/DD', true);
              if (!valueInMoment.isValid()) {
                return Promise.reject(new Error('invalid date'));
              }
              const age = moment().startOf('D').diff(valueInMoment, 'years', true);
              if (age < 16) {
                return Promise.reject(new Error('customer must be 16+'));
              }
              return Promise.resolve(true);
            }
          })
        ]}
      >
        <MaskedInput mask={'0000/00/00'} placeholder='YYYY/MM/DD' />
      </Form.Item>

      <Form.Item label='性別' name='gender'>
        <Select>
          <Select.Option value={1}>FEMALE</Select.Option>
          <Select.Option value={2}>MALE</Select.Option>
        </Select>
      </Form.Item>
    </>}

    <Space align='center' direction='vertical' style={{ width: '100%' }}>
      <Form.Item>
        <Button type="primary" htmlType="submit">保存</Button>
      </Form.Item>
    </Space>
  </Form>;
};

UpdateCustomerInfoForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func,
  isLoginInfoOnly: PropTypes.bool
};

const useEditCustomerInfo = (data = {}, customerId, isLoginInfoOnly = false) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    if (_isEmpty(data)) {
      return null;
    }

    return {
      avatar: data.avatar || '',
      username: data.username,
      fullName: data.fullName,
      phonetic: data.phonetic,
      email: data.email,
      birthday: data.birthday ? moment(data.birthday).format('YYYY/MM/DD') : undefined,
      gender: data.gender
    };
  }, [data]);

  const submit = async(values) => {
    const difference = Object.keys(values).filter(k => values[k] !== initialValues[k]);
    const _values = _pick(values, difference);
    if (_.has(_values, 'birthday')) {
      const birthday = (!_values.birthday || _values.birthday === '____/__/__')
        ? '' : moment.utc(_values.birthday).toISOString();
      _values.birthday = birthday;
    }
    if (_.has(_values, 'avatar') && _values.avatar) {
      _values.avatarId = _values.avatar.objectId;
      delete _values.avatar;
    }
    await dispatch(updateCustomerInfo({ userId: customerId, ..._values }));
    setIsOpen(false);
    dispatch(fetchCustomerProfile(customerId));
  };

  const Popup = <Modal
    title='ユーザー情報を編集'
    visible={isOpen}
    destroyOnClose
    footer={null}
    onCancel={() => setIsOpen(false)}
    width={980}
  >
    {initialValues && <UpdateCustomerInfoForm
      initialValues={initialValues} submit={submit} isLoginInfoOnly={isLoginInfoOnly} />}
  </Modal>;

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default useEditCustomerInfo;