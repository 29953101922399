export const FETCH_NAILIST_LIST = 'FETCH_NAILIST_LIST';
export const FETCH_NAILIST_LIST_SUCCESS = 'FETCH_NAILIST_LIST_SUCCESS';
export const FETCH_NEWEST_NAILIST_LIST_SUCCESS = 'FETCH_NEWEST_NAILIST_LIST_SUCCESS';
export const RESET = 'RESET';

export const SET_LOADING_SEARCH_NAILIST = 'SET_LOADING_SEARCH_NAILIST';

export const FETCH_NAILIST_PROFILE = 'FETCH_NAILIST_PROFILE';
export const FETCH_NAILIST_PROFILE_SUCCESS = 'FETCH_NAILIST_PROFILE_SUCCESS';

export const FETCH_NAILIST_BANK_INFO = 'FETCH_NAILIST_BANK_INFO';
export const FETCH_NAILIST_BANK_INFO_SUCCESS = 'FETCH_NAILIST_BANK_INFO_SUCCESS';

export const FETCH_NAILIST_CATEGORY_LIST = 'FETCH_NAILIST_CATEGORY_LIST';
export const FETCH_NAILIST_CATEGORY_LIST_SUCCESS = 'FETCH_NAILIST_CATEGORY_LIST_SUCCESS';

export const FETCH_NAILIST_MENU = 'FETCH_NAILIST_MENU';
export const FETCH_NAILIST_MENU_SUCCESS = 'FETCH_NAILIST_MENU_SUCCESS';

export const CREATE_NAILIST_MENU = 'CREATE_NAILIST_MENU';
export const CREATE_NAILIST_MENU_SUCCESS = 'CREATE_NAILIST_MENU_SUCCESS';

export const EDIT_NAILIST_MENU = 'EDIT_NAILIST_MENU';
export const EDIT_NAILIST_MENU_SUCCESS = 'EDIT_NAILIST_MENU_SUCCESS';

export const DELETE_NAILIST_MENU = 'DELETE_NAILIST_MENU';
export const DELETE_NAILIST_MENU_SUCCESS = 'DELETE_NAILIST_MENU_SUCCESS';

export const CHANGE_NAILIST_MENU_STATUS = 'CHANGE_NAILIST_MENU_STATUS';
export const CHANGE_NAILIST_MENU_STATUS_SUCCESS = 'CHANGE_NAILIST_MENU_STATUS_SUCCESS';

export const CHANGE_NAILIST_MENU_POSITION = 'CHANGE_NAILIST_MENU_POSITION';
export const CHANGE_NAILIST_MENU_POSITION_SUCCESS = 'CHANGE_NAILIST_MENU_POSITION_SUCCESS';

export const FETCH_NAILIST_COUNTS = 'FETCH_NAILIST_COUNTS';
export const FETCH_NAILIST_COUNTS_SUCCESS = 'FETCH_NAILIST_COUNTS_SUCCESS';

export const UNSET_NALIST_PROFILE = 'UNSET_NALIST_PROFILE';

export const FETCH_NAILIST_CURRENT_BALANCE = 'FETCH_NAILIST_CURRENT_BALANCE';
export const FETCH_NAILIST_CURRENT_BALANCE_SUCCESS = 'FETCH_NAILIST_CURRENT_BALANCE_SUCCESS';
export const RECALCULATE_INCOME = 'RECALCULATE_INCOME';
export const RECALCULATE_INCOME_SUCCESS = 'RECALCULATE_INCOME_SUCCESS';

export const FETCH_BRIEF_NAILIST_PROFILE = 'FETCH_BRIEF_NAILIST_PROFILE';
export const FETCH_BRIEF_NAILIST_PROFILE_SUCCESS = 'FETCH_BRIEF_NAILIST_PROFILE_SUCCESS';

export const FETCH_NAILIST_JOIN_SALON = 'FETCH_NAILIST_JOIN_SALON';
export const FETCH_NAILIST_JOIN_SALON_SUCCESS = 'FETCH_NAILIST_JOIN_SALON_SUCCESS';
export const SET_LOADING_NAILIST_JOIN_SALON = 'SET_LOADING_NAILIST_JOIN_SALON';

export const FETCH_NAILIST_INSIGHT = 'FETCH_NAILIST_INSIGHT';
export const FETCH_NAILIST_INSIGHT_SUCCESS = 'FETCH_NAILIST_INSIGHT_SUCCESS';
export const FETCH_NAILIST_POSTS = 'FETCH_NAILIST_POSTS';
export const FETCH_NAILIST_POSTS_SUCCESS = 'FETCH_NAILIST_POSTS_SUCCESS';
export const FETCH_NAILIST_POST_DETAIL = 'FETCH_NAILIST_POST_DETAIL';
export const FETCH_NAILIST_POST_DETAIL_SUCCESS = 'FETCH_NAILIST_POST_DETAIL_SUCCESS';
export const FETCH_NAILIST_POST_DETAIL_FAIL = 'FETCH_NAILIST_POST_DETAIL_FAIL';
export const FORCE_DELETE_POST = 'FORCE_DELETE_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';
export const RESET_POSTS_PROVIDER = 'RESET_POSTS_PROVIDER';

export const UPDATE_NAILIST_WORKING_TIME = 'UPDATE_NAILIST_WORKING_TIME';
export const UPDATE_NAILIST_WORKING_TIME_SUCCESS = 'UPDATE_NAILIST_WORKING_TIME_SUCCESS';
export const UPDATE_NAILIST_WORKING_TIME_FAIL = 'UPDATE_NAILIST_WORKING_TIME_FAIL';

export const UPDATE_NAILIST_INFO = 'UPDATE_NAILIST_INFO';
export const UPDATE_NAILIST_INFO_SUCCESS = 'UPDATE_NAILIST_INFO_SUCCESS';
export const UPDATE_NAILIST_INFO_FAIL = 'UPDATE_NAILIST_INFO_FAIL';

export const UPDATE_NAILIST_PROFILE_SALON = 'UPDATE_NAILIST_PROFILE_SALON';
export const UPDATE_NAILIST_PROFILE_SALON_SUCCESS = 'UPDATE_NAILIST_PROFILE_SALON_SUCCESS';
export const UPDATE_NAILIST_PROFILE_SALON_FAIL = 'UPDATE_NAILIST_PROFILE_SALON_FAIL';

export const FETCH_NAILIST_SLOT_SCHEDULE = 'FETCH_NAILIST_SLOT_SCHEDULE';
export const FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS = 'FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS';
export const FETCH_NAILIST_SLOT_SCHEDULE_FAIL = 'FETCH_NAILIST_SLOT_SCHEDULE_FAIL';

export const VERIFY_NAILIST_EMAIL = 'VERIFY_NAILIST_EMAIL';
export const VERIFY_NAILIST_EMAIL_SUCCESS = 'VERIFY_NAILIST_EMAIL_SUCCESS';

export const FETCH_NAILIST_DISABLED_LIST = 'FETCH_NAILIST_DISABLED_LIST';
export const FETCH_NAILIST_DISABLED_LIST_SUCCESS = 'FETCH_NAILIST_DISABLED_LIST_SUCCESS';

export const ACTION_NAILIST_REJOIN = 'ACTION_NAILIST_REJOIN';
export const ACTION_NAILIST_REJOIN_SUCCESS = 'ACTION_NAILIST_REJOIN_SUCCESS';
export const ACTION_NAILIST_REJOIN_FAIL = 'ACTION_NAILIST_REJOIN_FAIL';