import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Table } from 'antd';

import { fetchBookingList } from 'providers/BookingProvider/actions';
import columnsTemplate from 'containers/Booking/Preset/columnsTemplate';
import { BOOKING_STATUS } from 'utils/constants';
import {
  makeScheduleBookingListPopupControl,
  makeScheduleBookingList } from 'providers/BookingOnScheduleProvider/selectors';
import { openSchedulePopup, closeSchedulePopup } from 'providers/BookingOnScheduleProvider/actions';

const {
  REQUESTED,
  EXPIRED,
  CANCELED_REQUEST,
  CANCELED_RESERVATION,
  DECLINED_REQUEST,
  DECLINED_RESERVATION,
  UNDONE
} = BOOKING_STATUS;

const BookingList = ({ treatmentDate, type }) => {
  const dispatch = useDispatch();
  const { list, total, page, isLoading } = useSelector(makeScheduleBookingList);

  let columns = useMemo(() => [
    columnsTemplate.bookingId({ newTab: true }),
    columnsTemplate.customer({ isLink: true, newTab: true }),
    columnsTemplate.nailist({ newTab: true }),
    columnsTemplate.coupon,
    columnsTemplate.treatmentDate,
    columnsTemplate.requestedDate,
    [REQUESTED, CANCELED_REQUEST, DECLINED_REQUEST, EXPIRED]
      .includes(type) ? {} : columnsTemplate.approvedDate,
    type === EXPIRED ? columnsTemplate.expiredDate : {},
    [CANCELED_REQUEST, CANCELED_RESERVATION, DECLINED_REQUEST, DECLINED_RESERVATION]
      .includes(type) ? columnsTemplate.canceledDate : {},
    columnsTemplate.point,
    [EXPIRED].includes(type) ? {} : columnsTemplate.totalFee,
    type === UNDONE ? columnsTemplate.memo : {},
    type === UNDONE ? columnsTemplate.isChecked : {},
    columnsTemplate.status,
    columnsTemplate.origin,
    columnsTemplate.paymentCardProvider,
    columnsTemplate.settlementId
  ], [type]);

  return (
    <Table
      rowKey='_id'
      columns={columns}
      dataSource={list}
      scroll={{ x: 1280 }}
      onChange={({ current }) => dispatch(fetchBookingList({ page: current, perPage: 10, treatmentDate, type }))}
      rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
      pagination={{
        total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSize: 10,
        current: page,
        showSizeChanger: false
      }}
      loading={isLoading}
    />
  );
};

BookingList.propTypes = {
  treatmentDate: PropTypes.string,
  type: PropTypes.string
};

const useViewBookingList = () => {
  const dispatch = useDispatch();
  const openPopup = (payload) => dispatch(openSchedulePopup(payload));
  const closePopUp = () => dispatch(closeSchedulePopup());
  const isOpen = useSelector(makeScheduleBookingListPopupControl);

  const Popup = <Modal
    title='Booking'
    visible={!!isOpen}
    destroyOnClose
    footer={null}
    onCancel={() => closePopUp(null)}
    width="100vw"
    className='view-booking-list-popup'
  >
    <BookingList {...isOpen} />
  </Modal>;

  return [openPopup, Popup];
};

export default useViewBookingList;
