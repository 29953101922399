// Checked
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editBankFee } from 'providers/PayoutProvider/actions';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';

const { Paragraph } = Typography;

const BookingFee = (props) => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const onChange = (string) => {
    if (string === String(value)) {
      return;
    }

    setLoading(true);
    props
      .editBankFee({
        bankFee: Number(string),
        payoutId: props.payoutId,
        payoutType: props.payoutType
      })
      .then((data) => {
        setValue(data.bankFee);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue(props.value);
  });

  return (
    <div className={`bkfee-container ${props.disabled ? 'disabled' : ''}`}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          {props.prefix && (
            <div className="bkfee-prefix bkfee-txt">{props.prefix}</div>
          )}
          <Paragraph
            editable={{
              onChange: onChange,
              ...(props.disabled ? { editing: false } : {})
            }}
            style={{ display: 'inline' }}
            className="bkfee-txt"
            disabled={props.disabled}
          >
            {String(value)}
          </Paragraph>
        </>
      )}
    </div>
  );
};

BookingFee.propTypes = {
  value: PropTypes.any,
  payoutId: PropTypes.any,
  prefix: PropTypes.any,
  editBankFee: PropTypes.func,
  status: PropTypes.any,
  disabled: PropTypes.bool,
  payoutType: PropTypes.string.isRequired
};

const mapsDispathToProps = (dispatch) => ({
  editBankFee: (params) => dispatch(editBankFee(params))
});

export default connect(null, mapsDispathToProps)(BookingFee);
