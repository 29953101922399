export const FETCH_PAYOUTS_LIST = 'FETCH_PAYOUTS_LIST';
export const FETCH_PAYOUTS_LIST_SUCCESS = 'FETCH_PAYOUTS_LIST_SUCCESS';
export const RESET = 'RESET';

export const SET_LOADING_PAYOUT = 'SET_LOADING_PAYOUT';

export const COMPLETE_PAYOUTS = 'COMPLETE_PAYOUTS';
export const COMPLETE_ALL_PAYOUTS = 'COMPLETE_ALL_PAYOUTS';
export const COMPLETE_PAYOUTS_SUCCESS = 'COMPLETE_PAYOUTS_SUCCESS';
export const COMPLETE_PAYOUTS_FAIL = 'COMPLETE_PAYOUTS_FAIL';

export const EDIT_BANK_FEE = 'EDIT_BANK_FEE';
export const EDIT_BANK_FEE_SUCCESS = 'EDIT_BANK_FEE_SUCCESS';
export const EDIT_BANK_FEE_FAIL = 'EDIT_BANK_FEE_FAIL';

export const REQUEST_PAYOUT_FILE = 'REQUEST_PAYOUT_FILE';
export const REQUEST_PAYOUT_FILE_SUCCESS = 'REQUEST_PAYOUT_FILE_SUCCESS';

export const FETCH_CHANGED_PAYOUTS = 'FETCH_CHANGED_PAYOUTS';
export const FETCH_CHANGED_PAYOUTS_SUCCESS = 'FETCH_CHANGED_PAYOUTS_SUCCESS';
export const FETCH_CHANGED_PAYOUTS_FAIL = 'FETCH_CHANGED_PAYOUTS_FAIL';

export const EXEC_CHANGE_PAYOUT = 'EXEC_CHANGE_PAYOUT';
export const EXEC_CHANGE_PAYOUT_SUCCESS = 'EXEC_CHANGE_PAYOUT_SUCCESS';
export const EXEC_CHANGE_PAYOUT_FAIL = 'EXEC_CHANGE_PAYOUT_FAIL';

export const EXEC_CHANGE_ALL_PAYOUT = 'EXEC_CHANGE_ALL_PAYOUT';
export const EXEC_CHANGE_ALL_PAYOUT_SUCCESS = 'EXEC_CHANGE_ALL_PAYOUT_SUCCESS';
export const EXEC_CHANGE_ALL_PAYOUT_FAIL = 'EXEC_CHANGE_ALL_PAYOUT_FAIL';
