import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';

const { MonthPicker } = DatePicker;

class MonthPickerField extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      input,
      disabledDate,
      meta: { touched, invalid, error }
    } = this.props;
    delete input.onBlur;

    return (
      <Form.Item
        label={label}
        help={touched && invalid && error}
        validateStatus={touched && invalid && error && 'error'}
      >
        <MonthPicker
          {...this.props}
          {...input}
          placeholder={placeholder}
          value={input.value}
          disabledDate={disabledDate}
        />
      </Form.Item>
    );
  }
}

MonthPickerField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabledDate: PropTypes.any
};

export default MonthPickerField;
