const columns = {};

columns.salonName = {
  title: 'Salon name',
  key: 'businessName',
};

columns.salonEmail = {
  title: 'Operator email',
  key: 'operatorEmail',
  dataIndex: 'operatorEmail',
};
columns.salonChangedAmount = {
  title: 'Changed amount ',
  key: 'changedAmount',
  dataIndex: 'changedAmount'
};

columns.nailistName = {
  title: 'User name',
  key: 'nailistUsername',
};

columns.nailistEmail = {
  title: 'Email',
  key: 'nailistEmailAddress',
  dataIndex: 'nailistEmailAddress',
};
columns.oldAmount = {
  title: 'Old amount',
  key: 'payoutBookingValue',
  dataIndex: 'payoutBookingValue',
};
columns.newAmount = {
  title: 'New amount',
  key: 'reCalcTotalBookingValue',
  dataIndex: 'reCalcTotalBookingValue',
};

export default columns;