/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import React, { useRef } from 'react';
import { Carousel, Spin } from 'antd';
import nextIcon from 'assets/icons/next.svg';
import prevIcon from 'assets/icons/prev.svg';
import PropTypes from 'prop-types';
import './styles.scss';

const MyCarousel = ({ imgLists, postDetail }) => {
  let postListImgs = [];
  if (!imgLists) { // old app use nailImage 
    postListImgs.push(postDetail?.nailImage);
  }
  const carouselRef = useRef(null);
  const isMultiImg = imgLists?.length > 1 ? true : false;
  const contentStyle = {
    height: '520px',
    width: '520px',
    objectFit: 'contain'
  };
  if (!postDetail?.nailImage) {
    return <div
      id="big-spin"
      style={{
        height: '520px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin size="large" />
    </div>;
  }
  return (
    <div className="carousel-container" >
      {isMultiImg &&
        <img
          className='prev-icon'
          src={prevIcon}
          onClick={() => {
            carouselRef.current.prev();
          }}
        />
      }
      <Carousel ref={carouselRef} className="carousel-images">
        {(imgLists || postListImgs).map(i => (<div><img src={i} style={contentStyle} /></div>))}
        {}
      </Carousel>
      {isMultiImg &&
        <img
          className='next-icon'
          src={nextIcon}
          onClick={() => {
            carouselRef.current.next();
          }}
        />
      }
    </div>

  );
};
MyCarousel.propTypes = {
  imgLists: PropTypes.array.isRequired,
  postDetail: PropTypes.array.isRequired
};
export default MyCarousel;