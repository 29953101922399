// Checked
import _get from 'lodash/get';
import queryString from 'query-string';

const makeSelectLocationPathname = (store) =>
  _get(store, 'router.location.pathname');

const makeSelectLocationSearch = (store) => {
  const search = _get(store, 'router.location.search', '');
  return queryString.parse(search);
};

export { makeSelectLocationPathname, makeSelectLocationSearch };
