import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';

const useTableControl = (defaultOrder) => {
  let history = useHistory();
  const { location } = history;
  const { pathname, search } = location;
  const searchObj = useMemo(() => ({...defaultOrder, ...parseSearchString(search)}), [search]);

  useEffect(() => { // apply default sort order
    const { orderBy, order } = defaultOrder;
    if (!searchObj.orderBy && !searchObj.order && orderBy && order) {
      const parsed = { ...searchObj, orderBy, order};
      history.push(`${history.location.pathname}?${qs.stringify(parsed)}`);
    }
  }, []);

  // handle change from antd table
  const onTableChange = (pagination, filters, { order, field }) => {
    const parsed = { ...searchObj, page: pagination.current };
    if (order) {
      parsed.order = order;
      parsed.orderBy = field;
    } else {
      delete parsed.order;
      delete parsed.orderBy;
    }
    history.push(`${pathname}?${qs.stringify(parsed)}`);
  };

  // handle change from filter form submition
  const onSubmitFilterForm = (values) => {
    const parsed = { ...values, page: 1 };
    history.push(`${pathname}?${qs.stringify(parsed)}`);
  };

  return [searchObj, onTableChange, onSubmitFilterForm];
};

function parseSearchString(search) {
  return qs.parse(search.replace(/^\?/, ''));
}

export default useTableControl;