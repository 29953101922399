import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';

import { TextField, NumericInput } from 'components/Form';
import validate from './validate';

const RewardPointsPopup = ({
  closeModal,
  handleSubmit,
  actionLoading,
}) => {
  return (
    <Modal
      title="ポイントプレゼント"
      visible={true}
      okText="保存"
      cancelText="キャンセル"
      onOk={handleSubmit}
      confirmLoading={actionLoading}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form onSubmit={handleSubmit} className="reward-points-form">
        <Field
          name="customer"
          type="text"
          label="カスタマー"
          placeholder="カスタマーIDを入力"
          component={TextField}
          required
        />
        <Field
          name="point"
          placeholder="ポイント数を入力（10,000ポイントまで）"
          component={NumericInput}
          maxLength={11}
          label="ポイント"
          required
          maxLength={5}
          min={1}
          pattern='[0-9]'
        />
        <Field
          name="title"
          placeholder="タイトルを入力（20文字まで）"
          component={TextField}
          label="タイトル"
          max={20}
        />
        <Field
          name="content"
          placeholder="テキストを入力（50文字まで）"
          component={TextField}
          label="ポップアップテキスト"
          max={50}
        />
      </Form>
    </Modal>
  );
};

RewardPointsPopup.propTypes = {
  closeModal: PropTypes.any,
  handleSubmit: PropTypes.any,
  actionLoading: PropTypes.any,
  showModal: PropTypes.bool
};

export default connect((state) => {})(reduxForm({
  form: 'rewardPointsForm',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true
})(RewardPointsPopup));
