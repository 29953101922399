/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import './styles.scss';
import GeneralTab from './GeneralTab';
import AfterTreatmentTab from './AfterTreatmentTab';
const { TabPane } = Tabs;

const BookingList = () => {
  const { type: activeTab = 'general' } = useParams();
  const history = useHistory();

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Receipt | Search</title>
      </Helmet>
      <PageHeader
        title="Booking"
        breadcrumb={PageHeader.Bread.booking}
      />
      <Tabs
        activeKey={activeTab || 'general'}
        className="booking-management-tabs"
        onTabClick={tab => {
          history.push(tab === 'general' ?
            '/dashboard/booking-management/list'
            : `/dashboard/booking-management/list/${tab}`);
        }}
      >
        <TabPane tab="General" key="general">
          {activeTab === 'general' && <GeneralTab />}
        </TabPane>
        <TabPane tab="カードエラー・施術後" key="card-error">
          {activeTab === 'card-error' && <AfterTreatmentTab />}
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

BookingList.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchReceiptList: PropTypes.func
};

export default BookingList;