import _ from 'lodash';

export const makeSearchNailistList = (state) => {
  return _.get(state, 'nailist.search.list');
};


export const makeNewNailistList = (state) => {
  return _.get(state, 'nailist.new.list');
};

export const makeSearchNalistTotal = (state) => {
  return _.get(state, 'nailist.search.total');
};

export const makeSearchNalistLastPage = (state) => {
  return _.get(state, 'nailist.search.lastPage');
};

export const makeSelectLocationLoading = (state) => {
  return _.get(state, 'nailist.search.loading');
};

export const makeNailistProfile = (state) => {
  return _.get(state, 'nailist.profile');
};

export const makeNailistCounts = (state) => {
  return _.get(state, 'nailist.count');
};

export const makeNailistProfilefromDict = (state, ownProps) => {
  const storePath = 'nailist.hashedDictionary.' + _.get(ownProps, 'data.nailistId');
  return _.get(state, storePath);
};

export const makeNailistJoinSalon = (state) => {
  return _.get(state, 'nailist.nailistInSalon');
};

export const makeNailistJoinSalonLoading = (state) => {
  return _.get(state, 'nailist.nailistInSalon.loading');
};

export const makeNailistInsight = (state) => _.get(state, 'nailist.nailistInsight');
export const makeNailistPosts = (state) => _.get(state, 'nailist.nailistPosts');

export const makeNailistDisabled = (state) => _.get(state, 'nailist.disabled');