import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Card, Space, Divider, Empty, Button, Typography, Spin, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, StarFilled, WarningOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { fetchBookingReview, deleteBookingReview } from 'providers/BookingProvider/actions';
import BookingReviewRating from './Rating';
import BookingReviewComment from './Comment';
import useEditBookingReviewRating from '../hooks/useEditBookingReviewRating';
import './styles.scss';

const { Title } = Typography;

const BookingReview = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const { bookingId } = routeMatch.params;
  const { review: { review, isLoading }, booking: { detail } } = useSelector(state => state.booking);
  const [openEditBookingReviewRatingPopup, EditBookingReviewRatingPopup] =
    useEditBookingReviewRating(review, bookingId);

  useEffect(() => {
    dispatch(fetchBookingReview(bookingId));
  }, []);

  const onDelete = () => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure want to this review?',
      icon: <WarningOutlined />,
      onOk() { dispatch(deleteBookingReview(bookingId)); }
    });
  };

  return (
    <Card
      bordered={false}
      title={<Title level={5}>レビュー</Title>}
      className='booking-review'
      extra={!_.isEmpty(review) && <Space>
        <Button danger icon={<DeleteOutlined />} onClick={onDelete}>Delete review</Button>
        <Button type="primary" icon={<EditOutlined />} onClick={openEditBookingReviewRatingPopup}>Edit rating</Button>
      </Space>}
    >
      {isLoading ? <Spin size='large' /> : _.isEmpty(review) ? <Empty description={false} /> : <>
        <Space size={4}>
          <Space align="end">
            <Title level={5}>総合評価</Title>
            <StarFilled className='booking-review-star' />
          </Space>
          <Title level={3} className='booking-review-average-rating'>{review.averageRating}</Title>
        </Space>

        <div style={{ height: 24 }} />

        <Space size={44}>
          <BookingReviewRating label='技術' value={review.skillsRating} />
          <BookingReviewRating label='接客' value={review.serviceRating} />
          <BookingReviewRating label='価格' value={review.priceRating} />
        </Space>

        {review.hasComment && <div className='booking-review-comment'>
          <BookingReviewComment {...detail.customer} commentAt={review._created_at} comment={review.comment} />
          {review.nailistComment && <>
            <Divider />
            <Space>
              <div style={{ width: 48 }} />
              <BookingReviewComment {...detail.nailist} commentAt={review.nailistCommentAt}
                comment={review.nailistComment} />
            </Space>
          </>}
        </div>}
      </>}

      {EditBookingReviewRatingPopup}
    </Card>
  );
};

BookingReview.propTypes = {
};

export default BookingReview;
