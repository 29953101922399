// https://docs.google.com/spreadsheets/d/1DMAq_vYCeza_0snUxiArNYsbZTngLdfCO6K2tR-MnjU/edit#gid=637191007

module.exports = {
  CHANGING_BK_STATUS: {
    DONE: 'DONE',                                                          // done
    CANCELED_REQUEST: 'CANCELED_REQUEST',                                  // customer cancel request
    CANCELED_RESERVATION: 'CANCELED_RESERVATION',                          // 0%
    CANCELED_RESERVATION_50_PERCENT: 'CANCELED_RESERVATION_50_PERCENT',
    CANCELED_RESERVATION_100_PERCENT: 'CANCELED_RESERVATION_100_PERCENT',
    DECLINED_RESERVATION: 'DECLINED_RESERVATION',                          // decline request
    NAILIST_CANCELED: 'NAILIST_CANCELED',
    UNDONE: 'UNDONE',                                                      // emergency
    NOVISIT: 'NOVISIT',                                                    // 0%
    NOVISIT_50_PERCENT: 'NOVISIT_50_PERCENT',
    NOVISIT_100_PERCENT: 'NOVISIT_100_PERCENT'
  },
  CHANGING_BK_STATUS_TEXT: {
    DONE: '施術完了',
    CANCELED_REQUEST: '[カ]キャンセル(直前以外)',
    CANCELED_RESERVATION: '[カ]キャンセル（直前0%）',
    CANCELED_RESERVATION_50_PERCENT: '[カ]キャンセル（直前50%）',
    CANCELED_RESERVATION_100_PERCENT: '[カ]キャンセル（直前100%）',
    DECLINED_RESERVATION: '[ネ]キャンセル',
    UNDONE: '緊急事態キャンセル',
    NOVISIT: '不来店（0%）',
    NOVISIT_50_PERCENT: '不来店（50%）',
    NOVISIT_100_PERCENT: '不来店（100%）'
  },
};
