import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, Typography } from 'antd';

import { formatYen } from 'utils/stringFormat';

const { Title } = Typography;

const PAYOUT_TYPE = {
  NAILIST: 'NAILIST',
  SALON: 'SALON'
};

const defaultByDate = (periodId) => {
  if (periodId >= 21030) {
    return 250;
  }

  return 500;
};
class Tab1 extends Component {
  render() {
    const { payoutData } = this.props;
    if (_.isEmpty(payoutData)) {
      return null;
    }

    let list;
    switch (_.get(payoutData, 'payoutType')) {
      case PAYOUT_TYPE.NAILIST: {
        list = [
          {
            title: '売上',
            value: payoutData.totalBookingValue
          },
          {
            title: 'New customer Booking Fee',
            value: -_.get(
              payoutData,
              'bookingFeePercent.firstBookingFeeAmount',
              0
            ),
            description: `-${Math.round(
              _.get(payoutData, 'bookingFeePercent.firstBookingFee') * 100
            )}% of first booking value`
          },
          {
            title: 'Repeated customer Booking Fee',
            value: -_.get(payoutData, 'bookingFeePercent.bookingFeeAmount', 0),
            description: `-${Math.round(
              _.get(payoutData, 'bookingFeePercent.bookingFee') * 100
            )}% of booking value`
          },
          {
            title: '決済手数料',
            value: -_.get(payoutData, 'settlementFee', 0)
          },
          {
            title: '振込手数料',
            value: -payoutData.bankFee,
            description: `Default by ${defaultByDate(payoutData.periodId)} ￥`
          }
        ];
        break;
      }

      case PAYOUT_TYPE.SALON: {
        list = [
          {
            title: '売上',
            value: payoutData.totalBookingValue
          },
          {
            title: 'Nailie予約手数料',
            value: -payoutData.nailieComission
          },
          {
            title: '決済手数料',
            value: -_.get(payoutData, 'settlementFee', 0)
          },
          {
            title: '振込手数料',
            value: -payoutData.bankFee
          }
        ];
        break;
      }

      default: {
        return null;
      }
    }

    return (
      <List
        itemLayout="horizontal"
        loadMore={() => {}}
        dataSource={list}
        renderItem={(item) => (
          <List.Item key={item.title} extra={formatYen(item.value)}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      >
        <List.Item
          key={'total'}
          extra={
            <Title level={3}>{formatYen(payoutData.transferAmount)}</Title>
          }
        >
          <List.Item.Meta title={'振込金額'} />
        </List.Item>
      </List>
    );
  }
}

Tab1.propTypes = {
  payoutData: PropTypes.any
};

export default Tab1;
