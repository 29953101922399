// Checked
import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Badge, Tooltip } from 'antd';

const colorDef = {
  INCOME: 'gold',
  COMPLETED: 'cyan',
  UNCOMPLETE: 'volcano'
};

const PayoutStatus = ({ error, status }) => {
  const tag = <Tag color={colorDef[status]}>{status}</Tag>;
  return error ? (
    <Tooltip placement="rightTop" title={error}>
      <Badge status="warning" style={{ zIndex: 0 }}>
        {tag}
      </Badge>
    </Tooltip>
  ) : (
    tag
  );
};

PayoutStatus.propTypes = {
  error: PropTypes.any,
  status: PropTypes.any
};

export default PayoutStatus;
