import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _, { isNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { Button, Modal, Select, Space, Divider } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';

import { changeBookingStatus, fetchBookingDetail } from 'providers/BookingProvider/actions';
import { changeReceiptBookingPrice } from 'providers/ReceiptProvider/actions';

import BookingStatusTag from 'components/BookingStatusTag';
import useChangingStatusEnum from './hooks/useChangingStatusEnum';

import { CHANGING_BK_STATUS } from 'utils/constants/changingBKStatusEnum';
import DEST_STATUS_MAPPING from './DEST_STATUS_MAPPING';

import { PriceInput, readPriceInput } from 'components/Common/Booking/ChangePriceNStatus/EditReceiptPriceButton/EditReceiptPriceButton';
// new_total_price_receipt is input field of PriceInput

function getValidatedPriceInput() {
  let priceInput = readPriceInput();
  priceInput = priceInput === '' ? '' : Number(priceInput);

  if (_.isNaN(priceInput)) {
    throw 'invalid price';
  }

  return priceInput;
}

function checkShowChangePriceSection(currentStatus, newStatus) {
  if (
    newStatus === CHANGING_BK_STATUS.DONE &&
    [
      CHANGING_BK_STATUS.CANCELED_RESERVATION_100_PERCENT,
      CHANGING_BK_STATUS.NOVISIT_100_PERCENT
    ].includes(currentStatus)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * 
 * @param {String} currentStatusEnum using DONE, UNDONE, CANCELED_REQUEST, DECLINED_RESERVATION, CANCELED_RESERVATION, CANCELED_RESERVATION_50_PERCENT, CANCELED_RESERVATION_100_PERCENT, NOVISIT, NOVISIT_50_PERCENT, NOVISIT_100_PERCENT
 */
const Content = ({ bookingId, currentStatusEnum, status, closePopup = () => {} }) => {
  const [newStatus, setNewStatus] = useState(undefined);
  const dispatch = useDispatch();

  async function applyChangeStatus() {
    try {
      await dispatch(changeBookingStatus({bookingId, status: newStatus}));

      if (checkShowChangePriceSection(currentStatusEnum, newStatus)) {
        const priceToChange = getValidatedPriceInput();

        (priceToChange !== '') && await dispatch(changeReceiptBookingPrice({
          bookingId: bookingId,
          finalPrice: priceToChange, // finalPrice = customerPrice because a all point and coupon has refunded 
          customerPrice: priceToChange
          // screen: 'BOOKING_DETAIL', //manually fetch below
        }));
      }

      dispatch(fetchBookingDetail({bookingId}));
      closePopup();
    } catch (err) {
      console.error(err);
    }
  }


  return (
    <Fragment>
      <Space style={{width: '100%', justifyContent: 'space-around'}}>
        <BookingStatusTag status={status}/>

        <ArrowRightOutlined />

        <Select
          placeholder='Destination Status'
          style={{ width: '296px' }}
          onChange={setNewStatus}
        >
          {DEST_STATUS_MAPPING[currentStatusEnum]}
        </Select>
      </Space>

      {checkShowChangePriceSection(currentStatusEnum, newStatus) && <PriceInput required={false}/>}
      <Divider style={{margin: '24px 0 12px'}}/>

      <Space style={{width: '100%', justifyContent: 'flex-end', gap: 8}}>
        <Button disabled={!newStatus} type='primary' onClick={applyChangeStatus}>OK</Button>
      </Space>
    </Fragment>
    
  );
};

const ChangeBookingStatusBookingButton = ({ status, penalty, bookingId }) => {
  const [showPopup, setPopup] = useState(false);
  const [currentStatusEnum] = useChangingStatusEnum({ status, penalty });

  return (
    <Fragment>
      <Button type='primary' onClick={() => setPopup(true)} disabled={!currentStatusEnum}>Change Status</Button>

      <Modal title={'Change Status'} destroyOnClose={true} width={625} visible={showPopup} onCancel={() => setPopup(false)} footer={null}>
        <Content key={currentStatusEnum} status={status} currentStatusEnum={currentStatusEnum} bookingId={bookingId} closePopup={() => setPopup(false)}/>
      </Modal>
    </Fragment>
  );
};

export default ChangeBookingStatusBookingButton;