import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';

const { Text, Title } = Typography;

const BookingReviewRating = ({ label, value }) => {
  return (
    <Space align="end">
      <Text className='booking-review-rating-label'>{label}</Text>

      <Space size={0} align="end">
        <Title level={5}>{value && value.toFixed(1)}</Title>
        <Title className='booking-review-rating-point'>点</Title>
      </Space>
    </Space>
  );
};

BookingReviewRating.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number
};

export default BookingReviewRating;
