import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Space, Avatar, Skeleton } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const BookingDirectMessageUserInfo = ({ userInfo = {}, loading }) => {
  const { role = '' } = userInfo;

  return (
    <Space size={16}>
      {loading ? <Skeleton.Avatar active /> : <Avatar src={userInfo.avatar} icon={<UserOutlined />} />}

      <Space direction='vertical' size={4}>
        {loading ? <Skeleton.Input active style={{ width: 70, height: 20 }} /> :
          <Link to={`/dashboard/${role.toLowerCase()}/profile/${userInfo.userId}`}>{userInfo.username}</Link>}
        {loading ? <Skeleton.Input active style={{ width: 70, height: 20 }} /> :
          <div>{role.charAt(0) + role.slice(1).toLowerCase()}</div>}
      </Space>
    </Space>
  );
};

BookingDirectMessageUserInfo.propTypes = {
  userInfo: PropTypes.object,
  loading: PropTypes.bool
};

export default BookingDirectMessageUserInfo;
