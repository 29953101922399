import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { execChangePayout } from 'providers/PayoutProvider/actions';


const ExecChangePayoutButton = (props) => {
  const [status, setStatus] = useState(props?.record?.status);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('Change');

  const handleChangePayout = () => {
    setLoading(true);
    props.execChangePayout(props.params)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setValue('Changed');
          setStatus('COMPLETE');
        }, 300);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      loading={loading}
      disabled={status === 'COMPLETE'}
      icon={<DeliveredProcedureOutlined />}
      onClick={() => { props.onClick(); handleChangePayout(); }}
    >
      {value}
    </Button>
  );
};

ExecChangePayoutButton.propTypes = {
  execChangePayout: PropTypes.func,
  onClick: PropTypes.func,
  params: PropTypes.array,
  record: PropTypes.object,
};
const mapsDispathToProps = (dispatch) => ({
  execChangePayout: (params) => dispatch(execChangePayout(params))
});

export default connect(null, mapsDispathToProps)(ExecChangePayoutButton);