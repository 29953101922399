import {
  FETCH_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_LIST,
  FETCH_CUSTOMER_LIST_SUCCESS,
  UNSET_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_COUPON_LISTS,
  FETCH_CUSTOMER_COUPON_LISTS_SUCCESS,
  UPDATE_CUSTOMER_INFO,
  UPDATE_CUSTOMER_INFO_SUCCESS,
  UPDATE_CUSTOMER_INFO_FAIL,
  VERIFY_CUSTOMER_EMAIL,
  VERIFY_CUSTOMER_EMAIL_SUCCESS,
  FETCH_CUSTOMER_POINT_INFO,
  FETCH_CUSTOMER_POINT_INFO_SUCCESS,
  FETCH_CUSTOMER_POINT_HISTORY,
  FETCH_CUSTOMER_POINT_HISTORY_SUCCESS
} from './constants';

export function fetchCustomerList(payload = {}) {
  return { type: FETCH_CUSTOMER_LIST, payload, meta: { thunk: true } };
}
export function fetchCustomerListSuccess(payload = {}, meta) {
  return { type: FETCH_CUSTOMER_LIST_SUCCESS, payload, meta };
}
export function fetchCustomerProfile(payload = {}) {
  return { type: FETCH_CUSTOMER_PROFILE, payload, meta: { thunk: true } };
}
export function fetchCustomerProfileSuccess(payload = {}, meta) {
  return { type: FETCH_CUSTOMER_PROFILE_SUCCESS, payload, meta };
}
export function unsetCustomerProfile(payload = {}) {
  return { type: UNSET_CUSTOMER_PROFILE, payload };
}

export function fetchCustomerCouponLists(payload = {}) {
  return { type: FETCH_CUSTOMER_COUPON_LISTS, payload, meta: { thunk: true } };
}
export function fetchCustomerCouponListsSuccess(payload = {}, meta) {
  return { type: FETCH_CUSTOMER_COUPON_LISTS_SUCCESS, payload, meta };
}

export function updateCustomerInfo(payload = {}) {
  return { type: UPDATE_CUSTOMER_INFO, payload, meta: { thunk: true } };
}
export function updateCustomerInfoSuccess(payload = {}, meta) {
  return { type: UPDATE_CUSTOMER_INFO_SUCCESS, payload, meta };
}
export function updateCustomerInfoFail(payload = {}, meta) {
  return { type: UPDATE_CUSTOMER_INFO_FAIL, payload, meta, error: true };
}

export function verifyCustomerEmail(payload = {}) {
  return { type: VERIFY_CUSTOMER_EMAIL, payload };
}
export function verifyCustomerEmailSuccess(payload = {}) {
  return { type: VERIFY_CUSTOMER_EMAIL_SUCCESS, payload };
}

export function fetchCustomerPointInfo(payload = {}) {
  return { type: FETCH_CUSTOMER_POINT_INFO, payload };
}
export function fetchCustomerPointInfoSuccess(payload = {}) {
  return { type: FETCH_CUSTOMER_POINT_INFO_SUCCESS, payload };
}

export function fetchCustomerPointHistory(payload = {}) {
  return { type: FETCH_CUSTOMER_POINT_HISTORY, payload };
}
export function fetchCustomerPointHistorySuccess(payload = {}) {
  return { type: FETCH_CUSTOMER_POINT_HISTORY_SUCCESS, payload };
}