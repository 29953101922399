import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PopupBannerList from './PopupBannerList';
import CreatePopupBanner from './CreatePopupBanner';
import EditPopupBanner from './EditPopupBanner';

const POPUP_BANNER_ROOT = '/dashboard/popup-banner';

const RedirectToBannerList = () => (
  <Redirect to={POPUP_BANNER_ROOT} />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Promotion Popup</title>
      </Helmet>
      <Switch>
        <Route
          path={POPUP_BANNER_ROOT}
          exact
          component={PopupBannerList}
        />
        <Route
          path={POPUP_BANNER_ROOT + '/create'}
          exact
          component={CreatePopupBanner}
        />
        <Route
          path={POPUP_BANNER_ROOT + '/edit/:objectId'}
          exact
          component={EditPopupBanner}
        />
        <Route path={POPUP_BANNER_ROOT} component={RedirectToBannerList} />
      </Switch>
    </>
  );
};

export default Router;
