import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_CUSTOMER_COUPON_LISTS_SUCCESS,
  FETCH_CUSTOMER_LIST_SUCCESS, FETCH_CUSTOMER_PROFILE_SUCCESS, UNSET_CUSTOMER_PROFILE,
  VERIFY_CUSTOMER_EMAIL_SUCCESS,
  FETCH_CUSTOMER_POINT_HISTORY,
  FETCH_CUSTOMER_POINT_INFO_SUCCESS,
  FETCH_CUSTOMER_POINT_HISTORY_SUCCESS
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1
  },
  profile: {
    info: {}
  },
  coupons: {
    couponlist: [],
    expiredCoupons: []
  },
  pointInfo: {},
  pointHistory: {
    loading: false,
    list: [],
    total: 0
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;
    case FETCH_CUSTOMER_PROFILE_SUCCESS:
      state.profile.info = _.get(action, 'payload');
      return state;
    case UNSET_CUSTOMER_PROFILE:
      state.profile = initialState.profile;
      state.profile = initialState.coupons;
      return state;
    case FETCH_CUSTOMER_COUPON_LISTS_SUCCESS:
      state.coupons = _.get(action, 'payload');
      return state;
    case VERIFY_CUSTOMER_EMAIL_SUCCESS:
      if (state.profile?.info?._id === _.get(action, 'payload.userId')) {
        state.profile.info.emailVerified = true;
      }
      return state;
    case FETCH_CUSTOMER_POINT_INFO_SUCCESS:
      state.pointInfo = _.get(action, 'payload');
      return state;
    case FETCH_CUSTOMER_POINT_HISTORY:
      state.pointHistory.loading = true;
      return state;
    case FETCH_CUSTOMER_POINT_HISTORY_SUCCESS:
      state.pointHistory.list = _.get(action, 'payload.data.data');
      state.pointHistory.total = _.get(action, 'payload.data.total');
      state.pointHistory.loading = false;
      return state;
    default:
      return state;
  }
});

export default reducer;
