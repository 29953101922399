import {
  FETCH_ADMIN_OPERATOR_LIST,
  FETCH_ADMIN_OPERATOR_LIST_SUCCESS,
  CREATE_ADMIN_OPERATOR,
  CREATE_ADMIN_OPERATOR_SUCCESS,
  UPDATE_ADMIN_OPERATOR,
  UPDATE_ADMIN_OPERATOR_SUCCESS,
  DELETE_ADMIN_OPERATOR,
  DELETE_ADMIN_OPERATOR_SUCCESS
} from './constants';

export function fetchAdminOperatorList(payload = {}) {
  return { type: FETCH_ADMIN_OPERATOR_LIST, payload };
}
export function fetchAdminOperatorListSuccess(payload = {}) {
  return { type: FETCH_ADMIN_OPERATOR_LIST_SUCCESS, payload };
}

export function createAdminOperator(payload = {}) {
  return { type: CREATE_ADMIN_OPERATOR, payload };
}
export function createAdminOperatorSuccess(payload = {}) {
  return { type: CREATE_ADMIN_OPERATOR_SUCCESS, payload };
}

export function updateAdminOperator(payload = {}) {
  return {
    type: UPDATE_ADMIN_OPERATOR, payload, meta: {thunk: true}};
}
export function updateAdminOperatorSuccess(payload = {}, meta) {
  return { type: UPDATE_ADMIN_OPERATOR_SUCCESS, payload, meta };
}

export function deleteAdminOperator(payload = {}) {
  return { type: DELETE_ADMIN_OPERATOR, payload };
}
export function deleteAdminOperatorSuccess(payload = {}) {
  return { type: DELETE_ADMIN_OPERATOR_SUCCESS, payload };
}