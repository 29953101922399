import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Row, Col, Statistic, Card
} from 'antd';

import { fetchNailistCounts } from 'providers/NailistProvider/actions';
import { makeNailistCounts } from 'providers/NailistProvider/selectors';

class NailistNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchNailistCounts();
  }

  render() {
    return (
      <Card className="nailist-count">
        <Row gutter={24} type="flex" justify="space-between">
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Statistic title="新規ネイリスト（過去7日間）" value={_.get(this.props.count, '_new')} /><em></em>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Statistic title="プレミアムネイリスト" value={_.get(this.props.count, 'premium')} /><em></em>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Statistic title="掲載ネイリスト（ネイリスト一覧）" value={_.get(this.props.count, 'seniority')} /><em></em>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Statistic title="退会済ネイリスト" value={_.get(this.props.count, 'inactive')} />
          </Col>
        </Row>
      </Card>
    );
  }
}

NailistNumber.propTypes = {
  fetchNailistCounts: PropTypes.func.isRequired,
  count: PropTypes.object
};

const mapStateToProps = (state) => ({
  count: makeNailistCounts(state)
});


const mapDispatchToProps = (dispatch) => ({
  fetchNailistCounts: () => dispatch(fetchNailistCounts())
});

export default connect(mapStateToProps, mapDispatchToProps)(NailistNumber);