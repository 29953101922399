module.exports = [
  {
    key: 'nailist',
    icon: 'user',
    text: 'Nailists',
  },
  {
    key: 'nailist-disabled',
    icon: 'user-delete',
    text: 'Disabled nailist'
  },
  {
    key: 'customer',
    icon: 'team',
    text: 'Customers'
  },
  {
    key: 'admin-operator',
    icon: 'team',
    text: 'Admin & Operators'
  },
  {
    key: 'salons',
    icon: 'shop',
    text: 'Salons'
  },
  {
    key: 'receipts',
    icon: 'file-text',
    text: 'Receipts'
  },
  {
    key: 'payout',
    icon: 'credit-card',
    text: 'Payment',
    subMenu: [
      {
        key: 'payout-nailist',
        icon: 'right',
        text: 'Nailist'
      },
      {
        key: 'payout-salon',
        icon: 'right',
        text: 'Salon'
      }
    ]
  },
 
  {
    key: 'booking-management',
    icon: 'account-book',
    text: 'Bookings'
  },
  {
    key: 'schedule',
    icon: 'insert-row-above',
    text: 'Schedule'
  },
  // {
  //   key: 'menu',
  //   icon: 'ordered-list',
  //   text: 'Menu',
  //   disabled: true
  // },
  // {
  //   key: 'direct-message',
  //   icon: 'message',
  //   text: 'Direct message',
  //   disabled: true
  // },
  {
    key: 'deleted-account',
    icon: 'delete',
    text: 'Deleted account'
  },
  {
    key: 'support',
    icon: 'heatMap',
    text: 'Support',
    component: 'COUNT_SUPPORT',
    subMenu: [
      {
        key: 'app',
        icon: 'right',
        text: 'App',
        component: 'COUNT_APP'
      },
      {
        key: 'web',
        icon: 'right',
        text: 'Web',
        subMenu: [
          {
            key: 'webContact',
            icon: 'right',
            text: 'Web Contact',
            component: 'COUNT_WEB'
          },
          {
            key: 'salonContact',
            icon: 'right',
            text: 'Salon Contact',
            component: 'COUNT_SALON'
          }
        ]
      },
      {
        key: 'verification',
        icon: 'right',
        text: 'Verification',
        component: 'COUNT_VERIFICATION'
      },
      {
        key: 'report',
        icon: 'right',
        text: 'Report'
      },
      {
        key: 'unlink-phone',
        icon: 'right',
        text: 'Unlink phone'
      }
    ]
  },
  {
    key: 'reward-points',
    icon: 'moneyCollect',
    text: 'Reward points'
  },
  {
    key: 'campaign-info',
    icon: 'fundProjectionScreen',
    text: 'Campaign Info',
    subMenu: [
      {
        key: 'go-to-nail',
        icon: 'right',
        text: 'Go-to-nail'
      },
      {
        key: 'friend-invitation',
        icon: 'right',
        text: 'Friend Invitation'
      },
      {
        key: 'olympic',
        icon: 'right',
        text: 'Olympic'
      },
      {
        key: 'go-to-nail-2',
        icon: 'right',
        text: 'Go-to-nail-2nd'
      },
      {
        key: 'go-to-nail-3',
        icon: 'right',
        text: 'Go-to-nail-3rd'
      },
      {
        key: 'instagram',
        icon: 'right',
        text: 'Instagram'
      }
    ]
  },
  {
    key: 'popup-banner',
    icon: 'switcher',
    text: 'Promotion Popup'
  },
  {
    key: 'banner',
    icon: 'block',
    text: 'Banner'
  },
  {
    key: 'announcement',
    icon: 'notification',
    text: 'Announcement  '
  },
  {
    key: 'setting',
    icon: 'setting',
    text: 'Setting',
    subMenu: [
      {
        key: 'booking-fee',
        icon: 'right',
        text: 'Booking Fee'
      },
      {
        key: 'users-roles',
        icon: 'right',
        text: 'Users and Roles',
        disabled: true
      },
      {
        key: 'activity-logs',
        icon: 'right',
        text: 'Activity logs',
        disabled: true
      }
    ]
  }
];
