const validate = (values) => {
  const errors = {};
  if (!values.subject) {
    errors.subject = 'こちらを記入してください。';
  }
  if (!values.answer) {
    errors.answer = 'こちらを記入してください。';
  } 
  return errors;
};

export default validate;
