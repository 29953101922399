import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Card } from 'antd';

import { fetchNailistList } from 'providers/NailistProvider/actions';
import {
  makeNewNailistList,
  makeSelectLocationLoading
} from 'providers/NailistProvider/selectors';

import './styles.scss';

import columnsTemplate from '../Preset/columnsTemplates';
const columns = [
  columnsTemplate.id,
  columnsTemplate.createdAt,
  columnsTemplate.fullName,
  columnsTemplate.username,
  columnsTemplate.salonName,
  columnsTemplate.status,
  columnsTemplate.email,
  columnsTemplate.phone,
  columnsTemplate.action
];

const NewNailists = ({ nailistList, loading, fetchNailistList }) => {
  useEffect(() => {
    fetchNailistList({
      limit: 20,
      order: 'descend',
      orderBy: '_created_at',
      for: 'newNailistsList'
    });
  }, []);

  const data = nailistList;

  return (
    <Card className="new-nalist-card" id="new-nalist-card">
      <h3>Newest Nailist</h3>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000, y: '50vh' }}
        pagination={false}
        loading={loading}
      />
      <Button block>
        <Link
          to={
            '/dashboard/nailist/search?order=descend&orderBy=_created_at'
          }
        >
          View All New Nailist
        </Link>
      </Button>
    </Card>
  );
};

NewNailists.propTypes = {
  fetchNailistList: PropTypes.func.isRequired,
  nailistList: PropTypes.array,
  loading: PropTypes.any
};

const mapStateToProps = (state) => ({
  nailistList: makeNewNailistList(state),
  loading: makeSelectLocationLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchNailistList: (payload) => dispatch(fetchNailistList(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewNailists);
