import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Space, Divider, Avatar, Image, Spin, Empty } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { fetchBookingMessageList } from 'providers/BookingProvider/actions';
import BookingDirectMessageUserInfo from './UserInfo';
import './styles.scss';

const BookingDirectMessage = () => {
  const dispatch = useDispatch();
  const bookingDetail = useSelector(state => state.booking.booking.detail) || {};
  const { clientId, nailistId } = bookingDetail;
  const bookingMessage = useSelector(state => state.booking.message) || {};
  const { isLoading, meta, messages } = bookingMessage;
  const { user1, user2, hasMore, latestSentAt, inCollection } = meta;

  const messageListWrapperRef = useRef(null);
  const { current = {} } = messageListWrapperRef;
  const messageListRef = useRef(null);
  const [isScroll, setIsScroll] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const messageListWrapperStyle = {};
  if (isScroll) {
    messageListWrapperStyle.overflowY = 'scroll';
  }
  if (isVisible) {
    messageListWrapperStyle.visibility = 'visible';
  }

  const onScroll = async() => {
    if (current && current.scrollTop === 0 && hasMore) {
      const params = {
        nailistId,
        clientId,
        skipMetaUser: '1',
        latestSentAt: latestSentAt.iso,
        inCollection
      };
      await dispatch(fetchBookingMessageList(params));
      current.scrollTo(0, 100);
    }
  };

  useEffect(() => {
    // First fetch
    if (nailistId && clientId) {
      dispatch(fetchBookingMessageList({ nailistId, clientId }));
    }
  }, [nailistId, clientId]);

  useEffect(() => {
    if (!_.isEmpty(meta)) { // After first fetch
      if (messageListRef) { // Check messageListRef inited
        if (messageListRef.current.scrollHeight > 524) { // Message list is overflow
          setIsScroll(true);
        } else if (hasMore) { // Message list has no scroll but hasMore to fetch
          dispatch(fetchBookingMessageList({ nailistId, clientId, skipMetaUser: '1', inCollection }));
        } else { // Message list has no scroll and hasMore
          setIsVisible(true);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (isScroll) { // Message list is overflow
      // Scroll to bottom before messageListWrapper is visible
      current && current.scrollTo(0, current.scrollHeight);
      setIsVisible(true);
    }
  }, [isScroll]);

  return (
    <Card bordered={false} title='Direct message' className='booking-dm'>
      <Card
        className='booking-dm-body'
        title={
          <Space size={16}>
            <BookingDirectMessageUserInfo userInfo={user1} loading={isLoading && _.isEmpty(meta)} />
            <Divider style={{ width: 12, borderColor: '#999999' }} />
            <BookingDirectMessageUserInfo userInfo={user2} loading={isLoading && _.isEmpty(meta)} />
          </Space>
        }
      >
        {isLoading && messages.length === 0 && <Spin spinning={true} className='message-list-spin' size='large' />}
        {!isLoading && messages.length === 0 && hasMore === false &&
          <Empty description={false} className='message-list-empty' />}

        <div ref={messageListWrapperRef} onScroll={onScroll} className='message-list-wrapper'
          style={messageListWrapperStyle}>
          <div ref={messageListRef} className='message-list'>
            {messages.length !== 0 && <Spin spinning={isLoading} size='large' />}
            {messages.map((message, index) => {
              const user = [user1, user2].find(u => u.userId === message.senderId);
              const nextMessage = messages[index - 1];
              const isSameSender = nextMessage && nextMessage.senderId === message.senderId;
              const isNailist = message.senderId === nailistId;
              const createdAt = moment(message.createdAt.iso);
              const now = moment();
              const format = `${now.year() - createdAt.year() > 0 ? 'YYYY/' : ''}MM/DD HH:mm`;

              return <div key={message.messageId}>
                {!isSameSender && <div className="message-user"
                  style={{ flexDirection: isNailist ? 'row-reverse' : 'row' }}>
                  <Avatar src={user.avatar} icon={<UserOutlined />} />
                  <div style={{ width: 8 }} />
                  <Link to={`/dashboard/${user.role.toLowerCase()}/profile/${user.userId}`}>{user.username}</Link>
                </div>}

                <div className="message-wrapper"
                  style={{ flexDirection: isNailist ? 'row-reverse' : 'row' }}>
                  <div style={{ width: 32, flexShrink: 0 }} />
                  {message.image ? <Image src={message.image} className="message-image" /> :
                    <div className="message-text">{message.text}</div>}
                  <div style={{ width: 8 }} />
                  <div className="message-time">{createdAt.utcOffset('+0900').format(format)}</div>
                </div>
              </div>;
            })}
          </div>
        </div>
      </Card>
    </Card>
  );
};

BookingDirectMessage.propTypes = {
};

export default BookingDirectMessage;
