import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import Sales from './Sales';

const mapStateToProps = (state) => ({
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  replace: (path) => dispatch(replace(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
