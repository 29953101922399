import _ from 'lodash';

export const makePayoutPopup = (state) => {
  return _.get(state, 'popup.payout');
};

export const makeCompletePayoutPopup = (state) => {
  return _.get(state, 'popup.completePayout');
};

export const makeAnyPopup = (state, popupName) => {
  return _.get(state, `popup.${popupName}`);
};
