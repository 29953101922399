import produce from 'immer';

import { ME_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL } from './constants';

const initialState = {
  me: {},
  token: '',
  error: ''
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case ME_SUCCESS:
      state.me = action.payload;
      return state;
    case LOGIN_SUCCESS:
      state.token = action.payload;
      return state;
    case LOGIN_FAIL:
      state.error = action.payload;
      return state;
    default:
      return state;
  }
});

export default reducer;
