import { BANK_TYPE } from 'utils/constants';
import UtilValidate from 'utils/validator';

const validate = (values) => {
  const errors = {};

  if (!values.type) {
    errors.type = 'Required';
  }
  if (!values.accountTypeId) {
    errors.accountTypeId = 'Required';
  }
  if (!values.bankId) {
    errors.bankId = 'Required';
  }
  if (!values.branchId) {
    errors.branchId = 'Required';
  }
  if (!values.accountNumber) {
    errors.accountNumber = 'Required';
  } else if (values.accountNumber.length !== 7) {
    errors.accountNumber = 'Invalid';
  }
  if (values.type && values.type === BANK_TYPE.COMPANY) {
    if (!values.accountHolder) {
      errors.accountHolder = 'Required';
    } else if (!UtilValidate.validCharKatakana(values.accountHolder)) {
      errors.accountHolder = 'Invalid';
    }
  }
  if (values.type && values.type === BANK_TYPE.PERSONAL) {
    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (!UtilValidate.validCharKatakana(values.firstName)) {
      errors.firstName = 'Invalid';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (!UtilValidate.validCharKatakana(values.lastName)) {
      errors.lastName = 'Invalid';
    }
  }

  return errors;
};

export default validate;
