const SB_email = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
const SB_phone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/

const validate = (values) => {
  const errors = {};

  if (!values.businessName) {
    errors.businessName = 'Required';
  } else if (values.businessName.length > 50) {
    errors.businessName = 'Too long, max 50 characters';
  }
  if (!values.salonSharedType) {
    errors.salonSharedType = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!SB_email.test(values.email)) {
    errors.email = 'Invalid';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  } else if (!SB_phone.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid';
  }

  return errors;
};

export default validate;
