import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';

const columns = {};

columns._id = {
  key: '_id',
  title: 'ネイリストID',
  render: (record) => {
    const { _id } = record;
    return (
      <Link to={`/dashboard/nailist/profile/${_id}?ref=disabled`}>{_id}</Link>
    );
  }
};

columns.rejoin = {
  fixed: 'right',
  dataIndex: '_id'
};

columns.email = {
  key: 'email',
  title: 'メールアドレス',
  dataIndex: 'email',
  render: (record) => <div style={{ display: 'block', wordBreak: 'break-all' }}>{record}</div>
};
columns.username = {
  key: 'username',
  title: 'ユーザーネーム',
  render: (record) => {
    const { _id, avatar, username } = record;
    return (
      <div style={{ display: 'block', wordBreak: 'break-all' }}>
        <Link to={`/dashboard/nailist/profile/${_id}?ref=disabled`}>
          <Space>
            <Avatar icon={<UserOutlined />} src={avatar.thumbnail} />
            <div>{username}</div>
          </Space>
        </Link>
      </div>
    );
  }
};

columns.fullName = {
  key: 'fullName',
  title: '姓名',
  dataIndex: 'fullName',
  render: (record) => <div style={{ display: 'block', wordBreak: 'break-all' }}>{record}</div>
};

columns.phone = {
  key: 'phone',
  title: '電話番号',
  dataIndex: 'phone',
  render: (record) => <div style={{ whiteSpace: 'nowrap' }}>{record }</div>
};

columns.createdDate = {
  key: '_created_at',
  title: 'アカウント作成日',
  dataIndex: '_created_at',
  render: (record) => moment(record).format('YYYY-MM-DD'),
  sorter: true
};
export default columns;