// Checked
import React from 'react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as ConstructionIcon } from 'assets/icons/under-construction.svg';

const WIPIcon = (props) => {
  return (
    <Tooltip placement="bottomRight" title={'Feature under construction'}>
      <Icon
        {...props}
        component={ConstructionIcon}
        style={{ opacity: 0.7, marginLeft: 8 }}
      />
    </Tooltip>
  );
};

export default WIPIcon;
