import { message as Alert, message } from 'antd';
import { put, call, takeEvery, takeLeading } from 'redux-saga/effects';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import request from 'utils/request';
import {
  fetchDeletedAccountListSuccess,
  fetchDeletedAccountListError,
  exportDeletedAccountCsvSuccess,
  exportDeletedAccountCsvError,
  fetchDeletedUserBookingSuccess,
  fetchDeletedUserBookingError,
  updateUserStatusSuccess,
  updateUserStatusError,
  earlyReleaseAccountError,
  earlyReleaseAccountSuccess
} from './actions';
import {
  FETCH_DELETED_ACCOUNT_LIST,
  FETCH_DELETED_USER_BOOKING,
  EXPORT_DELETED_ACCOUNT_CSV,
  UPDATE_USER_STATUS,
  EARLY_RELEASE_ACCOUNT
} from './constants';
import { convertJSONToCSV, saveFileCSV, splitInfor } from 'utils/common';
import { CSV_DELETED_ACCOUNT_STRUCTURE, USER_STATUS } from 'utils/constants';
import moment from 'moment';

function* handleGetDeletedAccountList(action) {
  try {
    let params = {};

    params = _pick(action.payload, ['page', 'limit', 'sortField', 'direction', 'keyword', 'status', 'role']);

    if (_get(action.payload, 'dateRange[0]')) {
      params.dateFrom = action.payload.dateRange[0];
    }
    if (_get(action.payload, 'dateRange[1]')) {
      params.dateTo = action.payload.dateRange[1];
    }

    if (action.payload.status) {
      params.sortField = action.payload.status;
    }
    if (action?.payload?.keyword) {
      params.keyword = action?.payload?.keyword.trim() || '';
    }
    params.order = action.payload.order || 'descend';
    params.sortField = action.payload.orderBy || 'deleteDate';
    const { data } = yield call(request, 'users/deletedAccounts', params, 'POST');
    yield put(fetchDeletedAccountListSuccess({ data, role: params.role }));
  } catch (e) {
    Alert.error(e.error);
    yield put(fetchDeletedAccountListError());
  }
}

function* handleGetDeletedUserBooking(action) {
  try {
    let params = {};

    params = _pick(action.payload, ['page', 'limit', 'userId']);

    const { data } = yield call(request, `users/deleted/${params.userId}/bookings`, {}, 'GET');
    yield put(fetchDeletedUserBookingSuccess({ data, userId: params.userId }));
  } catch (e) {
    Alert.error(e.error);
    yield put(fetchDeletedUserBookingError());
  }
}

function* handleUpdateUserStatus(action) {
  try {
    let params = {};
    params = _pick(action.payload, ['reason', 'detail', 'status']);
    const userRole = action.payload.role;
    const userId = action.payload.userId;

    const { data } = yield call(request, `${userRole}/${userId}/status`, params, 'PATCH');
    yield put(updateUserStatusSuccess({ data, userId: params.userId }, action.meta));
    Alert.success('Update successfully!');
  } catch (e) {
    if (e?.error?.ja) {
      Alert.error(e?.error?.ja);
    } else {
      Alert.error('既にメールアドレスまたは電話番号が再利用済みのため、アカウントをACTIVEにすることはできません。');
    }
    yield put(updateUserStatusError(e, action.meta));
  }
}

function* handleExportDeletedAccountCSV(action) {
  const params = _pick(action.payload, ['role']);
  try {
    let page = 1;
    let lastPage;

    const dateFrom = _get(action, 'payload.dateRange[0]');
    const dateTo = _get(action, 'payload.dateRange[1]');

    if (dateFrom) {
      params.dateFrom = dateFrom;
    }
    if (dateTo) {
      params.dateTo = dateTo;
    }
    if (action.payload.status) {
      params.status = action.payload.status;
    }
    if (action?.payload?.keyword) {
      params.keyword = action?.payload?.keyword.trim() || '';
    }
    params.order = action.payload.order || 'descend';
    params.sortField = action.payload.orderBy || 'deleteDate';

    const getAllData = async() => {
      const results = [];
      let resListPromises = [];
      while (!lastPage || page <= lastPage) {
        resListPromises.push(request('users/deletedAccounts', { ...params, page, limit: 150 }, 'POST'));
        page++;
        
        if (resListPromises.length >= 5) {
          results.push(..._flatten((await Promise.all(resListPromises)).map(d => d?.data?.data)));
          lastPage = (await resListPromises[0])?.data?.lastPage || 1;
          resListPromises = [];
        }
      }
      results.push(..._flatten((await Promise.all(resListPromises)).map(d => d?.data?.data)));

      return results;
    };

    const list = yield call(getAllData);

    yield put(exportDeletedAccountCsvSuccess(params));

    if (!_isEmpty(list)) {
      const csvContent = convertJSONToCSV(list.map(i =>
        ({...i, 
          deleteDate: moment(i?.deleteDate).utcOffset('+0900').format(),
          username: splitInfor(i.username),
          email: splitInfor(i.email),
          phone: i?.phone ? `\t${i?.phone}` : '',
          deletionType: USER_STATUS[i.deleteType || i.status]?.value
        })),
      CSV_DELETED_ACCOUNT_STRUCTURE);
      const startDate = params?.dateFrom ? `_${dateFrom}` : '';
      const endDate = params?.dateTo ? `-${dateTo}` : '';
      const roleType = params?.role ? params?.role.toLowerCase() : '';
      saveFileCSV(csvContent, `deleted_${roleType}_account${startDate}${endDate}.csv`);
    }
  } catch (e) {
    console.log('csv error', e);
    yield put(exportDeletedAccountCsvError(params));
    Alert.error(e.error);
  }
}

function* handleEarlyReleaseACcount(action) {
  try {
    const { data } = yield call(request, `users/deleted/${action.payload.userId}/release`, { id: action?.payload?.id }, 'PUT');
    yield put(earlyReleaseAccountSuccess(data, action.meta));
    message.success('Email and phone released!');
  } catch (e) {
    yield put(earlyReleaseAccountError(e, action.meta));
    Alert.error(e.error);

  }
}

export default function* watchSetting() {
  yield takeEvery(FETCH_DELETED_ACCOUNT_LIST, handleGetDeletedAccountList);
  yield takeEvery(FETCH_DELETED_USER_BOOKING, handleGetDeletedUserBooking);
  yield takeLeading(EXPORT_DELETED_ACCOUNT_CSV, handleExportDeletedAccountCSV);
  yield takeLeading(UPDATE_USER_STATUS, handleUpdateUserStatus);
  yield takeEvery(EARLY_RELEASE_ACCOUNT, handleEarlyReleaseACcount);
}
