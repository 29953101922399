import {
  fetchSalonListSuccess,
  setSearchSalonLoading,
  createSalonSuccess,
  createSalonFail,
  fetchSalonProfileSuccess,
  unlinkStaffSuccess,
  unlinkStaffFail,
  uploadSalonImageSuccess,
  uploadSalonImageFail,
  updateSalonProfileSuccess,
  updateSalonProfileFail,
  updateSalonEmailSuccess,
  updateSalonEmailFail,
  getAddressByPostCodeSuccess,
  getAddressByPostCodeFail,
  getAllAreasSuccess,
  getAllAreasFail,
  getAllPropertiesSuccess,
  getAllPropertiesFail
} from './actions';
import { message as Alert } from 'antd';
import { SetPopup } from 'providers/PopupProvider/actions';
import { message } from 'antd';
import {
  FETCH_SALON_LIST,
  CREATE_SALON,
  FETCH_SALON_PROFILE,
  UNLINK_STAFF,
  UPDATE_SALON_PROFILE,
  UPDATE_SALON_EMAIL,
  GET_ADDRESS_BY_POST_CODE,
  GET_ALL_AREAS,
  GET_ALL_PROPERTIES,
  UPLOAD_SALON_IMAGE
} from './constants';
import _ from 'lodash';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { mappingS3Domain } from 'utils/mappingS3Domain';

function* fetchSalonList(action) {
  try {
    yield put(setSearchSalonLoading({ loading: true }));

    const { keyword, ...filter} = action.payload;
    const { data } = yield call(request, 'salons/list', { keyword: keyword ? _.trim(keyword) : undefined, ...filter});
    const result = {
      ...data,
      data: data.data.map(s => {
        return {
          ...s,
          salonSharedType: s.salonType
        };
      })
    };
    yield put(fetchSalonListSuccess(result));
    yield put(setSearchSalonLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* createSalon(action) {
  try {
    yield put(setSearchSalonLoading({ loading: true }));
    const { salonSharedType, phoneNumber } = action.payload;

    if (!['REG_A', 'REG_B', 'SHARED', 'FIVE_STAR'].includes(salonSharedType)) {
      throw ({ data: 'Please provide a valid salon type"' });
    }
    if (!phoneNumber) {
      throw ({ data: 'Please provide a valid phone number' });
    }

    const { data } = yield call(request, 'salons/', {
      businessName: action.payload.businessName.trim(),
      salonType: action.payload.salonSharedType.trim(),
      email: action.payload.email.trim(),
      phoneNumber: action.payload.phoneNumber.trim()
    });

    yield put(createSalonSuccess({...data, salonSharedType, isNew: true, success: true}, action.meta));
    yield put(SetPopup({name: 'createSalon', show: false}));
    yield put(setSearchSalonLoading({ loading: false }));
  } catch (e) {
    yield put(createSalonFail({id: e.data, error: true}, action.meta));
    yield put(setSearchSalonLoading({ loading: false }));
    message.error(e.data);
  }
}

function* fetchSalonProfile(action) {
  try {
    const id = _.get(action, 'payload.id');
    const { data } = yield call(request, `salons/${id}`, {}, 'GET');
    yield put(fetchSalonProfileSuccess(data));
  } catch (e) {
    console.log(e);
  }
}
function* unlinkStaff(action) {
  const id = action?.payload;
  try {
    const { data } = yield call(request, 'salons/staff/remove', {
      staffId: id, disableStaff: true }, 'POST');
    yield put(unlinkStaffSuccess(data, action.meta));
    message.success('スタッフを削除しました');
  } catch (e) {
    yield put(unlinkStaffFail(e));
    message.error('該当のネイリストにはまだ完了していない予約があります');
    console.log(e);
  }
}

function* uploadSalonImage(action) {
  try {
    const { file } = action.payload;
    const signedKey = yield call(request, '/salons/media/images/signature', { fileName: file.name, salonId: action.payload.salonId }, 'POST');

    const headers = {
      'Content-Type': file.type
    };
    yield call(request, '', file, 'PUT', { baseURL: signedKey.data.signed, headers });

    yield put(uploadSalonImageSuccess(signedKey.data.url, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(uploadSalonImageFail(error, action.meta));
  }
}

//Update salon 
function* updateSalonProfile(action) {
  try {
    const { businessName, phoneNumber, logoUrl, salonId, area, postCode, prefecture, cityOrTown, building, address, latLng, isPrivate, station, photos, propertyIds, description, listEmail, token } = action.payload;
    yield call(request, `salons/${salonId}`, {
      businessName,
      phoneNumber,
      logoUrl,
      location: {
        postCode,
        prefecture,
        cityOrTown,
        address,
        building,
        latLng,
        area,
        station,
        isPrivate
      },
      description,
      photos,
      propertyIds,
      listEmail
    }, 'PATCH', token);

    yield put(updateSalonProfileSuccess({}, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    if (error?.data) {
      Alert.error(error?.data);
    }
    yield put(updateSalonProfileFail(error?.data, action.meta));
  }
}

//Update salon email
function* updateSalonEmail(action) {
  Alert.info('変更中...');
  try {
    const { newEmail, salonId, listEmail } = action.payload;
    yield call(request, `salons/${salonId}`, {
      email: newEmail,
      listEmail: [ 
        {email: newEmail, label: ''},
        ...listEmail
      ]
    }, 'PATCH');

    yield put(updateSalonEmailSuccess({}, action.meta));
    Alert.destroy();
    Alert.success('設定を変更しました');
  } catch (error) {
    if (error?.data) {
      Alert.destroy();
      Alert.error(error?.data);
    }
    yield put(updateSalonEmailFail(error?.data, action.meta));
  }
}

function* getAddressByPostCode(action) {
  try {
    const { postCode } = action.payload;

    const result = yield call(request, 'salons/media/postal-code-to-address', { postCode }, 'POST');

    yield put(getAddressByPostCodeSuccess(result, action.meta));
  } catch (error) {
    yield put(getAddressByPostCodeFail(error, action.meta));
  }
}

function* getAllAreas(action) {
  try {
    const areas = yield call(request, 'salons/all-areas', {}, 'GET');
    yield put(getAllAreasSuccess(areas, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getAllAreasFail(error, action.meta));
  }
}

function* getAllProperties(action) {
  try {
    const { token } = action.payload;
    const result = yield call(request, 'salons/all-properties', {}, 'GET', token);
    // mapping s3 domain
    result.data = result.data.map(p => {
      return {
        ...p,
        icon: mappingS3Domain(p.icon)
      };
    });

    yield put(getAllPropertiesSuccess(result, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getAllPropertiesFail(error, action.meta));
  }
}

export default function* watchSalon() {
  yield takeLeading(FETCH_SALON_LIST, fetchSalonList);
  yield takeLeading(CREATE_SALON, createSalon);
  yield takeLeading(FETCH_SALON_PROFILE, fetchSalonProfile);
  yield takeLeading(UNLINK_STAFF, unlinkStaff);
  yield takeLeading(UPDATE_SALON_PROFILE, updateSalonProfile);
  yield takeLeading(UPDATE_SALON_EMAIL, updateSalonEmail);
  yield takeEvery(UPLOAD_SALON_IMAGE, uploadSalonImage);
  yield takeEvery(GET_ADDRESS_BY_POST_CODE, getAddressByPostCode);
  yield takeLeading(GET_ALL_AREAS, getAllAreas);
  yield takeLeading(GET_ALL_PROPERTIES, getAllProperties);

}
