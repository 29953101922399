import { connect } from 'react-redux';
import { fetchReceiptList } from 'providers/ReceiptProvider/actions';
import { makeSearchReceiptList } from 'providers/ReceiptProvider/selectors';

import ReceiptEmailList from './ReceiptEmailList';

const mapStateToProps = (state) => ({
  list: makeSearchReceiptList(state).list,
  total: makeSearchReceiptList(state).total
});

const mapDispatchToProps = (dispatch) => ({
  fetchReceiptList: (params) => dispatch(fetchReceiptList(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptEmailList);