import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';

const useTabController = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { activeTab } = routeMatch.params;

  const onChangeTab = (tabKey) => {
    const newPath = generatePath(routeMatch.path, { ...routeMatch.params, activeTab: tabKey });
    history.replace(newPath);
  };

  return { activeTab, onChangeTab};
};

export default useTabController;