/* eslint-disable react/display-name */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Typography, Popover } from 'antd';

import { formatYen } from 'utils/stringFormat';
import { makePayoutDetailBookingsData } from 'providers/BookingProvider/selectors';
import {
  fetchPayoutDetailBookingList,
  resetBookingProvider
} from 'providers/BookingProvider/actions';

const { Text } = Typography;

class Tab2 extends Component {
  componentDidMount() {
    const { loadBookings, payoutData } = this.props;

    loadBookings({
      page: 0,
      incomeId: _.get(payoutData, '_id'),
      payoutType: 'CURRENT_PERIOD_SALON',
      nailistId: _.get(payoutData, 'nailistId')
    });
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { bookings } = this.props;
    if (!bookings) {
      return null;
    }

    const columns = [
      {
        title: 'Booking ID',
        dataIndex: '_id',
        key: 'name',
        fixed: 'left'
      },
      {
        title: 'Salon ID',
        dataIndex: 'salonId',
        key: 'salonId',
        filters: _.uniqBy(bookings.list, 'salonId').map((o) => ({
          text: o.salonId,
          value: o.salonId
        })),
        onFilter: (value, record) => record.salonId === value
      },
      {
        title: 'Booking Date',
        dataIndex: 'treatmentDate',
        render: (record) => moment(record).format('YYYY-MM-DD'),
        key: 'treatmentDate'
      },
      {
        title: 'Charged Date',
        dataIndex: 'chargeDate',
        render: (record) => moment(record).format('YYYY-MM-DD HH:mm'),
        key: 'chargeDate'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (record) => <Tag>{record}</Tag>,
        key: 'status'
      },
      {
        title: 'Total fee',
        dataIndex: 'chargedFinal',
        render: (record, obj) => (
          <div style={{ textAlign: 'right' }}>
            <Popover
              placement="rightTop"
              content={
                <div>
                  {(!obj.bkFeePercent && obj.bkFeePercent !== 0)
                    ? null
                    : `${Math.round((obj.bkFeePercent * 10000) / 100)}% for ${
                      obj.isFirstOnNailist
                        ? 'New customer'
                        : 'Repeated customer'
                    }`}
                </div>
              }
            >
              <div>
                <Text underline strong>
                  {formatYen(record)}
                </Text>
              </div>
            </Popover>
          </div>
        ),
        key: 'chargedFinal'
      }
    ];

    return (
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={bookings.list}
        pagination={{
          showSizeChanger: false
        }}
      />
    );
  }
}

Tab2.propTypes = {
  payoutData: PropTypes.any,
  bookings: PropTypes.any,
  loadBookings: PropTypes.func,
  reset: PropTypes.func
};

const mapStateToProps = (state) => ({
  bookings: makePayoutDetailBookingsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadBookings: (params) => dispatch(fetchPayoutDetailBookingList(params)),
  reset: () => dispatch(resetBookingProvider())
});

export default connect(mapStateToProps, mapDispatchToProps)(Tab2);
