export const FETCH_DELETED_ACCOUNT_LIST = '@Deleted/FETCH_DELETED_ACCOUNT_LIST';
export const FETCH_DELETED_ACCOUNT_LIST_SUCCESS = '@Deleted/FETCH_DELETED_ACCOUNT_LIST_SUCCESS';
export const FETCH_DELETED_ACCOUNT_LIST_ERROR = '@Deleted/FETCH_DELETED_ACCOUNT_LIST_ERROR';

export const FETCH_DELETED_USER_BOOKING = '@Deleted/FETCH_DELETED_USER_BOOKING';
export const FETCH_DELETED_USER_BOOKING_SUCCESS = '@Deleted/FETCH_DELETED_USER_BOOKING_SUCCESS';
export const FETCH_DELETED_USER_BOOKING_ERROR = '@Deleted/FETCH_DELETED_USER_BOOKING_ERROR';

export const EXPORT_DELETED_ACCOUNT_CSV = '@DeletedAccountProvider/EXPORT_DELETED_ACCOUNT_CSV';
export const EXPORT_DELETED_ACCOUNT_CSV_SUCCESS = '@DeletedAccount/EXPORT_DELETED_ACCOUNT_CSV_SUCCESS';
export const EXPORT_DELETED_ACCOUNT_CSV_ERROR = '@DeletedAccount/EXPORT_DELETED_ACCOUNT_CSV_ERROR';

export const UPDATE_USER_STATUS = '@DeletedAccountProvider/UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_SUCCESS = '@DeletedAccount/UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = '@DeletedAccount/UPDATE_USER_STATUS_ERROR';

export const EARLY_RELEASE_ACCOUNT = '@DeletedAccountProvider/EARLY_RELEASE_ACCOUNT';
export const EARLY_RELEASE_ACCOUNT_SUCCESS = '@DeletedAccount/EARLY_RELEASE_ACCOUNT_SUCCESS';
export const EARLY_RELEASE_ACCOUNT_ERROR = '@DeletedAccount/EARLY_RELEASE_ACCOUNT_ERROR';