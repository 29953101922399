// Checked
export const GET_BOOKING_FEE_SETTING = 'GET_BOOKING_FEE_SETTING';
export const GET_BOOKING_FEE_SETTING_SUCCESS = 'GET_BOOKING_FEE_SETTING_SUCCESS';
export const SET_BOOKING_FEE_SETTING = 'SET_BOOKING_FEE_SETTING';
export const SET_BOOKING_FEE_SETTING_SUCCESS = 'SET_BOOKING_FEE_SETTING_SUCCESS';

// Get setting salon properties
export const SETTING_SALON_PROPERTIES = 'SETTING_SALON_PROPERTIES';
export const SETTING_SALON_PROPERTIES_SUCCESS = 'SETTING_SALON_PROPERTIES_SUCCESS';
export const SETTING_SALON_PROPERTIES_FAIL = 'SETTING_SALON_PROPERTIES_FAIL';
