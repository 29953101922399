import React, { useEffect, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Card, Row, Col, Space, Button, DatePicker, Spin } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, RedoOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import _chunk from 'lodash/chunk';
import _keys from 'lodash/keys';
import qs from 'querystring';
import moment from 'moment';

import { fetchBookingSchedule, fetchBookingList } from 'providers/BookingProvider/actions';
import PageHeader from 'components/PageHeader';
import NumberBookingMonth from './NumberBookingMonth';
import useViewBookingList from './useViewBookingList';
import './styles.scss';

const dictEvent_Status_Position = [
  'REQUESTED',
  'CONFIRMED',
  'WAITING_DONE',
  'DONE',
  'EXPIRED',
  'DECLINED_REQUEST',
  'DECLINED_RESERVATION',
  'CANCELED_REQUEST',
  'CANCELED_RESERVATION',
  'UNDONE',
  'NOVISIT',
  'NAILIST_CANCELED',
  'NAILIE_CANCELED'
];

const bookingStatusList = [
  'REQUESTED', 'CONFIRMED', 'WAITING_DONE', 'DONE',
  'CANCELED_REQUEST', 'CANCELED_RESERVATION', 'DECLINED_REQUEST', 'DECLINED_RESERVATION',
  'EXPIRED', 'NOVISIT', 'NAILIST_CANCELED', 'UNDONE', 'NAILIE_CANCELED'
];

const bookingStatusMap = {
  REQUESTED: {
    color: '#43959A',
    text: 'リクエスト中'
  },
  CONFIRMED: {
    color: '#417CD7',
    text: '確定中'
  },
  WAITING_DONE: {
    color: '#C23836',
    text: 'お会計待ち'
  },
  DONE: {
    color: '#569B30',
    text: '施術完了'
  },
  CANCELED_REQUEST: {
    color: '#203DBC',
    text: 'カスタマーキャンセル（リクエスト）'
  },
  CANCELED_RESERVATION: {
    color: '#C6702B',
    text: 'カスタマーキャンセル（確定済み予約）'
  },
  DECLINED_REQUEST: {
    color: '#203DBC',
    text: 'ネイリストキャンセル（リクエスト）'
  },
  DECLINED_RESERVATION: {
    color: '#C7722E',
    text: 'ネイリストキャンセル（施術前予約）'
  },
  EXPIRED: {
    color: '#C44626',
    text: '期限切れ'
  },
  NOVISIT: {
    color: '#CF9645',
    text: '不来店'
  },
  NAILIST_CANCELED: {
    color: '#B4337D',
    text: 'ネイリストキャンセル（施術後）'
  },
  UNDONE: {
    color: '#4C27A4',
    text: '未施術'
  },
  NAILIE_CANCELED: {
    color: '#252525',
    text: '事務局キャンセル'
  }
};

const SchedulePage = () => {
  const dispatch = useDispatch();
  const [openViewBookingListPopup, ViewBookingListPopup] = useViewBookingList();

  const history = useHistory();
  const { location } = history;
  const { search, pathname } = location;
  const { date: searchDateString } = qs.parse(search.replace(/^\?/, ''));
  const searchDate = moment(searchDateString);
  const now = moment();
  const date = searchDate.isValid() ? searchDate : now;
  const dateString = date.format('YYYY-MM');

  const calendarRef = useRef();
  const { current } = calendarRef;
  const calendarApi = current && current.getApi();

  const { schedule, isLoading } = useSelector(state => state.booking.schedule);
  const { total = {}, dates = [] } = schedule;

  const events = [];
  dates.forEach(({ day, status }) => {
    dictEvent_Status_Position.forEach(key => {
      if (!_keys(status).includes(key)) {
        return;
      }

      const { color, text } = bookingStatusMap[key];

      events.push({
        date: day,
        treatmentDate: day,
        type: key,
        text,
        total: status[key],
        totalColor: `${color}99`,
        color: `${color}1A`,
        textColor: 'black'
      });
    });
  });

  const getBookingSchedule = () => dispatch(fetchBookingSchedule(dateString)).then(() => {
    if (calendarApi) {
      calendarApi.gotoDate(dateString);
    }
  });

  useEffect(() => {
    getBookingSchedule();
  }, [searchDateString]);

  const setSearch = (date) => history.push(`${pathname}?${qs.stringify({ date })}`);

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Schedule</title>
      </Helmet>
      <PageHeader
        title="Schedule"
        breadcrumb={PageHeader.Bread.schedule}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <Spin spinning={isLoading}>
          <Card bordered={false}>
            <Row gutter={16} style={{ marginBottom: 32 }}>
              {_chunk(bookingStatusList, 4).map((chunk, index) =>
                <Col span={6} key={index}>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {chunk.map(status =>
                      <NumberBookingMonth key={status} total={total[status]} status={bookingStatusMap[status]} />
                    )}
                  </Space>
                </Col>
              )}
            </Row>

            <Space style={{ marginBottom: 24 }}>
              <Button
                className='headerToolbar-button'
                onClick={() => setSearch(date.subtract(1, 'M').format('YYYY-MM'))}
              >
                <CaretLeftOutlined />
              </Button>
              <DatePicker
                value={date}
                picker="month"
                onChange={(_, dateString) => setSearch(dateString)}
                allowClear={false}
              />
              <Button
                className='headerToolbar-button'
                onClick={() => setSearch(date.add(1, 'M').format('YYYY-MM'))}
              >
                <CaretRightOutlined />
              </Button>
              <Button
                className='headerToolbar-button'
                onClick={getBookingSchedule}
              >
                <RedoOutlined />
              </Button>
              <Button
                className='headerToolbar-button'
                style={{ padding: '4px 16px' }}
                onClick={() => setSearch(now.format('YYYY-MM'))}
              >
                Today
              </Button>
            </Space>

            <FullCalendar
              initialDate={dateString}
              ref={calendarRef}
              plugins={[dayGridPlugin]}
              events={events}
              eventContent={({ event: { extendedProps } }) => {
                const { text, totalColor, total } = extendedProps;

                return <Space className='eventContent'>
                  <div className='eventContent-text'>{text}</div>
                  <div style={{ color: totalColor, fontWeight: 700 }}>{total}</div>
                </Space>;
              }}
              eventClick={({ event: { extendedProps } }) => {
                const { treatmentDate, type } = extendedProps;
                dispatch(fetchBookingList({ page: 1, perPage: 10, treatmentDate, type }));
                openViewBookingListPopup({ treatmentDate, type });
              }}
              dayCellContent={({ isToday, isOther, dayNumberText }) => {
                let color = 'black';
                if (isToday) {
                  color = '#E5004E';
                }
                if (isOther) {
                  color = '#D9D9D9';
                }

                return <div style={{ color }} className="dayCellContent">{dayNumberText}</div>;
              }}
              dayHeaderContent={({ text }) => <div className='dayHeaderContent'>{text}</div>}
              dayHeaderClassNames="dayHeaderClassNames"
              height="auto"
              fixedWeekCount={false}
              headerToolbar={false}
            />
          </Card>
        </Spin>
      </div>

      {ViewBookingListPopup}
    </Fragment>
  );
};

export default SchedulePage;
