import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_PAYOUTS_LIST,
  FETCH_PAYOUTS_LIST_SUCCESS,
  RESET,
  SET_LOADING_PAYOUT,
  EDIT_BANK_FEE_SUCCESS,
  FETCH_CHANGED_PAYOUTS,
  FETCH_CHANGED_PAYOUTS_SUCCESS,
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  detail: {},
  count: {},
  recalculate: {
    list: [],
    loading: false
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYOUTS_LIST:
      state.search = {
        list: [],
        total: 0,
        lastPage: 1,
        loading: false
      };
      return state;

    case FETCH_PAYOUTS_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data', []).map((payout) => ({
        ...payout,
        key: payout._id
      }));
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;

    case SET_LOADING_PAYOUT:
      state.search.loading = _.get(action, 'payload.loading');
      return state;

    case EDIT_BANK_FEE_SUCCESS:
      state.search.list = state.search.list.map((payout) => {
        if (payout._id == _.get(action, 'payload.payoutId')) {
          payout = {
            ...payout,
            ..._.omit(_.get(action, 'payload.bankFee'), '_id')
          };
        }
        return payout;
      });
      return state;

    case FETCH_CHANGED_PAYOUTS:
      state.recalculate.loading = true;
      return state;
    
    case FETCH_CHANGED_PAYOUTS_SUCCESS:
      state.recalculate = {
        loading: false,
        list: _.get(action, 'payload'),
      };
      return state;

    case RESET:
      return initialState;

    default:
      return state;
  }
});

export default reducer;
