import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Divider, Button, Space, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import MyCarousel from 'components/Carousel';
import totalLikeIcon from 'assets/icons/total-like.png';
import { DeleteOutlined } from '@ant-design/icons';
import { deletePost, forceDeletePost } from 'providers/NailistProvider/actions';
import { formatYen } from 'utils/stringFormat';


const usePostDetail = (postDetail = {}, nailistId, fetchNailistPosts, onClose) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  let postImgLists = postDetail?.nailImages;

  const handleDeletePost = (id, postId) => {
    Modal.confirm({
      title: 'Confirm',
      centered: true,
      content: 'Are you sure want to delete this post?',
      onOk:
        async() => {
          await dispatch(deletePost({ id, postId }));
          setIsOpen(false);
          onClose();
        },

      width: '560px'
    });
  };

  function HighlightHashtag(text, maxHashtag) {
    if (text.includes('#')) {
      const regex = /#[a-zA-Z0-9]+/g;
      let count = 0;
      const highlightedText = text.replace(regex, (match) => {
        if (count >= maxHashtag) {
          return match;
        }
        count++;
        return '<span style="color: #009092; font-weight: bold;"">' + match + '</span>';
      });
      return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;

    } else {
      return <div>{text}</div>;
    }
  }
  
  const PostDetail = () => {
    const caption = postDetail?.caption;
    const maxHashtag = postDetail?.maxHashtagHighlight || 10;
    let highlightedHashtags = '';
    if (caption !== undefined) {
      highlightedHashtags = HighlightHashtag(caption, maxHashtag);
    }
    return (
      <div style={{ marginTop: 40 }}>
        {/* count like, date */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: 16, height: 16, marginRight: 8 }}
            src={totalLikeIcon}
          />
          <span style={{
            fontSize: 14, fontWeight: 500, marginRight: 24
          }}>{postDetail?.bookmarkCount} いいね！</span>
          <span style={{ fontSize: 14, fontWeight: 400, color: '#000000', opacity: 0.4 }}>
            {moment(postDetail?.createdAt?.iso).format('YYYY/MM/DD')}
          </span>
        </div>
        {/* post content */}
        <div style={{ fontSize: 14, fontWeight: 400, color: '#000000', opacity: 0.8, marginTop: 16 }}>
          {highlightedHashtags}
        </div>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {
            postDetail?.taggedMenu?.state === 'ACTIVE' ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '50%',
                  borderRadius: 8,
                  backgroundColor: '#F5F5F5',
                  padding: '16px 16px',
                  fontWeight: 500,
                  fontSize: 14
                }}>
                <span >{postDetail?.taggedMenu?.title}</span>
                <span>{formatYen(postDetail?.taggedMenu?.price)}</span>
              </div> : <div></div>
          }
          <Space>
            <DeleteOutlined style={{ color: '#EA4747', marginRight: -16 }} />
            <Button
              type="link"
              danger
              disabled={postDetail?.code === 500}
              onClick={() => handleDeletePost(nailistId, postDetail?.objectId)}

            >Delete post</Button>
          </Space>
        </div>


      </div>
    );
  };


  const Popup = <Modal
    title='Post detail'
    visible={isOpen}
    destroyOnClose
    footer={null}
    onCancel={() => {
      setIsOpen(false);
      postImgLists = undefined;
      if (postDetail?.deletedPostId) { //id for remove this post from reducer
        dispatch(forceDeletePost(postDetail?.deletedPostId));
      }
      onClose();
    }
    }
    width={980}
  >
    {
      postDetail?.code === 500 ? <div style={{height: 520}}></div> :
        <MyCarousel imgLists={postImgLists} postDetail={postDetail} />
    }
    <PostDetail />
  </Modal>;

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default usePostDetail;