import React, { useState } from 'react';
import { Form, Input, Select, Button, Spin } from 'antd';

import { unlinkPhoneAuth } from 'providers/SupportProvider/actions';
import { useDispatch } from 'react-redux';

const { Option } = Select;

const UnlinkPhonePage = () => {
  const dispatch = useDispatch();
  const [ spinning, setSpinning ] = useState(false);

  async function onFinish(values) {
    setSpinning(true);
    await dispatch(unlinkPhoneAuth(values));

    setSpinning(false);
  }

  return (
    <Spin spinning={spinning}>
      <Form onFinish={onFinish} layout='vertical'>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            { required: true, message: 'Please input your phone number!' },
            { pattern: /^(?!\s+$).+/, message: 'Please input your phone number!' }
          ]}
        >
          <Input style={{ width: '100%' }} placeholder='07080008000'/>
        </Form.Item>

        <Form.Item
          name="unlinkOption"
          label="Command"
          rules={[{ required: true, message: 'Please select an option!' }]}
        >
          <Select placeholder="Select an option">
            <Option value="unlinkPhoneAuthProduction">unlinkPhoneAuth</Option>
            <Option value="unlinkPhoneAuthKeepPhone">unlinkPhoneAuthKeepPhone</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UnlinkPhonePage;
