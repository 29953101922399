import _ from 'lodash';

export const makeSearchPayoutsList = (state) => {
  return _.get(state, 'payout.search.list');
};

export const makeSearchPayoutTotal = (state) => {
  return _.get(state, 'payout.search.total');
};

export const makePayoutLoading = (state) => {
  return _.get(state, 'payout.search.loading');
};

export const makeChangedPayoutList = (state) => {
  return _.get(state, 'payout.recalculate.list');
};

export const makeChangePayoutLoading = (state) => {
  return _.get(state, 'payout.recalculate.loading');
};
