import {
  FETCH_PAYOUTS_LIST,
  FETCH_PAYOUTS_LIST_SUCCESS,
  SET_LOADING_PAYOUT,
  COMPLETE_ALL_PAYOUTS,
  COMPLETE_PAYOUTS,
  COMPLETE_PAYOUTS_FAIL,
  COMPLETE_PAYOUTS_SUCCESS,
  EDIT_BANK_FEE,
  EDIT_BANK_FEE_SUCCESS,
  EDIT_BANK_FEE_FAIL,
  REQUEST_PAYOUT_FILE,
  REQUEST_PAYOUT_FILE_SUCCESS,
  FETCH_CHANGED_PAYOUTS,
  FETCH_CHANGED_PAYOUTS_SUCCESS,
  FETCH_CHANGED_PAYOUTS_FAIL,
  EXEC_CHANGE_PAYOUT,
  EXEC_CHANGE_PAYOUT_SUCCESS,
  EXEC_CHANGE_PAYOUT_FAIL,
  EXEC_CHANGE_ALL_PAYOUT,
  EXEC_CHANGE_ALL_PAYOUT_SUCCESS,
  EXEC_CHANGE_ALL_PAYOUT_FAIL
} from './constants';

export function fetchPayoutsList(payload = {}) {
  return { type: FETCH_PAYOUTS_LIST, payload };
}

export function fetchPayoutsListSuccess(payload = {}) {
  return { type: FETCH_PAYOUTS_LIST_SUCCESS, payload };
}

export function setPayoutLoading(payload = {}) {
  return { type: SET_LOADING_PAYOUT, payload };
}

export function completeAllPayouts(payload = {}) {
  return {
    type: COMPLETE_ALL_PAYOUTS,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function completePayouts(payload = {}) {
  return {
    type: COMPLETE_PAYOUTS,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function completePayoutFail(payload, meta) {
  return {
    type: COMPLETE_PAYOUTS_FAIL,
    payload,
    error: true,
    meta
  };
}

export function completePayoutsSuccess(payload = {}, meta) {
  return { type: COMPLETE_PAYOUTS_SUCCESS, payload, meta };
}

export function editBankFee(payload = {}) {
  return {
    type: EDIT_BANK_FEE,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function editBankFeeSuccess(payload = {}, meta) {
  return {
    type: EDIT_BANK_FEE_SUCCESS,
    payload,
    meta
  };
}

export function editBankFeeFail(payload, meta) {
  return {
    type: EDIT_BANK_FEE_FAIL,
    payload,
    error: true,
    meta
  };
}

export function requestPayoutFile(payload = {}) {
  return {
    type: REQUEST_PAYOUT_FILE,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function requestPayoutFileSuccess(payload = {}, meta) {
  return {
    type: REQUEST_PAYOUT_FILE_SUCCESS,
    payload,
    meta
  };
}

export function fetchChangedPayouts(payload = {}) {
  return {
    type: FETCH_CHANGED_PAYOUTS,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function fetchChangedPayoutSuccess(payload = {}, meta) {
  return {
    type: FETCH_CHANGED_PAYOUTS_SUCCESS,
    payload,
    meta
  };
}

export function fetchChangedPayoutsFail(payload = {}) {
  return {
    type: FETCH_CHANGED_PAYOUTS_FAIL,
    payload,
  };
}

export function execChangePayout(payload = {}) {
  return {
    type: EXEC_CHANGE_PAYOUT,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function execChangePayoutSuccess(payload = {}, meta) {
  return {
    type: EXEC_CHANGE_PAYOUT_SUCCESS,
    payload,
    meta,
  };
}

export function execChangePayoutFail(payload = {}) {
  return {
    type: EXEC_CHANGE_PAYOUT_FAIL,
    payload,
    meta: {
      thunk: true
    },
    error: true
  };
}

export function execChangeAllPayout(payload = {}) {
  return {
    type: EXEC_CHANGE_ALL_PAYOUT,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function execChangeAllPayoutSuccess(payload = {}, meta) {
  return {
    type: EXEC_CHANGE_ALL_PAYOUT_SUCCESS,
    payload,
    meta,
  };
}

export function execChangeAllPayoutFail(payload = {}) {
  return {
    type: EXEC_CHANGE_ALL_PAYOUT_FAIL,
    payload,
    meta: {
      thunk: true
    },
    error: true
  };
}


