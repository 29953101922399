import produce from 'immer';

import {
  GET_BANK_LIST,
  GET_BANK_LIST_SUCCESS,
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_SUCCESS,
  GET_ACCOUNT_TYPE_LIST,
  GET_ACCOUNT_TYPE_LIST_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAILED
} from './constants';

const initialState = {
  dataLoading: false,
  actionLoading: false,
  bankList: {},
  branchList: {},
  accountTypeList: {},
  error: {}
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_LIST:
      state.dataLoading = true;
      state.bankList = {};
      return state;

    case GET_BANK_LIST_SUCCESS:
      state.dataLoading = false;
      state.bankList = action.payload.data;
      return state;

    case GET_BRANCH_LIST:
      state.dataLoading = true;
      state.branchList = {};
      return state;

    case GET_BRANCH_LIST_SUCCESS:
      state.dataLoading = false;
      state.branchList = action.payload.data;
      return state;

    case GET_ACCOUNT_TYPE_LIST:
      state.dataLoading = true;
      state.accountTypeList = {};
      return state;

    case GET_ACCOUNT_TYPE_LIST_SUCCESS:
      state.dataLoading = false;
      state.accountTypeList = action.payload.data;
      return state;

    case UPDATE_BANK_ACCOUNT:
      state.actionLoading = true;
      return state;

    case UPDATE_BANK_ACCOUNT_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_BANK_ACCOUNT_FAILED:
      state.actionLoading = false;
      state.error = action.payload.error;
      return state;

    default:
      return state;
  }
});

export default reducer;
