import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onOk = (e) => {
    this.props.updateDataField(e);
  };

  render() {
    return (
      <div>
        <DatePicker
          placeholder="Transfer Date"
          allowClear={false}
          defaultValue={moment()}
          format={'YYYY-MM-DD'}
          showTime
          onOk={this.onOk}
        />
      </div>
    );
  }
}

Content.defaultProps = {
  profile: {}
};

Content.propTypes = {
  data: PropTypes.any,
  updateDataField: PropTypes.func
};

export default Content;
