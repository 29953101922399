export default {
  set: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get: (key) => {
    try {
      const rawValue = localStorage.getItem(key);
      return JSON.parse(rawValue);
    } catch {
      return {};
    }
  }
};
