import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  TextField,
  Select,
  RadioField,
  NumericInput,
  UploadImage
} from 'components/Form';
import { BANK_TYPE } from 'utils/constants';
import validate from './validate';

import './styles.scss';

const formItemLayout = {
  labelCol: {
    xs: { span: 21 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 21 },
    sm: { span: 16 }
  }
};

const BankForm = ({
  handleSubmit,
  bankOptions,
  branchOptions,
  accountTypeOptions,
  bankId,
  type,
  ...props
}) => {
  const handleBankChange = (value) => {
    const { change, onBankChange } = props;
    change('branchId', '');
    onBankChange(value);
  };
  const handleBankTypeChange = (value) => {
    const { change } = props;
    change('firstName', '');
    change('lastName', '');
    change('accountHolder', '');
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} className="bank-form">
      <Field
        name="type"
        label="振込選択"
        component={RadioField}
        onFormValueChange={handleBankTypeChange}
        options={[
          {
            value: BANK_TYPE.PERSONAL,
            label: '個人'
          },
          {
            value: BANK_TYPE.COMPANY,
            label: '法人'
          }
        ]}
      />
      <Field
        name="accountTypeId"
        label="口座種別"
        placeholder="口座種別"
        allowClear={false}
        component={Select}
        options={accountTypeOptions}
      />
      <Field
        name="bankId"
        label="銀行名"
        placeholder="銀行名"
        allowClear={false}
        showSearch={true}
        filterOption={(input, { children }) =>
          children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        component={Select}
        options={bankOptions}
        onFormValueChange={handleBankChange}
      />
      <Field
        name="branchId"
        label="支店名"
        placeholder="支店名"
        allowClear={false}
        showSearch={true}
        filterOption={(input, { children }) =>
          children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        component={Select}
        options={branchOptions}
        disabled={!bankId}
      />
      <Field
        name="accountNumber"
        label="口座番号"
        placeholder="口座番号"
        component={NumericInput}
        maxLength={7}
      />
      {type === BANK_TYPE.PERSONAL && (
        <>
          <Field
            name="firstName"
            label="口座名義 （メイ）"
            placeholder="口座名義 （メイ）"
            component={TextField}
          />
          <Field
            name="lastName"
            label="口座名義 （セイ）"
            placeholder="口座名義 （セイ）"
            component={TextField}
          />
        </>
      )}
      {type === BANK_TYPE.COMPANY && (
        <>
          <Field
            name="accountHolder"
            label="口座名義"
            placeholder="口座名義"
            component={TextField}
          />
          <Field
            name="photo"
            label="通帳の写真"
            placeholder="通帳の写真"
            component={UploadImage}
          />
        </>
      )}
    </Form>
  );
};

BankForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  bankOptions: PropTypes.array,
  branchOptions: PropTypes.array,
  accountTypeOptions: PropTypes.array,
  onBankChange: PropTypes.func,
  change: PropTypes.any,
  bankId: PropTypes.any,
  type: PropTypes.any
};

const selector = formValueSelector('updateBankForm');

export default connect((state) => {
  const bankId = selector(state, 'bankId');
  const type = selector(state, 'type');
  return {
    bankId,
    type
  };
})(
  reduxForm({ form: 'updateBankForm', validate, destroyOnUnmount: true })(
    BankForm
  )
);
