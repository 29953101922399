import { connect } from 'react-redux';


import CustomerDetail from './CustomerDetail';
import {
  fetchCustomerProfile, unsetCustomerProfile,
  fetchCustomerPointInfo, fetchCustomerPointInfoSuccess,
  fetchCustomerPointHistory, fetchCustomerPointHistorySuccess
} from 'providers/CustomerProvider/actions';
import {
  makeCustomerProfile, makeCustomerPointInfo, makeCustomerPointHistory
} from 'providers/CustomerProvider/selectors';
import { updateUserStatus } from 'providers/DeletedAccountProvider/actions';
import { getFriendInvitationList } from 'providers/FriendInvitationProvider/actions';
import {
  makeFriendInvitationList,
  makeFriendInvitationLoading
} from 'providers/FriendInvitationProvider/selectors';

const mapStateToProps = (state) => ({
  profile: makeCustomerProfile(state),
  pointInfo: makeCustomerPointInfo(state),
  pointHistory: makeCustomerPointHistory(state),
  friendInvitationLoading: makeFriendInvitationLoading(state),
  friendInvitationList: makeFriendInvitationList(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerProfile: (params) => dispatch(fetchCustomerProfile(params)),
  unsetCustomerProfile: () => dispatch(unsetCustomerProfile()),
  updateUserStatus: (params) => dispatch(updateUserStatus(params)),
  getFriendInvitationList: (params) => dispatch(getFriendInvitationList(params)),
  fetchCustomerPointInfo: (params) => dispatch(fetchCustomerPointInfo(params)),
  fetchCustomerPointInfoSuccess: (params) => dispatch(fetchCustomerPointInfoSuccess(params)),
  fetchCustomerPointHistory: (params) => dispatch(fetchCustomerPointHistory(params)),
  fetchCustomerPointHistorySuccess: (params) => dispatch(fetchCustomerPointHistorySuccess(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);