import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_BOOKING_SCHEDULE,
  FETCH_BOOKING_SCHEDULE_SUCCESS,
  OPEN_SCHEDULE_POPUP_LIST,
  CLOSE_SCHEDULE_POPUP_LIST
} from './constants';

import {
  FETCH_BOOKING_LIST,
  FETCH_BOOKING_LIST_SUCCESS
} from 'providers/BookingProvider/constants';

const initialState = {
  schedule: {},
  isLoading: false,
  bookingPopupControl: null, // null || { treatmentDate, type }
  booking: {
    page: 1,
    list: [],
    total: 0,
    isLoading: true
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOKING_SCHEDULE:
      state.isLoading = true;
      return state;

    case FETCH_BOOKING_SCHEDULE_SUCCESS:
      state.isLoading = false;
      state.schedule = _.get(action, 'payload.data', {});
      return state;

    case OPEN_SCHEDULE_POPUP_LIST:
      state.bookingPopupControl = _.get(action, 'payload', {});
      return state;
    case CLOSE_SCHEDULE_POPUP_LIST:
      state.bookingPopupControl = null;
      return state;

    case FETCH_BOOKING_LIST:
      state.booking.isLoading = true;
      return state;
    case FETCH_BOOKING_LIST_SUCCESS:
      state.booking.page = _.get(action, 'payload.data.page', 1);
      state.booking.list = _.get(action, 'payload.data.data', []);
      state.booking.total = _.get(action, 'payload.data.total', 0);
      state.booking.isLoading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
