import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, Upload, message } from 'antd';
import { PlusOutlined, DeleteTwoTone } from '@ant-design/icons';
import { MAX_IMAGE_SIZE } from 'utils/config';

import './styles.scss';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg'
];

/**
 * 
 * @param {Object<Image>} input.value of Image
 * @param {String<URL>} input.value.data of image url Image
 * @param {String<ObjectId>} input.value._id of Image 
 * @returns 
 */
const UploadImage = ({ label, input, meta }) => {
  const { touched, invalid, error } = meta;

  const validateFile = (file) => {
    let error;
    if (!acceptedFileExtensions.includes(file.type)) {
      error = 'File extension not allowed';
    }
    if (file.size > MAX_IMAGE_SIZE) {
      error = `File size must smaller than ${MAX_IMAGE_SIZE / 1e6}MB`;
    }

    if (error) {
      message.error(error);
      return false;
    }

    return true;
  };

  const onChange = ({ file }) => {
    if (file.originFileObj && validateFile(file.originFileObj)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        input.onChange({data: reader.result});
      };
      reader.readAsDataURL(file.originFileObj);
    }
  };

  const onRemove = () => {
    input.onChange(null);
  };

  return (
    <Form.Item
      label={label}
      help={touched && invalid && error}
      validateStatus={touched && invalid && error && 'error'}
    >
      <Badge
        count={input.value ? <DeleteTwoTone onClick={onRemove} /> : 0}
        offset={[-10, 0]}
      >
        <Upload
          listType="picture-card"
          showUploadList={false}
          multiple={false}
          accept="image/*"
          onChange={onChange}
        >
          {input.value ? (
            <img
              src={input.value.data}
              alt="uploadimage"
              style={{ width: '100%' }}
            />
          ) : (
            <>
              <PlusOutlined />
              <div style={{ marginLeft: 8 }}>Upload</div>
            </>
          )}
        </Upload>
      </Badge>
    </Form.Item>
  );
};

UploadImage.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default UploadImage;
