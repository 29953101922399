import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

// input.value is moment format
class RangePickerField extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      input,
      meta: { touched, invalid, error },
      usingStringFormat = false
    } = this.props;
    delete input.onBlur;

    let { value } = input;
    let onChange;
    if (usingStringFormat) {
      value = input.value && [input.value[0] && moment(input.value[0]), input.value[1] && moment(input.value[1]) ];
      onChange = (v) => input.onChange(v && [v[0] && v[0].format('YYYY-MM-DD'), v[1] && v[1].format('YYYY-MM-DD') ]);
    }

    return (
      <Form.Item label={label} help={touched && invalid && error}>
        <RangePicker
          {...this.props}
          {...input}
          placeholder={placeholder}
          value={value}
          {...(onChange ? { onChange } : {})}
        />
      </Form.Item>
    );
  }
}

RangePickerField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.any,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  usingStringFormat: PropTypes.bool
};

export default RangePickerField;
