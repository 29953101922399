import produce from 'immer';

import {
  GET_FRIEND_INVITATION_LIST,
  GET_FRIEND_INVITATION_LIST_SUCCESS,
  EXPORT_FRIEND_INVITATION_LIST,
  EXPORT_FRIEND_INVITATION_LIST_SUCCESS,
  GET_GO_TO_NAIL_INFO,
  GET_GO_TO_NAIL_INFO_SUCCESS,
  GET_GO_TO_NAIL_LIST,
  GET_GO_TO_NAIL_LIST_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST,
  EXPORT_GO_TO_NAIL_LIST_SUCCESS,
  UPDATE_GO_TO_NAIL,
  UPDATE_GO_TO_NAIL_SUCCESS,
  GET_OLYMPIC_INFO,
  GET_OLYMPIC_INFO_SUCCESS,
  GET_OLYMPIC_LIST,
  GET_OLYMPIC_LIST_SUCCESS,
  EXPORT_OLYMPIC_LIST,
  EXPORT_OLYMPIC_LIST_SUCCESS,
  UPDATE_OLYMPIC,
  UPDATE_OLYMPIC_SUCCESS,
  GET_GO_TO_NAIL_INFO_2ND,
  GET_GO_TO_NAIL_INFO_2ND_SUCCESS,
  GET_GO_TO_NAIL_LIST_2ND,
  GET_GO_TO_NAIL_LIST_2ND_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST_2ND,
  EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS,
  UPDATE_GO_TO_NAIL_2ND,
  UPDATE_GO_TO_NAIL_2ND_SUCCESS,
  GET_GO_TO_NAIL_INFO_3RD,
  GET_GO_TO_NAIL_INFO_3RD_SUCCESS,
  GET_GO_TO_NAIL_LIST_3RD,
  GET_GO_TO_NAIL_LIST_3RD_SUCCESS,
  EXPORT_GO_TO_NAIL_LIST_3RD,
  EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS,
  UPDATE_GO_TO_NAIL_3RD,
  UPDATE_GO_TO_NAIL_3RD_SUCCESS,
  GET_INSTAGRAM_COUPON_INFOR,
  GET_INSTAGRAM_COUPON_INFOR_SUCCESS,
  GET_INSTAGRAM_COUPON_LIST,
  GET_INSTAGRAM_COUPON_LIST_SUCCESS,
  EXPORT_INSTAGRAM_COUPON,
  EXPORT_INSTAGRAM_COUPON_SUCCESS,
  UPDATE_INSTAGRAM_COUPON,
  UPDATE_INSTAGRAM_COUPON_SUCCESS
} from './constants';

const initialState = {
  dataLoading: false,
  actionLoading: false,
  friendInvitationList: {},
  goToNailInfo: {},
  goToNailList: {},
  olympicInfo: {},
  olympicList: {},
  goToNailInfo2: {},
  goToNailList2: {},
  goToNailInfo3: {},
  goToNailList3: {},
  instagramCouponInfor: {},
  instagramCouponList: {}
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_FRIEND_INVITATION_LIST:
      state.dataLoading = true;
      state.friendInvitationList = {};
      return state;

    case GET_FRIEND_INVITATION_LIST_SUCCESS:
      state.dataLoading = false;
      state.friendInvitationList = action.payload.data;
      return state;

    case EXPORT_FRIEND_INVITATION_LIST:
      state.actionLoading = true;
      return state;

    case EXPORT_FRIEND_INVITATION_LIST_SUCCESS:
      state.actionLoading = false;
      return state;

    case GET_GO_TO_NAIL_INFO:
      state.dataLoading = true;
      // state.goToNailInfo = {};
      return state;

    case GET_GO_TO_NAIL_INFO_SUCCESS:
      state.dataLoading = false;
      state.goToNailInfo = action.payload.data;
      return state;

    case GET_GO_TO_NAIL_LIST:
      state.dataLoading = true;
      state.goToNailList = {};
      return state;

    case GET_GO_TO_NAIL_LIST_SUCCESS:
      state.dataLoading = false;
      state.goToNailList = action.payload.data;
      return state;

    case EXPORT_GO_TO_NAIL_LIST:
      state.actionLoading = true;
      return state;

    case EXPORT_GO_TO_NAIL_LIST_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_GO_TO_NAIL:
      state.actionLoading = true;
      return state;

    case UPDATE_GO_TO_NAIL_SUCCESS:
      state.actionLoading = false;
      return state;

    case GET_OLYMPIC_INFO:
      state.dataLoading = true;
      // state.goToNailInfo = {};
      return state;

    case GET_OLYMPIC_INFO_SUCCESS:
      state.dataLoading = false;
      state.olympicInfo = action.payload.data;
      return state;

    case GET_OLYMPIC_LIST:
      state.dataLoading = true;
      state.olympicList = {};
      return state;

    case GET_OLYMPIC_LIST_SUCCESS:
      state.dataLoading = false;
      state.olympicList = action.payload.data;
      return state;

    case EXPORT_OLYMPIC_LIST:
      state.actionLoading = true;
      return state;

    case EXPORT_OLYMPIC_LIST_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_OLYMPIC:
      state.actionLoading = true;
      return state;

    case UPDATE_OLYMPIC_SUCCESS:
      state.actionLoading = false;
      return state;
    
    case GET_GO_TO_NAIL_INFO_2ND:
      state.dataLoading = true;
      // state.goToNailInfo = {};
      return state;

    case GET_GO_TO_NAIL_INFO_2ND_SUCCESS:
      state.dataLoading = false;
      state.goToNailInfo2 = action.payload.data;
      return state;

    case GET_GO_TO_NAIL_LIST_2ND:
      state.dataLoading = true;
      state.goToNailList2 = {};
      return state;

    case GET_GO_TO_NAIL_LIST_2ND_SUCCESS:
      state.dataLoading = false;
      state.goToNailList2 = action.payload.data;
      return state;

    case EXPORT_GO_TO_NAIL_LIST_2ND:
      state.actionLoading = true;
      return state;

    case EXPORT_GO_TO_NAIL_LIST_2ND_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_GO_TO_NAIL_2ND:
      state.actionLoading = true;
      return state;

    case UPDATE_GO_TO_NAIL_2ND_SUCCESS:
      state.actionLoading = false;
      return state;
    // GO TO NAIL 3RD
    case GET_GO_TO_NAIL_INFO_3RD:
      state.dataLoading = true;
      return state;

    case GET_GO_TO_NAIL_INFO_3RD_SUCCESS:
      state.dataLoading = false;
      state.goToNailInfo3 = action.payload.data;
      return state;

    case GET_GO_TO_NAIL_LIST_3RD:
      state.dataLoading = true;
      state.goToNailList3 = {};
      return state;

    case GET_GO_TO_NAIL_LIST_3RD_SUCCESS:
      state.dataLoading = false;
      state.goToNailList3 = action.payload.data;
      return state;

    case EXPORT_GO_TO_NAIL_LIST_3RD:
      state.actionLoading = true;
      return state;

    case EXPORT_GO_TO_NAIL_LIST_3RD_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_GO_TO_NAIL_3RD:
      state.actionLoading = true;
      return state;

    case UPDATE_GO_TO_NAIL_3RD_SUCCESS:
      state.actionLoading = false;
      return state;
    
    // Instagram campain 
    case GET_INSTAGRAM_COUPON_INFOR:
      state.dataLoading = true;
      return state;

    case GET_INSTAGRAM_COUPON_INFOR_SUCCESS:
      state.dataLoading = false;
      state.instagramCouponInfor = action.payload.data;
      return state;
    case GET_INSTAGRAM_COUPON_LIST:
      state.dataLoading = true;
      state.instagramCouponList = {};
      return state;

    case GET_INSTAGRAM_COUPON_LIST_SUCCESS:
      state.dataLoading = false;
      state.instagramCouponList = action.payload.data;
      return state;
    
    case EXPORT_INSTAGRAM_COUPON:
      state.actionLoading = true;
      return state;

    case EXPORT_INSTAGRAM_COUPON_SUCCESS:
      state.actionLoading = false;
      return state;

    case UPDATE_INSTAGRAM_COUPON:
      state.actionLoading = true;
      return state;

    case UPDATE_INSTAGRAM_COUPON_SUCCESS:
      state.actionLoading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
