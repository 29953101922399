// import _ from 'lodash';
import moment from 'moment';
import React from 'react';
const columns = {};

columns.id = {
  title: 'ID',
  width: 180,
  dataIndex: 'objectId',
  key: 'objectId',
  //sorter: true,
  //sortOrder: _.get(search, 'orderBy') === '_id' && _.get(search, 'order')
};
columns.createdAt = {
  title: 'Date',
  width: 200,
  dataIndex: '_created_at',
  render: (record) => moment(record).format('HH:mm YYYY-MM-DD'),
  key: 'createdAt',
  sorter: true,
  // sortOrder:
  //   _.get(search, 'orderBy') === '_created_at' && _.get(search, 'order')
};
columns.name = {
  title: '姓名',
  dataIndex: 'fullName',
  key: 'fullName',
  render: (record) => (<p className="name-field">{record}</p>),
  width: 160,
  sorter: true
};
columns.phone = {
  title: '電話番号',
  dataIndex: 'phone',
  key: 'phone',
  width: 160,
  sorter: true
};
columns.email = {
  title: 'メールアドレス',
  dataIndex: 'email',
  key: 'email',
  width: 250,
  sorter: true
};
columns.question = {
  title: 'Question',
  dataIndex: 'question',
  key: 'question',
  render: (record) => (<p className="question-field">{record}</p>),
  width: 280,
  sorter: true
};
columns.deviceInfo = {
  title: 'deviceInfo',
  width: 120,
  dataIndex: 'deviceInfo',
  key: 'deviceInfo'
};
columns.subType = {
  title: 'お問い合わせ内容',
  width: 200,
  dataIndex: 'subType',
  key: 'subType',
  render: (record) => {
    if (record === 'INFORMATION')
      return 'サロンマネージャーの詳細を知りたい';
    if (record === 'REQUEST')
      return 'サロンマネージャーを利用申請したい';
    return record;
  },
  sorter: true
};
columns.status = {
  title: 'ステータス',
  dataIndex: 'types',
  key: 'types',
  width: 180,
  render: (record) => record[0],
};
export default columns;
