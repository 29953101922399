import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  getGoToNailInfo2nd,
  getGoToNailList2nd,
  exportGoToNailList2nd,
  updateGoToNail2nd
} from 'providers/FriendInvitationProvider/actions';
import {
  makeGoToNailInfo2nd,
  makeGoToNailList2nd,
  makeGoToNailTotal2nd,
  makeFriendInvitationLoading,
  makeFriendInvitationActionLoading
} from 'providers/FriendInvitationProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import GoToNail from './GoToNailList';

const mapStateToProps = (state) => ({
  goToNailInfo: makeGoToNailInfo2nd(state),
  goToNailList: makeGoToNailList2nd(state),
  goToNailTotal: makeGoToNailTotal2nd(state),
  goToNailDataLoading: makeFriendInvitationLoading(state),
  goToNailActionLoading: makeFriendInvitationActionLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  getGoToNailInfo: (params) => dispatch(getGoToNailInfo2nd(params)),
  getGoToNailList: (params) => dispatch(getGoToNailList2nd(params)),
  exportGoToNailList: (params) => dispatch(exportGoToNailList2nd(params)),
  updateGoToNail: (params) => dispatch(updateGoToNail2nd(params)),
  replace: (path) => dispatch(replace(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoToNail);
