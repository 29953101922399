/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card, Button, Modal, Input, Form } from 'antd';
import useTableControl from '../hooks/useTableControl';
import FilterForm from './FilterForm';
import {
  fetchCardErrorBookingList,
  completeBooking,
  exportBookingCSV,
  notifyErrorBooking
} from 'providers/BookingProvider/actions';
import columnsTemplate from '../../Preset/columnsTemplate';
import BookingStatusTag from 'components/BookingStatusTag';
import '../styles.scss';

const PER_PAGE = 10;

const AfterTreatmentTab = () => {
  const [form] = Form.useForm();
  const list = useSelector(state => state.booking.cardErrorBooking.list);
  const total = useSelector(state => state.booking.cardErrorBooking.total);
  const isLoading = useSelector(state => state.booking.cardErrorBooking.isLoading);
  const isExportingCSV = useSelector(state => state.booking.isExporting);
  const notifiedEmailIds = useSelector(state => state.booking.notifiedCustomerIds);

  const [searchParams, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const dispatch = useDispatch();
  const pageRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(fetchCardErrorBookingList({ page: 1, perPage: PER_PAGE, ...searchParams }));
  }, [searchParams]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record) => ({
      disabled: notifiedEmailIds.includes(record._id)
    })};

  let columns = [
    columnsTemplate.bookingId(),
    columnsTemplate.customer({isLink: true}),
    columnsTemplate.nailist(),
    columnsTemplate.errorDate,
    {...columnsTemplate.totalFee, title: '最終金額'},
    columnsTemplate.treatmentDate,
    {...columnsTemplate.status, render: () => <BookingStatusTag status="CARD_ERROR" />},
    columnsTemplate.origin,
    columnsTemplate.paymentCardProvider,
    columnsTemplate.settlementId,
    {...columnsTemplate.action,
      render: (record) => {
        return <div style={{ display: 'flex', alignSelf: 'flex-start'}}>
          <Button
            onClick={() => handleCompleteCardErrorBooking(record._id)}
            type='primary'
          >
            Complete
          </Button>
          <Button
            onClick={() => handlenotifyErrorBooking([record._id])}
            loading={notifiedEmailIds.includes(record._id)}
            style={{ backgroundColor: '#f7b423', color: '#fff', marginLeft: 8}}>
              Notify by email
          </Button>
        </div>;
      }}
  ];

  if (!_.isEmpty(columns)) {
    columns = columns.reduce((prevCols, col) => {
      if (col.key) {
        if (col.key === searchParams.orderBy) {
          col.sortOrder = searchParams.order;
        } else delete col.sortOrder;
        return prevCols.concat(col);
      }
      return prevCols;
    }, []);
  }

  const handleFilterFormChange = (values) => {
    const orderParams = {};
    if (values.type !== searchParams.type) {
      orderParams.orderBy = 'bookingDate';
      orderParams.order = 'descend';
    }
    setSelectedRowKeys([]);
    handleSubmitFilterForm({
      ...values,
      ...orderParams
    });
  };

  const handleActionTableChange = (...args) => {
    setSelectedRowKeys([]);
    handleTableChange(...args);
  };

  const handleCompleteCardErrorBooking = (bookingId) => {
    Modal.confirm({
      title: "This customer's bank transfer has completed?",
      content:
        <Form
          form={form}
          initialValues={{ userNote: '' }}
          onFinish={async(values) => {
            await dispatch(completeBooking({ bookingId, userNote: values.userNote }));
            dispatch(fetchCardErrorBookingList({ ...searchParams, perPage: PER_PAGE }));
            setSelectedRowKeys(keys => keys.filter(key => key !== bookingId));
          }}
        >
          <Form.Item name="userNote" style={{ width: '100%'}}>
            <Input.TextArea placeholder='Memo' />
          </Form.Item>
        </Form>,
      cancelText: 'キャンセル',
      onOk: async() => form.submit(),
      afterClose: () => form.resetFields(['userNote']),
      width: '600px',
      getContainer: () => pageRef.current
    });
  };

  const handlenotifyErrorBooking = (ids) => {
    Modal.confirm({
      title: 'Confirm',
      // TODO: Replace with japanese text, don't have to distinguish the plural nouns 
      content: 'Are you sure want to sent Card Error notifying email to this customer?',
      okText: '送信 ',
      cancelText: 'キャンセル ',
      onOk: () => {
        dispatch(notifyErrorBooking({ ids }))
          .then(() => setSelectedRowKeys(keys => keys.filter(id => !ids.includes(id))));
      },
      width: '600px',
      getContainer: () => pageRef.current
    });
  };

  return (
    <Fragment>
      <div className="page-container" id="new-booking-list" style={{ display: 'block' }} ref={pageRef}>
        <Card>
          <div className="search-section flex justify-between" >
            <FilterForm
              onSubmit={handleFilterFormChange}
              initialValues={_.pick(searchParams, ['keyword', 'type', 'treatmentDate', 'origin'])}
            />
            <Form layout='inline' style={{ alignSelf: 'flex-start', justifyContent: 'flex-end', width: '100%' }}>
              <Form.Item>
                {
                  total > 0 &&
                    <Button
                      style={{ marginBottom: 8 }}
                      loading={isExportingCSV}
                      onClick={() => dispatch(exportBookingCSV(searchParams))}
                    >
                      データのエクスポート
                    </Button>
                }
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => handlenotifyErrorBooking(selectedRowKeys)}
                  disabled={_.isEmpty(selectedRowKeys) || selectedRowKeys.every(v => notifiedEmailIds.includes(v))}
                  style={{ backgroundColor: '#f7b423', color: '#fff', marginLeft: 8 }}>
                    Notify selected via email
                </Button>
              </Form.Item>
            </Form>
          </div>
          <Table
            rowSelection={rowSelection}
            rowKey='_id'
            columns={columns}
            dataSource={list}
            scroll={{ x: 'max-content' }}
            onChange={handleActionTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: PER_PAGE,
              current: Number(searchParams.page) || 1,
              showSizeChanger: false
            }}
            loading={isLoading}
          />
        </Card>
      </div>
    </Fragment>
  );
};

AfterTreatmentTab.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchReceiptList: PropTypes.func
};

export default AfterTreatmentTab;