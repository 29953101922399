import {
  fetchRewardPointListSuccess,
  createRewardPointSuccess,
  setGetRewardPointListLoading
} from './actions';
import { message } from 'antd';
import {
  FETCH_REWARD_POINT_LIST,
  CREATE_REWARD_POINT,
} from './constants';
import { put, takeLeading, call, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';

function* fetchRewardPointList(action) {
  try {
    yield put(setGetRewardPointListLoading({ loading: true }));
    const filter = action.payload;
    const { data } = yield call(request, '/rewardPoint/list', filter, 'GET');

    yield put(fetchRewardPointListSuccess({ data }));
    yield put(setGetRewardPointListLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* createRewardPoint(action) {
  try {
    const { data } = yield call(request, '/rewardPoint/create', action.payload);

    yield put(createRewardPointSuccess({ data }, action.meta));
  } catch (e) {
    message.error(e.error)
  }
}

export default function* watchSalon() {
  yield takeLatest(FETCH_REWARD_POINT_LIST, fetchRewardPointList);
  yield takeLeading(CREATE_REWARD_POINT, createRewardPoint);
}
