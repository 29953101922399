import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SalonSearchList from './SalonSearchList';
import SalonDetailPage from './SalonDetailPage';

const RedirectToSalonList = () => (
  <Redirect to="/dashboard/salons/list" />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Salon</title>
      </Helmet>
      <Switch>
        <Route
          path="/dashboard/salons/list"
          exact
          component={SalonSearchList}
        />
        <Route
          path='/dashboard/salons/list/profile/:id'
          exact
          component={SalonDetailPage}
          />
        <Route path="/dashboard/salons" component={RedirectToSalonList} />
      </Switch>
    </>
  );
};

export default Router;
