import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';

const NumberBookingMonth = ({ total = 0, status }) => {
  const { color } = status;

  return (
    <Space style={{ background: `${color}1A` }} className="number-booking-month">
      <div style={{ background: `${color}CC` }} className="number-booking-month-total">{total}</div>
      <div style={{ color }} className="number-booking-month-text">{status.text}</div>
    </Space>
  );
};

NumberBookingMonth.propTypes = {
  total: PropTypes.number,
  status: PropTypes.object
};

export default NumberBookingMonth;
