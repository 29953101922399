import React from 'react';
import PropTypes from 'prop-types';

import iconO from './icon-o.svg';
import iconX from './icon-x.svg';
import icon_ from './icon-_.svg';

const Slot = ({ slot = 'X' }) => {
  if (slot === '-') {
    return <img
      src={icon_}
      alt={'-'}
      className='slot'
    />;
  }

  if (slot === 'X') {
    return <img
      src={iconX}
      alt={'X'}
      className='slot'
      // style={{backgroundColor: '#eee'}}
    />;
  }

  return <img
    src={iconO}
    alt={'O'}
    className='slot'
  />;
};

Slot.propTypes = {
  slot: PropTypes.string
};

export default Slot;
