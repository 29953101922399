import { connect } from 'react-redux';

import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import SalonDetailPage from './SalonDetailPage';
import { fetchSalonProfile, unsetSalonProfile } from 'providers/SalonProvider/actions';
import { makeSalonProfile } from 'providers/SalonProvider/selectors';

const mapStateToProps = (state) => ({
  profile: makeSalonProfile(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchSalonProfile: (params) => dispatch(fetchSalonProfile(params)),
  unsetSalonProfile: () => dispatch(unsetSalonProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalonDetailPage);
