import moment from 'moment';
import _ from 'lodash';
import { put, takeEvery, call, takeLeading, takeLatest } from 'redux-saga/effects';

import request from 'utils/request';
import {
  deleteAnsweredTicketSuccess,
  deleteUnansweredTicketSuccess,
  deleteAnsweredTicketFail,
  deleteUnansweredTicketFail,
  replyTicketSuccess,
  replyTicketFail,
  uploadImageSuccess,

  getSupportForSalonAnswered,
  getSupportForSalonUnanswered,
  getSupportForSalonAnsweredSuccess,
  getSupportForSalonUnansweredSuccess,
  setFilterSalonUnanswered,
  setFilterSalonAnswered,
  countUnreadSalonTickets,
  countUnreadSalonTicketsSuccess,

  getSupportForWebAnswered,
  getSupportForWebUnanswered,
  getSupportForWebAnsweredSuccess,
  getSupportForWebUnansweredSuccess,
  setFilterWebUnanswered,
  setFilterWebAnswered,
  countUnreadWebTickets,
  countUnreadWebTicketsSuccess,

  getSupportForAppAnswered,
  getSupportForAppUnanswered,
  getSupportForAppAnsweredSuccess,
  getSupportForAppUnansweredSuccess,
  setFilterAppUnanswered,
  setFilterAppAnswered,
  countUnreadAppTickets,
  countUnreadAppTicketsSuccess,

  getSupportForVerificationAnswered,
  getSupportForVerificationUnanswered,
  getSupportForVerificationAnsweredSuccess,
  getSupportForVerificationUnansweredSuccess,
  setFilterVerificationUnanswered,
  setFilterVerificationAnswered,
  countUnreadVerificationTickets,
  countUnreadVerificationTicketsSuccess,

  getReportListSuccess,
  unlinkPhoneAuthSuccess,
  unlinkPhoneAuthFail
} from './actions';
import {
  DELETE_SUPPORT_TICKET,
  SET_IS_READ_TICKETS,
  REPLY_TICKET,
  UPLOAD_IMAGE,

  GET_SUPPORT_FOR_SALON_ANSWERED,
  GET_SUPPORT_FOR_SALON_UNANSWERED,
  COUNT_UNREAD_SALON_TICKETS,

  GET_SUPPORT_FOR_WEB_ANSWERED,
  GET_SUPPORT_FOR_WEB_UNANSWERED,
  COUNT_UNREAD_WEB_TICKETS,

  GET_SUPPORT_FOR_APP_ANSWERED,
  GET_SUPPORT_FOR_APP_UNANSWERED,
  COUNT_UNREAD_APP_TICKETS,

  GET_SUPPORT_FOR_VERIFICATION_ANSWERED,
  GET_SUPPORT_FOR_VERIFICATION_UNANSWERED,
  COUNT_UNREAD_VERIFICATION_TICKETS,

  GET_REPORT_LIST,
  GET_REPORT_LIST_BY_USER,
  UNLINK_PHONE_AUTH
} from './constants';
import { message } from 'antd';

const SUPPORT_TYPE = {
  APP: 'APP',
  WEB: 'WEB',
  SALON_CONTACT: 'SALON_CONTACT',
  VERIFY: 'VERIFY'
};

function* handleDeleteSupportTicket(action) {
  try {
    const { objectId, type, isAnswered } = action.payload;
    const response = (yield call(request, `supportTicket/${type}/${objectId}`, {}, 'DELETE'));
    if (isAnswered) {
      yield put(deleteAnsweredTicketSuccess(response.data ? true : false));
      switch (type) {
        case SUPPORT_TYPE.APP: {
          yield put(getSupportForAppAnswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.WEB: {
          yield put(getSupportForWebAnswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.SALON_CONTACT: {
          yield put(getSupportForSalonAnswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.VERIFY: {
          yield put(getSupportForVerificationAnswered({..._.get(action.payload, 'filter')}));
          break;
        }
        default: {
          throw new Error('Invalid SUPPORT_TYPE request');
        }
      }
    } else {
      yield put(deleteUnansweredTicketSuccess(response.data ? true : false));
      switch (type) {
        case SUPPORT_TYPE.APP: {
          yield put(countUnreadAppTickets());
          yield put(getSupportForAppUnanswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.WEB: {
          yield put(countUnreadWebTickets());
          yield put(getSupportForWebUnanswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.SALON_CONTACT: {
          yield put(countUnreadSalonTickets());
          yield put(getSupportForSalonUnanswered({..._.get(action.payload, 'filter')}));
          break;
        }
        case SUPPORT_TYPE.VERIFY: {
          yield put(countUnreadVerificationTickets());
          yield put(getSupportForVerificationUnanswered({..._.get(action.payload, 'filter')}));
          break;
        }
        default: {
          throw new Error('Invalid SUPPORT_TYPE request');
        }
      }
    }
  } catch (error) {
    yield put(deleteAnsweredTicketFail(false));
    yield put(deleteUnansweredTicketFail(false));
    console.log(
      '🚀 ~ file: saga.js ~ function*handleDeleteSupportTicket ~ error', error
    );
  }
}

function* handleIsReadTickets(action) {
  try {
    const { ids, search } = action.payload;
    const { supportType } = search;
    const response = (yield call(request, 'supportTicket/forceUpdateIsReadByIds', {ids: ids}, 'PUT'));
    if (response.data) {

      switch (supportType) {
        case SUPPORT_TYPE.APP: {
          yield put(getSupportForAppUnanswered(search));
          yield put(countUnreadAppTickets());
          break;
        }
        case SUPPORT_TYPE.WEB: {
          yield put(getSupportForWebUnanswered(search));
          yield put(countUnreadWebTickets());
          break;
        }
        case SUPPORT_TYPE.SALON_CONTACT: {
          yield put(getSupportForSalonUnanswered(search));
          yield put(countUnreadSalonTickets());
          break;
        }
        case SUPPORT_TYPE.VERIFY: {
          yield put(getSupportForVerificationUnanswered(search));
          yield put(countUnreadVerificationTickets());
          break;
        }
        default: {
          throw new Error('Invalid SUPPORT_TYPE request');
        }
      }
    }
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*handleIsReadTickets ~ error', error
    );
  }
}

function* handleReplyTicket(action) {
  try {
    const { subject, answer, _id, supportType, filter = {} } = action.payload;
    const response = (yield call(request, `supportTicket/${SUPPORT_TYPE[supportType]}/${_id}/reply`, {
      answer: answer,
      subject: subject
    }, 'POST'));
    
    if (response.data) {
      yield put(replyTicketSuccess());

      switch (supportType) {
        case SUPPORT_TYPE.APP: {
          if (_.get(filter, 'isAnswered')) {
            yield put(getSupportForAppAnswered({..._.get(action.payload, 'filter')}));
          } else {
            yield put(getSupportForAppUnanswered({..._.get(action.payload, 'filter')}));
            yield put(getSupportForAppAnswered({..._.get(action.payload, 'filter')}));
          }
          break;
        }
        case SUPPORT_TYPE.WEB: {
          if (_.get(filter, 'isAnswered')) {
            yield put(getSupportForWebAnswered({..._.get(action.payload, 'filter')}));
          } else {
            yield put(getSupportForWebUnanswered({..._.get(action.payload, 'filter')}));
            yield put(getSupportForWebAnswered({..._.get(action.payload, 'filter')}));
          }
          break;
        }
        case SUPPORT_TYPE.SALON_CONTACT: {
          if (_.get(filter, 'isAnswered')) {
            yield put(getSupportForSalonAnswered({..._.get(action.payload, 'filter')}));
          } else {
            yield put(getSupportForSalonUnanswered({..._.get(action.payload, 'filter')}));
            yield put(getSupportForSalonAnswered({..._.get(action.payload, 'filter')}));
          }
          break;
        }
        case SUPPORT_TYPE.VERIFY: {
          if (_.get(filter, 'isAnswered')) {
            yield put(getSupportForVerificationAnswered({..._.get(action.payload, 'filter')}));
          } else {
            yield put(getSupportForVerificationUnanswered({..._.get(action.payload, 'filter')}));
            yield put(getSupportForVerificationAnswered({..._.get(action.payload, 'filter')}));
          }
          break;
        }
        default: {
          throw new Error('Invalid SUPPORT_TYPE request');
        }
      }
    }
    
  } catch (error) {
    yield put(replyTicketFail());
    console.log(
      '🚀 ~ file: saga.js ~ function*handleReplyTicket ~ error', error
    );
  }
}

function* handleSupportUnansweredTicketsList(action) {
  try {
    const filter = action.payload;
    filter.limit = filter.limit || 12;
    filter.dateFrom = filter['date-range'] && filter['date-range'][0] ? moment(filter['date-range'][0]).toDate() : filter.dateFrom;
    filter.dateTo = filter['date-range'] && filter['date-range'][1] ? moment(filter['date-range'][1]).toDate() : filter.dateTo;
    filter.sortField = filter.orderBy;
    filter.direction = filter.order === 'ascend' ? 'asc' : 'desc';
    filter.isAnswered = false;
    if (!filter['date-range']) {
      filter.dateFrom = undefined;
      filter.dateTo = moment().endOf('month').toDate();
    }
    delete filter['date-range'];
    delete filter.orderBy;
    delete filter.order;

    let data;
    const supportType = _.get(action.payload, 'supportType');

    switch (supportType) {
      case SUPPORT_TYPE.APP: {
        yield put(setFilterAppUnanswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.APP}/list`, filter, 'GET')).data;
        yield put(getSupportForAppUnansweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.WEB: {
        yield put(setFilterWebUnanswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.WEB}/list`, filter, 'GET')).data;
        yield put(getSupportForWebUnansweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.SALON_CONTACT: {
        yield put(setFilterSalonUnanswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.SALON_CONTACT}/list`, filter, 'GET')).data;
        yield put(getSupportForSalonUnansweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.VERIFY: {
        yield put(setFilterVerificationUnanswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.VERIFY}/list`, filter, 'GET')).data;
        yield put(getSupportForVerificationUnansweredSuccess(data));
        break;
      }
      default: {
        throw new Error('Invalid SUPPORT_TYPE request');
      }
    }

  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*handleSupportUnansweredTicketsList ~ error', error
    );
  }
}

function* handleSupportAnsweredTicketsList(action) {
  try {
    const filter = action.payload;
    filter.limit = filter.limit || 12;
    filter.dateFrom = filter['date-range'] && filter['date-range'][0] ? moment(filter['date-range'][0]).toDate() : filter.dateFrom;
    filter.dateTo = filter['date-range'] && filter['date-range'][1] ? moment(filter['date-range'][1]).toDate() : filter.dateTo;
    filter.sortField = filter.orderBy;
    filter.direction = filter.order === 'ascend' ? 'asc' : 'desc';
    filter.isAnswered = true;
    if (!filter['date-range']) {
      filter.dateFrom = undefined;
      filter.dateTo = moment().endOf('month').toDate();
    }
    delete filter['date-range'];
    delete filter.orderBy;
    delete filter.order;

    let data;
    const supportType = _.get(action.payload, 'supportType');

    switch (supportType) {
      case SUPPORT_TYPE.APP: {
        yield put(setFilterAppAnswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.APP}/list`, filter, 'GET')).data;
        yield put(getSupportForAppAnsweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.WEB: {
        yield put(setFilterWebAnswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.WEB}/list`, filter, 'GET')).data;
        yield put(getSupportForWebAnsweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.SALON_CONTACT: {
        yield put(setFilterSalonAnswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.SALON_CONTACT}/list`, filter, 'GET')).data;
        yield put(getSupportForSalonAnsweredSuccess(data));
        break;
      }
      case SUPPORT_TYPE.VERIFY: {
        yield put(setFilterVerificationAnswered(filter));
        data = (yield call(request, `supportTicket/${SUPPORT_TYPE.VERIFY}/list`, filter, 'GET')).data;
        yield put(getSupportForVerificationAnsweredSuccess(data));
        break;
      }
      default: {
        throw new Error('Invalid SUPPORT_TYPE request');
      }
    }

  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*handleSupportAnsweredTicketsList ~ error', error
    );
  }
}

function* countUnreadSalonSupportTickets() {
  try {
    const data = (yield call(request, `supportTicket/${SUPPORT_TYPE.SALON_CONTACT}/countUnRead`, {}, 'GET')).data;
    yield put(countUnreadSalonTicketsSuccess({ count: data.count }));
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*countUnreadSalonSupportTickets ~ error', error
    );
  }
}

function* countUnreadWebSupportTickets() {
  try {
    const data = (yield call(request, `supportTicket/${SUPPORT_TYPE.WEB}/countUnRead`, {}, 'GET')).data;
    yield put(countUnreadWebTicketsSuccess({ count: data.count }));
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*countUnreadWebSupportTickets ~ error', error
    );
  }
}

function* handleUploadImage(action) {
  try {
    const { payload } = action
    let data;
    if (payload.getId) {
      data = (yield call(request, '/upload?getId=1', payload.formData)).data;
    } else {
      data = (yield call(request, '/upload', payload)).data;
    }
    yield put(
      uploadImageSuccess(
        { data },
        action.meta
      )
    );
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*handleUploadImage ~ error', error
    );
  }
}

function* countUnreadAppSupportTickets() {
  try {
    const data = (yield call(request, `supportTicket/${SUPPORT_TYPE.APP}/countUnRead`, {}, 'GET')).data;
    yield put(countUnreadAppTicketsSuccess({ count: data.count }));
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*countUnreadAppSupportTickets ~ error', error
    );
  }
}

function* countUnreadVerificationSupportTickets() {
  try {
    const data = (yield call(request, `supportTicket/${SUPPORT_TYPE.VERIFY}/countUnRead`, {}, 'GET')).data;
    yield put(countUnreadVerificationTicketsSuccess({ count: data.count }));
  } catch (error) {
    console.log(
      '🚀 ~ file: saga.js ~ function*countUnreadVerificationSupportTickets ~ error', error
    );
  }
}

function* getReportList(action) {
  try {
    const { data } = yield call(request, '/report/users', { ...action.payload, limit: 10 }, 'POST');
    yield put(getReportListSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* getReportListByUser(action) {
  try {
    const { id, ...filter } = action.payload;
    const { data } = yield call(request, `/report/users/${id}`, { ...filter, limit: 10 }, 'POST');
    yield put(getReportListSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* unlinkPhoneAuth(action) {
  try {
    const { phoneNumber, unlinkOption } = action.payload;
    const phone = phoneNumber.trim();

    if (unlinkOption === 'unlinkPhoneAuthKeepPhone') {
      yield call(request, `supportTicket/unlink-phone/unlinkPhoneAuthKeepPhone/${phone}`, undefined, 'GET');
    } else {
      yield call(request, `supportTicket/unlink-phone/unlinkPhoneAuth/${phone}`, undefined, 'GET');
    }
    yield put(unlinkPhoneAuthSuccess(true, action.meta));
    message.success(`Unlink phone auth for phone number ${phone} complete!`);
  } catch (e) {
    yield put(unlinkPhoneAuthFail(true, action.meta));
    message.error(e.error);
  }
}

export default function* watchSetting() {
  yield takeLeading(DELETE_SUPPORT_TICKET, handleDeleteSupportTicket);
  yield takeLeading(SET_IS_READ_TICKETS, handleIsReadTickets);
  yield takeLeading(REPLY_TICKET, handleReplyTicket);
  yield takeEvery(UPLOAD_IMAGE, handleUploadImage);

  yield takeLatest(GET_SUPPORT_FOR_SALON_ANSWERED, handleSupportAnsweredTicketsList);
  yield takeLatest(GET_SUPPORT_FOR_SALON_UNANSWERED, handleSupportUnansweredTicketsList);
  yield takeLeading(COUNT_UNREAD_SALON_TICKETS, countUnreadSalonSupportTickets);

  yield takeLatest(GET_SUPPORT_FOR_WEB_ANSWERED, handleSupportAnsweredTicketsList);
  yield takeLatest(GET_SUPPORT_FOR_WEB_UNANSWERED, handleSupportUnansweredTicketsList);
  yield takeLeading(COUNT_UNREAD_WEB_TICKETS, countUnreadWebSupportTickets);

  yield takeLatest(GET_SUPPORT_FOR_APP_ANSWERED, handleSupportAnsweredTicketsList);
  yield takeLatest(GET_SUPPORT_FOR_APP_UNANSWERED, handleSupportUnansweredTicketsList);
  yield takeLeading(COUNT_UNREAD_APP_TICKETS, countUnreadAppSupportTickets);

  yield takeLatest(GET_SUPPORT_FOR_VERIFICATION_ANSWERED, handleSupportAnsweredTicketsList);
  yield takeLatest(GET_SUPPORT_FOR_VERIFICATION_UNANSWERED, handleSupportUnansweredTicketsList);
  yield takeLeading(COUNT_UNREAD_VERIFICATION_TICKETS, countUnreadVerificationSupportTickets);

  // yield takeLeading(GET_SUPPORT_FOR_VERIFICATION_ANSWERED, handleVerificationAnsweredTicketsList);
  // yield takeLeading(GET_SUPPORT_FOR_VERIFICATION_UNANSWERED, handleVerificationUnansweredTicketsList);
  yield takeLatest(GET_REPORT_LIST, getReportList);
  yield takeLatest(GET_REPORT_LIST_BY_USER, getReportListByUser);

  yield takeLatest(UNLINK_PHONE_AUTH, unlinkPhoneAuth);
}
