
import React from 'react';
import { Tag } from 'antd';

const PAYMENT_PROVIDER = {
  ATONE: <Tag color='blue'>ATONE</Tag>,
  STRIPE: <Tag color='purple'>STRIPE</Tag>,
  VERITRANS: <Tag color='cyan'>VERITRANS</Tag>,
  ONLINE: <Tag>ONLINE</Tag>
};

export default PAYMENT_PROVIDER;