import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Space, Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatYen } from 'utils/stringFormat';

import BookingStatusTag from 'components/BookingStatusTag';
import EditReceiptPriceButton from 'components/Common/Booking/ChangePriceNStatus/EditReceiptPriceButton';
import ResendReceiptEmailButton from 'components/Common/Booking/ChangePriceNStatus/ResendReceiptEmailButton';
import PAYMENT_PROVIDER from 'utils/constants/PAYMENT_PROVIDER';
import _isEmpty from 'lodash/isEmpty';
import './style.scss';

const BK_DETAIL_ROUTE = '/dashboard/booking-management/list';

const ReceiptEmailBooking = ({
  booking: {
    _id, bookingDate, status,
    slot, expectedEndTime,
    price, additionalPrice,
    veritransOrderId,
    atoneMerchant,
    penalty,
    bookingPointInfo,
    couponInfo
  } = {},
  receipt: {
    emailParams: {
      paymentCardProvider,
      customerFullName,
      nailistUsername,
      chargeId,
    } = {}
  } = {},
  loading = false
}) => {

  return (
    <Spin spinning={loading}>
      <Card id="receipt_booking" title="Booking Information" extra={
        <Space>
          <ResendReceiptEmailButton bookingId={_id} disabled={_isEmpty(paymentCardProvider)} />
          <EditReceiptPriceButton bookingId={_id} bookingData={{ couponInfo, bookingPointInfo, penalty }} disabled={status !== 'DONE'} />
        </Space>
      }>
        <Descriptions
          bordered
          size="small"
          column={1}
        >
          <Descriptions.Item label="Booking ID:"><Link to={`${BK_DETAIL_ROUTE}/${_id}`}>{_id}</Link></Descriptions.Item>
          <Descriptions.Item label="Settlement ID:">
            {(paymentCardProvider === 'STRIPE' && chargeId) || (paymentCardProvider === 'VERITRANS' && veritransOrderId) || (paymentCardProvider === 'ATONE' && atoneMerchant)}
            <span style={{userSelect: 'none', display: loading ? 'none' : undefined}}>{'　'}{PAYMENT_PROVIDER[paymentCardProvider || 'ONLINE']}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Booking status:">{<BookingStatusTag status={status}/>}</Descriptions.Item>
          <Descriptions.Item label="Customer info:">{customerFullName}</Descriptions.Item>
          <Descriptions.Item label="Nailist:">{nailistUsername}</Descriptions.Item>
          <Descriptions.Item label="Booking date:">{moment(bookingDate).format('YYYY-MM-DD')} {degitsToTimeHHmm(slot)}〜{degitsToTimeHHmm(expectedEndTime)}</Descriptions.Item>
          <Descriptions.Item label="Total price:">{formatYen(additionalPrice || price || 0)} <span style={{ opacity: 0.45 }}>(Booking menu)</span></Descriptions.Item>
        </Descriptions>
      </Card>
    </Spin>
  );
};

ReceiptEmailBooking.propTypes = {
  booking: PropTypes.object,
  receipt: PropTypes.object,
  loading: PropTypes.bool
};

function degitsToTimeHHmm(degits) {
  if (!degits && degits !== 0) {
    return '';
  }
  return String(degits).padStart(4, '0').match(/.{1,2}/g).join(':') || '';
}

export default ReceiptEmailBooking;