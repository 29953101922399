export const GET_BANK_LIST = 'GET_BANK_LIST';
export const GET_BANK_LIST_SUCCESS = 'GET_BANK_LIST_SUCCESS';

export const GET_BRANCH_LIST = 'GET_BRANCH_LIST';
export const GET_BRANCH_LIST_SUCCESS = 'GET_BRANCH_LIST_SUCCESS';

export const GET_ACCOUNT_TYPE_LIST = 'GET_ACCOUNT_TYPE_LIST';
export const GET_ACCOUNT_TYPE_LIST_SUCCESS = 'GET_ACCOUNT_TYPE_LIST_SUCCESS';

export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAILED = 'UPDATE_BANK_ACCOUNT_FAILED';
