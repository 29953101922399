import {
  GET_BANK_LIST,
  GET_BANK_LIST_SUCCESS,
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_SUCCESS,
  GET_ACCOUNT_TYPE_LIST,
  GET_ACCOUNT_TYPE_LIST_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAILED
} from './constants';

export function getBankList(payload = {}) {
  return { type: GET_BANK_LIST, payload };
}

export function getBankListSuccess(payload = {}) {
  return { type: GET_BANK_LIST_SUCCESS, payload };
}

export function getBranchList(payload = {}) {
  return { type: GET_BRANCH_LIST, payload };
}

export function getBranchListSuccess(payload = {}) {
  return { type: GET_BRANCH_LIST_SUCCESS, payload };
}

export function getAccountTypeList(payload = {}) {
  return { type: GET_ACCOUNT_TYPE_LIST, payload };
}

export function getAccountTypeListSuccess(payload = {}) {
  return { type: GET_ACCOUNT_TYPE_LIST_SUCCESS, payload };
}

export function updateBankAccount(payload = {}) {
  return { type: UPDATE_BANK_ACCOUNT, payload, meta: { thunk: true } };
}

export function updateBankAccountSuccess(payload = {}, meta) {
  return { type: UPDATE_BANK_ACCOUNT_SUCCESS, payload, meta };
}

export function updateBankAccountFailed(payload = {}, meta) {
  return { type: UPDATE_BANK_ACCOUNT_FAILED, payload, meta };
}
