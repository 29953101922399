import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import '@ant-design/compatible/assets/index.css';
import FormLayout from './FilterForm';

const FilterForm = (props) => {
  return (
    <FormLayout {...props} />
  );
};

FilterForm.propTypes = {
  handleSubmit: PropTypes.any,
  filters: PropTypes.array
};

export default reduxForm({
  form: 'support-verification-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);
