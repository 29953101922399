import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Table, Card, Typography, Row, Col } from 'antd';
import moment from 'moment';
import qs from 'querystring';
import { POINT_CARD_BORDER_COLOR, RANK_NAME } from 'utils/constants';

const { Text, Title } = Typography;

function parseSearchString(search) {
  return qs.parse(search.replace(/^\?/, ''));
}

const CustomerPointDetail = ({
  customerId, pointInfo, fetchCustomerPointInfo, pointHistory, fetchCustomerPointHistory
}) => {
  const history = useHistory();
  const search = useMemo(() => parseSearchString(history.location.search), [history.location.search]);
  const { currentPoint = 0, earnedPoint = 0, usedPoint = 0 } = pointInfo;

  const columns = [
    {
      key: 'point',
      title: 'Earned point',
      render: (_, record) => {
        const { operation, point } = record;
        return <Text type={operation === '-' ? 'danger' : 'success'}>{operation}{point}P</Text>;
      }
    },
    {
      key: 'createdAt',
      title: 'Date earned',
      dataIndex: '_created_at',
      render: (record) => moment(record).format('HH:mm YYYY-MM-DD')
    },
    {
      key: 'type',
      title: 'Type',
      render: (_, record) => {
        const { actionType, note } = record;
        const booking = record._p_booking || '';
        const bookingId = booking.split('$')[1];
        const bookingLink = <Link to={`/dashboard/booking-management/list/${bookingId}`}>{bookingId}</Link>;
        switch (actionType) {
          case 'REWARDED_LIKE_POST':
            return 'デザインいいね';
          case 'REWARDED_RANK_UP': {
            const subnotes = note.split(' ');
            return `ランクアップ（${RANK_NAME[subnotes[subnotes.length - 1]]}）`;
          }
          case 'TAKE_BACK_RANK_DOWN':
            return 'ランクダウン';
          case 'REWARDED_FRIEND_INVITATION': {
            const { info = {} } = record;
            const { invitee } = info;
            const inviteeLink = <Link to={`/dashboard/customer/profile/${invitee}`}>{invitee}</Link>;
            return <Text>招待されたユーザーは{inviteeLink}、初の予約は{bookingLink}</Text>;
          }
          case 'REWARDED_BOOKING_DONE':
            return <Text>施術完了 ({bookingLink})</Text>;
          case 'REWARDED_CAMPAIGN':
            return 'キャンペーン';
          case 'REWARDED_BY_OPERATOR':
            return '運営からの付与' + (note ? ` (${note})` : '');
          case 'REFUNDED_CANCEL_BOOKING':
            return <Text>予約キャンセル ({bookingLink})</Text>;
          case 'USED':
            return <Text>予約時の利用 ({bookingLink})</Text>;
          case 'EXPIRED':
            return '有効期限切れ（失効）';
          case 'CANCEL_BY_OPERATOR':
            return <Text>予約がオペレータによってキャンセルされたため、利用したポイントを返却 ({bookingLink})</Text>;
          case 'TAKE_BACK_REWARD_ADMIN':
            return <Text>予約がオペレータによってキャンセルされたため、付与したポイントを回収 ({bookingLink})</Text>;
          case 'CHANGE_BOOKING_PRICE_UP':
            return <Text>運営からの値上げ ({bookingLink})</Text>;
          case 'CHANGE_BOOKING_PRICE_DOWN':
            return <Text>運営からの値下げ ({bookingLink})</Text>;
          case 'NAILIST_CHANGE_PRICE':
            return <Text>ネイリストによる値下げ ({bookingLink})</Text>;
        }
      }
    }
  ];

  useEffect(() => {
    fetchCustomerPointHistory({ customerId, limit: 10, page: Number(search.page || 1) });
    fetchCustomerPointInfo(customerId);
  }, [customerId]);

  useEffect(() => {
    fetchCustomerPointHistory({ customerId, limit: 10, page: Number(search.page) });
  }, [search]);

  const onTableChange = (pagination) => {
    history.push(`${history.location.pathname}?${qs.stringify({ page: pagination.current })}`);
  };

  const renderPointCard = (label, value, colorKey) => {
    return (
      <Card
        style={{
          borderRadius: 8,
          borderTop: `8px solid ${POINT_CARD_BORDER_COLOR[colorKey]}`
        }}
      >
        <Title level={5}>{label}</Title>
        <Title level={2} style={{ margin: 0 }}>{value}P</Title>
      </Card>
    );
  };

  return (
    <Card bordered={false} title='Total point'>
      <Row style={{ marginBottom: 16 }}>
        <Col span={5} style={{ marginRight: 16 }}>
          {renderPointCard('Current point', currentPoint, 'currentPoint')}
        </Col>
        <Col span={5} style={{ marginRight: 16 }}>
          {renderPointCard('Point earned', earnedPoint, 'earnedPoint')}
        </Col>
        <Col span={5} style={{ marginRight: 16 }}>
          {renderPointCard('Point used', usedPoint, 'usedPoint')}
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={pointHistory.list}
        onChange={onTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: pointHistory.total,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSize: 10,
          current: Number(search.page || 1),
          showSizeChanger: false
        }}
        rowKey={(record) => record._id}
        loading={pointHistory.loading}
      />
    </Card>
  );
};

CustomerPointDetail.propTypes = {
  customerId: PropTypes.string,
  pointInfo: PropTypes.object,
  fetchCustomerPointInfo: PropTypes.func,
  pointHistory: PropTypes.object,
  fetchCustomerPointHistory: PropTypes.func
};

export default CustomerPointDetail;
