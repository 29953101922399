import produce from 'immer';
import _ from 'lodash';

import { SET_POP_UP, CLOSE_ALL_POP_UP } from './constants';

const initialState = {
  payout: false,
  completePayout: false,
  createSalon: false,
  bankPopup: false,
  accountNoBank: false,
  salonProfile: false,
  payoutChanged: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_POP_UP:
      state[_.get(action, 'payload.name')] = _.get(action, 'payload.show');
      return;

    case CLOSE_ALL_POP_UP:
      return initialState;

    default:
      return state;
  }
});

export default reducer;
