import {
  FETCH_POPUP_BANNER_LIST,
  FETCH_POPUP_BANNER_LIST_SUCCESS,
  TOGGLE_POPUP_BANNER_STATUS,
  TOGGLE_POPUP_BANNER_STATUS_SUCCESS,
  CREATE_POPUP_BANNER,
  CREATE_POPUP_BANNER_SUCCESS,
  UPDATE_POPUP_BANNER,
  UPDATE_POPUP_BANNER_SUCCESS,
  UPDATE_POPUP_BANNER_FAIL,
  UPDATE_POPUP_BANNERS_POSITION,
  UPDATE_POPUP_BANNERS_POSITION_SUCCESS,
  UPDATE_POPUP_BANNERS_POSITION_FAIL
} from './constants';

export function fetchPopupBannerList(payload = {}) {
  return { type: FETCH_POPUP_BANNER_LIST, payload, meta: { thunk: true } };
}
export function fetchPopupBannerListSuccess(payload = {}, meta) {
  return { type: FETCH_POPUP_BANNER_LIST_SUCCESS, payload, meta };
}
export function togglePopupBannerStatus(payload = {}) {
  return { type: TOGGLE_POPUP_BANNER_STATUS, payload, meta: { thunk: true } };
}
export function togglePopupBannerStatusSuccess(payload = {}, meta) {
  return { type: TOGGLE_POPUP_BANNER_STATUS_SUCCESS, payload, meta };
}

export function createPopupBanner(payload = {}) {
  return { type: CREATE_POPUP_BANNER, payload, meta: { thunk: true } };
}
export function createPopupBannerSuccess(payload = {}, meta) {
  return { type: CREATE_POPUP_BANNER_SUCCESS, payload, meta };
}

export function updatePopupBanner(payload = {}) {
  return { type: UPDATE_POPUP_BANNER, payload, meta: { thunk: true } };
}
export function updatePopupBannerSuccess(payload = {}, meta) {
  return { type: UPDATE_POPUP_BANNER_SUCCESS, payload, meta };
}
export function updatePopupBannerFail(error, meta) {
  return { type: UPDATE_POPUP_BANNER_FAIL, error, meta };
}


export function updatePopupBannersPosition(payload = {}) {
  return { type: UPDATE_POPUP_BANNERS_POSITION, payload, meta: { thunk: true } };
}
export function updatePopupBannersPositionSuccess(payload = {}, meta) {
  return { type: UPDATE_POPUP_BANNERS_POSITION_SUCCESS, payload, meta };
}
export function updatePopupBannersPositionFail(error, meta) {
  return { type: UPDATE_POPUP_BANNERS_POSITION_FAIL, error, meta };
}
