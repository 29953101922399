import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const style = {
  border: '1px solid #f0f0f0',
  width: '100%',
  height: 1440
};

const ReceiptEmailPreview = ({
  receiptId,
  fetchRenderReceiptEmail
}) => {
  const [htmlMailDoc, setMailDoc] = useState('');

  useEffect(() => {
    (async() => {
      const htmlMail = await fetchRenderReceiptEmail({ receiptId });
      setMailDoc(htmlMail);
    })();
  }, [receiptId]);

  return (
    <iframe style={style} srcDoc={htmlMailDoc} />
  );
};

ReceiptEmailPreview.propTypes = {
  receiptId: PropTypes.string,
  fetchRenderReceiptEmail: PropTypes.func
};

export default ReceiptEmailPreview;