/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment';
import { Typography, Tag } from 'antd';
import { USER_STATUS } from 'utils/constants';
import { splitInfor } from 'utils/common';
const { Paragraph } = Typography;


const columnsTemplate = {
  id: {
    title: 'ID',
    width: 130,
    dataIndex: 'userId',
    key: 'userId'
  },
  username: {
    title: 'Username',
    width: 170,
    dataIndex: 'username',
    key: 'username',
    render: (record) => {
      const userName = splitInfor(record);
      return (
        <div style={{ display: 'block', fontSize: 12, wordBreak: 'break-all' }}>
          {userName}
        </div>

      );
    }
    
  },
  role: {
    title: 'Role',
    width: 120,
    dataIndex: 'role',
    key: 'role'
  },
  email: {
    title: 'Email',
    width: 200,
    dataIndex: 'email',
    key: 'email',
    render: (record) => {
      const email = splitInfor(record);
      return (
        <div style={{ display: 'block', fontSize: 12, wordBreak: 'break-all' }}>
          {email}
        </div>

      );
    }
  },
  phone: {
    title: 'Phone number',
    width: 120,
    dataIndex: 'phone',
    key: 'phone'
  },
  deletedAt: {
    title: 'Deleted date',
    width: 170,
    dataIndex: 'deleteDate',
    render: (deleteDate) => {
      if (deleteDate) {
        return (<div>{moment(deleteDate).format('HH:mm YYYY-MM-DD')}</div>);
      }
    },
    key: 'deleteDate'
  },
  status: {
    title: 'ステータス',
    width: 140,
    render: (record) => {
      const statusValue = record?.deleteType || record?.status;
      return (<Tag color={USER_STATUS[statusValue]?.color}>{USER_STATUS[statusValue]?.value}</Tag>);
    },
    key: 'status'
  },
  reason: {
    title: '退会の理由',
    width: 200,
    dataIndex: 'reasonDeleteAccount',
    key: '退会の理由'
  },
  detail: {
    title: '詳細を教えてください',
    width: 200,
    dataIndex: 'detailDeleteAccount',
    key: 'detail',
    render: (detail) =>
      <Paragraph style={{ whiteSpace: 'pre-line' }} ellipsis={{ rows: 3, expandable: true }}>
        {detail}
      </Paragraph>
  }
};

export default columnsTemplate;