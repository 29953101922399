import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Empty, Typography } from 'antd';

import SortableCategoryItem from './SortableCategoryItem';

const { Panel } = Collapse;
const { Text } = Typography;

const CategoryItem = ({ nailistId, category, openManageMenuPopup, hideMenuButton }) => {
  const { menus = [] } = category;
  const menusLength = menus.length;
  // Init collapse state
  const [activeKey, setActiveKey] = useState(menusLength ? [category.objectId] : []);

  useEffect(() => {
    // Case remove service: category has no service and collapse is opened => close collapse
    if (menusLength === 0 && activeKey.length === 1) {
      setActiveKey([]);
    }
    // Case add service: category has service and collapse is closed => open collapse
    if (menusLength > 0 && activeKey.length === 0) {
      setActiveKey([category.objectId]);
    }
  }, [menusLength]);

  return (
    <Collapse
      style={{ marginBottom: '16px' }}
      activeKey={activeKey} // Collapse cateogry with no service
      expandIconPosition="right"
      onChange={(key) => setActiveKey(key)}
    >
      <Panel key={category.objectId} header={<Text strong>{category.title}</Text>}>
        {menusLength ? (
          <SortableCategoryItem
            nailistId={nailistId}
            categoryId={category.objectId}
            menus={menus}
            openManageMenuPopup={openManageMenuPopup}
            hideMenuButton={hideMenuButton}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Panel>
    </Collapse>
  );
};

CategoryItem.propTypes = {
  nailistId: PropTypes.string,
  category: PropTypes.object,
  openManageMenuPopup: PropTypes.func,
  hideMenuButton: PropTypes.bool
};

export default CategoryItem;
