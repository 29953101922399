import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Avatar, Typography, Card, Space, Table, Tag, Modal, Button } from 'antd';
import FilterForm from './Filter/FilterForm';
import { UserOutlined, QuestionCircleOutlined, DisconnectOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchNailistJoinSalon } from 'providers/NailistProvider/actions';
import { unlinkStaff } from 'providers/SalonProvider/actions';

import { makeNailistJoinSalon, makeNailistJoinSalonLoading } from 'providers/NailistProvider/selectors';
import _ from 'lodash';

const { Title, Text } = Typography;

const renderModalInformationHelper = () => {
  Modal.info({
    width: '540px',
    icon: '',
    content: (
      <div>
        <Title level={5}>デザイン投稿(アプリ)・対応メニュー設定はお済みでしょうか?</Title>
        <Text>※上記対応していただくと、5分程で掲載OKに変わります</Text>
      </div>
    ),
    onOk() { },
  });
};

const NailistTab = ({ salonId }) => {
  const dispatch = useDispatch();
  const nailistInSalon = useSelector(makeNailistJoinSalon);
  const loading = useSelector(makeNailistJoinSalonLoading);
  const [searchState, setSearchState] = useState({
    page: 1,
    limit: 10
  });

  useEffect(() => {
    dispatch(fetchNailistJoinSalon({ salonId, ...searchState }));
  }, []);

  useEffect(() => {
    dispatch(fetchNailistJoinSalon({ salonId, ...searchState }));
  }, [searchState]);
  const handleUnlinkStaff = (id) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Are you sure to delete this Staff?',
      content: '',
      okType: 'danger',
      onOk() {
        dispatch(unlinkStaff(id)).then(() => {
          dispatch(fetchNailistJoinSalon({ salonId, ...searchState }));
        });
      },
      onCancel() {}
    });
  };

  const columns = [
    {
      title: '姓名',
      key: 'name',
      render: (record) => (
        <Space align="center" size="middle">
          <Avatar
            icon={<UserOutlined />}
            src={_get(record, 'connections[0].data.avatarLink')}
            size="large"
          />
          <a target='_blank' href={`/dashboard/nailist/profile/${_get(record, 'connections[0].sourceId')}`}>
            {_get(record, 'connections[0].data.username')}
          </a>
        </Space>
      )
    },
    {
      title: '掲載',
      key: 'booking',
      render: function renderItem(record) {

        const isPublished = _get(record, 'connections[0].data.extraInfo.isPublished');
        const assignedMenuStatus = (record?.services || []).filter(i => i.active === true)?.length;

        return (
          <div >
            {
              (isPublished && assignedMenuStatus > 0)
                ? <Text>掲載OK</Text>
                : <>
                  <span style={{ color: 'red', marginRight: 8 }}>掲載NG</span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => renderModalInformationHelper()}
                  >
                    <QuestionCircleOutlined />
                  </span>
                </>
            }
          </div >
        );
      },

    },
    {
      title: 'ステータス',
      key: 'status',
      render: (record) =>
        (_get(record, 'connections[0]') ? <Tag color="green">Connected</Tag> : <Tag>Disconnected</Tag>)
    },
    {
      title: '連携解除',
      key: '',
      render: (record) => (
        <Button
          icon={<DisconnectOutlined />}
          type="primary"
          onClick={() => { handleUnlinkStaff(record?.id); }}
        >
          Unlink
        </Button>
      )
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setSearchState(() => ({
      page: Number(pagination.current),
      limit: Number(pagination.pageSize)
    }));
  };

  const handleSubmit = (values) => {
    setSearchState({
      ...searchState,
      keyword: _.get(values, 'keyword', '')
    })
  }

  return (
    <React.Fragment>
      <Card className="nailist-list-tab">
        <Space align="center" className="search-section">
          <FilterForm
            filters={['keyword']}
            onSubmit={handleSubmit}
          />
          <div className="count-section">
            <Title level={4}>{_.get(nailistInSalon, 'pagination.total', 0)}</Title>
            <Text type="secondary">Nailist connected</Text>
          </div>
        </Space>
        <Table
          columns={columns}
          size="small"
          bordered={true}
          onChange={onTableChange}
          rowKey={(record) => record.id}
          dataSource={nailistInSalon.data}
          pagination={{
            total: _.get(nailistInSalon, 'pagination.total'),
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: 10,
            current: _.get(nailistInSalon, 'pagination.page'),
            showSizeChanger: false
          }}
          loading={loading}
        />
      </Card>
    </React.Fragment>
  );
}

NailistTab.propTypes = {
  data: PropTypes.array
};

export default NailistTab;
