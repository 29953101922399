import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Table, Card, Button, Space } from 'antd';

import {
  getBookingFeeSetting,
  setBookingFeeSetting
} from 'providers/SettingProvider/actions';
import {
  makeBookingFeeSetting,
  makeBookingFeeSettingActionLoading
} from 'providers/SettingProvider/selectors';

import BigPageHeader from 'components/PageHeader';
import EditFeePopUp from './EditFeePopUp';

const Bread = BigPageHeader.Bread;

const BookingFee = () => {
  const dispatch = useDispatch();
  const bookingFees = useSelector(makeBookingFeeSetting);
  const setBookingFeeActionLoading = useSelector(
    makeBookingFeeSettingActionLoading
  );
  let [popUpData, setPopUp] = useState(undefined);

  useEffect(() => {
    dispatch(getBookingFeeSetting());
  }, []);

  const lastSettingBookingFee = _.map(bookingFees, (o) => ({
    ...o,
    ...o.lastSetting
  }));

  const newBookingFeeColumns = [
    ...columns,
    {
      title: 'Created At',
      width: 180,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) =>
        _.get(record, 'iso') &&
        moment(_.get(record, 'iso')).format('YYYY-MM-DD HH:mm')
    },
    {
      title: '',
      key: 'Action',
      width: 120,
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              setPopUp({
                ...record,
                bookingFee: Math.round(
                  (_.get(record, 'bookingFee') * 10000) / 100
                ),
                firstBookingFee: Math.round(
                  (_.get(record, 'firstBookingFee') * 10000) / 100
                )
              });
            }}
          />
        </div>
      )
    }
  ];

  return (
    <>
      <Helmet>
        <title>{'Setting | Booking fee'}</title>
      </Helmet>
      <BigPageHeader
        title={'Booking Fee'}
        breadcrumb={Bread.settingBookingFee}
      />
      <Card bordered={false} className="styled-transparent">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card bordered={false} title="Current Booking Fee">
            <Table
              columns={columns}
              dataSource={bookingFees}
              scroll={{ x: 1000 }}
              pagination={false}
            />
          </Card>
          <Card bordered={false} title="Setting For New Booking Fee">
            <Table
              columns={newBookingFeeColumns}
              dataSource={lastSettingBookingFee}
              scroll={{ x: 1000 }}
              pagination={false}
            />
          </Card>
        </Space>
      </Card>
      {popUpData && (
        <EditFeePopUp
          actionLoading={setBookingFeeActionLoading}
          closeModal={() => setPopUp(undefined)}
          onSubmit={async({ ...values }) => {
            await dispatch(
              setBookingFeeSetting({
                ...values,
                id: popUpData.objectId,
                bookingFee: _.get(values, 'bookingFee') / 100,
                firstBookingFee: _.get(values, 'firstBookingFee') / 100
              })
            );
            setPopUp(undefined);
          }}
          initialValues={{
            bookingFee: popUpData.bookingFee,
            firstBookingFee: popUpData.firstBookingFee
          }}
        />
      )}
    </>
  );
};

BookingFee.propTypes = {};

const columns = [
  {
    title: 'Nailist',
    width: 560,
    dataIndex: 'title',
    key: 'name'
  },
  {
    title: 'New Customer Booking',
    width: 280,
    dataIndex: 'firstBookingFee',
    key: 'new_customer_fee',
    render: (record) => `${Math.round((record * 10000) / 100)}%`
  },
  {
    title: 'Repeated Customer Booking Fee',
    width: 280,
    dataIndex: 'bookingFee',
    key: 'customer_fee',
    render: (record) => `${Math.round((record * 10000) / 100)}%`
  },
  {
    title: 'Apply From',
    width: 180,
    dataIndex: 'applyFrom',
    key: 'applyFrom',
    render: (record) => moment(record).format('YYYY-MM-DD HH:mm')
  }
];

export default BookingFee;
