import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';

import { TextField, MonthPicker, RadioField } from 'components/Form';
import { getPeriodDate } from 'utils/common';
import validate from './validate';

const EditBankFeePopUp = ({
  closeModal,
  handleSubmit,
  actionLoading,
  applyFromMonth
}) => {
  return (
    <Modal
      title="Booking Fee"
      visible={true}
      onOk={handleSubmit}
      confirmLoading={actionLoading}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form onSubmit={handleSubmit}>
        <Field
          name="bookingFee"
          type="number"
          placeholder="Booking fee"
          component={TextField}
          label="Booking fee"
          min={0}
          max={100}
          suffix="%"
        />
        <Field
          name="firstBookingFee"
          type="number"
          placeholder="First booking fee"
          component={TextField}
          label="First booking fee"
          min={0}
          max={100}
          suffix="%"
        />
        <Field
          name="applyFromMonth"
          placeholder="Apply from"
          component={MonthPicker}
          label="Apply from"
          disabledDate={(current) =>
            current &&
            moment(current).endOf('month').isBefore(moment().endOf('month'))
          }
        />
        <Field
          name="applyFrom"
          component={RadioField}
          options={[
            {
              value: moment(applyFromMonth).startOf('month').toString(),
              label: 'First half',
              disabled:
                !applyFromMonth ||
                moment(getPeriodDate()[1]).isSameOrAfter(
                  moment(applyFromMonth).startOf('month')
                )
            },
            {
              value: moment(applyFromMonth)
                .startOf('month')
                .date(16)
                .toString(),
              label: 'Second half',
              disabled:
                !applyFromMonth ||
                moment(getPeriodDate()[1]).isSameOrAfter(
                  moment(applyFromMonth).startOf('month').date(16)
                )
            }
          ]}
        />
      </Form>
    </Modal>
  );
};

EditBankFeePopUp.propTypes = {
  closeModal: PropTypes.any,
  handleSubmit: PropTypes.any,
  actionLoading: PropTypes.any,
  applyFromMonth: PropTypes.any
};

const selector = formValueSelector('editBankFeeForm');

export default connect((state) => {
  const applyFromMonth = selector(state, 'applyFromMonth');
  return {
    applyFromMonth
  };
})(reduxForm({ form: 'editBankFeeForm', validate })(EditBankFeePopUp));
