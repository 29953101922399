import _ from 'lodash';

export const makePayoutDetailBookingsData = (state) => {
  return _.get(state, 'booking.payoutDetail');
};

export const makeReceiptDetailBooking = (state) => {
  return _.get(state, 'booking.receiptDetail');
};

export const makeScheduleBookingListPopupControl = (state) => {
  return _.get(state, 'booking.schedule.bookingPopupControl');
};