import { put, takeLeading, call } from 'redux-saga/effects';

import request from 'utils/request';
import {
  fetchBookingScheduleSuccess,
  fetchBookingScheduleFail
} from './actions';
import {
  FETCH_BOOKING_SCHEDULE
} from './constants';

function* fetchBookingSchedule(action) {
  try {
    const { data } = yield call(request, `bookings/schedule/${action.payload}`, {}, 'GET');
    yield put(fetchBookingScheduleSuccess({ data }, action.meta));
  } catch (e) {
    yield put(fetchBookingScheduleFail(action.meta));
  }
}

export default function* watchBookingOnSchedule() {
  yield takeLeading(FETCH_BOOKING_SCHEDULE, fetchBookingSchedule);
}
