import { connect } from 'react-redux';

import { fetchRenderReceiptEmail } from 'providers/ReceiptProvider/actions';

import ReceiptEmailPreview from './ReceiptEmailPreview';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  fetchRenderReceiptEmail: ({ receiptId }) => dispatch(fetchRenderReceiptEmail({ receiptId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptEmailPreview);