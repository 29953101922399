import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Table, Card, Tag } from 'antd';
import moment from 'moment';

import { formatYen } from 'utils/stringFormat';
import FilterForm from './FilterForm';
import useTableControl from '../../hooks/useTableControl';

const FriendInvitationList = ({ userId, ...props }) => {
  const [search, , handleSubmitFilterForm] = useTableControl({});
  const { dateFrom, dateTo } = search;
  const initialValues = {
    dateRange: [dateFrom ? moment(dateFrom, 'YYYY-MM-DD') : null, dateTo ? moment(dateTo, 'YYYY-MM-DD') : null]
  };

  useEffect(() => {
    if (!dateFrom) {
      delete search.dateFrom;
    }
    if (!dateTo) {
      delete search.dateTo;
    }
    props.getFriendInvitationList({ userId, ...search });
  }, [search]);

  const columns = [
    {
      title: 'Invitation code',
      key: 'invitationCode',
      dataIndex: 'user',
      render: (record) => <Tag color="blue">{record.invitationCode}</Tag>
    },
    {
      title: '招待回数',
      key: 'inviteeNumber',
      dataIndex: 'inviteeNumber'
    },
    {
      title: 'クーポン利用回数',
      key: 'inviteeUsedCouponNumber',
      dataIndex: 'inviteeUsedCouponNumber'
    },
    {
      title: 'クーポン割引金額',
      key: 'inviteeUsedCouponPrice',
      dataIndex: 'inviteeUsedCouponPrice',
      className: 'column-money',
      render: (record) => formatYen(record)
    },
    {
      title: 'ポイント獲得回数',
      key: 'inviteeDone1stBKNumber',
      dataIndex: 'inviteeDone1stBKNumber'
    },
    {
      title: '獲得ポイント',
      key: 'inviteeDone1stBKPrice',
      dataIndex: 'inviteeDone1stBKPrice',
      className: 'column-money',
      render: (record) => formatYen(record)
    }
  ];

  const handleFilterFormChange = ({ dateRange }) => {
    const values = {};
    if (dateRange) {
      values.dateFrom = dateRange[0].format('YYYY-MM-DD');
      values.dateTo = dateRange[1].format('YYYY-MM-DD');
    } else {
      values.dateFrom = null;
      values.dateTo = null;
    }
    handleSubmitFilterForm(values);
  };

  return (
    <Card bordered={false}>
      <div style={{ marginBottom: 16 }}>
        <FilterForm onSubmit={handleFilterFormChange} initialValues={initialValues} />
      </div>
      <Table
        columns={columns}
        dataSource={props.friendInvitationList}
        scroll={{ x: true }}
        loading={props.friendInvitationLoading}
        size="small"
        pagination={false}
      />
    </Card>
  );
};

FriendInvitationList.propTypes = {
  userId: PropTypes.string,
  getFriendInvitationList: PropTypes.func.isRequired,
  friendInvitationList: PropTypes.array,
  friendInvitationLoading: PropTypes.bool
};

export default FriendInvitationList;
