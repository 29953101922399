import { connect } from 'react-redux';
import {
  SetPopup
} from 'providers/PopupProvider/actions';
import { makeAnyPopup } from 'providers/PopupProvider/selectors';
import PayoutChangedPopup from './Popup';
import { execChangeAllPayout, fetchChangedPayouts } from 'providers/PayoutProvider/actions';
import { makeChangePayoutLoading, makeChangedPayoutList } from 'providers/PayoutProvider/selectors';

const mapStateToProps = (state) => ({
  data: makeAnyPopup(state, 'payoutChanged'),
  changedPayoutsList: makeChangedPayoutList(state),
  changeLoading: makeChangePayoutLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({ name: 'payoutChanged', show: false })),
  fetchChangedPayouts: (filter) => dispatch(fetchChangedPayouts(filter)),
  execChangeAllPayout: (filter)=> dispatch(execChangeAllPayout(filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(PayoutChangedPopup);