import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CategoryList from './CategoryList';
import useManageMenu from '../hooks/useManageMenu';

const Menu = ({ nailistId, data, hideMenuButton }) => {
  const [openManageMenuPopup, ManageMenuPopup] = useManageMenu(nailistId);

  return (
    <div style={{ padding: '0 24px' }}>
      {!hideMenuButton && <Row style={{ justifyContent: 'flex-end', marginBottom: 16 }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={openManageMenuPopup} style={{ margin: 0 }}>
          メニューを追加
        </Button>
      </Row>}
      <CategoryList
        nailistId={nailistId}
        categories={data}
        openManageMenuPopup={openManageMenuPopup}
        hideMenuButton={hideMenuButton}
      />
      {ManageMenuPopup}
    </div>
  );
};

Menu.propTypes = {
  nailistId: PropTypes.string,
  data: PropTypes.array,
  hideMenuButton: PropTypes.bool
};

export default Menu;
