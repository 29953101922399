import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table, Row, Col, Badge, Tag, Avatar, Modal } from 'antd';
import { MenuOutlined, PictureOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import {
  deleteNailistMenu, changeNailistMenuStatus, changeNailistMenuPosition, changeNailistMenuPositionSuccess
} from 'providers/NailistProvider/actions';

const MENU_REPEAT_TYPE = {
  NEW: '新規限定',
  REPEATER: '新規限定',
  ALL: '全員OK'
};

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const SortableItem = sortableElement((props) => <tr {...props} />);
const DragableBodyRow = ({ ...restProps }) => <SortableItem index={restProps['data-row-key']} {...restProps} />;

const SortableCategoryItem = sortableContainer(({
  nailistId, categoryId, menus, openManageMenuPopup, hideMenuButton
}) => {
  const dispatch = useDispatch();
  const dataSource = menus.map((menu, index) => ({ ...menu, index, key: menu.id }));

  const deleteMenu = (menuId) => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure to delete the service?',
      onOk: () => {
        dispatch(deleteNailistMenu({ nailistId, menuId }));
      }
    });
  };

  const changeMenuStatus = ({ objectId, state }) => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure to ${state === 'ACTIVE' ? 'inactive' : 'active'} the service?`,
      onOk: () => {
        dispatch(changeNailistMenuStatus({
          nailistId, menuId: objectId, status: state === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        }));
      }
    });
  };

  const columns = [
    {
      width: 30,
      className: 'drag-visible',
      render: () => <div style={{ paddingLeft: 45 }}>{!hideMenuButton && <DragHandle />}</div>
    },
    {
      dataIndex: 'image',
      width: 40,
      className: 'drag-visible',
      render: (image) => <Avatar shape="square" size="large" src={image} icon={<PictureOutlined />} />
    },
    {
      dataIndex: 'repeatType',
      width: 30,
      className: 'drag-visible',
      render: (repeatType) => <Tag color="cyan">{MENU_REPEAT_TYPE[repeatType]}</Tag>
    },
    {
      className: 'drag-visible',
      render: ({ state, title }) =>
        <>{title} {state !== 'ACTIVE' && <Badge count="非公開" style={{ backgroundColor: 'gray' }} />}</>
    },
    {
      dataIndex: 'time',
      width: '15%',
      render: (time) => `${time} min`
    },
    {
      dataIndex: 'price',
      width: '15%',
      render: (price) => Number(price || 0).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    },
    {
      width: '3%',
      render: (record) => !hideMenuButton && (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button danger type="link" onClick={() => deleteMenu(record.objectId)}>
            削除
          </Button>
          <Button type="link" onClick={() => changeMenuStatus(record)}>
            {record.state === 'ACTIVE' ? '非公開' : '公開'}
          </Button>
          <Button type="link" onClick={() => openManageMenuPopup({ categoryId, ...record })}>
            編集
          </Button>
        </div>
      )
    }
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newMenus = arrayMoveImmutable(menus, oldIndex, newIndex);
      dispatch(changeNailistMenuPositionSuccess({ categoryId, menus: newMenus }));
      dispatch(changeNailistMenuPosition({ nailistId, categoryId, menuIds: newMenus.map(menu => menu.objectId) }));
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  return dataSource.length > 0 && (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
      <Col className="gutter-row" span={24}>
        <Table
          pagination={false}
          showHeader={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DragableBodyRow
            }
          }}
        />
      </Col>
    </Row>
  );
});

export default SortableCategoryItem;
