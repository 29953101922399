// Checked
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Menu, Affix, Avatar, Dropdown, Typography } from 'antd';
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';

import { logout } from 'providers/AuthProvider/actions';
import { makeMe } from 'providers/AuthProvider/selectors';

const { Header } = Layout;

const CustomNav = ({ me, logout }) => {
  const { role = ' ' } = me;

  return (
    <Affix offsetTop={0} style={{ zIndex: 10 }}>
      <Header>
        <div
          style={{
            height: '64px',
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexFlow: 'row-reverse',
            alignItems: 'center',
            boxShadow: '0 1px 4px rgba(0, 21, 41, 0.15)',
            borderBottom: '1px solid rgba(0, 21, 41, 0.12)'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={logout}>
                  <PoweroffOutlined />
                  <Typography.Text>{'  Logout'}</Typography.Text>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <div
              style={{
                marginRight: '24px',
                height: '40px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {role[0] + role.slice(1).toLowerCase()}
              <Avatar
                shape="circle"
                size="large"
                icon={<UserOutlined />}
                style={{ marginLeft: '8px' }}
              />
            </div>
          </Dropdown>
        </div>
      </Header>
    </Affix>
  );
};

CustomNav.propTypes = {
  me: PropTypes.object,
  logout: PropTypes.any
};

const mapStateToProps = (state) => ({
  me: makeMe(state)
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNav);
