import _ from 'lodash';

export const makeAccountNoBankList = (state) => {
  return _.get(state, 'accountNoBank.accountNoBank.data');
};

export const makeAccountNoBankTotal = (state) => {
  return _.get(state, 'accountNoBank.accountNoBank.total');
};

export const makeAccountNoBankLoading = (state) => {
  return _.get(state, 'accountNoBank.dataLoading');
};
