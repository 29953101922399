import { connect } from 'react-redux';
import { changeReceiptBookingPrice } from 'providers/ReceiptProvider/actions';

import EditReceiptPriceButton from './EditReceiptPriceButton';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  changeReceiptBookingPrice: ({ bookingId, finalPrice, customerPrice, screen }) => dispatch(changeReceiptBookingPrice({ bookingId, finalPrice, customerPrice, screen }))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReceiptPriceButton);