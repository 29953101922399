import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd';

import { countUnreadWebTickets } from 'providers/SupportProvider/actions';
import { makeCountUnreadWebTickets } from 'providers/SupportProvider/selectors';

const CountTickets = ({ text }) => {
  const dispatch = useDispatch();
  const count = useSelector(makeCountUnreadWebTickets);

  useEffect(() => {
    dispatch(countUnreadWebTickets());}, []);
  
  return count > 0 ? (
    <Badge count={count} showZero>
      {text}
    </Badge>
  ) : <>{text}</>
}

export default CountTickets;
