export const FETCH_RECEIPT_LIST = 'FETCH_RECEIPT_LIST';
export const FETCH_RECEIPT_LIST_SUCCESS = 'FETCH_RECEIPT_LIST_SUCCESS';

export const FETCH_RELATED_RECEIPT_LIST = 'FETCH_RELATED_RECEIPT_LIST';
export const FETCH_RELATED_RECEIPT_LIST_SUCCESS = 'FETCH_RELATED_RECEIPT_LIST_SUCCESS';

export const FETCH_RENDER_RECEIPT = 'FETCH_RENDER_RECEIPT';
export const FETCH_RENDER_RECEIPT_SUCCESS = 'FETCH_RENDER_RECEIPT_SUCCESS';

export const CHANGE_RECEIPT_BOOKING_PRICE = 'CHANGE_RECEIPT_BOOKING_PRICE';
export const CHANGE_RECEIPT_BOOKING_PRICE_SUCCESS = 'CHANGE_RECEIPT_BOOKING_PRICE_SUCCESS';

export const RESEND_RECEIPT_EMAIL = 'RESEND_RECEIPT_EMAIL';
export const RESEND_RECEIPT_EMAIL_SUCCESS = 'RESEND_RECEIPT_EMAIL_SUCCESS';
export const RESEND_RECEIPT_EMAIL_FAIL = 'RESEND_RECEIPT_EMAIL_FAIL';
