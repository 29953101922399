import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';


import PageHeader from 'components/PageHeader';
import FilterForm from '../../components/FilterForm';
import NewNailists from './NewNailists';
import NailistNumber from './NailistNumber';

const { Bread } = PageHeader;

const NailistDashboard = () => {
  const history = useHistory();

  return (
    <>
      <PageHeader
        title={'Nailists'}
        goBack={false}
        breadcrumb={Bread.nailist}
      >
        <FilterForm
          onSubmit={(values) => history.push(`/dashboard/nailist/search?${qs.stringify(values)}`)}
        />
      </PageHeader>

      <div className="page-container" id="nailist-page">
        <NailistNumber />
        <NewNailists />
      </div>
    </>
  );
};

NailistDashboard.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default NailistDashboard;
