import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import { put, takeLeading, call, takeEvery, takeLatest } from 'redux-saga/effects';

import request from 'utils/request';
import { getPeriodDate, JSONclone } from 'utils/common';
import {
  fetchNailistListSuccess,
  setSearchNailistLoading,
  fetchNailistProfileSuccess,
  fetchNailistBankInfoSuccess,
  fetchNailistCategoryListSuccess,
  fetchNailistMenu as fetchNailistMenuRequest,
  fetchNailistMenuSuccess,
  fetchNailistCountsSuccess,
  fetchNewestNailistListSuccess,
  fetchNailistCurrentBalanceSuccess,
  fetchBriefNailistProfileSuccess,
  fetchNailistJoinSalonSuccess,
  setNailistJoinSalonLoading,
  fetchNailistInsightSuccess,
  recalculateIncomeSuccess,
  updateNailistWorkingTimeSuccess,
  updateNailistWorkingTimeFail,
  updateNailistInfoSuccess,
  updateNailistInfoFail,
  updateNailistProfileSalonSuccess,
  updateNailistProfileSalonFail,
  fetchNailistSlotSCheduleSuccess,
  verifyNailistEmailSuccess,
  fetchNailistSlotSCheduleFail,
  fetchNailistPostsSuccess,
  fetchNailistPostDetailSuccess,
  deletePostSuccess,
  deletePostFail,
  fetchNailistPostDetailFail,
  fetchNailistDisabledListSuccess,
  actionNailistRejoin,
  actionNailistRejoinFail,
  actionNailistRejoinSuccess
} from './actions';
import {
  FETCH_NAILIST_LIST,
  FETCH_NAILIST_PROFILE,
  FETCH_NAILIST_BANK_INFO,
  FETCH_NAILIST_CATEGORY_LIST,
  FETCH_NAILIST_MENU,
  CREATE_NAILIST_MENU,
  EDIT_NAILIST_MENU,
  DELETE_NAILIST_MENU,
  CHANGE_NAILIST_MENU_STATUS,
  CHANGE_NAILIST_MENU_POSITION,
  FETCH_NAILIST_COUNTS,
  FETCH_NAILIST_CURRENT_BALANCE,
  FETCH_NAILIST_JOIN_SALON,
  FETCH_NAILIST_INSIGHT,
  RECALCULATE_INCOME,
  UPDATE_NAILIST_WORKING_TIME,
  UPDATE_NAILIST_INFO,
  UPDATE_NAILIST_PROFILE_SALON,
  FETCH_NAILIST_SLOT_SCHEDULE,
  VERIFY_NAILIST_EMAIL,
  FETCH_NAILIST_POST_DETAIL,
  FETCH_NAILIST_POSTS,
  DELETE_POST,
  FETCH_NAILIST_DISABLED_LIST,
  ACTION_NAILIST_REJOIN
} from './constants';
import { deformatWorkingTime, formatScheduleFromDateToTimeSlot } from 'utils/scheduleAndWorkTime';

function* fetchNalistList(action) {
  const { meta } = action;
  try {
    yield put(setSearchNailistLoading({ loading: true }));

    const filter = {...action.payload};
    if (filter['date-range']) {
      filter.dateRange = filter['date-range'];
      delete filter['date-range'];
    }

    if (filter['premium']) {
      filter.isMark = filter['premium'] === 'No' ? false : true;
      delete filter['premium'];
    }
    filter.limit = Number(filter.limit) || 10;

    let callbackDispatcher = fetchNailistListSuccess;
    if (filter.for === 'newNailistsList') {
      callbackDispatcher = fetchNewestNailistListSuccess;
    }

    const { data } = yield call(request, 'nailist/getNailistsList', filter);

    yield put(callbackDispatcher(data, meta));
    yield put(setSearchNailistLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNalistProfile(action) {
  try {
    const id = _.get(action, 'payload.id');
    const { data } = yield call(request, 'nailist/getNailistProfile', { _id: id });

    if (!_.get(action, 'payload.brief')) {
      yield put(fetchNailistProfileSuccess(data));
    }
    yield put(fetchBriefNailistProfileSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNalistBankInfo(action) {
  try {
    const id = _.get(action, 'payload.id');
    const { data } = yield call(request, 'nailist/getNailistBankInfo', { nailistId: id });

    data._id = id;
    yield put(fetchNailistBankInfoSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNalistCategoryList(action) {
  try {
    const id = action.payload;
    const { data } = yield call(request, `nailists/${id}/menu/categories`, {}, 'GET');
    yield put(fetchNailistCategoryListSuccess(data.result));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNalistMenu(action) {
  try {
    const id = action.payload;
    const { data } = yield call(request, `nailists/${id}/menu`, {}, 'GET');
    yield put(fetchNailistMenuSuccess(data.result));
  } catch (e) {
    console.log(e);
  }
}

function* createNailistMenu(action) {
  try {
    const { nailistId, ...values } = action.payload;
    yield call(request, `nailists/${nailistId}/menu`, values);
    yield put(fetchNailistMenuRequest(nailistId));
  } catch (e) {
    console.log(e);
  }
}

function* editNailistMenu(action) {
  try {
    const { nailistId, menuId, ...values } = action.payload;
    yield call(request, `nailists/${nailistId}/menu/${menuId}`, values);
    yield put(fetchNailistMenuRequest(nailistId));
  } catch (e) {
    console.log(e);
  }
}

function* deleteNailistMenu(action) {
  try {
    const { nailistId, menuId } = action.payload;
    yield call(request, `nailists/${nailistId}/menu/${menuId}`, {}, 'DELETE');
    yield put(fetchNailistMenuRequest(nailistId));
  } catch (e) {
    console.log(e);
  }
}

function* changeNailistMenuStatus(action) {
  try {
    const { nailistId, menuId, status } = action.payload;
    yield call(request, `nailists/${nailistId}/menu/${menuId}/status`, { status }, 'PATCH');
    yield put(fetchNailistMenuRequest(nailistId));
  } catch (e) {
    console.log(e);
  }
}

function* changeNailistMenuPosition(action) {
  try {
    const { nailistId, categoryId, menuIds } = action.payload;
    yield call(request, `nailists/${nailistId}/menu/position`, { categoryId, menuIds }, 'PATCH');
    yield put(fetchNailistMenuRequest(nailistId));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNalistCounts() {
  try {
    const types = ['NEW', 'SENIORITY', 'PREMIUM', 'ACTIVE', 'INACTIVE'];
    const { data } = yield call(request, 'nailist/countNailists', { types: types });
    yield put(fetchNailistCountsSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNailistCurrentBalance(action) {
  try {
    if (!action.payload) {
      return;
    }

    const now = moment().utc().format('YYYY-MM-DD');
    const filter = { nailistId: action.payload };
    filter.startDate = now;
    filter.endDate = now;
    filter.includeIncome = true;

    const { data } = yield call(request, 'payout/getNailistsPayouts', filter);
    let result = _.get(data, 'data[0]', {});

    if (!result.period) {
      const thisPeriod = getPeriodDate(new Date());
      result.period = {
        startDate: thisPeriod[0],
        endDate: thisPeriod[1]
      };
    } else {
      result.period = {
        startDate: moment.utc(_.get(result, 'period.startDate')),
        endDate: moment.utc(_.get(result, 'period.endDate'))
      };
    }

    const grossList = _.reduce(_.get(result, 'list', []), (arrResult, obj) => {
      const foundObj = _.find(arrResult, (o) => o.salonId === obj.salonId);
      if (foundObj) {
        _.merge(foundObj, { ...obj, allBookingsNumber: foundObj.allBookingsNumber + obj.allBookingsNumber });
      } else {
        arrResult.push({ ...obj });
      }
      return arrResult;
    }, []);

    result = {
      period:
        moment(result.period.startDate).format('MM月DD日') +
        ' ~ ' +
        moment(result.period.endDate).format('MM月DD日'),
      bookings: result.allBookingsNumber,
      totalBookingValue: result.totalBookingValue,
      nailieComission: result.nailieComission,
      transferAmount: result.transferAmount,
      settlementFee: result.settlementFee,
      list: _.get(result, 'list', []),
      listAllBookingsNumber: grossList
    };


    yield put(fetchNailistCurrentBalanceSuccess(result));
  } catch (e) {
    console.log(e);
  }
}
function* recalculateIncome(action) {
  try {
    if (!action.payload) {
      return;
    }
    const filter = { nailistId: action.payload.nailistId };
    const thisPeriod = getPeriodDate(new Date());
    filter.date = moment(thisPeriod[0]).format('YYYY-MM-DD');
    const { data } = yield call(request, '/devtools/triggerIncome', filter, 'GET');

    yield put(recalculateIncomeSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchNailistJoinSalon(action) {
  try {
    try {
      yield put(setNailistJoinSalonLoading({ loading: true }));
      const { salonId, keyword, page, limit } = action.payload;
      const { data } = yield call(request, `/salons/${salonId}/staff`, { limit, page, keyword: keyword ? _.trim(keyword) : undefined, connectionStatus: '1' }, 'GET');

      yield put(fetchNailistJoinSalonSuccess(data));
      yield put(setNailistJoinSalonLoading({ loading: false }));
    } catch (error) {
      console.log(error);
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchNailistInsight(action) {
  try {
    const id = _.get(action, 'payload.id');
    const { data } = yield call(request, `nailists/${id}/insight`, {}, 'GET');
    yield put(fetchNailistInsightSuccess(data));
  } catch (e) {
    console.log(e);
  }
}
function* fetchNailistPosts(action) {
  try {
    const id = _.get(action, 'payload.id');
    const page = _.get(action, 'payload.currentPage');
    const { data } = yield call(request, `nailists/${id}/posts`, { page, limit: 100 }, 'GET');
    yield put(fetchNailistPostsSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}
function* fetchNailistPostDetail(action) {
  try {
    const id = _.get(action, 'payload.id');
    const postId = _.get(action, 'payload.postId');
    const { data } = yield call(request, `nailists/${id}/post/${postId}`, {}, 'GET');
    yield put(fetchNailistPostDetailSuccess(data, action.meta));
  } catch (e) {
    yield put(fetchNailistPostDetailFail(e, action.meta));
    console.log(e);
  }
}
function* deletePost(action) {
  try {
    const id = _.get(action, 'payload.id');
    const postId = _.get(action, 'payload.postId');
    const { data } = yield call(request, `nailists/${id}/post/${postId}`, {}, 'DELETE');
    yield put(deletePostSuccess({ data, postId }, action.meta, postId));
  } catch (e) {
    console.log(e);
    yield put(deletePostFail(e, action.meta));
    message.error('Post not found');
  }
}

function* updateNailistWorkingTime(action) {
  const f = deformatWorkingTime;
  try {
    const userId = _.get(action, 'payload.userId');
    const value = _.get(action, 'payload.values');
    const isFieldsTouched = _.get(action, 'payload.isFieldsTouched');

    let weekdays = {
      monday: { closeTime: f(value.monEnd), openTime: f(value.monStart), isOff: !value.monIsOpen },
      tuesday: { closeTime: f(value.tueEnd), openTime: f(value.tueStart), isOff: !value.tueIsOpen },
      wednesday: { closeTime: f(value.wedEnd), openTime: f(value.wedStart), isOff: !value.wedIsOpen },
      thursday: { closeTime: f(value.thuEnd), openTime: f(value.thuStart), isOff: !value.thuIsOpen },
      friday: { closeTime: f(value.friEnd), openTime: f(value.friStart), isOff: !value.friIsOpen },
      saturday: { closeTime: f(value.satEnd), openTime: f(value.satStart), isOff: !value.satIsOpen },
      sunday: { closeTime: f(value.sunEnd), openTime: f(value.sunStart), isOff: !value.sunIsOpen }
    };
    const updateState = JSONclone(weekdays);
    weekdays = _.mapValues(weekdays, function (day, key) {
      const WD = {
        monday: ['monEnd', 'monStart', 'monIsOpen'],
        tuesday: ['tueEnd', 'tueStart', 'tueIsOpen'],
        wednesday: ['wedEnd', 'wedStart', 'wedIsOpen'],
        thursday: ['thuEnd', 'thuStart', 'thuIsOpen'],
        friday: ['friEnd', 'friStart', 'friIsOpen'],
        saturday: ['satEnd', 'satStart', 'satIsOpen'],
        sunday: ['sunEnd', 'sunStart', 'sunIsOpen']
      };

      if (!isFieldsTouched([WD[key][0], WD[key][1]])) {
        delete day.closeTime;
        delete day.openTime;
      }
      if (!isFieldsTouched([WD[key][2]])) {
        delete day.isOff;
      }
      return _.isEmpty(day) ? undefined : day;
    });

    yield call(request, `nailists/${userId}/weekdays`, { weekdays }, 'POST');
    yield put(updateNailistWorkingTimeSuccess({ weekdays: updateState, userId }, action.meta));
    message.success('updated!');
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    yield put(updateNailistWorkingTimeFail(error, action.meta));
  }
}

function* updateNailistInfo(action) {
  try {
    const userId = _.get(action, 'payload.userId');
    const values = action.payload;
    const birthday = (!values.birthday || values.birthday === '____/__/__') ? '' : moment.utc(values.birthday).toISOString();
    yield call(request, `nailists/${userId}/info`, { ...values, birthday }, 'POST');
    yield put(updateNailistInfoSuccess({ ...values }, action.meta));
    message.success('updated!');
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    yield put(updateNailistInfoFail(error, action.meta));
  }
}
function* updateNailistProfileSalon(action) {
  try {
    const userId = _.get(action, 'payload.userId');
    const values = action.payload;

    yield call(request, `nailists/${userId}/profile`, { ...values }, 'POST');
    yield put(updateNailistProfileSalonSuccess({ ...values }, action.meta));
    message.success('updated!');
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    yield put(updateNailistProfileSalonFail(error, action.meta));
  }
}

function* fetchNailistSlotSChedule(action) {
  try {
    const userId = _.get(action, 'payload.userId');
    const months = _.get(action, 'payload.months');

    const { data } = yield call(request, `nailists/${userId}/schedule`, { months }, 'GET');
    const timeSlotSchedule = formatScheduleFromDateToTimeSlot(data?.scheduleByMonths[0]?.schedule, data.weekdays);
    yield put(fetchNailistSlotSCheduleSuccess({ schedule: data.scheduleByMonths[0]?.schedule, timeSlotSchedule }, action.meta));
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
    yield put(fetchNailistSlotSCheduleFail(error, action.meta));
  }
}

function* verifyNailistEmail(action) {
  try {
    const userId = _.get(action, 'payload.userId');

    yield call(request, `nailists/${userId}/verify-email`, {}, 'PATCH');
    yield put(verifyNailistEmailSuccess({ userId }));
    message.success('Verified!');
  } catch (error) {
    message.error(error.error || error.message || 'Something went wrong');
  }
}

function* fetchNalistDisabledList(action) {
  try {
    yield put(setSearchNailistLoading({ loading: true }));
    let filter = action.payload;
    if (action?.payload?.keyword) {
      filter.keyword = action?.payload?.keyword.trim() || '';
    }

    filter.limit = filter.limit || 10;

    let callbackDispatcher = fetchNailistDisabledListSuccess;
    const { data } = yield call(request, 'nailist/getListNailistDisable', filter);

    yield put(callbackDispatcher(data));
    yield put(setSearchNailistLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* callActionNailistRejoin(action) {
  try {
    const id = _.get(action, 'payload.nailistId');
    const { data } = yield call(request, `nailist/rejoin/${id}`, {}, 'GET');
    yield put(actionNailistRejoinSuccess(data, action.meta));
  } catch (e) {
    yield put(actionNailistRejoinFail(e, action.meta));
    console.log(e);
  }
}

export default function* watchNailist() {
  yield takeLatest(FETCH_NAILIST_LIST, fetchNalistList);
  yield takeLeading(FETCH_NAILIST_PROFILE, fetchNalistProfile);
  yield takeLeading(FETCH_NAILIST_BANK_INFO, fetchNalistBankInfo);
  yield takeLeading(FETCH_NAILIST_CATEGORY_LIST, fetchNalistCategoryList);
  yield takeLeading(FETCH_NAILIST_MENU, fetchNalistMenu);
  yield takeLeading(CREATE_NAILIST_MENU, createNailistMenu);
  yield takeLeading(EDIT_NAILIST_MENU, editNailistMenu);
  yield takeLeading(DELETE_NAILIST_MENU, deleteNailistMenu);
  yield takeLeading(CHANGE_NAILIST_MENU_STATUS, changeNailistMenuStatus);
  yield takeLeading(CHANGE_NAILIST_MENU_POSITION, changeNailistMenuPosition);
  yield takeLeading(FETCH_NAILIST_COUNTS, fetchNalistCounts);
  yield takeLeading(FETCH_NAILIST_CURRENT_BALANCE, fetchNailistCurrentBalance);
  yield takeLeading(RECALCULATE_INCOME, recalculateIncome);
  yield takeLeading(FETCH_NAILIST_JOIN_SALON, fetchNailistJoinSalon);
  yield takeLeading(FETCH_NAILIST_INSIGHT, fetchNailistInsight);
  yield takeLeading(FETCH_NAILIST_POSTS, fetchNailistPosts);
  yield takeLeading(FETCH_NAILIST_POST_DETAIL, fetchNailistPostDetail);
  yield takeLeading(DELETE_POST, deletePost);
  yield takeLeading(UPDATE_NAILIST_WORKING_TIME, updateNailistWorkingTime);
  yield takeLeading(UPDATE_NAILIST_INFO, updateNailistInfo);
  yield takeLeading(UPDATE_NAILIST_PROFILE_SALON, updateNailistProfileSalon);
  yield takeLeading(FETCH_NAILIST_SLOT_SCHEDULE, fetchNailistSlotSChedule);
  yield takeLeading(VERIFY_NAILIST_EMAIL, verifyNailistEmail);
  yield takeLatest(FETCH_NAILIST_DISABLED_LIST, fetchNalistDisabledList);
  yield takeEvery(ACTION_NAILIST_REJOIN, callActionNailistRejoin);
}
