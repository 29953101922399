import {
  FETCH_REWARD_POINT_LIST,
  FETCH_REWARD_POINT_LIST_SUCCESS,
  CREATE_REWARD_POINT,
  CREATE_REWARD_POINT_SUCCESS,
  CREATE_REWARD_POINT_FAIL,
  SET_REWARD_POINT_LIST_LOADING
} from './constants';

export function fetchRewardPointList(payload = {}) {
  return { type: FETCH_REWARD_POINT_LIST, payload };
}

export function fetchRewardPointListSuccess(payload = {}) {
  return { type: FETCH_REWARD_POINT_LIST_SUCCESS, payload };
}

export function setGetRewardPointListLoading(payload = { loading: false }) {
  return { type: SET_REWARD_POINT_LIST_LOADING, payload };
}

export function createRewardPoint(payload = {}) {
  return { type: CREATE_REWARD_POINT, payload, meta: { thunk: true } };
}

export function createRewardPointSuccess(payload = {}, meta) {
  return { type: CREATE_REWARD_POINT_SUCCESS, payload, meta };
}

export function createRewardPointFail(payload = {}, meta) {
  return { type: CREATE_REWARD_POINT_FAIL, payload, meta, error: true };
}
