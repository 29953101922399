import {
  fetchBannerListSuccess, toggleBannerStatusSuccess, createBannerSuccess,
  updateBannerSuccess, updateBannerFail,
  updateBannersPositionSuccess, updateBannersPositionFail
} from './actions';
import {
  FETCH_BANNER_LIST, TOGGLE_BANNER_STATUS, CREATE_BANNER,
  UPDATE_BANNER, UPDATE_BANNERS_POSITION
} from './constants';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import { goBack, replace } from 'connected-react-router';
import request from 'utils/request';
import { message } from 'antd';

function* fetchBannerList(action) {
  const filter = {...action.payload};
  try {
    filter.status = filter.status ? [filter.status] : [];
    filter.showOnRoles = filter.showOnRoles ? [filter.showOnRoles] : undefined;
    const { data } = yield call(request, 'banner/', { ...filter, limit: 10 }, 'GET');

    yield put(fetchBannerListSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}
function* toggleBannerStatus(action) {
  const { id, status } = action.payload;
  try {
    const { data } = yield call(request, `banner/${id}/toggleStatus`, { status }, 'PATCH');

    yield put(toggleBannerStatusSuccess({ ...data, id, status }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* createBanner(action) {
  try {
    yield call(request, 'banner/create', action.payload, 'POST');
    yield put(createBannerSuccess());
    message.success('成功しました！ (バナーのリストにリダイレクト中)');
    yield put(replace('/dashboard/banner'));
  } catch (e) {
    console.log(e);
  }
}

function* updateBanner(action) {
  try {
    yield call(request, `banner/${action.payload.objectId}`, action.payload, 'POST');
    yield put(updateBannerSuccess());
    message.success('成功しました！ (バナーのリストにリダイレクト中)');
    yield put(goBack());
  } catch (e) {
    yield put(updateBannerFail());
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

function* updateBannersPos(action) {
  let { oldList, newList } = action?.payload;
  newList = newList.map((r, index) => ({ ...r, id: r.objectId, orderNo: oldList[index].orderNo }));

  try {
    yield put(fetchBannerListSuccess({ list: newList })); // temp list
    yield call(request, 'banner/orderNo', { banners: newList }, 'POST');
    yield put(updateBannersPositionSuccess({}, action.meta));
    message.success('成功しました！');
  } catch (e) {
    yield put(updateBannersPositionFail({}, action.meta));
    yield put(fetchBannerListSuccess({ list: oldList })); // apply old list
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

export default function* watchBanner() {
  yield takeEvery(FETCH_BANNER_LIST, fetchBannerList);
  yield takeEvery(TOGGLE_BANNER_STATUS, toggleBannerStatus);
  yield takeLeading(CREATE_BANNER, createBanner);
  yield takeLeading(UPDATE_BANNER, updateBanner);
  yield takeLeading(UPDATE_BANNERS_POSITION, updateBannersPos);
}
