import {
  FETCH_ANNOUNCEMENT_LIST,
  FETCH_ANNOUNCEMENT_LIST_SUCCESS,
  TOGGLE_ANNOUNCEMENT_STATUS,
  TOGGLE_ANNOUNCEMENT_STATUS_SUCCESS,
  CREATE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_FAIL,
  UPDATE_ANNOUNCEMENTS_POSITION,
  UPDATE_ANNOUNCEMENTS_POSITION_SUCCESS,
  UPDATE_ANNOUNCEMENTS_POSITION_FAIL
} from './constants';

export function fetchAnnouncementList(payload = {}) {
  return { type: FETCH_ANNOUNCEMENT_LIST, payload, meta: { thunk: true } };
}
export function fetchAnnouncementListSuccess(payload = {}, meta) {
  return { type: FETCH_ANNOUNCEMENT_LIST_SUCCESS, payload, meta };
}
export function toggleAnnouncementStatus(payload = {}) {
  return { type: TOGGLE_ANNOUNCEMENT_STATUS, payload, meta: { thunk: true } };
}
export function toggleAnnouncementStatusSuccess(payload = {}, meta) {
  return { type: TOGGLE_ANNOUNCEMENT_STATUS_SUCCESS, payload, meta };
}

export function createAnnouncement(payload = {}) {
  return { type: CREATE_ANNOUNCEMENT, payload, meta: { thunk: true } };
}
export function createAnnouncementSuccess(payload = {}, meta) {
  return { type: CREATE_ANNOUNCEMENT_SUCCESS, payload, meta };
}

export function updateAnnouncement(payload = {}) {
  return { type: UPDATE_ANNOUNCEMENT, payload, meta: { thunk: true } };
}
export function updateAnnouncementSuccess(payload = {}, meta) {
  return { type: UPDATE_ANNOUNCEMENT_SUCCESS, payload, meta };
}
export function updateAnnouncementFail(error, meta) {
  return { type: UPDATE_ANNOUNCEMENT_FAIL, error, meta };
}


export function updateAnnouncementsPosition(payload = {}) {
  return { type: UPDATE_ANNOUNCEMENTS_POSITION, payload, meta: { thunk: true } };
}
export function updateAnnouncementsPositionSuccess(payload = {}, meta) {
  return { type: UPDATE_ANNOUNCEMENTS_POSITION_SUCCESS, payload, meta };
}
export function updateAnnouncementsPositionFail(error, meta) {
  return { type: UPDATE_ANNOUNCEMENTS_POSITION_FAIL, error, meta };
}
