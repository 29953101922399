import { connect } from 'react-redux';

import {
  fetchNailistProfile,
  fetchNailistBankInfo,
  unsetNalistProfile,
  fetchNailistCategoryList,
  fetchNailistMenu,
  fetchNailistCurrentBalance,
  fetchNailistInsight,
  fetchNailistPosts,
  fetchNailistPostDetail,
  fetchNailistDisabledList
} from 'providers/NailistProvider/actions';
import { makeNailistProfile, makeNailistInsight, makeNailistPosts, makeNailistDisabled } from 'providers/NailistProvider/selectors';

import { SetPopup } from 'providers/PopupProvider/actions';

import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import NailistDetailPage from './NailistDetailPage';
import { updateUserStatus } from 'providers/DeletedAccountProvider/actions';

const mapStateToProps = (state) => ({
  profile: makeNailistProfile(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state),
  nailistInsight: makeNailistInsight(state),
  nailistPosts: makeNailistPosts(state),
  nailistDisabled: makeNailistDisabled(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchNailistDisabled: (params) => dispatch(fetchNailistDisabledList(params)),
  fetchNailistProfile: (params) => dispatch(fetchNailistProfile(params)),
  fetchNailistBankInfo: (params) => dispatch(fetchNailistBankInfo(params)),
  fetchNailistCategoryList: (params) => dispatch(fetchNailistCategoryList(params)),
  fetchNailistMenu: (params) => dispatch(fetchNailistMenu(params)),
  fetchNailistCurrentBalance: (params) => dispatch(fetchNailistCurrentBalance(params)),
  unsetNalistProfile: () => dispatch(unsetNalistProfile()),
  openPayoutPopup: (show) =>
    dispatch(
      SetPopup({
        name: 'payout',
        show: { payoutType: 'NAILIST', payoutCurrentPeriod: true, ...show }
      })
    ),
  openBankPopup: (show) => dispatch(SetPopup({ name: 'bankPopup', show })),
  fetchNailistInsight: (params) => dispatch(fetchNailistInsight(params)),
  fetchNailistPosts: (params) => dispatch(fetchNailistPosts(params)),
  fetchNailistPostDetail: (params) => dispatch(fetchNailistPostDetail(params)),
  updateUserStatus: (params) => dispatch(updateUserStatus(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(NailistDetailPage);
