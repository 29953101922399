import produce from 'immer';

import { GET_ACCOUNT_NO_BANK, GET_ACCOUNT_NO_BANK_SUCCESS } from './constants';

const initialState = {
  dataLoading: false,
  accountNoBank: {}
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_NO_BANK:
      state.dataLoading = true;
      state.accountNoBank = {};
      return state;

    case GET_ACCOUNT_NO_BANK_SUCCESS:
      state.dataLoading = false;
      state.accountNoBank = action.payload.data;
      return state;

    default:
      return state;
  }
});

export default reducer;
