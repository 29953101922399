import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  getGoToNailInfo3rd,
  getGoToNailList3rd,
  exportGoToNailList3rd,
  updateGoToNail3rd
} from 'providers/FriendInvitationProvider/actions';
import {
  makeFriendInvitationLoading,
  makeFriendInvitationActionLoading,
  makeGoToNailInfo3rd,
  makeGoToNailList3rd,
  makeGoToNailTotal3rd
} from 'providers/FriendInvitationProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import GoToNail from './GoToNailList';

const mapStateToProps = (state) => ({
  goToNailInfo: makeGoToNailInfo3rd(state),
  goToNailList: makeGoToNailList3rd(state),
  goToNailTotal: makeGoToNailTotal3rd(state),
  goToNailDataLoading: makeFriendInvitationLoading(state),
  goToNailActionLoading: makeFriendInvitationActionLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  getGoToNailInfo: (params) => dispatch(getGoToNailInfo3rd(params)),
  getGoToNailList: (params) => dispatch(getGoToNailList3rd(params)),
  exportGoToNailList: (params) => dispatch(exportGoToNailList3rd(params)),
  updateGoToNail: (params) => dispatch(updateGoToNail3rd(params)),
  replace: (path) => dispatch(replace(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoToNail);
