import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

import CategoryItem from './CategoryItem';

const CategoryList = ({ nailistId, categories, openManageMenuPopup, hideMenuButton }) => {
  return (
    <>
      {categories.length > 0
        ? categories.map((category) =>
          <CategoryItem
            key={category.id}
            category={category}
            nailistId={nailistId}
            openManageMenuPopup={openManageMenuPopup}
            hideMenuButton={hideMenuButton}
          />)
        : <Empty />}
    </>
  );
};

CategoryList.propTypes = {
  nailistId: PropTypes.string,
  categories: PropTypes.array,
  openManageMenuPopup: PropTypes.func,
  hideMenuButton: PropTypes.bool
};

export default CategoryList;
