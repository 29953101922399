import _ from 'lodash';

export const SLOT_SELECTIONS = [
  '00:00', '00:30',
  '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30',
  '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
  '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30',
  '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30',
  '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'
];

// Business hours: timeNumber 830 => 08:30
export function formatWorkingTime(timeNumber) {
  if (!timeNumber && timeNumber != 0) {
    return undefined;
  }

  let result = ('0000' + timeNumber).slice(-4);

  result = result && (result.slice(0, 2) + ':' + result.slice(2));
  return result;
}

// Business hours: timeSlotString `08:30` => 830
export function deformatWorkingTime(timeSlotString) {
  if (!timeSlotString) {
    return undefined;
  }

  return Number(timeSlotString.replace(':', ''));
}

/**
 * generate form data value base on server data
 * server data input <weekdays>:
 * {
 *  "monday": {
 *    "openTime": 1000,
 *    "closeTime": 2000,
 *    "isOff": false
 *  },
 *  ...,
 *  "sunday": {
 *    "openTime": 1000,
 *    "closeTime": 2000,
 *    "isOff": false
 *  }
 * }
 * @param {weekdays} data 
 * @returns 
 */
export function initWeekDaySettingValue(data) {
  return {
    monStart: formatWorkingTime(data?.monday?.openTime) || '10:00', monEnd: formatWorkingTime(data?.monday?.closeTime) || '20:00', monIsOpen: !data?.monday?.isOff,
    tueStart: formatWorkingTime(data?.tuesday?.openTime) || '10:00', tueEnd: formatWorkingTime(data?.tuesday?.closeTime) || '20:00', tueIsOpen: !data?.tuesday?.isOff,
    wedStart: formatWorkingTime(data?.wednesday?.openTime) || '10:00', wedEnd: formatWorkingTime(data?.wednesday?.closeTime) || '20:00', wedIsOpen: !data?.wednesday?.isOff,
    thuStart: formatWorkingTime(data?.thursday?.openTime) || '10:00', thuEnd: formatWorkingTime(data?.thursday?.closeTime) || '20:00', thuIsOpen: !data?.thursday?.isOff,
    friStart: formatWorkingTime(data?.friday?.openTime) || '10:00', friEnd: formatWorkingTime(data?.friday?.closeTime) || '20:00', friIsOpen: !data?.friday?.isOff,
    satStart: formatWorkingTime(data?.saturday?.openTime) || '10:00', satEnd: formatWorkingTime(data?.saturday?.closeTime) || '20:00', satIsOpen: !data?.saturday?.isOff,
    sunStart: formatWorkingTime(data?.sunday?.openTime) || '10:00', sunEnd: formatWorkingTime(data?.sunday?.closeTime) || '20:00', sunIsOpen: !data?.sunday?.isOff
  };
}


export function formatScheduleFromDateToTimeSlot(schedule, weekdays) {
  const min = _.chain(weekdays).map(_.property('openTime')).min().value();
  const max = _.chain(weekdays).map(_.property('closeTime')).max().value();
  
  let result = [];
  let writeBulk = [];

  let bookingPool = []; // prevent render duplicate booking

  SLOT_SELECTIONS.forEach(slotString => {
    const slotNum = deformatWorkingTime(slotString);

    const row = {
      slotNum: slotNum,
      onDate: _.reduce(schedule, function(onDate, dateOfSchedule) {
        let slotVal = dateOfSchedule?.slots[slotNum];
        if (_.isObject(slotVal)) {
          if (bookingPool.includes(slotVal.bookingId)) {
            slotVal = slotVal.bookingId;
          } else {
            bookingPool.push(slotVal.bookingId);
          }
        }

        if (!_.isUndefined(slotVal)) {
          onDate[dateOfSchedule.date] = slotVal;
        }
        return onDate;
      }, {})
    };

    writeBulk.push(row);
    if (!_.isEmpty(row.onDate)) {
      result.push(...writeBulk);
      writeBulk = [];
    }

    if (_.isEmpty(row.onDate) && row.slotNum < min && _.isEmpty(result)) {
      writeBulk = [];
    }
  });

  return result;
}