import { loginSuccess, loginFail, meSuccess } from './actions';
import { ME, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from './constants';
import { put, takeLeading, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import request from 'utils/request';
import qs from 'query-string';

function* getSelfInfo() {
  try {
    const { data } = yield call(request, 'users/me', {}, 'GET');
    yield put(meSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* login(action) {
  try {
    const { username, password } = action.payload;
    const data = yield call(request, 'users/login', { username, password });
    if (!data.token) {
      return;
    }

    localStorage.setItem('__a_token', data.token);
    yield put(loginSuccess(data.token));
  } catch (e) {
    yield put(loginFail('Please check your account and login again later'));
  }
}

function* loginFailHandler(action) {
  let search = '';
  if (!window.location.pathname.startsWith('/login')) {
    search = `?href=${encodeURIComponent(
      window.location.pathname + window.location.search
    )}`;
  }
  localStorage.removeItem('__a_token');
  yield put(replace('/login' + search));
  yield put(meSuccess({}));
}

function* loginSuccessHandler() {
  const search = window.location.search || '{}';
  const { href } = qs.parse(search);
  if (href) {
    yield put(replace(decodeURIComponent(href)));
  } else {
    yield put(replace('/dashboard'));
  }
}

export default function* watchNailist() {
  yield takeLeading(ME, getSelfInfo);
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGIN_FAIL, loginFailHandler);
  yield takeLeading(LOGIN_SUCCESS, loginSuccessHandler);
}
