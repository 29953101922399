import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import indexRoutes from './indexRoutes';

class PublicRoutes extends React.PureComponent {
  render() {
    const routes = [];
    {
      indexRoutes.map((route) => {
        if (!route.requireLogin) {
          routes.push(
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          );
        }
      });
    }

    return (
      <React.Fragment>
        <Switch>{routes}</Switch>
      </React.Fragment>
    );
  }
}

PublicRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.string.isRequired
};

export default PublicRoutes;
