export const FETCH_ADMIN_OPERATOR_LIST = 'FETCH_ADMIN_OPERATOR_LIST';
export const FETCH_ADMIN_OPERATOR_LIST_SUCCESS = 'FETCH_ADMIN_OPERATOR_LIST_SUCCESS';

export const CREATE_ADMIN_OPERATOR = 'CREATE_ADMIN_OPERATOR';
export const CREATE_ADMIN_OPERATOR_SUCCESS = 'CREATE_ADMIN_OPERATOR_SUCCESS';

export const UPDATE_ADMIN_OPERATOR = 'UPDATE_ADMIN_OPERATOR';
export const UPDATE_ADMIN_OPERATOR_SUCCESS = 'UPDATE_ADMIN_OPERATOR_SUCCESS';

export const DELETE_ADMIN_OPERATOR = 'DELETE_ADMIN_OPERATOR';
export const DELETE_ADMIN_OPERATOR_SUCCESS = 'DELETE_ADMIN_OPERATOR_SUCCESS';