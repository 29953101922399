import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const SlotBooking = ({ bookingId, userId, avatar, numberSlot }) => {

  return (
    <Link to={`/dashboard/booking-management/list/${bookingId}`} target="_blank">
      <Tag
        className="slot-booking"
        style={{width: 91, height: numberSlot * 55 - 1}}
        color="magenta"
      >
        <Link to={`/dashboard/customer/profile/${userId}`} target="_blank">
          <Avatar src={avatar?.thumbNail?.url} size="small" icon={<UserOutlined />} />
        </Link>
      </Tag>
    </Link>
  );
};

SlotBooking.propTypes = {
  bookingId: PropTypes.string.isRequired,
  numberSlot: PropTypes.number,
  userId: PropTypes.string.isRequired,
  avatar: PropTypes.any
};

export default SlotBooking;
