// import _ from 'lodash';
import moment from 'moment';
import _get from 'lodash/get';

import PAYMENT_PROVIDER from 'utils/constants/PAYMENT_PROVIDER';

const columnsTemplate = {
  id: {
    title: 'Receipt ID',
    width: 200,
    dataIndex: '_id',
    key: '_id'
  },
  bookingId: {
    title: 'Booking ID',
    width: 200,
    dataIndex: 'emailParams',
    render: (record) => _get(record, 'bookingId'),
    key: 'bookingId'
  },
  nailist: {
    title: 'Nailist',
    width: 'auto',
    dataIndex: 'emailParams',
    render: (record) => _get(record, 'nailistUsername'),
    key: 'nailist'
  },
  customer: {
    title: 'Customer',
    width: 'auto',
    dataIndex: 'emailParams',
    render: (record) => _get(record, 'customerFullName'),
    key: 'customer'
  },
  price: {
    title: 'Price',
    width: 'auto',
    dataIndex: 'emailParams',
    render: (record) => `¥${_get(record, 'bookingPrice', '0')}`,
    key: 'bookingPrice'
  },
  sentAt: {
    title: 'Sending Time',
    width: 'auto',
    dataIndex: '_created_at',
    render: (record) =>
      /*_.has(record) && */ moment(record).format('HH:mm YYYY-MM-DD'),
    key: '_created_at'
    // sorter: true,
    // sortOrder:
    //   _.get(search, 'orderBy') === '_created_at' && _.get(search, 'order')
  },
  cardProvider: {
    title: 'Payment',
    width: 'auto',
    dataIndex: 'emailParams',
    render: (record) => PAYMENT_PROVIDER[_get(record, 'paymentCardProvider')],
    key: 'cardProvider'
  },
  action: {
    width: 100,
    fixed: 'right',
    key: 'viewAction'
  },
};

export default columnsTemplate;