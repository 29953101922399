import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';

import { SetPopup } from 'providers/PopupProvider/actions';
import { fetchPayoutsList } from 'providers/PayoutProvider/actions';

import {
  makeSearchPayoutsList,
  makeSearchPayoutTotal,
  makePayoutLoading
} from 'providers/PayoutProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import StaffPayout from './StaffPayout';

const mapStateToProps = (state) => ({
  payoutsList: makeSearchPayoutsList(state),
  total: makeSearchPayoutTotal(state),
  loading: makePayoutLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayoutsList: (params) => dispatch(fetchPayoutsList({ payoutType: 'STAFF', ...params })),
  push: (path) => dispatch(push(path)),
  replace: (path) => dispatch(replace(path)),
  openPayoutPopup: (show) => dispatch(SetPopup({ name: 'payout', show: { payoutType: 'STAFF', ...show } }))
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffPayout);
