import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Rate, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { editBookingReview } from 'providers/BookingProvider/actions';

const { Text } = Typography;

const EditBookingReviewRatingForm = ({ initialValues, submit }) => {
  const [form] = Form.useForm();

  return <Form
    form={form}
    initialValues={initialValues}
    onFinish={submit}
    labelCol={{ span: 10 }}
    wrapperCol={{ span: 14 }}
    id="EditBookingReviewRatingForm"
    colon={false}
  >
    <Form.Item label={<Text strong>技術</Text>} name='skillsRating'><Rate /></Form.Item>
    <Form.Item label={<Text strong>接客</Text>} name='serviceRating'><Rate /></Form.Item>
    <Form.Item label={<Text strong>価格</Text>} name='priceRating'><Rate /></Form.Item>
  </Form>;
};

EditBookingReviewRatingForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func
};

const useEditBookingReviewRating = (data = {}, bookingId) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const submit = async(values) => {
    await dispatch(editBookingReview({ bookingId, ...values }));
    setIsOpen(false);
  };

  const Popup = <Modal
    title='Edit rating'
    visible={isOpen}
    destroyOnClose
    onCancel={() => setIsOpen(false)}
    okText="Save"
    okButtonProps={{ form: 'EditBookingReviewRatingForm', htmlType: 'submit' }}
  >
    {!_isEmpty(data) && <EditBookingReviewRatingForm initialValues={data} submit={submit} />}
  </Modal>;

  const openPopup = () => setIsOpen(true);

  return [openPopup, Popup];
};

export default useEditBookingReviewRating;