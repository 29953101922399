// Checked
import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import NavBar from './Nav';
import SideMenu from './SideMenu';

import './styles.scss';

const DashboardLayout = ({ children }) => {
  return (
    <Layout>
      <Layout>
        <SideMenu />
      </Layout>

      <Layout style={{ marginLeft: '240px' }}>
        <NavBar />
        {children}
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
