import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ME,
  ME_SUCCESS
} from './constants';

export function login(payload = {}) {
  return {type: LOGIN, payload};
}

export function me(payload = {}) {
  return {type: ME, payload};
}

export function loginSuccess(payload = {}) {
  return {type: LOGIN_SUCCESS, payload};
}
export function loginFail(payload = {}) {
  return {type: LOGIN_FAIL, payload};
}
export function logout() {
  return {type: LOGIN_FAIL, payload: ''};
}

export function meSuccess(payload = {}) {
  return {type: ME_SUCCESS, payload};
}
