import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import Content from './Content';

const PayoutPopup = (props) => {
  return (
    <Modal
      title="銀行情報のない口座"
      style={{ top: 36 }}
      width={768}
      visible={!!props.data}
      destroyOnClose={true}
      onCancel={props.closePopup}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Content {...props} />
    </Modal>
  );
};

PayoutPopup.propTypes = {
  data: PropTypes.any,
  closePopup: PropTypes.func
};

export default PayoutPopup;
