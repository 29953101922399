import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { TextField } from 'components/Form';

const FilterForm = ({ handleSubmit }) => {
  return (
    <Form layout="inline" onSubmit={handleSubmit}>
      <Field
        name="keyword"
        placeholder="NailistID, username, fullname, email, phone number"
        component={TextField}
        allowClear
        className="search-input"
        suffix={<SearchOutlined />}
      />

      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

FilterForm.propTypes = {
  handleSubmit: PropTypes.any
};

export default reduxForm({
  form: 'report-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);
