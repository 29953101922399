// Checked
import {
  GET_BOOKING_FEE_SETTING,
  GET_BOOKING_FEE_SETTING_SUCCESS,
  SETTING_SALON_PROPERTIES,
  SETTING_SALON_PROPERTIES_FAIL,
  SETTING_SALON_PROPERTIES_SUCCESS,
  SET_BOOKING_FEE_SETTING,
  SET_BOOKING_FEE_SETTING_SUCCESS
} from './constants';

export function getBookingFeeSetting(payload = {}) {
  return { type: GET_BOOKING_FEE_SETTING, payload };
}

export function getBookingFeeSettingSuccess(payload = {}) {
  return { type: GET_BOOKING_FEE_SETTING_SUCCESS, payload };
}

export function setBookingFeeSetting(payload = {}) {
  return {
    type: SET_BOOKING_FEE_SETTING,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function setBookingFeeSettingSuccess(payload = {}, meta) {
  return { type: SET_BOOKING_FEE_SETTING_SUCCESS, payload, meta };
}
export function getSettingSalonProperties(payload = {}) {
  return { type: SETTING_SALON_PROPERTIES, payload, meta: { thunk: true } };
}
export function getSettingSalonPropertiesSuccess(payload = {}, meta) {
  return { type: SETTING_SALON_PROPERTIES_SUCCESS, payload, meta };
}
export function getSettingSalonPropertiesFail(payload = {}, meta) {
  return { type: SETTING_SALON_PROPERTIES_FAIL, payload, meta, error: true };
}
