import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router-dom';

import indexRoutes from './indexRoutes';
import DashboardLayout from 'components/DashboardLayout';
import AccountNoBankPopup from 'components/Popup/AccountNoBankPopup';

class PrivateRoute extends React.PureComponent {
  render() {
    const routes = [];
    indexRoutes.map((route) => {
      if (route.requireLogin) {
        routes.push(
          <Route
            path={'/dashboard' + route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <route.component {...props} />
              </>
            )}
            key={route.path}
          />
        );
      }
    });

    return (
      <>
        <DashboardLayout>
          <AccountNoBankPopup />
          <Switch>{routes}</Switch>
        </DashboardLayout>
      </>
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.string
};

export default withRouter(PrivateRoute);
