import React, { useEffect, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Table, Card } from 'antd';

import { getReportList, getReportListByUser } from 'providers/SupportProvider/actions';
import PageHeader from 'components/PageHeader';
import columns, { defaultOrder, defaultOrderDetailList } from './Preset/columns';
import FilterForm from './Preset/FilterForm';
import useTableControl from './hooks/useTableControl';

const ReportPage = () => {
  const routeMatch = useRouteMatch();
  const { id } = routeMatch.params;
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl(id ? defaultOrderDetailList : defaultOrder);
  const dispatch = useDispatch();
  const { list, total, loading } = useSelector(state => state.support.report);
  const title = id ? 'Report detail' : 'Report management';

  useEffect(() => {
    const params = {
      ...search,
      order: search.order ? search.order.slice(0, search.order.length - 3) : undefined // descend => desc, ascend => asc
    };

    if (id) {
      dispatch(getReportListByUser({ id, ...params }));
    } else {
      dispatch(getReportList(params));
    }
  }, [id, search]);

  const reportColumns = [
    columns.userId,
    { ...columns.reporter, title: '通報された人' },
    { ...columns.count, defaultSortOrder: columns.count.key === search.orderBy ? search.order : undefined },
    { ...columns.latestDate, defaultSortOrder: columns.latestDate.key === search.orderBy ? search.order : undefined },
    columns.view
  ];

  const reportByUserColumns = [
    { ...columns.reporter, title: '通報した人' },
    columns.reason,
    columns.type,
    columns.reportDate,
    columns.reportImage
  ];

  const handleFilterFormChange = ({ keyword = '' }) => {
    const values = { ...search, keyword: keyword.trim() };
    if (!values.keyword) {
      delete values.keyword;
    }
    handleSubmitFilterForm(values);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | {title}</title>
      </Helmet>
      <PageHeader
        title={title}
        breadcrumb={id ? PageHeader.Bread.reportDetail(id) : PageHeader.Bread.report}
        goBack={id && true}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <Card bordered={false}>
          {!id && <div style={{ marginBottom: 16 }}>
            <FilterForm initialValues={search} onSubmit={handleFilterFormChange} />
          </div>}
          <Table
            columns={id ? reportByUserColumns : reportColumns}
            dataSource={list}
            onChange={handleTableChange}
            pagination={{
              total,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(search.page) || 1,
              showSizeChanger: false
            }}
            rowKey={(record) => record._id}
            loading={loading}
            scroll={{ x: true }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default ReportPage;
