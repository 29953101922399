import moment from 'moment';
import { put, takeEvery, call, takeLeading } from 'redux-saga/effects';
import { mappingS3Domain } from 'utils/mappingS3Domain';

import request from 'utils/request';
import {
  getBookingFeeSettingSuccess,
  getBookingFeeSetting,
  setBookingFeeSettingSuccess,
  getSettingSalonPropertiesSuccess,
  getSettingSalonPropertiesFail
} from './actions';
import { GET_BOOKING_FEE_SETTING, SETTING_SALON_PROPERTIES, SET_BOOKING_FEE_SETTING } from './constants';

function* handleGetBKSetting(action) {
  try {
    const filter = action.payload;
    const { data } = yield call(request, 'setting/booking_fee', filter, 'GET');
    yield put(getBookingFeeSettingSuccess({ data }));
  } catch (e) {
    console.log(
      '🚀 ~ file: saga.js ~ line 13 ~ function*handleGetBKSetting ~ e',
      e
    );
  }
}

function* setBKSetting(action) {
  try {
    const payload = action.payload;
    console.log(payload);
    const { data } = yield call(
      request,
      'setting/booking_fee',
      {
        ...payload,
        bookingFreeSettingId: payload.id,
        applyFrom: moment(payload.applyFrom)
      },
      'PUT'
    );
    yield put(setBookingFeeSettingSuccess({ data }, action.meta));
    yield put(getBookingFeeSetting({}));
  } catch (e) {
    console.log(
      '🚀 ~ file: saga.js ~ line 13 ~ function*handleGetBKSetting ~ e',
      e
    );
  }
}
function* getSettingSalonProperties(action) {
  try {
    const { token } = action.payload;
    const result = yield call(request, 'setting/salon-properties', {}, 'GET', token);
    // mapping s3 domain
    result.data = result.data.map(p => {
      return {
        ...p,
        icon: mappingS3Domain(p.icons?.normal)
      };
    });

    yield put(getSettingSalonPropertiesSuccess(result, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getSettingSalonPropertiesFail(error, action.meta));
  }
}

export default function* watchSetting() {
  yield takeEvery(GET_BOOKING_FEE_SETTING, handleGetBKSetting);
  yield takeLeading(SET_BOOKING_FEE_SETTING, setBKSetting);
  yield takeEvery(SETTING_SALON_PROPERTIES, getSettingSalonProperties);
}
