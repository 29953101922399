import React from 'react';
import PropTypes from 'prop-types';
import _forOwn from 'lodash/forOwn';
import { useSelector } from 'react-redux';
import { Table, Card, Button } from 'antd';
import { formatWorkTime } from 'utils/stringFormat';
import useEditWorkingTimePopupController from './useEditWorkingTimePopupController';
import NailistSlotSchedule from './NailistSlotSchedule/NailistSlotSchedule';

const renderCell = (item) => {
  return <span key={item.key}>
    {
      item.notSetYet ?
        'Not Set Yet' :
        formatWorkTime(item.openTime, item.closeTime, item.isOff)
    }
  </span>;
};

const workingTimesColumns = [
  {
    title: '月曜日',
    dataIndex: 'monday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: '火曜日',
    dataIndex: 'tuesday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: '水曜日',
    dataIndex: 'wednesday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: '木曜日',
    dataIndex: 'thursday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: '金曜日',
    dataIndex: 'friday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: <span style={{ color: 'blue', fontWeight: 'bold' }}>{'土曜日'}</span>,
    dataIndex: 'saturday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  },
  {
    title: <span style={{ color: 'red', fontWeight: 'bold' }}>{'日曜日'}</span>,
    dataIndex: 'sunday',
    width: '10%',
    fixed: 'left',
    render: renderCell
  }
];

const WorkingTime = ({ data, nailistId, disableEdit = false, hasSchedule, minCanBookingHour }) => {
  const [openPopup, Popup] = useEditWorkingTimePopupController(data, nailistId, !hasSchedule);
  const key = useSelector(state => state?.nailist?.schedule?.key);

  if (!hasSchedule) {
    _forOwn(data, d => d.notSetYet = true);
  }
  
  return (
    <>
      <Card
        className="nailist-schedule-tab"
        title="営業時間"
        extra={<Button type="primary" onClick={openPopup} disabled={disableEdit}>Edit</Button>}
      >
        { !data?.monday?.closeTime ? null :
          <Table
            columns={workingTimesColumns}
            dataSource={[
              data
            ]}
            pagination={false}
            // bordered
          />
        }
        {Popup}
      </Card>

      <Card
        className="nailist-schedule-tab"
        title="Schedule"
      >
        <NailistSlotSchedule nailistId={nailistId} minCanBookingHour={minCanBookingHour} key={key}/>
      </Card>
    </>
  );
};

WorkingTime.propTypes = {
  data: PropTypes.object.isRequired,
  nailistId: PropTypes.string,
  disableEdit: PropTypes.bool,
  hasSchedule: PropTypes.any
};

export default WorkingTime;
