import {
  FETCH_NAILIST_LIST,
  FETCH_NAILIST_LIST_SUCCESS,
  FETCH_NEWEST_NAILIST_LIST_SUCCESS,
  SET_LOADING_SEARCH_NAILIST,
  FETCH_NAILIST_PROFILE,
  FETCH_NAILIST_PROFILE_SUCCESS,
  UNSET_NALIST_PROFILE,
  FETCH_NAILIST_BANK_INFO,
  FETCH_NAILIST_BANK_INFO_SUCCESS,
  FETCH_NAILIST_MENU,
  FETCH_NAILIST_MENU_SUCCESS,
  FETCH_NAILIST_CATEGORY_LIST,
  FETCH_NAILIST_CATEGORY_LIST_SUCCESS,
  CREATE_NAILIST_MENU,
  CREATE_NAILIST_MENU_SUCCESS,
  EDIT_NAILIST_MENU,
  EDIT_NAILIST_MENU_SUCCESS,
  DELETE_NAILIST_MENU,
  DELETE_NAILIST_MENU_SUCCESS,
  CHANGE_NAILIST_MENU_STATUS,
  CHANGE_NAILIST_MENU_STATUS_SUCCESS,
  CHANGE_NAILIST_MENU_POSITION,
  CHANGE_NAILIST_MENU_POSITION_SUCCESS,
  FETCH_NAILIST_COUNTS,
  FETCH_NAILIST_COUNTS_SUCCESS,
  FETCH_NAILIST_CURRENT_BALANCE,
  FETCH_NAILIST_CURRENT_BALANCE_SUCCESS,
  FETCH_BRIEF_NAILIST_PROFILE_SUCCESS,
  FETCH_NAILIST_JOIN_SALON,
  FETCH_NAILIST_JOIN_SALON_SUCCESS,
  SET_LOADING_NAILIST_JOIN_SALON,
  FETCH_NAILIST_INSIGHT,
  FETCH_NAILIST_INSIGHT_SUCCESS,
  RECALCULATE_INCOME,
  RECALCULATE_INCOME_SUCCESS,
  UPDATE_NAILIST_WORKING_TIME,
  UPDATE_NAILIST_WORKING_TIME_SUCCESS,
  UPDATE_NAILIST_WORKING_TIME_FAIL,
  UPDATE_NAILIST_INFO,
  UPDATE_NAILIST_INFO_SUCCESS,
  UPDATE_NAILIST_INFO_FAIL,
  UPDATE_NAILIST_PROFILE_SALON,
  UPDATE_NAILIST_PROFILE_SALON_SUCCESS,
  UPDATE_NAILIST_PROFILE_SALON_FAIL,
  FETCH_NAILIST_SLOT_SCHEDULE,
  FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS,
  VERIFY_NAILIST_EMAIL,
  VERIFY_NAILIST_EMAIL_SUCCESS,
  FETCH_NAILIST_SLOT_SCHEDULE_FAIL,
  FETCH_NAILIST_POSTS,
  FETCH_NAILIST_POSTS_SUCCESS,
  FETCH_NAILIST_POST_DETAIL,
  FETCH_NAILIST_POST_DETAIL_SUCCESS,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  RESET_POSTS_PROVIDER,
  DELETE_POST_FAIL,
  FETCH_NAILIST_POST_DETAIL_FAIL,
  FORCE_DELETE_POST,
  FETCH_NAILIST_DISABLED_LIST,
  FETCH_NAILIST_DISABLED_LIST_SUCCESS,
  ACTION_NAILIST_REJOIN_SUCCESS,
  ACTION_NAILIST_REJOIN_FAIL,
  ACTION_NAILIST_REJOIN
} from './constants';

export function fetchNailistList(payload = {}) {
  return { type: FETCH_NAILIST_LIST, payload, meta: { thunk: true } };
}

export function fetchNailistListSuccess(payload = {}, meta) {
  return { type: FETCH_NAILIST_LIST_SUCCESS, payload, meta };
}

export function fetchNewestNailistListSuccess(payload = {}, meta) {
  return { type: FETCH_NEWEST_NAILIST_LIST_SUCCESS, payload, meta };
}

export function setSearchNailistLoading(payload = {}) {
  return { type: SET_LOADING_SEARCH_NAILIST, payload };
}

export function fetchNailistProfile(payload = {}) {
  return { type: FETCH_NAILIST_PROFILE, payload };
}

export function fetchNailistProfileSuccess(payload = {}) {
  return { type: FETCH_NAILIST_PROFILE_SUCCESS, payload };
}

export function fetchNailistBankInfo(payload = {}) {
  return { type: FETCH_NAILIST_BANK_INFO, payload };
}

export function fetchNailistBankInfoSuccess(payload = {}) {
  return { type: FETCH_NAILIST_BANK_INFO_SUCCESS, payload };
}

export function fetchNailistCategoryList(payload = {}) {
  return { type: FETCH_NAILIST_CATEGORY_LIST, payload };
}

export function fetchNailistCategoryListSuccess(payload = {}) {
  return { type: FETCH_NAILIST_CATEGORY_LIST_SUCCESS, payload };
}

export function fetchNailistMenu(payload = {}) {
  return { type: FETCH_NAILIST_MENU, payload };
}

export function fetchNailistMenuSuccess(payload = {}) {
  return { type: FETCH_NAILIST_MENU_SUCCESS, payload };
}

export function createNailistMenu(payload = {}) {
  return { type: CREATE_NAILIST_MENU, payload };
}

export function createNailistMenuSuccess(payload = {}) {
  return { type: CREATE_NAILIST_MENU_SUCCESS, payload };
}

export function editNailistMenu(payload = {}) {
  return { type: EDIT_NAILIST_MENU, payload };
}

export function editNailistMenuSuccess(payload = {}) {
  return { type: EDIT_NAILIST_MENU_SUCCESS, payload };
}

export function deleteNailistMenu(payload = {}) {
  return { type: DELETE_NAILIST_MENU, payload };
}

export function deleteNailistMenuSuccess(payload = {}) {
  return { type: DELETE_NAILIST_MENU_SUCCESS, payload };
}

export function changeNailistMenuStatus(payload = {}) {
  return { type: CHANGE_NAILIST_MENU_STATUS, payload };
}

export function changeNailistMenuStatusSuccess(payload = {}) {
  return { type: CHANGE_NAILIST_MENU_STATUS_SUCCESS, payload };
}

export function changeNailistMenuPosition(payload = {}) {
  return { type: CHANGE_NAILIST_MENU_POSITION, payload };
}

export function changeNailistMenuPositionSuccess(payload = {}) {
  return { type: CHANGE_NAILIST_MENU_POSITION_SUCCESS, payload };
}

export function unsetNalistProfile(payload = {}) {
  return { type: UNSET_NALIST_PROFILE, payload };
}

export function fetchNailistCounts(payload = {}) {
  return { type: FETCH_NAILIST_COUNTS, payload };
}

export function fetchNailistCountsSuccess(payload = {}) {
  return { type: FETCH_NAILIST_COUNTS_SUCCESS, payload };
}

export function fetchNailistCurrentBalance(payload = {}) {
  return { type: FETCH_NAILIST_CURRENT_BALANCE, payload };
}

export function fetchNailistCurrentBalanceSuccess(payload = {}) {
  return { type: FETCH_NAILIST_CURRENT_BALANCE_SUCCESS, payload };
}

export function recalculateIncome(payload = {}) {
  return { type: RECALCULATE_INCOME, payload, meta: { thunk: true } };
}

export function recalculateIncomeSuccess(payload = {}, meta) {
  return { type: RECALCULATE_INCOME_SUCCESS, payload, meta };
}

export function fetchBriefNailistProfileSuccess(payload = {}) {
  return { type: FETCH_BRIEF_NAILIST_PROFILE_SUCCESS, payload };
}

export function fetchNailistJoinSalon(payload = {}) {
  return { type: FETCH_NAILIST_JOIN_SALON, payload };
}

export function fetchNailistJoinSalonSuccess(payload = {}) {
  return { type: FETCH_NAILIST_JOIN_SALON_SUCCESS, payload };
}

export function setNailistJoinSalonLoading(payload = {}) {
  return { type: SET_LOADING_NAILIST_JOIN_SALON, payload };
}

export function fetchNailistInsight(payload = {}) {
  return { type: FETCH_NAILIST_INSIGHT, payload };
}

export function fetchNailistInsightSuccess(payload = {}) {
  return { type: FETCH_NAILIST_INSIGHT_SUCCESS, payload };
}
//Post list
export function fetchNailistPosts(payload = {}) {
  return { type: FETCH_NAILIST_POSTS, payload, meta: { thunk: true } };
}

export function fetchNailistPostsSuccess(payload = {}, meta) {
  return { type: FETCH_NAILIST_POSTS_SUCCESS, payload, meta };
}
// Post detail
export function fetchNailistPostDetail(payload = {}) {
  return { type: FETCH_NAILIST_POST_DETAIL, payload, meta: { thunk: true } };
}
export function fetchNailistPostDetailSuccess(payload = {}, meta) {
  return { type: FETCH_NAILIST_POST_DETAIL_SUCCESS, payload, meta };
}
export function fetchNailistPostDetailFail(payload = {}, meta) {
  return { type: FETCH_NAILIST_POST_DETAIL_FAIL, payload, meta };
}
// DELETE POSTS
export function forceDeletePost(payload = {}) {
  return { type: FORCE_DELETE_POST, payload, meta: { thunk: true } };
}
export function deletePost(payload = {}) {
  return { type: DELETE_POST, payload, meta: { thunk: true } };
}
export function deletePostSuccess(payload = {}, meta) {
  return { type: DELETE_POST_SUCCESS, payload, meta };
}
export function deletePostFail(payload = {}, meta) {
  return { type: DELETE_POST_FAIL, payload, meta };
}

export function updateNailistWorkingTime(payload = {}) {
  return { type: UPDATE_NAILIST_WORKING_TIME, payload, meta: { thunk: true } };
}
export function updateNailistWorkingTimeSuccess(payload = {}, meta) {
  return { type: UPDATE_NAILIST_WORKING_TIME_SUCCESS, payload, meta };
}
export function updateNailistWorkingTimeFail(payload = {}, meta) {
  return { type: UPDATE_NAILIST_WORKING_TIME_FAIL, payload, meta, error: true };
}


export function updateNailistInfo(payload = {}) {
  return { type: UPDATE_NAILIST_INFO, payload, meta: { thunk: true } };
}
export function updateNailistInfoSuccess(payload = {}, meta) {
  return { type: UPDATE_NAILIST_INFO_SUCCESS, payload, meta };
}
export function updateNailistInfoFail(payload = {}, meta) {
  return { type: UPDATE_NAILIST_INFO_FAIL, payload, meta, error: true };
}

export function updateNailistProfileSalon(payload = {}) {
  return { type: UPDATE_NAILIST_PROFILE_SALON, payload, meta: { thunk: true } };
}
export function updateNailistProfileSalonSuccess(payload = {}, meta) {
  return { type: UPDATE_NAILIST_PROFILE_SALON_SUCCESS, payload, meta };
}
export function updateNailistProfileSalonFail(payload = {}, meta) {
  return { type: UPDATE_NAILIST_PROFILE_SALON_FAIL, payload, meta, error: true };
}

export function fetchNailistSlotSChedule(payload = {}) {
  return { type: FETCH_NAILIST_SLOT_SCHEDULE, payload, meta: { thunk: true } };
}
export function fetchNailistSlotSCheduleSuccess(payload = {}, meta) {
  return { type: FETCH_NAILIST_SLOT_SCHEDULE_SUCCESS, payload, meta };
}
export function fetchNailistSlotSCheduleFail(payload = {}, meta) {
  return { type: FETCH_NAILIST_SLOT_SCHEDULE_FAIL, payload, meta, error: true };
}

export function verifyNailistEmail(payload = {}) {
  return { type: VERIFY_NAILIST_EMAIL, payload };
}
export function verifyNailistEmailSuccess(payload = {}) {
  return { type: VERIFY_NAILIST_EMAIL_SUCCESS, payload };
}

export function resetPostsProvider(payload = {}) {
  return { type: RESET_POSTS_PROVIDER, payload };
}


export function fetchNailistDisabledList(payload = {}) {
  return { type: FETCH_NAILIST_DISABLED_LIST, payload };
}

export function fetchNailistDisabledListSuccess(payload = {}) {
  return { type: FETCH_NAILIST_DISABLED_LIST_SUCCESS, payload };
}

export function actionNailistRejoin(payload = {}) {
  return {
    type: ACTION_NAILIST_REJOIN, payload, meta: {
      thunk: true
    }
  };
}

export function actionNailistRejoinSuccess(payload = {}, meta) {
  return { type: ACTION_NAILIST_REJOIN_SUCCESS, payload, meta };
}

export function actionNailistRejoinFail(payload = {}, meta) {
  return { type: ACTION_NAILIST_REJOIN_FAIL, payload, meta };
}