import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Content from './Content';

const UserRecentReservationPopup = (props) => {
  return (
    <Modal
      style={{ top: 36 }}
      width={1280}
      visible={!!props.visible}
      destroyOnClose={true}
      className="recent-reservation-popup"
      onCancel={props.closePopup}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
    >
      <Content {...props} />
    </Modal>
  );
};

UserRecentReservationPopup.propTypes = {
  visible: PropTypes.any,
  profile: PropTypes.any,
  closePopup: PropTypes.func,
  popupTitle: PropTypes.any
};

export default UserRecentReservationPopup;
