import _ from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import './styles.scss';

import BigPageHeader from 'components/PageHeader';
import Profile from './Profile';
import Nailist from './NailistTab';
import SalonUpdateProfileForm from './SalonUpdateProfileForm';
import BigSpin from 'components/BigSpin';

class SalonDetailPage extends React.PureComponent {
  componentDidMount() {
    this.props.fetchSalonProfile({
      id: _.get(this.props, 'match.params.id')
    });
  }
  componentWillUnmount() {
    this.props.unsetSalonProfile();
  }

  render() {
    const { TabPane } = Tabs;
    const { info = {} } = this.props.profile;

    const pageTitle = `Nailie Dashboard | Salon ${info.businessName && `| ${info.businessName}`}`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <BigPageHeader
          goBack={true}
          title={_.get(info, 'businessName', '...')}
          breadcrumb={BigPageHeader.Bread.salonProfile(info)}
        >

        </BigPageHeader>
        <div className="page-container" id="salon-detail-page">
          <Tabs defaultActiveKey="1">
            <TabPane tab="プロフィール" key="1">
              {
                info?.businessName ? <SalonUpdateProfileForm salon={info} key={info.operatorEmail}/> : <BigSpin/>
              }
            </TabPane>
            <TabPane tab="ネイリスト" key="2">
              <Nailist salonId={_.get(this.props, 'match.params.id')} />
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
}

SalonDetailPage.propTypes = {
  fetchSalonProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  unsetSalonProfile: PropTypes.func.isRequired
};

export default SalonDetailPage;
