import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { BOOKING_ORIGIN } from 'utils/constants';

const {
  WEB,
  APP
} = BOOKING_ORIGIN;

const color = {
  [APP]: '',
  [WEB]: 'magenta'
};

const displayPlatform = {
  [APP]: 'App',
  [WEB]: 'Web'
};
const BookingOriginTag = ({ platform }) => (
  <Tag color={color[platform]}>{displayPlatform[platform || APP]}</Tag>
);

BookingOriginTag.propTypes = {
  platform: PropTypes.string
};

export default BookingOriginTag;
