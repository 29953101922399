import _ from 'lodash';
import qs from 'querystring';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col, Statistic, Typography, Tag, Popover } from 'antd';
import { CalendarTwoTone } from '@ant-design/icons';

import NailistPayoutContainer from 'containers/Payout/Nailist';
import StaffPayoutContainer from 'containers/Payout/Staff';
import { formatYen } from 'utils/stringFormat';

import '../styles.scss';
import QuickRecalcIncomeButton from 'components/Common/Sales/QuickRecalcIncomeButton';

let NailistPayout = withRouter(NailistPayoutContainer);
let StaffPayout = withRouter(StaffPayoutContainer);

const Sales = ({ data, nailistInfo, replace, pathname, ...props }) => {
  const isSalonStaff = _.get(nailistInfo, 'CBStaffId');
  const [activeTabKey, setActiveTabKey] = useState(isSalonStaff ? 'tabSalon' : 'tabNailist');
  
  useEffect(() => {
    const parsed = {
      orderBy: 'period.startDate',
      order: -1
    };

    replace(`${pathname}?${qs.stringify(parsed)}`);

    if (_.isEmpty(data)) {
      props.getNailistCurrentBalance();
    }
  }, []);

  const tabContent = {
    tabNailist: <NailistPayout nailistInfo={nailistInfo} />,
    tabSalon: <StaffPayout nailistInfo={nailistInfo} />
  };

  const sttPopoverComp = {
    allBookingsNumber: [],
    totalBookingValue: [],
    nailieComission: [],
    settlementFee: [],
    transferAmount: []
  };

  _.forEach(_.get(data, 'listAllBookingsNumber', []), (o) => {
    const title = o.salonId ? 'Salon' : 'Nailist';
    const color = o.salonId ? 'purple' : 'darkgreen';

    sttPopoverComp.allBookingsNumber.push(
      <div style={{ color }}>
        {title}: {o.allBookingsNumber || 0}
      </div>
    );
  });

  _.forEach(_.get(data, 'list', []), (o) => {
    const title = o.salonId ? 'Salon' : 'Nailist';
    const color = o.salonId ? 'purple' : 'darkgreen';
  
    sttPopoverComp.totalBookingValue.push(
      <div style={{ color }}>
        {title}: {formatYen(o.totalBookingValue || 0)}
      </div>
    );
    sttPopoverComp.nailieComission.push(
      <div style={{ color }}>
        {title}: {formatYen(o.nailieComission || 0)}
      </div>
    );
    sttPopoverComp.settlementFee.push(
      <div style={{ color }}>
        {title}: {formatYen(o?.settlementFee || 0)}
      </div>
    );
    sttPopoverComp.transferAmount.push(
      <div style={{ color }}>
        {title}: {formatYen(o.transferAmount || 0)}
      </div>
    );
  });

  return (
    <>
      <Card
        className="nailist-profile-tab"
        title="現在の収益"
        extra={<QuickRecalcIncomeButton nailistId={nailistInfo?._id}/> }>
        <Row gutter={24} type="flex" justify="space-between">
          <Col lg={{ span: 5 }} xs={{ span: 24 }}>
            <Statistic
              title="期間"
              value={_.get(data, 'period', '--/--/-- ~ --/--/--')}
            />
            <em></em>
          </Col>
          <Col lg={{ span: 3 }} xs={{ span: 12 }}>
            <Popover
              placement="bottomLeft"
              content={_.get(sttPopoverComp, 'allBookingsNumber')}
            >
              <Statistic
                title="現在の予約数"
                value={_.get(data, 'bookings', 0)}
              />
            </Popover>
            <em></em>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Popover
              placement="bottomLeft"
              content={_.get(sttPopoverComp, 'totalBookingValue')}
            >
              <Statistic
                title="決済された金額"
                prefix="¥"
                precision={0}
                value={_.get(data, 'totalBookingValue', 0)}
              />
            </Popover>
            <em></em>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Popover
              placement="bottomLeft"
              content={_.get(sttPopoverComp, 'nailieComission')}
            >
              <Statistic
                title="Nailie予約手数料"
                prefix="¥"
                precision={0}
                value={_.get(data, 'nailieComission', 0)}
              />
            </Popover>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Popover
              placement="bottomLeft"
              content={_.get(sttPopoverComp, 'settlementFee')}
            >
              <Statistic
                title="決済手数料"
                prefix="¥"
                precision={0}
                value={_.get(data, 'settlementFee', 0)}
              />
            </Popover>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Popover
              placement="bottomLeft"
              content={_.get(sttPopoverComp, 'transferAmount')}
            >
              <Statistic
                title="現在の振込金額"
                prefix="¥"
                precision={0}
                value={ _.get(data, 'transferAmount', 0) < 0 ? 0 : _.get(data, 'transferAmount', 0) }
              />
            </Popover>
          </Col>
        </Row>
        <div
          style={{
            marginTop: 10
          }}
        >
          <Typography.Link
            onClick={() => {
              props.openPayoutPopup({
                nailistId: _.get(nailistInfo, '_id'),
                popupTitle: (
                  <div>
                    <span>Booking list&nbsp;&nbsp;</span>
                    <Tag icon={<CalendarTwoTone />}>
                      {_.get(data, 'period', '--/--/-- ~ --/--/--')}
                    </Tag>
                  </div>
                )
              });
            }}
          >
            View booking list
          </Typography.Link>
        </div>
      </Card>
      <Card
        className="nailist-profile-tab"
        bodyStyle={{
          padding: '1px 0 0 0'
        }}
        tabList={[
          {
            key: 'tabNailist',
            tab: 'Nailist Payout'
          },
          {
            key: 'tabSalon',
            tab: 'Salon Staff Payout'
          }
        ]}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          const parsed = {
            orderBy: 'period.startDate',
            order: -1
          };
          replace(`${pathname}?${qs.stringify(parsed)}`);
          setActiveTabKey(key);
        }}
      >
        {tabContent[activeTabKey]}
      </Card>
    </>
  );
};

Sales.defaultProps = {
  data: {},
  getNailistCurrentBalance: () => {}
};

Sales.propTypes = {
  data: PropTypes.object,
  nailistInfo: PropTypes.object,
  getNailistCurrentBalance: PropTypes.func,
  openPayoutPopup: PropTypes.func,
  replace: PropTypes.func,
  pathname: PropTypes.any,
  search: PropTypes.any
};

export default Sales;
