import moment from 'moment';

const columns = {};

columns.id = {
  key: 'id',
  title: 'ID',
  dataIndex: '_id'
};

columns.createdAt = {
  key: 'createdAt',
  title: 'Create date',
  dataIndex: '_created_at',
  render: (record) => moment(record).format('HH:mm YYYY-MM-DD')
};

columns.username = {
  key: 'username',
  title: 'Username',
  dataIndex: 'username'
};

columns.name = {
  key: 'name',
  title: 'Fullname (phonetic)',
  dataIndex: 'name'
};

columns.phone = {
  key: 'phone',
  title: 'Phone number',
  dataIndex: 'phone'
};

columns.email = {
  key: 'email',
  title: 'Email',
  dataIndex: 'email'
};

columns.status = {
  key: 'status',
  title: 'Status',
  dataIndex: 'status'
};

export default columns;