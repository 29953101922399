import {
  fetchPopupBannerListSuccess, togglePopupBannerStatusSuccess, createPopupBannerSuccess,
  updatePopupBannerSuccess, updatePopupBannerFail,
  updatePopupBannersPositionSuccess, updatePopupBannersPositionFail
} from './actions';
import {
  FETCH_POPUP_BANNER_LIST, TOGGLE_POPUP_BANNER_STATUS, CREATE_POPUP_BANNER,
  UPDATE_POPUP_BANNER, UPDATE_POPUP_BANNERS_POSITION
} from './constants';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import { goBack, replace } from 'connected-react-router';
import request from 'utils/request';
import { message } from 'antd';

function* fetchPopupBannerList(action) {
  const filter = {...action.payload};
  try {
    filter.status = filter.status ? [filter.status] : [];
    filter.showOnRoles = filter.showOnRoles ? [filter.showOnRoles] : undefined;
    const { data } = yield call(request, 'popup/', { ...filter, limit: 10 }, 'GET');

    yield put(fetchPopupBannerListSuccess(data, action.meta));
  } catch (e) {
    console.log(e);
  }
}
function* togglePopupBannerStatus(action) {
  const { id, status } = action.payload;
  try {
    const { data } = yield call(request, `popup/${id}/toggleStatus`, { status }, 'PATCH');

    yield put(togglePopupBannerStatusSuccess({ ...data, id, status }, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* createPopupBanner(action) {
  try {
    yield call(request, 'popup/create', action.payload, 'POST');
    yield put(createPopupBannerSuccess());
    message.success('成功しました！ (ポップアップのリストにリダイレクト中)');
    yield put(replace('/dashboard/popup-banner'));
  } catch (e) {
    console.log(e);
  }
}

function* updatePopupBanner(action) {
  try {
    yield call(request, `popup/${action.payload.objectId}`, action.payload, 'POST');
    yield put(updatePopupBannerSuccess());
    message.success('成功しました！ (ポップアップのリストにリダイレクト中)');
    yield put(goBack());
  } catch (e) {
    yield put(updatePopupBannerFail());
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

function* updatePopupBannersPos(action) {
  let { oldList, newList } = action?.payload;
  newList = newList.map((r, index) => ({ ...r, id: r.objectId, orderNo: oldList[index].orderNo }));

  try {
    yield put(fetchPopupBannerListSuccess({ list: newList })); // temp list
    yield call(request, 'popup/orderNo', { banners: newList }, 'POST');
    yield put(updatePopupBannersPositionSuccess({}, action.meta));
    message.success('成功しました！');
  } catch (e) {
    yield put(updatePopupBannersPositionFail({}, action.meta));
    yield put(fetchPopupBannerListSuccess({ list: oldList })); // apply old list
    message.error(e?.message || e?.code || e?.data || String(e));
  }
}

export default function* watchPopupBanner() {
  yield takeEvery(FETCH_POPUP_BANNER_LIST, fetchPopupBannerList);
  yield takeEvery(TOGGLE_POPUP_BANNER_STATUS, togglePopupBannerStatus);
  yield takeLeading(CREATE_POPUP_BANNER, createPopupBanner);
  yield takeLeading(UPDATE_POPUP_BANNER, updatePopupBanner);
  yield takeLeading(UPDATE_POPUP_BANNERS_POSITION, updatePopupBannersPos);
}
