import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { Card, Form, Input, Select, Space, Button, Typography, Checkbox, Row, Divider, Modal, DatePicker } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { createBanner, updateBanner } from 'providers/BannerProvider/actions';
import CommonImageField from 'components/CommonImageField';
import './styles.scss';

const { Title } = Typography;
const { RangePicker } = DatePicker;

let modalControl = null; // Modal instance

const BannerForm = ({ initialValues, updateObjectId }) => {
  const [preview, setPreview] = useState(initialValues || { });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const linkContentDetailProps = {
    label: '詳細画面お内容',
    rules: [{ required: true, message: '詳細画面お内容が必要です' }]
  };

  useEffect(() => {
    return () => {
      modalControl && modalControl.destroy();
    };
  }, []);

  return (
    <Card id="banner-form" style={{ display: 'flex', justifyContent: 'center' }}>
      <Space align='start'>
        <Form
          style={{ maxWidth: 560 }}
          form={form}
          initialValues={initialValues || { rectBannerImage: '', linkType: 'HTML', linkImage: '' }}
          onFinish={(valuesOrg) => {
            const values = JSON.parse(JSON.stringify(valuesOrg));
            modalControl = Modal.confirm({
              title: `このバナーを${!updateObjectId ? '作成' : '保存'}してもよろしいですか?`,
              cancelText: 'いいえ',
              okText: 'はい',
              onOk() {
                values.rectBannerImage = values.rectBannerImage.data;
                if (values.title) {
                  values.title = values.title.trim();
                }
                if (values.content) {
                  values.content = values.content.trim();
                }
                if (values.linkType === 'IMAGE') {
                  values.linkTo = { type: 'IMAGE', location: values.linkImage.data };
                } else {
                  values.linkTo = { type: 'HTML', location: values.linkContentDetail.trim() };
                }
                if (!_.isEmpty(values.startEndTime)) {
                  values.startTime = moment(values.startEndTime[0]).set({ second: 0, millisecond: 0 }).toDate();
                  values.endTime = moment(values.startEndTime[1]).set({ second: 0, millisecond: 0 }).toDate();
                } else if (updateObjectId) { // update case, exclude create case
                  values.startTime = null;
                  values.endTime = null;
                }
                if (values.showOnRoles && values.showOnRoles.includes('NEW_CUSTOMER')) {
                  values.showOnRoles = _.pull(values.showOnRoles, 'CUSTOMER');
                }
                
                if (updateObjectId) {
                  dispatch(updateBanner({...values, objectId: updateObjectId}));
                } else {
                  dispatch(createBanner(values));
                }
              }
            });
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onValuesChange={(values) => {
            const name = Object.keys(values)[0];
            if (['rectBannerImage', 'title', 'content', 'types'].includes(name)) {
              setPreview({ ...preview, ...values });
            }
          }}
        >
          <Title level={5} style={{ marginBottom: 16 }}>ターゲット</Title>
          <div style={{ marginBottom: 32 }}>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.showOnRoles !== currentValues.showOnRoles}
              wrapperCol={{}}
            >
              {({ getFieldValue }) => (
                <Space style={{ background: '#F5F5F5', borderRadius: 4, padding: 16, alignItems: 'stretch' }}>
                  <Form.Item
                    name='showOnRoles'
                    rules={[{ required: true, message: '少なくとも1つのターゲットを選択する必要があります.' }]}
                    noStyle
                  >
                    <Checkbox.Group disabled={updateObjectId}>
                      <Row><Checkbox value="NAILIST">NAILIST</Checkbox></Row>
                      <Row><Checkbox value="CUSTOMER" disabled={getFieldValue('showOnRoles') && getFieldValue('showOnRoles').includes('NEW_CUSTOMER')}>CUSTOMER</Checkbox></Row>
                      <Row><Checkbox value="NEW_CUSTOMER">New customer</Checkbox></Row>
                      <Row><Checkbox value="GUEST">GUEST (non-login users)</Checkbox></Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Divider type='vertical' style={{ height: '100%' }} />
                  <div>
                    <div>Please contact DEV team if there are special banners.</div>
                    <br />
                    <div><InfoCircleTwoTone /> New customer:</div>
                    <ul>
                      <li>Customer do not have any booking</li>
                      <li>Customer only have [cancelled] booking</li>
                    </ul>
                  </div>
                </Space>
              )}
            </Form.Item>
          </div>

          <Title level={5} style={{ marginBottom: 16 }}>Start - end date and time for banner (JST)</Title>
          <Form.Item
            name='startEndTime'
            label='間隔'
            rules={[{ validator: (rule, value) => {
              if (value && value[0].isSame(value[1], 'minute')) {
                return Promise.reject('無効なデータ!');
              }

              return Promise.resolve();
            }}]}
          >
            <RangePicker
              showTime={{ format: 'HH:mm', minuteStep: 5 }}
              format={(value) => value.utcOffset(9).format('YYYY-MM-DD HH:mm')}
              // format=  "YYYY-MM-DD HH:mm"
            />
          </Form.Item>

          <Title level={5} style={{ marginBottom: 16 }}>Overview setting:</Title>
          <div><InfoCircleTwoTone twoToneColor="orange" />{' Please choose small file size to help application to load image faster and save money of S3/Cloudfont (<1MB is recommended).'}</div>
          <Form.Item name="rectBannerImage" label="画像" rules={[{ required: true, message: '画像が必要です' }]}>
            <CommonImageField width={375} height={250}/>
          </Form.Item>
          

          <Title level={5} style={{ marginBottom: 16 }}>Content detail setting</Title>

          <Form.Item label='Link to' name='linkType' rules={[{ required: true }]}>
            <Select>
              <Select.Option value="HTML">HTML (insert URL)</Select.Option>
              <Select.Option value="IMAGE">Image (upload image)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.linkType !== currentValues.linkType}
          >
            {({ getFieldValue }) => getFieldValue('linkType') === 'HTML' &&
              <Form.Item {...{
                ...linkContentDetailProps,
                name: 'linkContentDetail',
                rules: linkContentDetailProps.rules.concat({
                  type: 'url', message: 'Please input correct URL format (http://www.example.com)'
                })
              }}>
                <Input />
              </Form.Item>
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.linkType !== currentValues.linkType}
          >
            {({ getFieldValue }) => getFieldValue('linkType') === 'IMAGE' &&
              <Form.Item {...linkContentDetailProps} name="linkImage">
                <CommonImageField />
              </Form.Item>
            }
          </Form.Item>

          <Space align='center' direction='vertical' style={{ width: '100%' }}>
            <Button type="primary" htmlType="submit">保存</Button>
          </Space>
        </Form>
      </Space>
    </Card>
  );
};

BannerForm.propTypes = {
  initialValues: PropTypes.any,
  updateObjectId: PropTypes.string
};

export default BannerForm;