import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactCrop from 'react-image-crop';
import { Button, Row, Col, Modal, Spin, Image } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { uploadSalonImage } from 'providers/SalonProvider/actions';
import { arrayMoveImmutable } from 'array-move';


import 'react-image-crop/dist/ReactCrop.css';
import './styles.scss';
import { useDispatch } from 'react-redux';

const styles = {
  sortableElementCol: {
    marginRight: 10,
    paddingRight: 0,
    zIndex: 999
  },
  sortableElementContainer: {
    position: 'relative',
    cursor: 'pointer'
  },
  sortableElementNumber: {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 1,
    fontSize: 20,
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 0.85)'
  },
  sortableElementAvatar: {
    borderRadius: 8
  },
  sortableElementButton: {
    position: 'absolute',
    bottom: -14,
    right: -14,
    zIndex: 2
  }
};

const ImageField = ({ value, onChange, maxLength = 1, aspect, id = 'image', salonId }) => {
  const dispatch = useDispatch();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);
  const [isCropingImageModal, setIsCropingImageModal] = useState(false);
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: aspect, x: 0, y: 0 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [fileName, setFileName] = useState();
  const [fileType, setFileType] = useState();

  const dataURLtoFile = (dataurl, filename, fileType) => {
    var arr = dataurl.split(',');
    // var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: fileType });
  };

  const onSelectFile = (e) => {
    if (aspect === undefined) {
      setIsUploading(true);
      dispatch(uploadSalonImage({ file: e.target.files[0], salonId }))
        .then((result) => {
          setIsUploading(false);
          if (maxLength === 1) {
            onChange(result);
          } else {
            onChange([...value, { objectId: result, thumbSmall: result }]);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsUploading(false);
        });
    } else {
      // Init Crop Area Default
      setCrop({ unit: '%', width: 30, aspect: aspect, x: 0, y: 0 });

      if (e.target.files && e.target.files.length > 0) {
        // reader.readAsDataURL: can't read large image data on Firefox Mobile. It's replaced by URL.createObjectURL.
        setUpImg(URL.createObjectURL(e.target.files[0]));
        setIsCropingImageModal(true);
        setFileName(e.target.files[0].name);
        setFileType(e.target.files[0].type);
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current || completedCrop.width <= 0 || completedCrop.height <= 0) {
      return;
    }

    const pixelRatio = 4;
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  const handleUploadImage = (previewCanvas, crop, fileName, fileType) => {
    setIsUploading(true);

    if (!crop || !previewCanvas || previewCanvas.width <= 0 || previewCanvas.height <= 0 || crop.width <= 0 || crop.height <= 0) {
      setIsUploading(false);
      return;
    }

    const dataURL = previewCanvas.toDataURL();
    const imageFile = dataURLtoFile(dataURL, fileName, fileType);

    dispatch(uploadSalonImage({ file: imageFile, salonId }))
      .then((result) => {
        setIsUploading(false);
        setIsCropingImageModal(false);
        if (maxLength === 1) {
          onChange(result);
        } else {
          onChange([...value, { objectId: result, thumbSmall: result }]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsUploading(false);
      });
  };

  const removeImage = imageId => () => {
    if (maxLength === 1) {
      onChange('');
    } else {
      const newImages = value.filter(image => image.objectId !== imageId);
      onChange(newImages);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMoveImmutable(value, oldIndex, newIndex));
  };

  const SortableItem = SortableElement(({ image, number }) => (
    <Col style={styles.sortableElementCol}>
      <div style={styles.sortableElementContainer}>
        <Image
          style={styles.sortableElementAvatar}
          width={108}
          height={108}
          src={image.thumbSmall}
        />
        <div style={styles.sortableElementButton}>
          <Button shape='circle' onClick={removeImage(image.objectId)}><CloseOutlined style={{ pointerEvents: 'none' }} /></Button>
        </div>
      </div>
    </Col>
  ));

  const SortableList = SortableContainer(() => {
    return (
      <div className="sortable-container">
        {value && value.map((image, index) => (
          <SortableItem
            key={image.objectId}
            image={image}
            index={index}
            number={index + 1}
          />
        ))}
      </div>
    );
  });

  return (
    <>
      <Modal
        centered
        visible={isCropingImageModal}
        title={'Edit Image'}
        destroyOnClose={true}
        confirmLoading={isUploading}
        onOk={() => {
          handleUploadImage(previewCanvasRef.current, completedCrop, fileName, fileType);
        }}
        onCancel={() => {
          if (isUploading === false) {
            setIsCropingImageModal(false);
          }
        }}
        okButtonProps={{ disabled: !completedCrop || !previewCanvasRef.current || !imgRef.current || completedCrop.width <= 0 || completedCrop.height <= 0 }}
        maskClosable={false}
      >
        {isUploading === true
          ? <Spin tip="Uploading...">
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => {
                setCrop(c);
              }}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
            />
            <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
          </Spin>
          : <>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => {
                setCrop(c);
              }}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
            />
            <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
          </>
        }
      </Modal>
      <Row gutter={24} type="flex" className="image-field">
        {maxLength === 1
          ? value !== '' &&
          <Col style={styles.sortableElementCol}>
            <div style={styles.sortableElementContainer}>
              <Image
                style={styles.sortableElementAvatar}
                width={108}
                height={108}
                src={value}
              />
              <div style={styles.sortableElementButton}>
                <Button shape='circle' onClick={removeImage(value)}><CloseOutlined style={{ pointerEvents: 'none' }} /></Button>
              </div>
            </div>
          </Col>
          : <SortableList axis="xy" onSortEnd={onSortEnd} distance={1} />
        }
        {value && value.length < maxLength && (
          <Col>
            <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
              <label htmlFor={id} shape="square">
                {!isUploading
                  ? <div className="upload-button">
                    <PlusOutlined />
                    <div>Upload Photo</div>
                  </div>
                  : <div className="upload-button">
                    <Spin />
                    <div>Upload Photo</div>
                  </div>
                }
              </label>
              <input
                id={id}
                name={id}
                accept="image/*"
                type="file"
                hidden
                onClick={(e) => {
                  e.currentTarget.value = null;
                }}
                onChange={onSelectFile}
              />
            </div>
          </Col>
        )}
        {value === '' && (
          <Col>
            <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
              <label htmlFor={id} shape="square">
                {!isUploading
                  ? <div className="upload-button">
                    <PlusOutlined />
                    <div>Upload Photo</div>
                  </div>
                  : <div className="upload-button">
                    <Spin />
                    <div>Upload Photo</div>
                  </div>
                }
              </label>
              <input
                id={id}
                name={id}
                accept="image/*"
                type="file"
                hidden
                onClick={(e) => {
                  e.currentTarget.value = null;
                }}
                onChange={onSelectFile}
              />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

ImageField.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  aspect: PropTypes.any,
  id: PropTypes.string,
  salonId: PropTypes.string
};

export default ImageField;
