import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  getOlympicInfo,
  getOlympicList,
  exportOlympicList,
  updateOlympic
} from 'providers/FriendInvitationProvider/actions';
import {
  makeOlympicInfo,
  makeOlympicList,
  makeOlympicTotal,
  makeFriendInvitationLoading,
  makeFriendInvitationActionLoading
} from 'providers/FriendInvitationProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import Olympic from './Olympic';

const mapStateToProps = (state) => ({
  olympicInfo: makeOlympicInfo(state),
  olympicList: makeOlympicList(state),
  olympicTotal: makeOlympicTotal(state),
  olympicDataLoading: makeFriendInvitationLoading(state),
  olympicActionLoading: makeFriendInvitationActionLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  getOlympicInfo: (params) => dispatch(getOlympicInfo(params)),
  getOlympicList: (params) => dispatch(getOlympicList(params)),
  exportOlympicList: (params) => dispatch(exportOlympicList(params)),
  updateOlympic: (params) => dispatch(updateOlympic(params)),
  replace: (path) => dispatch(replace(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(Olympic);
