import React, { useEffect } from 'react';
import { Card, Typography, Descriptions } from 'antd';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { StarFilled, PlusOutlined, MinusOutlined, RiseOutlined, FallOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Insight = ({ data, nailistId, fetchNailistInsight }) => {
  useEffect(() => {
    fetchNailistInsight({ id: nailistId });
  }, [nailistId]);

  const wardName = _get(data, 'rankingAreaObject.wards.name');
  const wardRanking = _get(data, 'rankingAreaObject.wards.ranking', '--');
  const wardRankingTotal = _get(data, 'rankingAreaObject.wards.total', '--');

  const provinceName = _get(data, 'rankingAreaObject.province.name') || '--';
  const provinceRanking = _get(data, 'rankingAreaObject.province.ranking', '--');
  const provinceRankingTotal = _get(data, 'rankingAreaObject.province.total', '--');

  const countryRanking = _get(data, 'rankingAreaObject.country.ranking', '--');
  const countryRankingTotal = _get(data, 'rankingAreaObject.country.total', '--');
  const numberOfBooking30Days = _get(data, 'numberOfBooking30Days', 0);
  const numberOfLikePost = _get(data, 'numberOfLikePost', 0);
  const numberOfFollowers = _get(data, 'follower.total', 0);

  const fiveStarPercent = _get(data, 'review.starPercentage.five', 0);
  const fourStarPercent = _get(data, 'review.starPercentage.four', 0);
  const threeStarPercent = _get(data, 'review.starPercentage.three', 0);
  const twoStarPercent = _get(data, 'review.starPercentage.two', 0);
  const oneStarPercent = _get(data, 'review.starPercentage.one', 0);

  const followerComparison = _get(data, 'follower.comparisonResult', 0);
  const followerChangeIcon = followerComparison > 0 ?
    <PlusOutlined className="primary-icon" /> : (followerComparison < 0 ? <MinusOutlined className="primary-icon" /> : null);

  const pageViewComparison = _get(data, 'numberOfViewMyPage.comparisonResult', 0);
  const pageViewTotal = _get(data, 'numberOfViewMyPage.total', 0);
  const countReview = _get(data, 'review.countReview', 0);
  const averageSkillRating = _get(data, 'review.averageSkillRating', 0);
  const averageServiceRating = _get(data, 'review.averageServiceRating', 0);
  const averagePriceRating = _get(data, 'review.averagePriceRating', 0);
  const nailDesignTotal = _get(data, 'nailDesign.total', 0);

  const renderChangeIcon = (number) => {
    if (number > 0) {
      return <RiseOutlined className="primary-icon" />;
    } else if (number < 0) {
      return <FallOutlined className="primary-icon" />;
    } else return null;
  };
  return (
    <Card className="nailist-insight-tab" title="インサイト">
      <Text strong>ネイリストランキング</Text>
      <Descriptions bordered column={1} size="small" labelStyle={{ width: 240 }} contentStyle={{ whiteSpace: 'pre-line' }}>
        {!_isEmpty(wardName) ?
          <>
            <Descriptions.Item label={wardName}>{wardRanking}位/ {wardRankingTotal}</Descriptions.Item>
            <Descriptions.Item label={provinceName}>{provinceRanking}位/ {provinceRankingTotal}</Descriptions.Item>
            <Descriptions.Item label="全国" className="divider">{countryRanking}位/ {countryRankingTotal}</Descriptions.Item>
          </>
          : <Descriptions.Item label="データがなし" className="divider"></Descriptions.Item>
        }
        <Descriptions.Item label="今月の予約数" className="divider">{numberOfBooking30Days}件</Descriptions.Item>
        <Descriptions.Item label="ページ閲覧数（過去30日間）" className="divider">
          {renderChangeIcon(pageViewComparison)} {pageViewTotal}件
        </Descriptions.Item>
        <Descriptions.Item label="獲得いいね数（過去30日間）" className="divider">
          {numberOfLikePost}件
        </Descriptions.Item>
        <Descriptions.Item label="フォロワー数">{numberOfFollowers}人</Descriptions.Item>
        <Descriptions.Item label="増減数（過去30日間）" className="divider">
          {followerChangeIcon}{Math.abs(followerComparison)}人
        </Descriptions.Item>
      </Descriptions>
      <div className="review-section">
        <Text strong>レビュー</Text>
        <Descriptions
          bordered
          column={1}
          size="small"
          labelStyle={{ width: 240 }}
        >
          <Descriptions.Item
            className="average-rating"
            label={<div className="rating">{(_get(data, 'review.totalAverage', 0).toFixed(1))}/5<StarFilled /></div>}>
          </Descriptions.Item>
          <Descriptions.Item label="5点">
            <Progress
              strokeColor="#1890ff"
              percent={Math.floor(fiveStarPercent * 100)}
              format={(percent) => `${percent}%`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="4点">
            <Progress
              strokeColor="#1890ff"
              percent={Math.floor(fourStarPercent * 100)}
              format={(percent) => `${percent}%`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="3点">
            <Progress
              strokeColor="#1890ff"
              percent={Math.floor(threeStarPercent * 100)}
              format={(percent) => `${percent}%`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="2点">
            <Progress
              strokeColor="#1890ff"
              percent={Math.floor(twoStarPercent * 100)}
              format={(percent) => `${percent}%`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="1点">
            <Progress
              strokeColor="#1890ff"
              percent={Math.floor(oneStarPercent * 100)}
              format={(percent) => `${percent}%`}
            />
          </Descriptions.Item>
          <Descriptions.Item label="件数">{countReview}件</Descriptions.Item>
          <Descriptions.Item label="技術">{averageSkillRating.toFixed(1)}点</Descriptions.Item>
          <Descriptions.Item label="接客">{averageServiceRating.toFixed(1)}点</Descriptions.Item>
          <Descriptions.Item label="価格" className="divider">{averagePriceRating.toFixed(1)}点</Descriptions.Item>
          <Descriptions.Item label="デザイン数">{nailDesignTotal}件</Descriptions.Item>
        </Descriptions>
      </div>
    </Card>
  );
};

Insight.propTypes = {
  data: PropTypes.object.isRequired,
  nailistId: PropTypes.string.isRequired,
  fetchNailistInsight: PropTypes.func.isRequired
};

export default Insight;