import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select as AntSelect } from 'antd';

import { TextField, NumericInput, Select } from 'components/Form';
import validate from './validate';
import './styles.scss';
const { Option } = AntSelect;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const CreateForm = ({ handleSubmit }) => {
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} id="create-salon-popup">
      <Field
        name="businessName"
        type="text"
        placeholder="サロン名"
        component={TextField}
        label="サロン名"
      />
      <Field
        name="salonSharedType"
        type="salonSharedType"
        placeholder="サロンタイプ"
        component={Select}
        label="サロンタイプ"
      >
        <Option value={'REG_A'}>ネイリー サロンマネージャー</Option>
        <Option value={'REG_B'}>通常-B型</Option>
        <Option value={'SHARED'}>シェア型</Option>
        <Option value={'FIVE_STAR'}>Five Star Studio</Option>
      </Field>

      <Field
        name="email"
        type="email"
        placeholder="メールアドレス"
        component={TextField}
        label="メールアドレス"
      />
      <Field
        name="phoneNumber"
        placeholder="電話番号"
        component={NumericInput}
        maxLength={11}
        label="電話番号"
      />
    </Form>
  );
};

const CreateFormWithRedux = connect((state) => {
  return {};
})(reduxForm({ form: 'createSalonForm', validate, destroyOnUnmount: true })(CreateForm));

const CreateSalonPopup = ({
  isShown,
  closePopup,
  createSalon,
  submitForm,
  actionLoading
}) => {
  return (
    <Modal
      title="サロンアカウント作成"
      visible={isShown}
      onOk={submitForm}
      confirmLoading={actionLoading}
      onCancel={closePopup}
      destroyOnClose={true}
      maskClosable={false}
    >
      <CreateFormWithRedux onSubmit={createSalon} />
    </Modal>
  );
};

CreateSalonPopup.propTypes = {
  isShown: PropTypes.any,
  closePopup: PropTypes.any,
  createSalon: PropTypes.any,
  submitForm: PropTypes.any,
  actionLoading: PropTypes.any
};

export default CreateSalonPopup;
