import axios from 'axios';
import _ from 'lodash';

import {
  API_APP_ID, API_SERVER_URL
} from './config';

export const getAxiosInstance = (domain = API_SERVER_URL, options) => {
  const token = localStorage.getItem('__a_token');
  const headers = {
    'X-Secret-Key': API_APP_ID,
    ...(token ? { Authorization: 'Bearer ' + token} : {})
  };

  const axiosInstance = axios.create({
    baseURL: domain,
    headers,
    ...options
  });

  axiosInstance.interceptors.response.use((response) => {
    if ([200, 201].includes(response.status)) {
      const result = response.data;
      if (_.isObject(result.isObject)) {
        result.statusCode = response.status;
      }
      return response.data;
    }
    return Promise.reject(response);
  }, (error) => {
    const { status } = error.response;
    const { code } = error.response.data;

    if (code) {
      // Authorize error
      if (code === 401) {
        window.store.dispatch({type: 'LOGIN_FAIL', payload: 'Auth error!'});
      }

      return Promise.reject(error.response.data);
    }
    if (status) {
      if (status === 401) {
        window.store.dispatch({ type: 'LOGIN_FAIL', payload: 'Auth error!' });
      }
    }
    return Promise.reject(error.response);
  });

  return axiosInstance;
};

const request = (url, data = {}, method = 'POST', options = {}) => {
  try {
    const API = getAxiosInstance(undefined, options);
    switch (method) {
      case 'GET': return API.get(url, { params: data });
      case 'PUT': return API.put(url, data);
      case 'PATCH': return API.patch(url, data);
      case 'DELETE': return API.delete(url, data);
      default: return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
