import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Card, Table, Row, Col } from 'antd';

import ReceiptEmailPreview from '../ReceiptEmailPreview';

import columnsTemplate from '../../Preset/columnsTemplate';
import './style.scss';

const ReceiptEmailRelatedList = ({
  relatedReceipts,
  receiptId
}) => {
  const columns = [
    columnsTemplate.sentAt,
    columnsTemplate.price,
    {
      ...columnsTemplate.action,
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <Link replace to={`/dashboard/receipts/${record._id}/related/booking/${_.get(record, 'emailParams.bookingId')}/`}>
          <Button disabled={record._id === receiptId}>View</Button>
        </Link>
      )
    }
  ];
  
  return (
    <Card id="related_receipt" title="Receipt history">
      <Row gutter={[16, 16]} style={{width: 'calc(100% + 16px)'}}>
        <Col span={12}>
          <Table
            rowKey={'_id'}
            columns={columns}
            dataSource={relatedReceipts}
            pagination={false}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys: [receiptId]
            }}
            id="related_receipt_select_table"
          />
        </Col>
        <Col span={12}>
          <ReceiptEmailPreview receiptId={receiptId}/>
        </Col>
      </Row>
    </Card>
  );
};

ReceiptEmailRelatedList.propTypes = {
  receiptId: PropTypes.string,
  relatedReceipts: PropTypes.array
};

export default ReceiptEmailRelatedList;