/* eslint-disable react/display-name */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Content = ({
  data,
  accountNoBankList,
  accountNoBankTotal,
  accountNoBankLoading,
  getAccountNoBank
}) => {
  const [searchState, setSearchState] = useState({
    page: 1,
    limit: 10
  });

  const getAccountNoBankList = () => {
    getAccountNoBank({
      ...searchState,
      accountType: _.get(data, 'accountType')
    });
  };

  useEffect(() => {
    getAccountNoBankList();
  }, []);

  useEffect(() => {
    getAccountNoBankList();
  }, [searchState]);

  const onTableChange = (pagination, filters, sorter) => {
    setSearchState(() => ({
      page: Number(pagination.current),
      limit: Number(pagination.pageSize)
    }));
  };

  const columns = [
    {
      title: 'Username',
      key: 'username',
      render: (record) => {
        const UsernameJSX = (
          <Space align="center" size="middle">
            <Avatar
              icon={<UserOutlined />}
              src={_.get(record, 'avatar')}
              size="large"
            />
            <div>{_.get(record, 'username')}</div>
          </Space>
        );
        if (_.get(record, 'type') !== 'SALON') {
          return (
            <Link
              to={'/dashboard/nailist/profile/' + _.get(record, '_id')}
              target="_blank"
            >
              {UsernameJSX}
            </Link>
          );
        }
        return UsernameJSX;
      }
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Phone Number',
      key: 'phone',
      dataIndex: 'phone'
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={accountNoBankList}
        scroll={{ x: true }}
        onChange={onTableChange}
        loading={accountNoBankLoading}
        size="small"
        pagination={{
          current: searchState.page,
          pageSize: searchState.limit,
          showSizeChanger: false,
          total: accountNoBankTotal,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </div>
  );
};

Content.propTypes = {
  data: PropTypes.any,
  accountNoBankList: PropTypes.any,
  accountNoBankTotal: PropTypes.any,
  accountNoBankLoading: PropTypes.bool,
  getAccountNoBank: PropTypes.func
};

export default Content;
