import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { SetPopup } from 'providers/PopupProvider/actions';
import { makeAnyPopup } from 'providers/PopupProvider/selectors';
import {
  getBankList,
  getBranchList,
  getAccountTypeList,
  updateBankAccount
} from 'providers/BankProvider/actions';
import {
  makeBankList,
  makeBranchList,
  makeAccountTypeList,
  makeBankActionLoading
} from 'providers/BankProvider/selectors';
import { fetchNailistBankInfo } from 'providers/NailistProvider/actions';

import Popup from './Popup';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({ name: 'bankPopup', show: false })),
  submitForm: () => dispatch(submit('updateBankForm')),
  getBankList: (data) => dispatch(getBankList(data)),
  getBranchList: (data) => dispatch(getBranchList(data)),
  getAccountTypeList: (data) => dispatch(getAccountTypeList(data)),
  updateBankAccount: (data) => dispatch(updateBankAccount(data)),
  fetchNailistBankInfo: (data) => dispatch(fetchNailistBankInfo(data))
});

const PopupLayer1 = connect(null, mapDispatchToProps)(Popup);

const mapStateToPropsLayer1 = (state) => ({
  data: makeAnyPopup(state, 'bankPopup'),
  bankList: makeBankList(state),
  branchList: makeBranchList(state),
  accountTypeList: makeAccountTypeList(state),
  actionLoading: makeBankActionLoading(state)
});

export default connect(mapStateToPropsLayer1, null)(PopupLayer1);
