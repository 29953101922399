import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import BigPageHeader from 'components/PageHeader';
import UnansweredTickets from './UnansweredTickets';
import AnsweredTickets from './AnsweredTickets';
import EditQuestionPopup from './EditQuestionPopup';

import { replyTicket } from 'providers/SupportProvider/actions';

import './styles.scss';

const Bread = BigPageHeader.Bread;

const SupportVerificationPage = () => {
  const dispatch = useDispatch();
  const [popUpData, setPopUp] = useState(undefined);
  const [showPopup, setShowPopup] = useState(false);

  const onPopUpData = (value) => {
    setShowPopup(true);
    setPopUp(value);
  }

  const handleSubmit = async (values) => {
    if(_.get(popUpData, 'ticket.subject') !== values.subject ||
    _.get(popUpData, 'ticket.answer') !== values.answer) {
      await dispatch(
        replyTicket({
          ...values,
          supportType: 'VERIFY',
          filter: _.get(popUpData, 'search')
        })
      );
    }
    setShowPopup(false);
  }

  return (
    <>
      <Helmet>
        <title>{'Support | Verification'}</title>
      </Helmet>
      <BigPageHeader
        goBack={false}
        title={'Support Verification'}
        breadcrumb={Bread.supportVerification}
      />
      <UnansweredTickets handleEdit={onPopUpData} />
      <AnsweredTickets handleEdit={onPopUpData} />

      {showPopup && (
        <EditQuestionPopup
          closeModal={() => setShowPopup(false)}
          onSubmit={handleSubmit}
          initialValues={{ title: popUpData.title, ...popUpData.ticket }}
        />
      )}
    </>
  );
};

SupportVerificationPage.propTypes = {};

export default SupportVerificationPage;
