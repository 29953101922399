import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select, Space, Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { createAdminOperator, updateAdminOperator } from 'providers/UserProvider/actions';
import useTableControl from '../hooks/useTableControl';
import { me as fetchMe } from 'providers/AuthProvider/actions';

const USERNAME_ERROR = {
  pattern: /^[a-zA-Z0-9_.]{3,30}$/,
  message: 'more than 2 and less than 30 characters, only alphabet, number, . and _'
};

const ManageAccountForm = ({ initialValues, submit }) => {
  const [form] = Form.useForm();

  return <Form
    form={form}
    initialValues={initialValues}
    onFinish={(values) => {
      form.validateFields();
      submit(values);
    }}
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 12 }}
  >
    <Form.Item label='ユーザーネーム' name='username' rules={[{ required: true }, USERNAME_ERROR]}>
      <Input maxLength={30} />
    </Form.Item>

    <Form.Item label='パスワード' name='password' rules={initialValues._id ? [] : [{ required: true }]}>
      <Input.Password placeholder='●●●●●●●●●●' autoComplete="new-password" />
    </Form.Item>

    <Form.Item label='メールアドレス' name='email' rules={[{ type: 'email' }, { required: true }]}>
      <Input maxLength={255} />
    </Form.Item>

    <Form.Item label='分類' name='role' rules={[{ required: true }]}>
      <Select>
        <Select.Option value="ADMIN">管理者</Select.Option>
        <Select.Option value="OPERATOR">オペレーター</Select.Option>
      </Select>
    </Form.Item>

    <Form.Item label='電話番号' name='phone' rules={[{ pattern: /^(\d{10}|\d{11})$/, message: 'invalid phone number' }]}>
      <Input />
    </Form.Item>

    {initialValues._id && <Form.Item label='Status' name='status' initialValue="ACTIVE">
      <Select>
        <Select.Option value="ACTIVE">ACTIVE</Select.Option>
        <Select.Option value="INACTIVE">INACTIVE</Select.Option>
      </Select>
    </Form.Item>}

    <Space align='center' direction='vertical' style={{ width: '100%' }}>
      <Button type="primary" htmlType="submit">保存</Button>
    </Space>
  </Form>;
};

ManageAccountForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func
};

const useManageAccountPopupController = () => {
  const [search] = useTableControl({});
  const [isOpen, setIsOpen] = useState({});
  const dispatch = useDispatch();
  const { _id } = isOpen;
  const { me } = useSelector(state => state.auth);

  const submit = async(values) => {
    const callback = () => setIsOpen(false);
    if (_id) {
      await dispatch(updateAdminOperator({ _id, ...values, search, callback }));
      if (me._id === _id) {
        dispatch(fetchMe());
      }
    } else {
      await dispatch(createAdminOperator({ ...values, search, callback }));
    }
  };

  const Popup = <Modal
    title={`${_id ? 'Edit' : 'Create'} account`}
    visible={!_isEmpty(isOpen)}
    destroyOnClose
    footer={null}
    onCancel={() => setIsOpen(false)}
    width={980}
  >
    <ManageAccountForm initialValues={isOpen} submit={submit} />
  </Modal>;

  const openPopup = (data) => setIsOpen(data);

  return [openPopup, Popup];
};

export default useManageAccountPopupController;