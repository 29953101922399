import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  getFriendInvitationList,
  exportFriendInvitationList
} from 'providers/FriendInvitationProvider/actions';
import {
  makeFriendInvitationList,
  makeFriendInvitationTotal,
  makeFriendInvitationLoading,
  makeFriendInvitationActionLoading
} from 'providers/FriendInvitationProvider/selectors';
import {
  makeSelectLocationSearch,
  makeSelectLocationPathname
} from 'providers/RouterProvider/selectors';

import FriendInvitationList from './FriendInvitationList';

const mapStateToProps = (state) => ({
  friendInvitationList: makeFriendInvitationList(state),
  friendInvitationTotal: makeFriendInvitationTotal(state),
  friendInvitationLoading: makeFriendInvitationLoading(state),
  friendInvitationActionLoading: makeFriendInvitationActionLoading(state),
  search: makeSelectLocationSearch(state),
  pathname: makeSelectLocationPathname(state)
});

const mapDispatchToProps = (dispatch) => ({
  getFriendInvitationList: (params) => dispatch(getFriendInvitationList(params)),
  exportFriendInvitationList: (params) => dispatch(exportFriendInvitationList(params)),
  replace: (path) => dispatch(replace(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendInvitationList);
