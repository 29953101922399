export const getContentByStatusType = (status) => {
  let modalTitle = '';
  let modalContent = '';
  if (status === 'ACTIVE') {
    modalTitle = 'ステータスを強制退会に変更します';
    modalContent = '強制退会にする理由を教えてください';
  } else {
    modalTitle = 'ステータスをACTIVEに変更します';
    modalContent = 'ステータスをACTIVEに変更してもよろしいですか?';
  }
  return { modalTitle, modalContent };
};
// ACTIVE => PERMANENT_DELETED
// INACTVE, PERMANENT_DELETED, DELETED => ACTIVE
export const getStatusParam = (status) => {
  let statusParam = '';
  if (status === 'ACTIVE') {
    statusParam = 'PERMANENT_DELETED';
  } else {
    statusParam = 'ACTIVE';
  }
  return { statusParam };
};



