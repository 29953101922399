import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerCouponLists, fetchCustomerCouponListsSuccess } from 'providers/CustomerProvider/actions';

import './style.scss';

const Coupon = ({ image, endTime: { iso: expDate } = {}, hideCode, code }) => {
  return (
    <div className="coupon-cell-containter">
      <img className="coupon-cell-img" src={image} alt={{ code }} />
      <div className="coupon-cell-subscript">
        {`有効期限：${moment(expDate).format('YYYY/MM/DD')}`}
        {hideCode ? '' : ` コード：${code}`}
      </div>
    </div>
  );
};

const CouponList = ({ customerId }) => {
  const dispatch = useDispatch();
  const { couponlist, expiredCoupons } = useSelector(state => state?.customer?.coupons || {});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async() => {
      setLoading(true);
      await dispatch(fetchCustomerCouponLists(customerId));
      setLoading(false);
    })();

    return () => {
      dispatch(fetchCustomerCouponListsSuccess({ couponlist: [], expiredCoupons: [] }));
    };
  }, [customerId]);

  return <Spin spinning={loading}>
    <Card>
      <Descriptions column={1} bordered >
        <Descriptions.Item label="保有クーポン">
          {couponlist.map((coupon, i) => <Coupon key={i} {...coupon} />)}
        </Descriptions.Item>
        <Descriptions.Item label="期限切れのクーポン">
          {expiredCoupons.map((coupon, i) => <Coupon key={i} {...coupon} />)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  </Spin>;
};

Coupon.propTypes = {
  image: PropTypes.string,
  endTime: PropTypes.any,
  hideCode: PropTypes.bool,
  code: PropTypes.string
};
CouponList.propTypes = {
  customerId: PropTypes.string.isRequired
};

export default CouponList;