// Checked
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu } from 'antd';

import { setActiveMenu } from 'providers/LayoutProvider/actions';
import { activeMenu } from 'providers/LayoutProvider/selectors';
import { makeMe } from 'providers/AuthProvider/selectors';

import MenuRoutes from './SideMenuRoutes';
import CountSalonTickets from 'containers/SupportPage/Salon/CountSalonTickets';
import CountWebTickets from 'containers/SupportPage/Web/CountTickets';
import CountAppTickets from 'containers/SupportPage/App/CountTickets';
import CountVerificationTickets from 'containers/SupportPage/Verification/CountTickets';
import CountSupports from 'containers/SupportPage/CountSupports';

import WIPIcon from 'components/WIPIcon';
import logoImg from 'assets/icons/logoNailie.svg';
import './styles.scss';

const { SubMenu } = Menu;
const { Sider } = Layout;

const components = {
  COUNT_SALON: CountSalonTickets,
  COUNT_WEB: CountWebTickets,
  COUNT_APP: CountAppTickets,
  COUNT_VERIFICATION: CountVerificationTickets,
  COUNT_SUPPORT: CountSupports,
};

const renderMenuItems = (menuItems) => {
  const resultJSX = [];
  let SpeComponent = null;

  menuItems.forEach((menu) => {
    SpeComponent = components[menu.component];
    if (_.has(menu, 'subMenu')) {
      resultJSX.push(
        <SubMenu
          icon={<LegacyIcon type={menu.icon} />}
          key={menu.key}
          disabled={menu.disabled}
          title={
            <>
              {/* {menu.icon && <LegacyIcon type={menu.icon} />} */}
              {menu.component && SpeComponent ? <SpeComponent text={menu.text} /> : menu.text}
              {menu.disabled && <WIPIcon />}
            </>
          }
        >
          {renderMenuItems(menu.subMenu)}
        </SubMenu>
      );
    } else {
      resultJSX.push(
        <Menu.Item icon={<LegacyIcon type={menu.icon} />} key={menu.key} disabled={menu.disabled}>
          <>
            {/* {menu.icon && <LegacyIcon type={menu.icon} />} */}
            {menu.component && SpeComponent ? <SpeComponent text={menu.text} /> : menu.text}
            {menu.disabled && <WIPIcon />}
          </>
        </Menu.Item>
      );
    }
  });

  return resultJSX;
};

const CustomSideMenu = ({ me, replace, ...props }) => {
  const onMenuItemClick = ({ keyPath }) => {
    const pushPath = `/${keyPath.reverse().join('/')}`;
    pushPath && replace('/dashboard' + pushPath);
  };

  const selectedKeys = _.get(props, 'location.pathname').split('/');

  return (
    <Sider
      width={240}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        boxShadow: '2px 0 6px rgba(0, 21, 41, 0.15)',
        zIndex: 15
      }}
    >
      <div
        style={{
          width: '240px',
          height: '64px',
          objectFit: 'contain',
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 21, 41, 0.15)'
        }}
      >
        <img
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
          src={logoImg}
          alt="Nailie"
        />
      </div>
      <Menu
        mode="inline"
        style={{ borderRight: 0 }}
        selectedKeys={selectedKeys}
        defaultOpenKeys={selectedKeys}
        onClick={onMenuItemClick}
      >
        {renderMenuItems(
          me.role === 'ADMIN' ? MenuRoutes : MenuRoutes.filter(route => route.key !== 'admin-operator')
        )}
      </Menu>
    </Sider>
  );
};

CustomSideMenu.propTypes = {
  me: PropTypes.object,
  replace: PropTypes.any
};

const mapStateToProps = (state) => ({
  activeMenu: activeMenu(state),
  me: makeMe(state)
});

const mapDispatchToProps = (dispatch) => ({
  setActiveMenu: (newMenu) =>
    dispatch(setActiveMenu({ newActiveMenu: newMenu })),
  replace: (value) => dispatch(replace(value))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CustomSideMenu);
