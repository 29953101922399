import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_POPUP_BANNER_LIST,
  FETCH_POPUP_BANNER_LIST_SUCCESS,
  TOGGLE_POPUP_BANNER_STATUS_SUCCESS
} from './constants';

const initialState = {
  search: {
    list: [],
    loading: false,
    total: 0
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_POPUP_BANNER_LIST:
      state.search.loading = true;
      return state;
    case FETCH_POPUP_BANNER_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.list');
      state.search.total = _.get(action, 'payload.total') || state.search.total;
      state.search.loading = false;
      return state;
    case TOGGLE_POPUP_BANNER_STATUS_SUCCESS:
      state.search.list = state.search.list.map(item => {
        if (action?.payload?.id === item?.objectId) {
          return {
            ...item,
            status: action?.payload?.newStatus
          };
        } else {
          return item;
        }
      });
      return state;

    default:
      return state;
  }
});

export default reducer;
