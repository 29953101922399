import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ja';
import { DatePicker, Space, Table } from 'antd';
import Slot from './Slot';
import SlotBooking from './SlotBooking';

import { fetchNailistSlotSChedule } from 'providers/NailistProvider/actions';
import { formatWorkingTime } from 'utils/scheduleAndWorkTime';

import './style.scss';

moment.locale('ja');

const THIS_MONTH = moment();

const CELL_WIDTH = 92;

function scrollToDate(d) {
  const [ element ] = document.querySelectorAll('#nailist-schedule-table div.ant-table-content');

  element.scrollTo({
    top: 0,
    left: CELL_WIDTH * (d - 1),
    behavior: 'smooth'
  });
}

function makeColumns(scheduleColumns, minCanBookingHour) {
  const firstCol = {
    title: '日時',
    dataIndex: 'slotNum',
    width: CELL_WIDTH,
    fixed: 'left',
    render: (slotNum) => formatWorkingTime(slotNum)
  };

  const cols = scheduleColumns.map(d => ({
    title: <span className={'d' + moment(d.date, 'YYYY-MM-DD').day()}>{moment(d.date, 'YYYY-MM-DD').format('D (ddd)')}</span>,
    // dataIndex: 'onDate',
    width: CELL_WIDTH,
    render: ({ onDate: timeSlotSchedule_onDate, slotNum}) => {
      const slot = timeSlotSchedule_onDate[d.date];
      if (typeof slot === 'object') {
        return <SlotBooking {...slot}/>;
      }

      const slotInDateFormat = moment(`${d.date} ${formatWorkingTime(slotNum)}`, 'YYYY-MM-DD HH:mm').utcOffset(9, true);
      if ((minCanBookingHour === 0 ? slotInDateFormat.startOf('D') : slotInDateFormat.add('H', -1 * minCanBookingHour)) < moment()) {
        return <Slot slot={'-'}/>;
      }
      return (
        <Slot slot={slot === false ? 'O' : 'X'}/>
      );
    }
  }));

  return [firstCol, ...cols];
}

const NailistSlotSchedule = ({ nailistId, minCanBookingHour }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(THIS_MONTH);

  const scheduleColumns = useSelector(state => state?.nailist?.schedule?.schedule || []);
  const columns = useMemo(() => makeColumns(scheduleColumns, minCanBookingHour), [scheduleColumns]);
  useEffect(() => {
    const now = moment();
    if (columns.length > 1 && now.format('YYYY-MM') === month.format('YYYY-MM')) {
      scrollToDate(now.get('date'));
    } else {
      scrollToDate(1);
    }
  }, [columns]);

  const data = useSelector(state => state?.nailist?.schedule?.timeSlotSchedule || []);

  useEffect(() => {
    (async () => {
      if (nailistId) {
        setLoading(true);
        await dispatch(fetchNailistSlotSChedule({ userId: nailistId, months: [month.format('YYYY-MM')]}));
        setLoading(false);
      }
    })();    

  }, [month, nailistId]);

  return (
    <>
      <Space direction='vertical' align='center' style={{width: '100%', marginBottom: 16}}>
        <DatePicker
          allowClear={false}
          picker="month"
          onChange={setMonth}
          value={month}
        />
      </Space>
      <Table
        id="nailist-schedule-table"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: true }}
        bordered
        loading={loading}
      />
    </>
  );
};

NailistSlotSchedule.propTypes = {
  nailistId: PropTypes.string.isRequired
};

export default NailistSlotSchedule;
