import _ from 'lodash';

const Crumb = {
  Home: { label: 'Home', to: '/dashboard' },
  Nailist: { label: 'Nailist', to: '/dashboard/nailist' },
  NailistDisabled: { label: 'Disabled nailist ', to: '/dashboard/nailist-disabled' },
  NailistName: (nailistData) => ({
    label: _.get(nailistData, 'username') || 'loading',
    to: '/dashboard/nailist/' + _.get(nailistData, '_id')
  }),
  NailistSearch: { label: 'Search', to: '/dashboard/nailist/search' },
  Customer: { label: 'Customer', to: '/dashboard/customer' },
  CustomerSearch: { label: 'Search', to: '/dashboard/customer/search' },
  CustomerName: (customerData) => ({
    label: _.get(customerData, 'username') || 'loading',
    to: '/dashboard/customer/profile/' + _.get(customerData, '_id')
  }),
  AdminOperator: { label: 'Admin & Operators', to: '/dashboard/admin-operator' },
  Salon: { label: 'Salon', to: '/dashboard/salons' },
  SalonName: (salonData) => ({
    label: _.get(salonData, 'businessName') || 'loading',
    to: '/dashboard/salons/' + _.get(salonData, 'id')
  }),
  Receipt: { label: 'Receipt management', to: '/dashboard/receipts' },
  ReceiptDetail: (receiptId) => ({ label: receiptId, to: '/dashboard/receipts' }),
  Booking: { label: 'Booking', to: '/dashboard/booking-management' },
  BookingDetail: (bookingId) => ({ label: 'Booking Detail', to: `/dashboard/booking-management/${bookingId}`}),
  BookingSearch: { label: 'Search', to: '/dashboard/booking/search' },
  Schedule: { label: 'Schedule', to: '/dashboard/schedule' },
  Payout: { label: 'Payout', to: '/dashboard/payout/search' },
  PayoutSearch: { label: 'Search', to: '/dashboard/payout/search' },
  Setting: { label: 'Setting', to: '/dashboard/setting/booking-fee' },
  SettingBookingFee: {
    label: 'Booking Fee',
    to: '/dashboard/setting/booking-fee'
  },
  Announcement: {
    label: 'Announcement',
    to: '/dashboard/announcement'
  },
  CreateAnnouncement: {
    label: 'Create Announcement',
    to: '/dashboard/announcement/create'
  },
  EditAnnouncement: {
    label: 'Edit Announcement',
    to: '/dashboard/announcement/edit'
  },
  Banner: {
    label: 'Banner',
    to: '/dashboard/banner'
  },
  CreateBanner: {
    label: 'Create Banner',
    to: '/dashboard/banner/create'
  },
  EditBanner: {
    label: 'Edit Banner',
    to: '/dashboard/banner/edit'
  },
  PromoPopupBanner: {
    label: 'Promotion Popup',
    to: '/dashboard/popup-banner'
  },
  CreatePromoPopupBanner: {
    label: 'Create Promotion Popup',
    to: '/dashboard/popup-banner/create'
  },
  EditPromoPopupBanner: {
    label: 'Edit Promotion Popup',
    to: '/dashboard/popup-banner/edit'
  },
  CampaignInfo: {
    label: 'Campaign Info',
    to: '/dashboard/campaign-info/go-to-nail'
  },
  FriendInvitation: {
    label: 'Friend Invitation',
    to: '/dashboard/campaign-info/friend-invitation'
  },
  GoToNail: {
    label: 'Go-to-nail',
    to: '/dashboard/campaign-info/go-to-nail'
  },
  Olympic: {
    label: 'Olympic',
    to: '/dashboard/campaign-info/olympic'
  },
  GoToNail2: {
    label: 'Go-to-nail-2nd',
    to: '/dashboard/campaign-info/go-to-nail-2'
  },
  GoToNail3: {
    label: 'Go-to-nail-3rd',
    to: '/dashboard/campaign-info/go-to-nail-3'
  },
  InstagramCoupon: {
    label: 'Instagram',
    to: '/dashboard/campaign-info/instagram'
  },
  Support: { label: 'Support', to: '/dashboard/support/salon' },
  SupportApp: {
    label: 'Support App',
    to: '/dashboard/support/app'
  },
  SupportWeb: {
    label: 'Support web',
    to: '/dashboard/support/web'
  },
  SupportSalon: {
    label: 'Support Salon',
    to: '/dashboard/support/salon'
  },
  RewardPoints: {
    label: 'Reward Points',
    to: '/dashboard/reward-points'
  },
  SupportVerification: {
    label: 'Support Verification',
    to: '/dashboard/support/verification'
  },
  DeletedAccount: { label: 'Deleted Account', to: '/dashboard/deleted-account' },
  DeletedNailist: {
    label: 'Nailist',
    to: '/dashboard/deleted-account/deleted-nailist'
  },
  DeletedCustomer: {
    label: 'Customer',
    to: '/dashboard/deleted-account/deleted-customer'
  },
  Report: { label: 'Report management', to: '/dashboard/support/report' },
  ReportDetail: (id) => ({ label: id, to: `/dashboard/support/report/${id}`})
};

export default {
  dashboard: () => [],
  nailist: () => [Crumb.Nailist],
  nailistDisabled: () => [Crumb.NailistDisabled],
  customer: () => [Crumb.Customer],
  customerProfile: (customerData) => [
    Crumb.Customer,
    Crumb.CustomerName(customerData)
  ],
  customerSearch: () => [Crumb.Customer, Crumb.CustomerSearch],
  adminOperator: () => [Crumb.AdminOperator],
  salon: () => [Crumb.Salon],
  salonProfile: (salonData) => [
    Crumb.Salon,
    Crumb.SalonName(salonData)
  ],
  receipt: () => [Crumb.Receipt],
  receiptDetail: (receiptId) => [Crumb.Receipt, Crumb.ReceiptDetail(receiptId)],
  booking: () => [Crumb.Booking],
  bookingDetail: (bookingId) => [Crumb.Booking, Crumb.BookingDetail(bookingId)],
  bookingSearch: () => [Crumb.Booking, Crumb.BookingSearch],
  schedule: () => [Crumb.Schedule],
  nailistProfile: (nailistData) => [
    Crumb.Nailist,
    Crumb.NailistName(nailistData)
  ],
  nailistProfileDisable: (nailistData) => [
    Crumb.NailistDisabled,
    Crumb.NailistName(nailistData)
  ],
  nailistSearch: () => [Crumb.Nailist, Crumb.NailistSearch],
  payoutSearch: () => [Crumb.Payout, Crumb.PayoutSearch],
  settingBookingFee: () => [Crumb.Setting, Crumb.SettingBookingFee],
  friendInvitation: () => [Crumb.CampaignInfo, Crumb.FriendInvitation],
  goToNail: () => [Crumb.CampaignInfo, Crumb.GoToNail],
  olympic: () => [Crumb.CampaignInfo, Crumb.Olympic],
  goToNail2: () => [Crumb.CampaignInfo, Crumb.GoToNail2],
  goToNail3: () => [Crumb.CampaignInfo, Crumb.GoToNail3],
  instagramCoupon: () => [Crumb.CampaignInfo, Crumb.InstagramCoupon],
  supportWeb: () => [Crumb.Support, Crumb.SupportWeb],
  SupportSalon: () => [Crumb.Support, Crumb.SupportSalon],
  rewardPoints: () => [Crumb.RewardPoints],
  announcement: () => [Crumb.Announcement],
  createAnnouncement: () => [Crumb.Announcement, Crumb.CreateAnnouncement],
  editAnnouncement: () => [Crumb.Announcement, Crumb.EditAnnouncement],
  banner: () => [Crumb.Banner],
  createBanner: () => [Crumb.Banner, Crumb.CreateBanner],
  editBanner: () => [Crumb.Banner, Crumb.EditBanner],
  promoPopupBanner: () => [Crumb.PromoPopupBanner],
  createPromoPopupBanner: () => [Crumb.PromoPopupBanner, Crumb.CreatePromoPopupBanner],
  editPromoPopupBanner: () => [Crumb.PromoPopupBanner, Crumb.EditPromoPopupBanner],
  supportApp: () => [Crumb.Support, Crumb.SupportApp],
  supportVerification: () => [Crumb.Support, Crumb.SupportVerification],
  deletedAccount: () => [Crumb.DeletedAccount],
  deletedNailist: () => [Crumb.DeletedAccount, Crumb.DeletedNailist],
  deletedCustomer: () => [Crumb.DeletedAccount, Crumb.DeletedCustomer],
  report: () => [Crumb.Support, Crumb.Report],
  reportDetail: (id) => [Crumb.Support, Crumb.Report, Crumb.ReportDetail(id)]
};
