import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_REWARD_POINT_LIST_SUCCESS,
  SET_REWARD_POINT_LIST_LOADING
} from './constants';

const initialState = {
  pointList: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_POINT_LIST_SUCCESS:
      state.pointList.list = _.get(action, 'payload.data.data');
      state.pointList.total = _.get(action, 'payload.data.total');
      return state;
    case SET_REWARD_POINT_LIST_LOADING:
      state.pointList.loading = _.get(action, 'payload.loading');
      return state;
    default:
      return state;
  }
});

export default reducer;
