import { useMemo } from 'react';
import { BOOKING_STATUS } from 'utils/constants';
import { CHANGING_BK_STATUS } from 'utils/constants/changingBKStatusEnum';

const useChangingStatusEnum = ({ status, penalty }) => {
  const statusEnum = useMemo(() => {
    if (status === BOOKING_STATUS.DONE) {
      return CHANGING_BK_STATUS.DONE;
    }

    if (status === BOOKING_STATUS.UNDONE) {
      return CHANGING_BK_STATUS.UNDONE;
    }

    if (status === BOOKING_STATUS.CANCELED_REQUEST) {
      // return CHANGING_BK_STATUS.CANCELED_REQUEST;
      return undefined;
    }

    if (status === BOOKING_STATUS.DECLINED_RESERVATION) {
      return CHANGING_BK_STATUS.DECLINED_RESERVATION;
    }
    if (status === BOOKING_STATUS.NAILIST_CANCELED) {
      return CHANGING_BK_STATUS.NAILIST_CANCELED;
    }

    if (status === BOOKING_STATUS.CANCELED_RESERVATION) {
      switch (penalty) {
        case undefined: case 0:
          return CHANGING_BK_STATUS.CANCELED_RESERVATION;
        case 0.5:
          return CHANGING_BK_STATUS.CANCELED_RESERVATION_50_PERCENT;
        case 1:
          return CHANGING_BK_STATUS.CANCELED_RESERVATION_100_PERCENT;
      }
    }

    if (status === BOOKING_STATUS.NOVISIT) {
      switch (penalty) {
        case 0:
          return CHANGING_BK_STATUS.NOVISIT;
        case 0.5:
          return CHANGING_BK_STATUS.NOVISIT_50_PERCENT;
        case 1: case undefined:
          return CHANGING_BK_STATUS.NOVISIT_100_PERCENT;
      }
    }
  }, [status, penalty]);

  return [
    statusEnum
  ];
};

export default useChangingStatusEnum;