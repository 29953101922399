import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import layout from 'providers/LayoutProvider/reducer';
import nailist from 'providers/NailistProvider/reducer';
import payout from 'providers/PayoutProvider/reducer';
import auth from 'providers/AuthProvider/reducer';
import popup from 'providers/PopupProvider/reducer';
import booking from 'providers/BookingProvider/reducer';
import setting from 'providers/SettingProvider/reducer';
import salon from 'providers/SalonProvider/reducer';
import receipt from 'providers/ReceiptProvider/reducer';
import bank from 'providers/BankProvider/reducer';
import friendInvitation from 'providers/FriendInvitationProvider/reducer';
import accountNoBank from 'providers/AccountNoBankProvider/reducer';
import support from 'providers/SupportProvider/reducer';
import rewardPoint from 'providers/RewardPointsProvider/reducer';
import deletedAccount from 'providers/DeletedAccountProvider/reducer';
import customer from 'providers/CustomerProvider/reducer';
import station from 'providers/StationProvider/reducer';
import user from 'providers/UserProvider/reducer';
import announcement from 'providers/AnnouncementProvider/reducer';
import banner from 'providers/BannerProvider/reducer';
import popupBanner from 'providers/PopupBannerProvider/reducer';
import bookingOnSchedule from 'providers/BookingOnScheduleProvider/reducer';

export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    layout,
    nailist,
    payout,
    auth,
    popup,
    booking,
    setting,
    salon,
    receipt,
    bank,
    friendInvitation,
    accountNoBank,
    support,
    rewardPoint,
    deletedAccount,
    customer,
    station,
    user,
    announcement,
    popupBanner,
    banner,
    bookingOnSchedule
  });
}
