import { put, takeLeading, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import request from 'utils/request';
import {
  FETCH_ADMIN_OPERATOR_LIST,
  CREATE_ADMIN_OPERATOR,
  UPDATE_ADMIN_OPERATOR,
  DELETE_ADMIN_OPERATOR
} from './constants';
import { fetchAdminOperatorList as fetchAdminOperatorListAction, fetchAdminOperatorListSuccess, updateAdminOperatorSuccess } from './actions';

function* fetchAdminOperatorList(action) {
  try {
    const filter = action.payload;
    const { data } = yield call(request, '/users', { ...filter, limit: 10 }, 'GET');
    yield put(fetchAdminOperatorListSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* createAdminOperator(action) {
  try {
    const { search, callback, ...data } = action.payload;
    yield call(request, '/users', data, 'POST');
    yield put(fetchAdminOperatorListAction(search));
    message.success('created!');
    callback();
  } catch (e) {
    message.error('Account already exists for this username.');
    console.log(e);
  }
}

function* updateAdminOperator(action) {
  try {
    const { _id, search, callback, ...data } = action.payload;
    yield call(request, `/users/${_id}`, data, 'PUT');
    yield put(updateAdminOperatorSuccess(data, action.meta));
    yield put(fetchAdminOperatorListAction(search));
    message.success('updated!');
    callback();
  } catch (e) {
    message.error('Account already exists for this username.');
    console.log(e);
  }
}

function* deleteAdminOperator(action) {
  try {
    const { id, ...search } = action.payload;
    yield call(request, `/users/${id}`, {}, 'DELETE');
    yield put(fetchAdminOperatorListAction(search));
    message.success('deleted!');
  } catch (e) {
    console.log(e);
  }
}

export default function* watchUser() {
  yield takeLatest(FETCH_ADMIN_OPERATOR_LIST, fetchAdminOperatorList);
  yield takeLeading(CREATE_ADMIN_OPERATOR, createAdminOperator);
  yield takeLeading(UPDATE_ADMIN_OPERATOR, updateAdminOperator);
  yield takeEvery(DELETE_ADMIN_OPERATOR, deleteAdminOperator);
}
