import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { BOOKING_STATUS } from 'utils/constants';

const {
  REQUESTED,
  CONFIRMED,
  WAITING_DONE,
  EXPIRED,
  CANCELED_REQUEST,
  CANCELED_RESERVATION,
  DECLINED_REQUEST,
  DECLINED_RESERVATION,
  DONE,
  NOVISIT,
  NAILIST_CANCELED,
  UNDONE,
  NAILIE_CANCELED
} = BOOKING_STATUS;

const color = {
  [REQUESTED]: 'cyan',
  [DECLINED_REQUEST]: 'geekblue',
  [CANCELED_REQUEST]: 'geekblue',
  [EXPIRED]: 'volcano',
  [CONFIRMED]: 'blue',
  [DONE]: 'green',
  [WAITING_DONE]: 'red',
  [CANCELED_RESERVATION]: 'orange',
  [DECLINED_RESERVATION]: 'orange',
  [NAILIST_CANCELED]: 'magenta',
  [NOVISIT]: 'gold',
  [UNDONE]: 'purple',
  [NAILIE_CANCELED]: ''
};

const displayStatus = {
  [NAILIE_CANCELED]: '事務局キャンセル',
  [REQUESTED]: 'リクエスト中',
  [CONFIRMED]: '確定中',
  [WAITING_DONE]: 'お会計待ち',
  [DONE]: '施術完了',
  [CANCELED_REQUEST]: 'カスタマーキャンセル（リクエスト）',
  [CANCELED_RESERVATION]: 'カスタマーキャンセル（確定済み予約）',
  [DECLINED_REQUEST]: 'ネイリストキャンセル（リクエスト）',
  [DECLINED_RESERVATION]: 'ネイリストキャンセル（施術前予約）',
  [NAILIST_CANCELED]: 'ネイリストキャンセル（施術後）',
  [NOVISIT]: '不来店',
  [UNDONE]: '未施術',
  [EXPIRED]: '期限切れ'
};
const BookingStatusTag = ({ status }) => (
  <Tag color={color[status]}>{displayStatus[status] || status}</Tag>
);

BookingStatusTag.propTypes = {
  status: PropTypes.string.isRequired
};

export default BookingStatusTag;
