import React from 'react';
import PropTypes from 'prop-types';
import { Space, Avatar, Typography } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const BookingReviewComment = ({ avatarThumbnail, username, commentAt, comment }) => {
  return (
    <Space direction="vertical" size={16}>
      <Space>
        <Avatar src={avatarThumbnail} className='booking-review-comment-avatar' />
        <Text strong>{username}</Text>
        <div className='booking-review-comment-date'>{moment(commentAt).format('YYYY/MM/DD')}</div>
      </Space>

      <div>{comment}</div>
    </Space>
  );
};

BookingReviewComment.propTypes = {
  avatarThumbnail: PropTypes.string,
  username: PropTypes.string,
  commentAt: PropTypes.string,
  comment: PropTypes.string
};

export default BookingReviewComment;
